import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class CountDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countDownText: '--:--',
    }
    this.countDown = this.countDown.bind(this)
  }

  componentDidMount() {
    this.startInverval()
  }

  componentDidUpdate(prevProps) {
    const { eventTime } = this.props
    if (eventTime > 0 && eventTime !== prevProps.eventTime) {
      this.startInverval()
    }
  }

  componentWillUnmount() {
    const { countDownInterval } = this.state
    clearInterval(countDownInterval)
  }

  startInverval() {
    const countDownInterval = setInterval(this.countDown, 1000)
    this.setState({
      countDownInterval
    })
  }

  countDown() {
    const { countDownInterval } = this.state
    const { eventTime } = this.props
    let durationInSeconds = moment.unix(eventTime) - moment()
    if (durationInSeconds < 0) {
      durationInSeconds = 0
    }
    const duration = moment.duration(durationInSeconds, 'milliseconds')
    this.setState({
      countDownText: moment.utc(duration.asMilliseconds()).format(
        durationInSeconds >= 60 * 60 * 1000 ? 'HH:mm:ss' : 'mm:ss'
      )
    })
    if (duration.asMilliseconds() <= 0) {
      clearInterval(countDownInterval)
    }
  }

  render() {
    const { countDownText } = this.state
    return (
      <b>
        {countDownText}
      </b>
    )
  }
}

CountDown.propTypes = {
  eventTime: PropTypes.number.isRequired,
}

export default CountDown
