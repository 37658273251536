import moment from 'moment'
import _ from 'lodash'

// UTILS
import * as utilBookingLocations from './locations/bookingLocations'
import * as utilBookingExtraServices from './bookingExtraServices'
import * as ultilTimeTypeLocation from './locations/verifyAfterChoosingTimeType'
import phoneNumberUtils from '../common/phoneNumber'
import {
  SUPPORTED_DATETIME_FORMATS, STEP_UPLOAD_TAB_ADVANCED, CREATED_FROM
} from 'constants/ezSpreadSheetConstants'
import { FULL_DAY, FULL_DAY_AMOUNT_DEFAULT, LONG_HAUL, PHOTO_ATTACHMENT_NUMBER } from 'constants/bookingConstants'
import { autoGenerateObjectNameWithDate } from '../common/batch'
import { minuMumBufferMinutesEZ } from './common'
import I18n from 'i18n/i18n'

const collectBookingsArr = (excelMatrix, batchTemplate) => {
  const bookingRows = {}
  if (batchTemplate.consolidates) {
    const consolidator = batchTemplate.mapping.consolidates
    for (let i = 1; i < _.size(excelMatrix); i += 1) {
      const excelRow = excelMatrix[i]
      let bkKey = excelRow[consolidator]
      if (_.isEmpty(bkKey)) {
        bkKey = 'Empty'
      }
      if (_.isEmpty(bookingRows[bkKey])) {
        bookingRows[bkKey] = []
      }
      bookingRows[bkKey].push(excelRow)
    }
  } else {
    bookingRows[0] = _.slice(excelMatrix, 1)
  }
  return bookingRows
}

const buildBookingExcelConsolidate = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const consolidate = bookingArr[i][mapping.consolidates]
      if (!_.isEmpty(_.trim(consolidate))) {
        return _.trim(consolidate)
      }
    }
  }
  return null
}

const getIDFromString = (mappingString) => {
  if (!_.isUndefined(mappingString)) {
    return _.toInteger(mappingString.split(' ')[0])
  }
  return ''
}

const buildBookingExcelVehicleTypeID = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const vehicleTypeIDString = _.trim(bookingArr[i][mapping.vehicle_type])
      if (vehicleTypeIDString.length > 0) {
        return getIDFromString(_.trim(vehicleTypeIDString))
      }
    }
  }
  return null
}

const buildBookingJobOrderNumber = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  let orderNumbers = []
  if (!_.isEmpty(mapping)) {
    _.forEach(bookingArr, (rowData) => {
      if (!_.isEmpty(rowData[mapping.job_order_number])) {
        orderNumbers = _.concat(orderNumbers, rowData[mapping.job_order_number])
      }
    })
  }
  return orderNumbers.join('; ')
}

const buildBookingNoteToDriver = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  let notes = []
  if (!_.isEmpty(mapping)) {
    _.forEach(bookingArr, (rowData) => {
      if (!_.isEmpty(rowData[mapping.note_to_driver])) {
        notes = _.concat(notes, rowData[mapping.note_to_driver])
      }
    })
  }
  return _.size(notes) > 0 ? notes.join('; ') : params.extraInfos.default_note
}

const setDefaultDropoffLocation = (params, countryCode = {}) => {
  const batchTemplate = params.batchTemplate
  const currentPickupContact = params.currentPickupContact
  const newLocation = params.newLocation
  const currentCustomer = params.currentCustomer
  const extraInfos = params.extraInfos
  let location = { ...newLocation }
  location = _.assign(
    {},
    location,
    {
      order: 0,
      temp_id: [Math.random().toString(36).slice(2), '-01'].join('-'),
    }
  )
  location = utilBookingLocations.checkAutoSettingCOPPOD(location, currentCustomer, extraInfos)
  if (batchTemplate.same_pickup && !_.isEmpty(currentPickupContact)) {
    const result = phoneNumberUtils.formatPhoneNumber(
      currentPickupContact.phone,
      countryCode,
      true
    )
    location = _.assign(
      {},
      location,
      {
        lat: currentPickupContact.latitude,
        lng: currentPickupContact.longitude,
        name: currentPickupContact.address,
        recipient_name: currentPickupContact.name,
        recipient_phone: result.phoneNumber || '',
        address_components: currentPickupContact.address_components,
        location_note: currentPickupContact.address_detail,
        verify: { name: true, recipient_name: true, recipient_phone: true },
        contact_type: currentPickupContact.contact_type,
        phone_mask: currentPickupContact.phone_mask,
      }
    )
  }
  return location
}

const buildBookingLocations = (bookingArr, params) => {
  let locations = []
  const {
    extraInfos,
    currentCustomer,
  } = params
  const excludePCODColumn = !currentCustomer.authentication_token || !currentCustomer.current_company_id
  const countryCode = extraInfos.country_code || _.toLower(currentCustomer.country_code)
  locations[0] = setDefaultDropoffLocation(params, countryCode)

  _.forEach(bookingArr, (rowData, rowIndex) => {
    if (!_.isEmpty(rowData)) {
      const locationParams = {
        excelRow: rowData,
        rowIndex: rowIndex + 1,
        batchTemplate: params.batchTemplate,
        newLocation: params.newLocation,
        currentCustomer: params.currentCustomer,
        extraInfos: params.extraInfos,
        excludePCODColumn,
      }
      const location = utilBookingLocations.matrixRowToLocationObject(locationParams, countryCode)
      locations = _.concat(locations, location)
    }
  })
  return locations
}

export const setBookingDefaultPayer = (booking, currentCustomer) => {
  const tempBooking = { ...booking }
  if (!currentCustomer.current_company_id) {
    const locations = [...tempBooking.locations]
    if (_.size(locations) > 1) {
      const isDefaultPayer = currentCustomer.default_payer_is_destination
      const index = _.findIndex(locations, location => location.is_payer === true)
      if (index === -1) {
        if (isDefaultPayer) {
          locations[_.size(locations) - 1].is_payer = true
        } else {
          locations[0].is_payer = true
        }
      } else {
        locations[index].is_payer = true
      }
      tempBooking.locations = locations
    }
  }
  return tempBooking
}

export const buildBookingsPayer = (bookings, currentCustomer) => {
  let tempBookings = []
  for (let i = 0; i < _.size(bookings); i += 1) {
    tempBookings = _.concat(tempBookings, setBookingDefaultPayer(bookings[i], currentCustomer))
  }
  return tempBookings
}

export const checkUnderLimitedAmount = (booking, params) => {
  const bookingTemp = utilBookingLocations.checkBookingCODLimitAmount(
    booking,
    params.currentCustomer,
    params.extraInfos
  )
  return bookingTemp
}

const buildBookingExcelExtraRequirement = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const excelExtraRequirements = _.trim(bookingArr[i][mapping.extra_requirements])
      if (excelExtraRequirements.length > 0) {
        return _.trim(excelExtraRequirements)
      }
    }
  }
  return null
}

const buildBookingExcelOtherReimbursements = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const otherReimbursements = _.trim(bookingArr[i][mapping.other_reimbursements])
      if (otherReimbursements.length > 0) {
        return _.trim(otherReimbursements)
      }
    }
  }
  return null
}

const renderEmptyAttachmentHash = () => {
  const attachment = {
    tmpID: _.uniqueId(),
    id: undefined,
    documentURL: undefined,
    documentType: undefined,
    documentFileName: undefined,
    allowToDelete: true,
    file: undefined,
    isAutoAttachment: false
  }
  return attachment
}

const renderExistingAttachmentHash = (attachment) => {
  const existAttch = {
    tmpID: _.uniqueId(),
    id: attachment.id,
    documentURL: attachment.document_url,
    allowToDelete: attachment.allow_to_delete,
    documentType: attachment.document_content_type,
    file: undefined,
    isAutoAttachment: true
  }
  return existAttch
}

export const buildBookingAttachments = (booking, attachments) => {
  const attachmentsAttributes = []
  let currentATMs
  const bookingAttachments = booking.bookingAttachmentsAttributes
  if (attachments.length > 0) {
    currentATMs = attachments
  } else if (!_.isUndefined(bookingAttachments) && bookingAttachments.length > 0) {
    currentATMs = booking.bookingAttachmentsAttributes
  }
  if (_.isUndefined(currentATMs)) {
    for (let i = 0; i < PHOTO_ATTACHMENT_NUMBER; i += 1) {
      attachmentsAttributes.push(renderEmptyAttachmentHash())
    }
  } else {
    currentATMs.map((attachment) => {
      attachmentsAttributes.push(renderExistingAttachmentHash(attachment))
    })
  }
  // These codes to make sure that every booking will have enough 3 attachments
  // When auto setting only have less than 3 photos or pdf files, the rest will be added (empty attachment(s))
  if (attachmentsAttributes.length < PHOTO_ATTACHMENT_NUMBER) {
    const missingATMNumber = PHOTO_ATTACHMENT_NUMBER - attachmentsAttributes.length
    for (let j = 1; j <= missingATMNumber; j += 1) {
      attachmentsAttributes.push(renderEmptyAttachmentHash())
    }
  }
  return attachmentsAttributes
}

export const getAttachmentIDs = (attachments) => {
  const attachmentIds = []
  if (attachments.length > 0) {
    attachments.map((attachment) => {
      attachmentIds.push(attachment.id)
      return true
    })
  }
  return attachmentIds
}

export const buildRelatedAttachmentIDs = (params) => {
  const autoSettingATMs = _.get(params.currentCustomer, 'auto_settings.attachments', [])
  return getAttachmentIDs(autoSettingATMs)
}

export const countingExistingAttachments = (attachments) => {
  let counting = 0
  attachments.map((attachment) => {
    counting += _.isUndefined(attachment.documentType) ? 0 : 1
  })
  return counting
}

const checkBookingTimeType = (timeType, params) => {
  if (!_.isEmpty(_.trim(timeType))) {
    const timeTypeOptions = params.extraInfos.booking_time_type_options
    const timeTypeKeys = _.keys(timeTypeOptions)
    const timeTypeValues = _.values(timeTypeOptions)
    const batchBookingTimeTypes = params.extraInfos.batch_booking_time_types
    for (let i = 0; i < _.size(timeTypeValues); i += 1) {
      if (_.toNumber(timeTypeValues[i]) === _.toNumber(timeType) && batchBookingTimeTypes[timeTypeKeys[i]]) {
        return {
          type_key: timeTypeKeys[i],
          type_value: timeTypeValues[i],
          fullday_selected_amount: FULL_DAY_AMOUNT_DEFAULT
        }
      }
    }
  }
  return null
}

const buildBookingExcelTimeType = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const timeType = getIDFromString(bookingArr[i][mapping.time_type])
      const bookingTimeType = checkBookingTimeType(timeType, params)
      if (!_.isEmpty(bookingTimeType)) {
        return bookingTimeType
      }
    }
  }
  return null
}
// ===== check buffer time at here =====

export const convertTextInputToPickupTime = (booking, textInput, extraInfos, isBookAgain = false) => {
  if (!_.isEmpty(_.trim(textInput))) {
    let minDate
    if (_.isEmpty(booking.vehicle_type)) {
      minDate = moment().add(extraInfos.minimum_pickup_time_hours, 'hours')
    } else {
      const minimumPickupTime = minuMumBufferMinutesEZ(booking, extraInfos, isBookAgain)
      minDate = moment().add(minimumPickupTime, 'minutes')
    }
    const time = moment(textInput, SUPPORTED_DATETIME_FORMATS, true)
    const maxDate = moment().endOf('day').add(extraInfos.maximum_pickup_time_days, 'days')
    const pickupTime = (time.isValid() && time.isBetween(minDate, maxDate)) ? time.format() : minDate.format()
    return pickupTime
  }
  return undefined
}

const buildBookingExcelPickupDateTime = (booking, bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      const pickupDateTime = bookingArr[i][mapping.pickup_date_time]
      if (!_.isEmpty(_.trim(pickupDateTime))) {
        return convertTextInputToPickupTime(booking, pickupDateTime, params.extraInfos)
      }
    }
  }
  return undefined
}

const buildBookingTime = (booking, bookingArr, params) => {
  const timeType = buildBookingExcelTimeType(bookingArr, params)
  const pickupTime = buildBookingExcelPickupDateTime(booking, bookingArr, params)
  const updateBooking = booking
  if (timeType) {
    updateBooking.time_type_option = timeType
    updateBooking.verify.timeType = true
  }
  if (pickupTime) {
    updateBooking.pickup_date_time = pickupTime
    updateBooking.verify.pickupTime = true
  }
  return updateBooking
}

const buildBookingExcelSubAccountTag = (bookingArr, params) => {
  const mapping = params.batchTemplate.mapping
  if (!_.isEmpty(mapping)) {
    for (let i = 0; i < _.size(bookingArr); i += 1) {
      return getIDFromString(bookingArr[i][mapping.sub_account_tag])
    }
  }
  return null
}

const buildBookingSubAccountTag = (booking, bookingArr, params) => {
  const { subAccountTagList } = params
  const subAccountTag = buildBookingExcelSubAccountTag(bookingArr, params)
  const updateBooking = booking
  if (subAccountTagList && subAccountTagList.length) {
    updateBooking.sub_account_tag = subAccountTagList.find(item => item.sub_account_id === subAccountTag)
  }
  return updateBooking
}

const checkLimitBookingLocations = (booking) => {
  const dropOffLength = booking.locations.length
  if (dropOffLength > booking.max_number_of_location) {
    return false
  }
  return true
}

export const updateBookingLimitDropOff = (booking) => {
  const limitStatus = checkLimitBookingLocations(booking)
  const bookingTemp = { ...booking }
  if (booking.verify.limitDropOff !== limitStatus) {
    bookingTemp.verify.limitDropOff = limitStatus
  }
  return bookingTemp
}

export const checkReimbursentSetting = (booking, params, isChangeTimeType = false) => {
  const bookingTemp = booking
  if (!_.isEmpty(params.currentCustomer.reimbursement) && isChangeTimeType) {
    const timeType = booking.time_type_option?.type_key
    const reimbursementSettings = params.currentCustomer.reimbursement
    bookingTemp.allow_tolls_fees = reimbursementSettings.allow_tolls_fees
    bookingTemp.allow_parking_fees = reimbursementSettings.allow_parking_fees
    bookingTemp.allow_waiting_time_fees = timeType === LONG_HAUL
      ? params.currentCustomer.allow_waiting_time_fees_long_haul : reimbursementSettings.allow_waiting_time_fees
    if (booking.time_type_option?.type_key === FULL_DAY) {
      bookingTemp.allow_waiting_time_fees = false
    }
    if (booking.time_type_option?.type_key === LONG_HAUL) {
      bookingTemp.allow_tolls_fees = false
      bookingTemp.allow_parking_fees = false
    }
  }
  return bookingTemp
}

const initBookingInfo = (bookingArr, params) => {
  let booking = {}
  const currentCustomer = params.currentCustomer
  booking.verify = {
    vehicle: true,
    extraRequirement: true,
    timeType: true,
    pickupTime: true,
    locationCodPod: true,
    codLimitAmount: undefined
  }
  booking.temp_id = Math.random().toString(36).slice(2)
  booking.name = autoGenerateObjectNameWithDate(false)
  booking.is_expand = true
  booking.cash_back_reward = null
  booking.enough_data_check_cashback = false
  booking.consolidates = buildBookingExcelConsolidate(bookingArr, params)
  booking.excel_vehicle_type_id = buildBookingExcelVehicleTypeID(bookingArr, params)
  booking.excel_extra_requirements = buildBookingExcelExtraRequirement(bookingArr, params)
  booking.excel_other_reimbursements = buildBookingExcelOtherReimbursements(bookingArr, params)
  booking.job_order_number = buildBookingJobOrderNumber(bookingArr, params)
  booking.note_to_driver = buildBookingNoteToDriver(bookingArr, params)
  booking.favoriteDriverIsHighPriority = false
  booking.sendFirstToFavorite = false
  booking.marked_as_favorite = false
  booking.is_selected = false
  booking.is_new_empty = false
  booking.is_optimized = false
  booking.require_signatures = params.requireSignatures
  booking.max_number_of_location = currentCustomer.max_number_of_location
  booking.sendFirstToFavorite = params.extraInfos.sendToFavoriteFirstSetting
  booking.bookingAttachmentsAttributes = buildBookingAttachments(booking, _.get(currentCustomer, 'auto_settings.attachments', []))
  booking.totalUploadedAttchment = countingExistingAttachments(booking.bookingAttachmentsAttributes)
  booking.relatedAttachmentIDs = buildRelatedAttachmentIDs(params)
  booking.locations = buildBookingLocations(bookingArr, params)
  booking = updateBookingLimitDropOff(booking)
  booking = setBookingDefaultPayer(booking, currentCustomer)
  booking = checkUnderLimitedAmount(booking, params)
  return booking
}

export const defaultBookingTimeType = (params) => {
  const timeType = {
    type_key: params.extraInfos.default_time_type,
    type_value: params.extraInfos.booking_time_type_options[params.extraInfos.default_time_type],
    fullday_selected_amount: FULL_DAY_AMOUNT_DEFAULT
  }
  return timeType
}

const markEmptyBooking = (booking) => {
  const tempBooking = booking
  tempBooking.is_new_empty = true
  for (let i = 0; i < _.size(tempBooking.locations); i += 1) {
    tempBooking.locations[i].is_new_empty = true
  }
  return tempBooking
}

export const initNewEmptyBookingInfo = (params) => {
  const bookings = params.batch.bookings
  const defaultDropOff = setDefaultDropoffLocation(params)
  const emptyLocations = [defaultDropOff]
  const initBooking = initBookingInfo(emptyLocations, params)
  const emptyBooking = markEmptyBooking(initBooking)
  const reimBooking = checkReimbursentSetting(emptyBooking, params, true)
  reimBooking.order = bookings.length === 0 ? 0 : parseInt(bookings[bookings.length - 1].order, 10) + 1
  reimBooking.added_from = CREATED_FROM.new_booking
  return reimBooking
}

export const addNewEmptyBookingLocation = (booking) => {
  const tempBooking = booking
  const locations = tempBooking.locations
  const lSize = _.size(locations)
  const newEmptyLocation = { ...locations[lSize - 1] }
  const isFullDayBooking = ultilTimeTypeLocation.isFullDayBooking(booking.time_type_option)
  newEmptyLocation.temp_id = [Math.random().toString(36).slice(2), '-01'].join('-')
  newEmptyLocation.order = lSize
  newEmptyLocation.customer_id = undefined
  newEmptyLocation.company_id = undefined
  newEmptyLocation.is_payer = false
  newEmptyLocation.lat = undefined
  newEmptyLocation.lng = undefined
  newEmptyLocation.name = undefined
  newEmptyLocation.formatted_address = undefined
  newEmptyLocation.recipient_name = undefined
  newEmptyLocation.recipient_phone = undefined
  newEmptyLocation.location_note = undefined
  newEmptyLocation.cod_note = undefined
  newEmptyLocation.pod_note = undefined
  newEmptyLocation.email = undefined
  newEmptyLocation.is_new_empty = true
  newEmptyLocation.address_components = undefined
  if (isFullDayBooking) {
    newEmptyLocation.verify = { name: true, recipient_name: true, recipient_phone: true }
  }
  locations[lSize] = newEmptyLocation
  tempBooking.locations = locations
  tempBooking.is_optimized = false
  return tempBooking
}

export const hasEmptyBookingPickupTime = (batch) => {
  if (_.isEmpty(batch.bookings)) {
    return false
  }
  for (let i = 0; i < _.size(batch.bookings); i += 1) {
    if (_.isEmpty(batch.bookings[i].pickup_date_time)) {
      return true
    }
  }
  return false
}

export const changeBookingNewEmptyRequire = (booking, location = {}) => {
  const bookingTemp = booking
  bookingTemp.is_new_empty = false
  if (!_.isEmpty(location)) {
    for (let i = 0; i < _.size(booking.locations); i += 1) {
      if (booking.locations[i].temp_id === location.temp_id) {
        bookingTemp.locations[i].is_new_empty = false
        return booking
      }
    }
  } else {
    for (let i = 0; i < _.size(booking.locations); i += 1) {
      bookingTemp.locations[i].is_new_empty = false
    }
  }
  return bookingTemp
}

export const isShowedToggleEntireBatch = (batch) => {
  if (_.isEmpty(batch.bookings) || _.size(batch.bookings) < 2) {
    return false
  }
  const timeTypeArr = []
  const vehicleTypeArr = []
  _.forEach(batch.bookings, (booking) => {
    const vehicleType = booking.vehicle_type
    const timeType = booking.time_type_option
    if (!_.isUndefined(timeType) && _.size(timeType) > 0) {
      timeTypeArr.push(timeType.type_value)
    }
    if (!_.isUndefined(vehicleType) && !_.isEmpty(vehicleType.name)) {
      vehicleTypeArr.push(vehicleType.name)
    }
  })
  if (_.size(_.uniq(timeTypeArr)) > 1 || _.size(_.uniq(vehicleTypeArr)) > 1) {
    return false
  }
  return true
}

export const mapOtherReimbursement = (booking) => {
  const otherReimbursements = booking.excel_other_reimbursements || ''
  const arrReimbursement = otherReimbursements.replace(' ', '').split(',')
  const customReimbursements = booking.customReimbursements
  arrReimbursement.forEach((reimbursement) => {
    const reimbursementInfo = reimbursement.split('-')
    const matchReimbursement = customReimbursements.find(reim => reim.id === +reimbursementInfo[0])
    if (matchReimbursement) {
      matchReimbursement.selected_amount = 0
      // Reimbursement user input amount
      if (matchReimbursement.allow_user_to_enter_amount) {
        matchReimbursement.selected_amount = +reimbursementInfo[1] ? 1 : 0
        matchReimbursement.user_input_amount = +reimbursementInfo[1] || 0
      }
      // Reimbursement check-box
      if (!matchReimbursement.allow_user_to_enter_amount && matchReimbursement.unit === 1) {
        matchReimbursement.selected_amount = 1
      }
      // Reimbursement select-box
      if (!matchReimbursement.allow_user_to_enter_amount && matchReimbursement.unit > 1) {
        if (+reimbursementInfo[1] > matchReimbursement.unit) {
          matchReimbursement.selected_amount = matchReimbursement.unit
        } else if (+reimbursementInfo[1]) {
          matchReimbursement.selected_amount = +reimbursementInfo[1]
        }
      }
    }
  })
  return booking
}

const buildBookingObject = (bookingArr, params) => {
  let booking = {}
  if (_.size(bookingArr) > 0) {
    booking = initBookingInfo(bookingArr, params)
    booking.added_from = CREATED_FROM.new_batchez
    booking = buildBookingTime(booking, bookingArr, params)
    booking = utilBookingExtraServices.buildVehicleExtraService(booking, params)
    booking = checkReimbursentSetting(booking, params, true)
    booking = mapOtherReimbursement(booking)
    booking = buildBookingSubAccountTag(booking, bookingArr, params)
  }
  return booking
}

export const matrixToBookings = (params) => {
  const bookingsArr = collectBookingsArr(params.excelMatrix, params.batchTemplate)
  let bookings = []
  _.forEach(bookingsArr, (bookingArr, bkIndex) => {
    const booking = buildBookingObject(bookingArr, params)
    booking.order = bkIndex
    bookings = _.concat(bookings, booking)
  })
  return bookings
}

const collectEZsheetName = (currentTab) => {
  let bookingNames = []
  currentTab.uploadedFiles.map((files) => {
    files.sheets.map((sheet) => {
      bookingNames = _.concat(bookingNames, sheet.sheetName)
    })
  })
  return bookingNames
}

export const buildBatchBookingsName = (bookings, currentTab) => {
  const bookingNames = collectEZsheetName(currentTab)
  _.forEach(bookings, (booking, index) => {
    const tempBooking = booking
    if (currentTab.tabName === STEP_UPLOAD_TAB_ADVANCED || _.isEmpty(bookingNames)) {
      tempBooking.name = autoGenerateObjectNameWithDate(false)
    } else {
      tempBooking.name = bookingNames[index]
    }
  })
  return bookings
}

export const buildDuplicateBooking = (batch) => {
  const bookings = [...batch.bookings]
  const selectedBookings = _.filter(bookings, { is_selected: true })
  let duplicateBookings = []
  _.forEach(selectedBookings, (booking, index) => {
    _.assign(booking, { is_selected: false })
    const newBooking = { ...booking }
    const newVerify = { ...booking.verify }
    let extraRequirements = []
    _.forEach(newBooking.extra_requirements, (extraRequirement) => {
      const newExtra = { ...extraRequirement }
      extraRequirements = _.concat(extraRequirements, newExtra)
    })
    newBooking.extra_requirements = extraRequirements
    newBooking.temp_id = Math.random().toString(36).slice(2)
    newBooking.order = _.parseInt(bookings[bookings.length - 1].order) + 1 + index
    newBooking.consolidates = _.parseInt(bookings[bookings.length - 1].consolidates) + 1 + index
    newBooking.draft_id = undefined
    let newLocations = []
    _.forEach(newBooking.locations, (location) => {
      const newLocation = { ...location }
      const newLocationVerify = { ...location.verify }
      newLocation.temp_id = [Math.random().toString(36).slice(2), '-01'].join('-')
      newLocation.verify = newLocationVerify
      newLocations = _.concat(newLocations, newLocation)
    })
    newBooking.locations = newLocations
    newBooking.verify = newVerify
    const newBookingAttachments = []
    _.forEach(newBooking.bookingAttachmentsAttributes, (attachment) => {
      const newAttachment = { ...attachment }
      newAttachment.tmpID = _.uniqueId()
      newBookingAttachments.push(newAttachment)
    })
    newBooking.bookingAttachmentsAttributes = newBookingAttachments
    newBooking.customReimbursements = _.cloneDeep(booking.customReimbursements)
    duplicateBookings = _.concat(duplicateBookings, newBooking)
  })
  return _.concat(batch.bookings, duplicateBookings)
}

export const unKeepingBatchData = (batch) => {
  const currentBatch = batch
  if (!_.isEmpty(currentBatch) && currentBatch.keep_data) {
    currentBatch.keep_data = false
  }
  return currentBatch
}

// todo: need to check this one to build dynamic columns base on booking extra by options
export const buildByOptionsFields = (extraServices) => {
  const byOption = {
    item_length: 0,
    item_names: [],
    extra_requirements: []
  }
  if (!_.isEmpty(extraServices.regularByOptionExtras)) {
    const groupItems = _.groupBy(extraServices.regularByOptionExtras, 'vehicle_type_id')
    _.forEach(groupItems, (item) => {
      if (!_.isEmpty(item) && _.size(item) > byOption.item_length) {
        byOption.item_length = _.size(item)
        byOption.item_names = _.map(item, 'name')
      }
    })
  }
  return byOption
}

export const hasByOptionExtra = (batch) => {
  if (!_.isEmpty(batch.by_option_extra) && batch.by_option_extra.item_length > 0) {
    return true
  }
  return false
}

const deleteExtraBookingElements = (booking) => {
  const newBooking = booking
  delete newBooking.booking_extra_requirements
  delete newBooking.booking_extra_requirements_negative_position
  return newBooking
}


const buildCustomReimbursements = (preReimbursement) => {
  const newReimbursement = []
  if (_.isEmpty(preReimbursement)) {
    return []
  }
  preReimbursement.forEach((reimbursement) => {
    newReimbursement.push({
      ...reimbursement,
      ...(reimbursement.allow_user_to_enter_amount ? { user_input_amount: reimbursement.fees } : {}),
      selected_amount: reimbursement.unit,
      id: reimbursement.vehicle_type_reimbursement_id
    })
  })
  return newReimbursement
}

export const buildBatchEzInfo = (bookings, extraInfos, currentCustomer, options = {}) => {
  const newBookings = []
  _.forEach(bookings, (booking, indexBooking) => {
    let newBooking = { ...booking }
    const attachements = booking.booking_attachments || []
    newBooking.temp_id = Math.random().toString(36).slice(2)
    newBooking.verify = {
      vehicle: true,
      extraRequirement: true,
      timeType: true,
      pickupTime: true,
      codLimitAmount: true,
      locationCodPod: true
    }
    newBooking.added_from = _.isEmpty(options) ? undefined : options.added_from
    newBooking.is_expand = true
    newBooking.is_selected = false
    newBooking.note_to_driver = extraInfos.default_note
    newBooking.order = indexBooking
    newBooking.sendFirstToFavorite = extraInfos.sendToFavoriteFirstSetting
    newBooking.marked_as_favorite = false
    newBooking.job_order_number = ''
    newBooking.time_type_option = {
      type_key: newBooking.time_type,
      type_value: extraInfos.booking_time_type_options[newBooking.time_type],
      fullday_selected_amount: newBooking.full_day_selected_amount,
      display_time_type_key: newBooking.display_time_type,
      display_time_type_value: extraInfos.booking_time_type_options[newBooking.display_time_type]
    }
    newBooking.bookingAttachmentsAttributes = buildBookingAttachments(newBooking, attachements)
    newBooking.relatedAttachmentIDs = getAttachmentIDs(attachements)
    newBooking.totalUploadedAttchment = countingExistingAttachments(newBooking.bookingAttachmentsAttributes)
    newBooking.pickup_date_time = moment.unix(newBooking.pickup_time)
    const extraRequirementNegative = newBooking.booking_extra_requirements_negative_position || []
    newBooking.extra_requirements = _.concat(
      newBooking.booking_extra_requirements, extraRequirementNegative
    )
    newBooking.badges = newBooking.booking_badges
    _.forEach(newBooking.locations, (location, indexLocation) => {
      let phoneMask
      if (location.is_phone_mask) {
        phoneMask = I18n.t('contacts.labels.not_show_phone')
      }
      _.assign(
        location, {
          temp_id: [Math.random().toString(36).slice(2), '-01'].join('-'),
          verify: { name: true, recipient_name: true, recipient_phone: true },
          order: indexLocation,
          location_note: location.description,
          lat: location.latitude,
          lng: location.longitude,
          can_toggle_need_pod: currentCustomer.can_toggle_pod,
          phone_mask: phoneMask,
        }
      )
    })
    _.forEach(newBooking.extra_requirements, (extraRequirement) => {
      _.assign(
        extraRequirement, {
          id: extraRequirement.extra_requirement_id,
          selected: true,
          vehicle_type_id: newBooking.vehicle_type_id,
          tmpID: _.uniqueId(),
        }
      )
    })
    _.forEach(newBooking.badges, (badge) => {
      _.assign(
        badge, {
          id: badge.badgeable_relation_id
        }
      )
    })
    newBooking.customReimbursements = buildCustomReimbursements(booking.custom_reimbursements)
    newBooking = deleteExtraBookingElements(newBooking)
    newBookings.push(newBooking)
  })
  return newBookings
}

export const rebuildBookingsOrder = (bookings) => {
  const tempBookings = bookings
  for (let i = 0; i < _.size(tempBookings); i += 1) {
    tempBookings[i].order = i + 1
  }
  return tempBookings
}

// buildBookings