import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY, V2_LOGO_GREEN, V2_LOGO_GREEN_TRANSPORTIFY } from 'constants/imageConstants';
// ASSETS
import $ from 'jquery';

class ModalResetPassword extends React.Component {
  static propTypes = {
    goToSignIn: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    submitFormResetPassword: PropTypes.func.isRequired,
    customerSignInDirect: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this.refs.form)).validate({
      rules: {
        "customer[login]": {
          required: true,
          email: true,
        }
      },
      messages: {
        "customer[login]": {
          required: I18n.t("validations.required"),
          email: I18n.t("validations.email")
        }
      },
      highlight: (element, errorClass, validClass) => {
        $(element).parents('.FormGroup').addClass('error')
          $(element).parents('.Input').addClass('error')
      },
      unhighlight: (element, errorClass, validClass) => {
        $(element).parents('.FormGroup').removeClass('error')
          $(element).parents('.Input').removeClass('error')
      },
      errorClass: "FormGroup-explanation",
      errorPlacement: (error, element) => {
        error.insertAfter($(element).parents('.Input'))
      },
      submitHandler: (form, event) => {
        event.preventDefault()
        this.onSubmitHandler()
        return false
      }
    })

    this.checkPosition()
  }

  checkPosition() {
    if ($(ReactDOM.findDOMNode(this.refs.form)).height() > $(ReactDOM.findDOMNode(this.refs.form)).parent().height()) {
      $(ReactDOM.findDOMNode(this.refs.form)).addClass('Box-Custom');
    } else {
      $(ReactDOM.findDOMNode(this.refs.form)).removeClass('Box-Custom');
    }
  }

  onSubmitHandler() {
    let params = {
      login: this.refs.resetPasswordLogin.value,
    }
    this.props.submitFormResetPassword(params)
  }

  render() {
    const { customerSignInDirect } = this.props
    let logo_icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    let logo = commonUtils.isTransportify() ? V2_LOGO_GREEN_TRANSPORTIFY : V2_LOGO_GREEN
    return (
      <div className="visible vertical-scroll Modal" ref="resetPasswordModal">
        <div className="LoginBox vertical-scroll">
          <form className="Box" ref="form">
            {
              !customerSignInDirect && (
                <span className="LoginBoxCustom-Close" onClick={this.props.closeModal}>
                  <i className="b material-icons Icon">close</i>
                </span>
              )
            }
            <div className="Box-Head">
              <div className="Logo"><img src={logo_icon} alt="deliveree" />
              </div>
              <h3>{I18n.t("webapp.label.reset_password")}</h3>
            </div>
            <div className="Box-Content">
              <div className="Form">
                <div className="FormGroup">
                  <label className="FormGroup-label">{I18n.t("devise_template.email_address")}</label>
                  <div className="Input">
                    <input type="text" ref="resetPasswordLogin" name="customer[login]" tabIndex="1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="Box-Actions">
              <input className="green Button" type="submit" value={I18n.t("devise_template.get_a_link_to_reset_password")} />
            </div>
            <div className="copyright Box-Actions">
              <h5>{I18n.t("devise_template.already_a_member", { app_name: commonUtils.appName() })} <a href="javascript:void(0)" onClick={this.props.goToSignIn}>{I18n.t("devise_template.log_in_here")}</a></h5>
            </div>
            {/*<div className="copyright Box-Actions">
                          <div className="Logo"><img src={logo} alt="deliveree"/>
                          </div>
                          <h5>{I18n.t("devise_template.copyright")}</h5>
                        </div>*/}
          </form>
        </div>
      </div>
    )
  }

};

export default ModalResetPassword;