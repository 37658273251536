import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState: any = {}

const batchMultipleSlice = createSlice({
  name: 'batchMultiple',
  initialState,
  reducers: {
    updateBatchMultiple: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload
    }),
  },
  extraReducers: () => {},
})

export const batchMultipleActionsCreator = batchMultipleSlice.actions

export default batchMultipleSlice
