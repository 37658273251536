import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const arrayDiffDaysSlice = createSlice({
  name: 'arrayDiffDays',
  initialState,
  reducers: {
    receiveArrayDiffDays: (state: any, action: PayloadAction<any>) => action.payload.arrayDiffDays,
  },
  extraReducers: () => {},
})

export const arrayDiffDaysActionsCreator = arrayDiffDaysSlice.actions

export default arrayDiffDaysSlice
