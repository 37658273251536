import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
// UTILS
import { UtilsHTML } from 'utils/html'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
import AttachmentPopup from '../../common/AttachmentPopup'
// CONSTANTS
import { ICON_PDF } from 'constants/imageConstants'
import { MAX_SIZE_OF_FILE, MAX_SIZE_OF_FILE_BEFORE_UPLOAD } from 'constants/newBookingConstants'
import { isEditBooking, setAttachments } from 'utils/booking/common'
import { Utils } from 'utils/Utils'
import * as attachmentActionCreator from 'store/actions/new_booking/attachmentActionCreators'

// ASSETS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  attachments: state.attachments,
})
function mapDispatchToProps(dispatch) {
  return {
    attachmentAction: bindActionCreators(attachmentActionCreator, dispatch),
  }
}


class Attachment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false
    }
  }

  componentDidMount() {
    // init the state attachments of store
    const { attachments, attachmentAction } = this.props
    if (_.isEmpty(attachments)) {
      const attachmentsList = setAttachments(attachments)
      attachmentAction.updateAttachments(attachmentsList)
    }
  }

  componentDidUpdate(prevProps) {
    const { attachments: preAttachments } = prevProps
    const { attachmentAction, attachments } = this.props
    if (!_.isEqual(preAttachments, attachments) && !_.isEmpty(attachments)) {
      const attachmentsList = setAttachments(attachments)
      attachmentAction.updateAttachments(attachmentsList)
    }
  }

  onChangeHandler = (index, event) => {
    const { attachmentAction } = this.props
    const input = event.target
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      const file = input.files[0]
      const image = new Image()
      const { size, name } = file
      const typeFile = file.type.split('/')
      reader.readAsDataURL(file)
      if (typeFile[0] === 'image' && size < MAX_SIZE_OF_FILE_BEFORE_UPLOAD) {
        reader.onloadend = () => {
          image.src = reader.result
          image.onload = () => {
            const compressedImage = Utils.compressImage(
              image,
              image.width,
              image.height,
              Utils.getQualityImage(size)
            )
            const fileCompressImage = Utils.dataURLtoFile(compressedImage, name)
            if (fileCompressImage.size > MAX_SIZE_OF_FILE) {
              this.setState({ flag: true })
              $(input).val('')
            } else {
              attachmentAction.updateAttachment(index, {
                id: null,
                document_url: compressedImage,
                document_content_type: fileCompressImage.type,
                document_file_name: fileCompressImage.name,
                document_file_size: fileCompressImage.size,
                document_type: undefined,
                allow_to_delete: true,
                file: fileCompressImage
              })
              window.Moengage.track_event('Booker Attachments Used')
            }
          }
        }
      } else if (size > MAX_SIZE_OF_FILE) {
        this.setState({
          flag: false
        }, () => this.setState({ flag: true }))
        $(input).val('')
      } else {
        reader.onload = (e) => {
          attachmentAction.updateAttachment(index, {
            id: null,
            document_url: e.target.result,
            document_content_type: file.type,
            document_file_name: file.name,
            document_file_size: file.size,
            document_type: undefined,
            allow_to_delete: true,
            file
          })
        }
        window.Moengage.track_event('Booker Attachments Used')
      }
    } else {
      this.removeHandler(index)
    }
  }

  removeHandler = (index) => {
    const { attachmentAction } = this.props
    attachmentAction.updateAttachment(index, {
      id: undefined,
      document_url: undefined,
      document_content_type: undefined,
      document_file_name: undefined,
      document_type: undefined,
      allow_to_delete: true,
      file: undefined
    })
  }

  renderAttachment(attachment, index) {
    if (attachment.document_content_type === 'application/pdf') return this.renderPDF(attachment, index)
    return this.renderPhotoOrEmpty(attachment, index)
  }

  renderPhotoOrEmpty(attachment, index) {
    if (_.isEmpty(attachment.document_url)) {
      return (
        <div className="block-item No-Border-Right" key={index}>
          <label className="Dropzone">
            <i className="xb material-icons Icon">
              add_circle
            </i>
            <input
              type="file"
              accept="image/jpeg, image/jpg, image/png, application/pdf"
              multiple=""
              name="booking[booking_attachments_attributes][][document]"
              onChange={e => this.onChangeHandler(index, e)}
              style={{ display: 'none' }}
            />
          </label>
        </div>
      )
    }

    return (
      <div className="block-item No-Border-Right" key={index}>
        <div className="ImageView">
          <div className="ImageViewer">
            <div
              className="ImageViewer-Delete"
              style={{ display: attachment.allow_to_delete ? 'flex' : 'none' }}
            >
              <i
                className="material-icons Icon"
                onClick={() => this.removeHandler(index)}
              >
                clear
              </i>
            </div>
            <label className="ImageViewer-Image">
              <span className="Fit-Gallery-Image" style={{ backgroundImage: `url(${attachment.document_url})` }} />
              {this.renderInput(attachment, index)}
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderPDF(attachment, index) {
    return (
      <div className="block-item No-Border-Right" key={index}>
        <div className="ImageView">
          <div className="ImageViewer">
            <div
              className="ImageViewer-Delete"
              style={{ display: attachment.allow_to_delete ? 'flex' : 'none' }}
            >
              <i
                className="material-icons Icon"
                onClick={() => this.removeHandler(index)}
              >
                clear
              </i>
            </div>
            <label className="FileView">
              <img src={ICON_PDF} />
              <label>
                {attachment.document_file_name}
              </label>
              {this.renderInput(attachment, index)}
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderInput(attachment, index) {
    return attachment.allow_to_delete && (
      <input
        type="file"
        accept="image/jpeg, image/jpg, image/png, application/pdf"
        multiple=""
        name="booking[booking_attachments_attributes][][document]"
        onChange={e => this.onChangeHandler(index, e)}
        style={{ display: 'none' }}
      />
    )
  }

  render() {
    const { flag } = this.state
    const { attachments } = this.props
    return (
      <div className="pb15">
        {UtilsHTML.renderOptionTitle(I18n.t('webapp.new_booking.step_2.attachments_block_title'), false)}
        <div className="Attachments block">
          <div className="block-item mar20 No-Border-Right">
            {
              flag && <AttachmentPopup />
            }
            {
              attachments.map((attachment, index) => this.renderAttachment(attachment, index))
            }
          </div>
        </div>
      </div>
    )
  }
}
Attachment.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  attachmentAction: PropTypes.shape({}).isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Attachment)
