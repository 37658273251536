import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _ from 'lodash'
// UTILS
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'

// ACTIONS
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'
// COMPONENTS
// CONSTANTS
// API
// ASSETS
import {
  ICON_DRIVER_PREFERENCES_FULL,
} from 'constants/imageConstants'

const mapStateToProps = (state, ownProps) => {
  const { bookingID, isMultiple, isBatch } = ownProps
  if (isMultiple) {
    const { bookings } = state
    const booking = _.find(bookings, { id: bookingID })
    return {
      tmpSelectedTimeAssignDriver: booking.tmpSelectedTimeAssignDriver,
      tmpSelectedDateAssignDriver: booking.tmpSelectedDateAssignDriver
    }
  }
  if (isBatch) {
    const { selectedBatch } = state
    const booking = _.find(selectedBatch.bookings, { id: bookingID })
    return {
      tmpSelectedTimeAssignDriver: booking.tmpSelectedTimeAssignDriver,
      tmpSelectedDateAssignDriver: booking.tmpSelectedDateAssignDriver
    }
  }
  return {
    tmpSelectedTimeAssignDriver: state.tmpSelectedTimeAssignDriver,
    tmpSelectedDateAssignDriver: state.tmpSelectedDateAssignDriver
  }
}

function mapDispatchToProps(dispatch) {
  return {
    bookingsActions: bindActionCreators(bookingsActionCreators, dispatch),
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch)
  }
}

class ConfirmPickupTimePopup extends React.Component {
  constructor(props) {
    super(props)
    this.closePopup = this.closePopup.bind(this)
  }

  closePopup(confirm) {
    const {
      handleShowChangePickupTimePopup,
      tmpSelectedTimeAssignDriver,
      tmpSelectedDateAssignDriver,
      handleShowPopup,
      bookingsActions,
      assignDriverActions,
      assignDriver,
      bookingID,
      isMultiple,
      isBatch
    } = this.props
    handleShowChangePickupTimePopup()
    if (confirm) {
      const [hour, minute] = _.split(tmpSelectedTimeAssignDriver, ':')
      const newPickupTime = moment(tmpSelectedDateAssignDriver).hours(hour).minutes(minute).format()
      if (isMultiple) {
        const dataChanged = {
          assignedPickupTime: newPickupTime,
          assignedDriver: assignDriver,
          tmpSelectedDateAssignDriver: ''
        }
        Promise.resolve(
          bookingsActions.updateBooking(bookingID, dataChanged)
        ).then(() => {
          handleShowPopup()
        })
      } else if (isBatch) {
        handleShowPopup(newPickupTime)
      } else {
        Promise.resolve(
          assignDriverActions.changeAssignedPickupTime(newPickupTime),
          assignDriverActions.changeAssignDriver(assignDriver),
          assignDriverActions.changeTmpSelectedDateAssignDriver(''),
        ).then(() => {
          handleShowPopup()
        })
      }
    }
  }

  render() {
    const {
      countryCode,
      tmpSelectedTimeAssignDriver,
      tmpSelectedDateAssignDriver,
      assignDriver
    } = this.props
    const [hour, minute] = _.split(tmpSelectedTimeAssignDriver, ':')
    const newPickupTime = moment(tmpSelectedDateAssignDriver).hours(hour).minutes(minute).format()
    const { fullTimeDate } = Utils.formatDateTime(moment(newPickupTime), countryCode)
    let driverName = ''

    if (assignDriver) {
      driverName = assignDriver.fleet_partner_name || Utils.driverFirstName(assignDriver.name)
    }

    return (
      <div className="popup-wrapper">
        <div className="overlay" onClick={() => this.closePopup()} />
        <div className="popup-inner">
          <div className="content-wrapper">
            <div className="icon">
              <img src={ICON_DRIVER_PREFERENCES_FULL} alt="icon-calendar" />
            </div>
            <p className="title">
              {I18n.t('webapp.new_booking.step_2.assign_driver.confirm_pickup_time')}
            </p>
            <p className="content">
              {I18n.t('webapp.new_booking.step_2.assign_driver.change_pickup_time', { driverName, pickupTime: fullTimeDate })}
            </p>
          </div>
          <div className="btn-wrapper">
            <button type="button" className="button" onClick={() => this.closePopup()}>
              {I18n.t('webapp.action.back')}
            </button>
            <button type="button" className="button confirm" onClick={() => this.closePopup(true)}>
              {I18n.t('webapp.action.confirm')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ConfirmPickupTimePopup.propTypes = {
  assignDriver: PropTypes.shape({}).isRequired,
  bookingsActions: PropTypes.shape({}).isRequired,
  assignDriverActions: PropTypes.shape({}).isRequired,
  handleShowChangePickupTimePopup: PropTypes.func.isRequired,
  handleShowPopup: PropTypes.func.isRequired,
  tmpSelectedTimeAssignDriver: PropTypes.string.isRequired,
  tmpSelectedDateAssignDriver: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  bookingID: PropTypes.string,
  isMultiple: PropTypes.bool,
  isBatch: PropTypes.bool
}

ConfirmPickupTimePopup.defaultProps = {
  bookingID: undefined,
  isMultiple: false,
  isBatch: false
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPickupTimePopup)
