import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as driverMarkerActionCreators from 'store/actions/new_booking/driverMarkerActionCreators'
import * as googleMarkerActionCreators from 'store/actions/new_booking/googleMarkerActionCreators'

import { GMAPS_BROWSER_KEY, GMAPS_API_VERSION, MAP_STYLE } from 'constants/appConstants'
import * as Utils from 'utils/new_booking/common'
import { drawFulldayMegazone } from 'utils/booking/common'
import I18n from 'i18n/i18n'
import { googleMapActionsCreator } from 'store/toolkit/googleMap/googleMap.reducer'

const mapStateToProps = state => ({
  googleMap: state.googleMap,
  extraInfos: state.extraInfos,
  locations: state.locations,
  timeType: state.timeType,
  dropOffZones: state.dropOffZones,
  driverMarkers: state.driverMarkers,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  selectedServiceTypeID: state.selectedServiceTypeID,
  bookAgainDetails: state.bookAgainDetails,
})

function mapDispatchToProps(dispatch) {
  return {
    loadMapToStore: (data) => dispatch(googleMapActionsCreator.loadMapToStore(data)),
    driverMarkerActions: bindActionCreators(driverMarkerActionCreators, dispatch),
    googleMarkerActions: bindActionCreators(googleMarkerActionCreators, dispatch),
  }
}

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.mega = null
    this.map = null
  }

  componentDidMount() {
    const {
      extraInfos, bookAgainDetails, searchNearby, zoom, latitude, longitude, loadMapToStore,
    } = this.props
    const zoomNumber = extraInfos.default_map_zoom || zoom
    window.mapLoaded = () => {
      if (!this.map) return
      // add animation for marker
      require('marker-animate')
      const map = new window.google.maps.Map(this.map, {
        zoom: zoomNumber,
        center: new window.google.maps.LatLng(latitude, longitude),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        },
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
          style: window.google.maps.ZoomControlStyle.SMALL
        },
        scaleControl: true,
        scaleControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        styles: MAP_STYLE
      })
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('show-route'))
      // Note: the fitBounds() happens asynchronously so setZoom() or setCenter() never work after fitBounds()
      // so you have to wait for a bounds_changed event before setting zoom or center works.
      window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if (extraInfos.default_center_lat_lng.length > 0 && _.isEmpty(bookAgainDetails)) {
          const addressDefault = JSON.parse(localStorage.getItem('addressDefault'))
          if (addressDefault) {
            const { lat, lng } = addressDefault.geometry.location
            map.setCenter(new window.google.maps.LatLng(lat, lng))
            window.localStorage.removeItem('addressDefault')
          } else {
            const latLng = extraInfos.default_center_lat_lng.split(',')
            map.setCenter(new window.google.maps.LatLng(latLng[0], latLng[1]))
          }
          map.setZoom(zoomNumber)
        }
      })


      window.google.maps.event.addListener(map, 'zoom_changed', () => {
        const { driverMarkers } = this.props
        _.map(driverMarkers, (driver) => {
          driver.marker.setVisible(Utils.shouldShowDriverMaker(map, extraInfos))
          driver.marker.setMap(map)
        })
      })

      this.currentSearchNearby(map, searchNearby)

      const geocoder = new window.google.maps.Geocoder()
      const directionsDisplay = new window.google.maps.DirectionsRenderer({
        draggable: false,
        map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: 'rgba(14,115,15,1)',
          strokeWeight: '6'
        }
      })
      const directionsService = new window.google.maps.DirectionsService()

      const mapObject = {
        map,
        directionsDisplay,
        directionsService,
        geocoder
      }
      loadMapToStore(mapObject)
    }
    if (window.google && window.google.maps) {
      window.mapLoaded()
    } else {
      const s = document.createElement('script')
      s.src = `https://maps.googleapis.com/maps/api/js?key=${GMAPS_BROWSER_KEY}&v=${GMAPS_API_VERSION}&libraries=places,geometry&callback=mapLoaded&language=${I18n.language}`
      document.head.appendChild(s)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      googleMap, timeType, locations, extraInfos, fullLoadView
    } = this.props
    if (!_.isUndefined(locations[0]) && fullLoadView) {
      const isChangeMegaZone = Number(locations[0].lat) !== Number(nextProps.locations[0].lat)
        || Number(locations[0].lng) !== Number(nextProps.locations[0].lng)
      if (window.google && (nextProps.timeType !== timeType || isChangeMegaZone)) {
        drawFulldayMegazone(googleMap.map, nextProps.timeType, extraInfos, nextProps.locations, this.mega, (circle) => {
          this.mega = circle
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      googleMap, searchNearby, extraInfos: currExtraInfos, zoom, fullLoadView
    } = this.props
    const { map } = googleMap
    const { extraInfos: prevExtraInfos } = prevProps

    if (!fullLoadView && this.mega) {
      this.mega.setMap(null)
    }

    if (map) {
      if (prevProps.searchNearby !== searchNearby) {
        this.currentSearchNearby(map, searchNearby)
      }

      if (currExtraInfos.default_center_lat_lng !== prevExtraInfos.default_center_lat_lng) {
        const latLng = currExtraInfos.default_center_lat_lng.split(',')
        map.setCenter(new window.google.maps.LatLng(latLng[0], latLng[1]))
        map.setZoom(currExtraInfos.default_map_zoom || zoom)
      }
    }
  }

  currentSearchNearby(map, searchNearby) {
    const {
      extraInfos,
      googleMarkerActions,
    } = this.props
    if (searchNearby) {
      const latLng = extraInfos.default_center_lat_lng.split(',')
      const location = new window.google.maps.LatLng(latLng[0], latLng[1])
      googleMarkerActions.renderNearbyMarker({
        location,
        radius: 2000,
        type: 'grocery_or_supermarket',
        key: GMAPS_BROWSER_KEY,
      }, map)
    } else {
      googleMarkerActions.removeNearbyMarker()
    }
  }

  render() {
    return (
      <div className="Map-Overlay hatest">
        <div className="Map" id="map" ref={(ref) => { this.map = ref }} style={{ position: 'relative', overflow: 'hidden' }} />
      </div>
    )
  }
}

Map.propTypes = {
  extraInfos: PropTypes.shape({ enable_full_day_megazone: PropTypes.bool }).isRequired,
  googleMarkerActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  googleMapActions: PropTypes.shape({}),
  googleMap: PropTypes.shape({}).isRequired,
  timeType: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({ lng: PropTypes.string, lat: PropTypes.string })).isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  driverMarkers: PropTypes.instanceOf(Array),
  bookAgainDetails: PropTypes.shape({}),
  searchNearby: PropTypes.bool,
  fullLoadView: PropTypes.bool,
}

Map.defaultProps = {
  latitude: 0,
  longitude: 0,
  zoom: 12,
  driverMarkers: [],
  bookAgainDetails: null,
  searchNearby: false,
  googleMapActions: {},
  fullLoadView: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
