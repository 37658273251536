import React from 'react'
import PropTypes from 'prop-types'

// COMPONENT
import EyeIcon from './EyeIcon'
import AreaTypePopup from './AreaTypePopup'

// API
import PopupAPI from 'api/popups'
import _ from 'lodash'
import {
  POPUP_ID_CUSTOMER_DND_POPUP_EYE
} from 'constants/common/popupConstants'

class PopupEyeArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasPopUpEyes: false,
      popupData: {},
    }
  }

  componentWillMount() {
    this.handlePopup()
  }

  onClickCloseAreaTypePopup = () => {
    const {
      areaId,
    } = this.props
    const popup = document.querySelector(`#booking-${areaId}-${POPUP_ID_CUSTOMER_DND_POPUP_EYE}`)
    if(popup) {
      popup.classList.remove('visible')
    }
  }

  handlePopup() {
    const {
      areaId,
      currentCustomer,
      popupType,
      popupItems,
    } = this.props
    const params = {
      area_id: areaId,
      popup_type: popupType,
    }
    PopupAPI.getAreaTypePopup(currentCustomer?.authentication_token, params, (result) => {
      const popupdnd = result?.data?.object
      if (result?.status === 200 && !_.isEmpty(popupdnd)) {
        this.setState({
          hasPopUpEyes: true
        })
        const popupData = {
          icon: popupdnd.icon
        }
        _.each(popupdnd.popup_items, (item) => {
          if (item.item_type === popupItems.title) {
            popupData.title = item.formated_content
          } else if (item.item_type === popupItems.content) {
            popupData.content = item.formated_content
          }
        })
        this.setState({ popupData })
      }
    })
  }

  render() {
    const { hasPopUpEyes, popupData } = this.state
    const {
      icon,
      areaId,
      iconClass,
      labelName,
    } = this.props
    return (
      <div>
        <EyeIcon
          icon={icon}
          areaId={areaId}
          iconClass={iconClass}
          labelName={labelName}
          hasPopUpEyes={hasPopUpEyes}
        />
        {hasPopUpEyes
          && (
            <AreaTypePopup
              popupData={popupData}
              areaId={areaId}
              onClickCloseAreaTypePopup={this.onClickCloseAreaTypePopup}
            />
          )
        }

      </div>
    )
  }
}

PopupEyeArea.propTypes = {
  areaId: PropTypes.number.isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  popupType: PropTypes.string.isRequired,
  popupItems: PropTypes.shape({}).isRequired,
  icon: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
}

export default PopupEyeArea
