import { memo } from 'react'
import I18n from 'i18n/i18n';
import CommonUtils from 'utils/common';
import { Helmet } from 'react-helmet';
import { IMAGE_SHARING_TRANSPORTIFY, IMAGE_SHARING_DELIVEREE } from 'constants/imageConstants'
import { PUBLIC_URL } from 'constants/appConstants'
import { useSelector } from 'react-redux';

function MetaTag () {
  const driverName = useSelector(state => state.booking?.driver?.name)
  const isPH = CommonUtils.isTransportify()

  const appName = isPH ? 'transportify' : 'deliveree'
  const image = appName === 'transportify' ? IMAGE_SHARING_TRANSPORTIFY : IMAGE_SHARING_DELIVEREE
  let title = I18n.t('share_social_network.default_title', {
    app_name: I18n.t(`app.${appName}`)
  })

  let description = I18n.t('share_social_network.default_description', {
    app_name: I18n.t(`app.${appName}`)
  })

  if(CommonUtils.isFollow()) {
    title = I18n.t('share_social_network.driver_location')
    if(driverName) {
      description = driverName
    }
  }

  
  const type = 'website'
  const url = window.location.href
  const isProduction = ['webapp.transportify.com.ph', "webapp.deliveree.com"].includes(window.location.hostname)
  const contentRobot = isProduction ? "index, nofollow" : "noindex"

  return (
    <Helmet htmlAttributes={{ lang:  I18n.language }}>
      <title>{isPH ? I18n.t('share_social_network.dlvr_ph_title') : I18n.t('share_social_network.dlvr_title')}</title>
      <link type="image/png" rel="shortcut icon" href={`${PUBLIC_URL}/${isPH ? 'favicon_transportify' : 'favicon'}.ico`} />
      <meta name="description" content={isPH ? I18n.t('share_social_network.ph_google_description') : I18n.t('share_social_network.google_description')} />
      <meta name="robots" content={contentRobot} />

      <meta property='og:title' content={title} />
      <meta property='og:type' content={type} />
      <meta property='og:image' content={image} />
      <meta property='og:url' content={url} />
      <meta property='og:description' content={description} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content={url}/>
      <meta name='twitter:title' content={title}/>
      <meta name='twitter:description' content={description}/>
      <meta name='twitter:image' content={image}/>
    </Helmet>
  );
};

export default MetaTag