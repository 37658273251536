import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const savedBookingsSlice = createSlice({
  name: 'savedBookings',
  initialState,
  reducers: {
    getMoreBookings: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const savedBookingsActionsCreator = savedBookingsSlice.actions

export default savedBookingsSlice
