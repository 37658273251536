import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// UTILS
import { UtilsHTML } from 'utils/html'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
import SubAccountTag from '../../common/sub_account_tag/SubAccountTag'
// CONSTANTS
// ASSETS
// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import { isEditBooking } from 'utils/booking/common'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'

const mapStateToProps = state => ({
  subAccountTagList: state.subAccountTagList,
  bookAgainDetails: state.bookAgainDetails,
})

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    bookingAgainDetailsActions: bindActionCreators(bookingAgainDetailsActionsCreator, dispatch),
    checkSubAccountActions: bindActionCreators(checkSubAccountActionsCreator, dispatch)
  }
}

class SwitchAccount extends React.PureComponent {
  static handleSwitchAccount() {
    window.showSwitchAccountModal()
  }

  constructor() {
    super()
    this.onCheckSubAccountTag = this.onCheckSubAccountTag.bind(this)
  }

  componentDidMount() {
    this.fetchSubAccount()
  }

  onCheckSubAccountTag(value) {
    const { bookingActions, bookAgainDetails, checkSubAccountActions, bookingAgainDetailsActions } = this.props
    checkSubAccountActions.updateSubAccountCheckBox({ value })
    if (!value) {
      if (bookAgainDetails) {
        bookingAgainDetailsActions.updateBookAgainDetails({ sub_account_tag: null })
      }
      bookingActions.addSubAccount()
    }
  }

  getCompanyByLastLoginId() {
    const { currentCustomer } = this.props
    return currentCustomer.companies?.find(
      item => item?.id === currentCustomer?.current_company_id
    )
  }

  getAccountEmail(isBusiness) {
    const { currentCustomer } = this.props
    if (isBusiness) {
      const selectedEmploy = currentCustomer.employs?.find(
        item => item?.company_id === currentCustomer?.current_company_id
      )
      return selectedEmploy?.invited_email
    }
    return currentCustomer?.email
  }

  getAccountImage(isBusiness) {
    const { currentCustomer } = this.props
    if (isBusiness) {
      const selectedCompany = this.getCompanyByLastLoginId()
      return selectedCompany?.photo_url
    }
    return currentCustomer?.avatar_square
  }

  getAccountName(isBusiness) {
    if (isBusiness) {
      const selectedCompany = this.getCompanyByLastLoginId()
      return selectedCompany?.name
    }
    return 'Personal'
  }

  fetchSubAccount(params = {}) {
    const { bookingActions } = this.props
    bookingActions.getSubAccountTagList(params)
  }

  checkPairSubAccount() {
    const {
      subAccountTagList,
      bookAgainDetails,
      bookingActions,
      checkSubAccountActions
    } = this.props
    let check = false
    let subAccount = {}

    // We should check the sub account is paired or not to show it on UI
    if (subAccountTagList && subAccountTagList.length > 0) {
      if (bookAgainDetails && !_.isEmpty(bookAgainDetails.sub_account_tag)
        && subAccountTagList.findIndex(({ id }) => id === bookAgainDetails.sub_account_tag.sub_account_id) !== -1) {
        check = true
        subAccount = {
          sub_account_id: bookAgainDetails.sub_account_tag.sub_account_id
        }
      }
    }
    checkSubAccountActions.updateSubAccountCheckBox({ value: check })
    bookingActions.addSubAccount(subAccount)
  }

  renderTitleSwitchAccount() {
    const { subAccountTagList, bookAgainDetails } = this.props
    const hasSubAccountTagInBookAgain = !!(bookAgainDetails
      && bookAgainDetails.sub_account_tag
      && bookAgainDetails.sub_account_tag.sub_account_id)
    if (subAccountTagList.length > 0 || hasSubAccountTagInBookAgain) {
      if (!isEditBooking()) {
        return UtilsHTML.renderSectionTitle(I18n.t('webapp.label.billing_and_sub_account'))
      }
      return UtilsHTML.renderSectionTitle(I18n.t('webapp.label.sub_account_tag'))
    }
    return UtilsHTML.renderSectionTitle(I18n.t('webapp.label.billing_account'))
  }

  render() {
    const {
      currentCustomer,
      isCheckedSubAccount,
      subAccountTagPicked,
      subAccountTagList,
      bookAgainDetails
    } = this.props

    const isBusiness = !!currentCustomer?.current_company_id

    const hasListSubAccountTag = !!(subAccountTagList && subAccountTagList.length)
    const hasSubAccountTagInBookAgain = !!(bookAgainDetails
      && bookAgainDetails.sub_account_tag
      && bookAgainDetails.sub_account_tag.sub_account_id)

    if (_.isEmpty(currentCustomer) || !currentCustomer.authentication_token
      || (isEditBooking() && (!hasSubAccountTagInBookAgain && !hasListSubAccountTag))) {
      return null
    }

    const icon = this.getAccountImage(isBusiness)
    const email = this.getAccountEmail(isBusiness)
    const accountName = this.getAccountName(isBusiness)

    return (
      <div className="mt20 mr10 ml10 White-bg Radius-default">
        {this.renderTitleSwitchAccount()}
        <div className="Radius-Bottom-Default pt20 pr15 pb15 pl15">
          {(!isEditBooking() && _.size(currentCustomer.companies) > 0) && (
            <div className="flex flex-start pb15 mb15 borderb">
              <div className="flex-index flex flex-start">
                <div className="w52px h52px Radius-Circle Avatar o-hidden">
                  <img src={icon} alt="" />
                </div>
                <div className="flex-index pl10 pr10 break-word">
                  <p className="reset m default-small-font default-color">
                    {accountName}
                  </p>
                  <p className="mt5 mar0 default-small-font default-color">
                    {email}
                  </p>
                </div>
              </div>
              <span
                className="cur-pointer default-smaller-font default-color pt5 pr12 pb5 pl12 Radius-three Solid-Line-Default"
                style={{
                  backgroundColor:
                    '#f9f9f9',
                  height: 12,
                  width: 53,
                  textAlign: 'center'
                }}
                onClick={() => {
                  SwitchAccount.handleSwitchAccount()
                }}
              >
                {I18n.t('webapp.long_haul.change')}
              </span>
            </div>
          )}
          <SubAccountTag
            subAccountTagList={subAccountTagList}
            onCheckSubAccountTag={this.onCheckSubAccountTag}
            booking={{
              id: null,
              isCheckSubAccount: isCheckedSubAccount,
              sub_account_tag: {
                sub_account_id: subAccountTagPicked.sub_account_id,
                sub_account_name: subAccountTagPicked.sub_account_name
              }
            }}
          />

        </div>
      </div>
    )
  }
}

SwitchAccount.propTypes = {
  currentCustomer: PropTypes.shape({}),
  isBusiness: PropTypes.bool,
  bookingActions: PropTypes.func,
  subAccountTagList: PropTypes.arrayOf(PropTypes.object),
  isCheckedSubAccount: PropTypes.shape({}),
  bookAgainDetails: PropTypes.shape({}),
  subAccountTagPicked: PropTypes.bool
}

SwitchAccount.defaultProps = {
  currentCustomer: undefined,
  isBusiness: false,
  subAccountTagList: [],
  bookingActions: () => { },
  isCheckedSubAccount: undefined,
  bookAgainDetails: undefined,
  subAccountTagPicked: false
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchAccount)
