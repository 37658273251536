import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { currentVehicleType as getCurrentVehicleType } from 'utils/new_booking/common'
import {
  ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT,
  ITEM_TYPE_VEHICLE_TYPE_FD_TITLE,
  ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE, ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG, ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL,
  ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_TITLE, ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT,
  ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT_SMALL
} from 'constants/common/popupConstants'
import { Utils } from 'utils/Utils'

class ReimbursementPopups extends React.PureComponent {
  static renderPopupItem(item, index) {
    switch (item.item_type) {
      case ITEM_TYPE_VEHICLE_TYPE_FD_TITLE: {
        return (
          <div key={index}>
            {index > 0 && (
              <div className="Normal-Booking-Devide" />
            )
            }
            <h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE: {
        return (
          <p key={index} className="center italic" dangerouslySetInnerHTML={{ __html: item.content }} />
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT: {
        return (
          <p key={index} className="center" dangerouslySetInnerHTML={{ __html: item.content }} />
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG: {
        return (
          <p key={index} className="center fullday" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL: {
        return (
          <p key={index} className="center yellow fullday-custom" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_TITLE: {
        return (
          <div key={index}>
            {index > 0 && (
              <div className="Normal-Booking-Devide" />
            )
            }
            <h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        )
      }
      case ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT: {
        return (
          <p key={index} className="center" dangerouslySetInnerHTML={{ __html: item.content }} />
        )
      }
      case ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT_SMALL: {
        return (
          <p key={index} className="center yellow fullday-custom" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      default:
        return null
    }
  }

  static renderPopupItems(items) {
    return items.map((item, index) => (
      ReimbursementPopups.renderPopupItem(item, index)
    ))
  }

  static renderReimbursemmentPopup(vehicleType) {
    if (_.isEmpty(vehicleType) || _.isEmpty(vehicleType.popups)) return <span />

    const reimbursementPopup = _.filter(vehicleType.popups, item => item.popup_type.indexOf('rb_') > -1)
    if (_.isEmpty(reimbursementPopup)) return <span />
    return reimbursementPopup.map((reimbursement) => {
      const idPopupCustom = Utils.generateIdPopupRebursement(
        reimbursement.popup_type, 0, 0, 0
      )
      return ReimbursementPopups.renderPopup(reimbursement, idPopupCustom)
    })
  }

  static renderPopup(popup, popupType) {
    return (
      <div
        className="vertical-scroll Modal Modal-No-action"
        id={`${popupType}`}
        key={popupType}
      >
        <div className="Normal-Booking">
          <div className="Box">
            <div className="Box-Icon">
              <div className="Logo">
                {popup.icon
                  && <img src={popup.icon} alt="deliveree" />
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal Normal-Hyperlink-Custom">
                {ReimbursementPopups.renderPopupItems(popup.popup_items)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { currentVehicleType } = this.props
    return (
      <div>
        {ReimbursementPopups.renderReimbursemmentPopup(currentVehicleType)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentVehicleType: getCurrentVehicleType(state)
})

ReimbursementPopups.defaultProps = {
  currentVehicleType: null
}

ReimbursementPopups.propTypes = {
  currentVehicleType: PropTypes.shape({}),
}
export default connect(mapStateToProps, null)(ReimbursementPopups)
