import React from 'react'
import ReactDOM from 'react-dom'

import DynamicPopup from './DynamicPopup'

const renderDynamicContent = (object) => {
  if (object.isPaymentPaid) return object.title
  return <p className={object.specialClass} dangerouslySetInnerHTML={{ __html: object.title }} /> // eslint-disable-line
}

const renderDynamicButton = (source, recoverButton) => (
  <div>
    {recoverButton
      && (
      <div className={recoverButton.specialClass}>
        {recoverButton.list.map(object => (
          <button
            key={`button-${object.title}`}
            type="button"
            className={object.specialClass}
            onClick={object.specialClick}
          >
            {object.title}
          </button>
        ))}
      </div>
      )
    }
    <div className={source.specialClass}>
      {source.list.map(object => (
        <button
          key={`button-${object.title}`}
          type="button"
          className={object.specialClass}
          onClick={object.specialClick}
        >
          {object.title}
        </button>
      ))}
    </div>
  </div>
)

const DynamicPopupWithButton = (
  renderNode, info, content, button, recoverButton = undefined,
  handleClickOutside = undefined, isNotClickOutside = false
) => {
  ReactDOM.render(
    <DynamicPopup
      {...info}
      isNotClickOutside={isNotClickOutside}
      renderNode={renderNode}
      renderDynamicContent={() => renderDynamicContent(content)}
      renderDynamicButton={() => {
        if (button) {
          return renderDynamicButton(button, recoverButton)
        }
        return undefined
      }}
      handleClickOutside={handleClickOutside}
    />,
    renderNode
  )
}

export default DynamicPopupWithButton
