import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
// UTILS
import {
  currentServiceType,
  verifyTimeType,
  verifyPickupTime,
  vehicleTypes as vehicleTypesUtils,
  getVehicleType,
  getQuickChoice,
  isCustomerEditBooking,
  currentVehicleType as currentVehicleTypeUtils,
} from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
// ACTIONS
import * as vehicleTypeActionCreators from 'store/actions/new_booking/vehicleTypeActionCreators'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'
// COMPONENTS
// CONSTANTS
import {
  ID_NEW_VEHICLE_TYPE,
} from 'constants/newBookingConstants'
import { NOW } from 'constants/bookingConstants'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import { showQuickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/showQuickChoice.reducer'
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  currentVehicleType: currentVehicleTypeUtils(state),
  currentServiceTypeProps: currentServiceType(state),
  selectedServiceTypeID: state.selectedServiceTypeID,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  vehicleTypes: vehicleTypesUtils(state),
  timeType: state.timeType,
  pickupTime: state.pickupTime,
  quickChoiceID: state.quickChoiceID,
  extraInfos: state.extraInfos,
})

function mapDispatchToProps(dispatch) {
  return {
    timeTypeActions: bindActionCreators(timeTypeActionsCreator, dispatch),
    vehicleTypeActions: bindActionCreators(vehicleTypeActionCreators, dispatch),
    quickChoiceActions: bindActionCreators(quickChoiceActionsCreator, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch),
    datePickerActions: bindActionCreators(showDatePickerActionsCreator, dispatch),
    extraInfoActions: bindActionCreators(extraInfosActionsCreator, dispatch),
    showQuickChoiceActions: bindActionCreators(showQuickChoiceActionsCreator, dispatch),

  }
}

class VehicleType extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      currentVehicleType
    } = this.props
    if (!_.isEqual(currentVehicleType, prevProps.currentVehicleType)
      && currentVehicleType?.id
      && currentVehicleType?.name
    ) {
      window.Moengage.track_event('Vehicle Sub-Type Selected', {
        ID: currentVehicleType.id,
        Name: currentVehicleType.name
      })
    }
  }
  onChangeVehicleType(vehicleTypeID, isDisabledClick) {
    const { vehicleTypeActions, extraInfoActions } = this.props
    vehicleTypeActions.getNumberOfOnlineDrivers(vehicleTypeID)
    if (!isDisabledClick) {
      const {
        assignedDriver, selectedVehicleTypeID, currentPopupIDActions, infoAssignDriverPopupActions
      } = this.props
      if (assignedDriver && selectedVehicleTypeID !== vehicleTypeID) {
        Utils.updateInfoAssignDriverPopupActions(
          { func: this.onChangePickupTimeAfterCheckBookingDetails.bind(this, vehicleTypeID) },
          currentPopupIDActions,
          infoAssignDriverPopupActions
        )
      } else {
        this.onChangePickupTimeAfterCheckBookingDetails(vehicleTypeID)
      }
    } else {
      const { editModeTextActions } = this.props
      editModeTextActions.updateEditModeText(I18n.t('webapp.booking.vehicle_type'))
    }
    extraInfoActions.resetCashbackWhenUpdateService()
  }

  onChangePickupTimeAfterCheckBookingDetails(vehicleTypeID) {
    const {
      selectedVehicleTypeID,
      vehicleTypeActions,
      timeTypeActions,
      vehicleTypes,
      quickChoiceID,
      quickChoiceActions,
      showQuickChoiceActions,
      datePickerActions,
      timeType,
      setIsShowPickupTimePopup
    } = this.props
    const newVehicleType = getVehicleType(vehicleTypes, vehicleTypeID)
    const vehicleType = getVehicleType(vehicleTypes, selectedVehicleTypeID)
    if (vehicleTypeID !== selectedVehicleTypeID) {
      if (this.verifyTimeTypeWhenChangeVehicle(newVehicleType)) {
        if (quickChoiceID > 0) {
          const currentQuickChoice = getQuickChoice(vehicleType.quick_choices, quickChoiceID)
          const newQuickchoices = newVehicleType.quick_choices
          const quickChoices = _.filter(newQuickchoices, {
            time_type: currentQuickChoice.time_type,
            schedule_time: currentQuickChoice.schedule_time
          })
          if (quickChoices.length > 0) {
            vehicleTypeActions.changeVehicleType(vehicleTypeID)
            quickChoiceActions.changeQuickChoice(quickChoices[0].id)
          } else {
            window.localStorage.setItem(ID_NEW_VEHICLE_TYPE, vehicleTypeID)
            showQuickChoiceActions.changeShowQuickChoices(true)
          }
        } else {
          const isValidPickupTime = this.verifyPickupTimeWhenChangeVehicle(newVehicleType.settings)
          if (isValidPickupTime) {
            vehicleTypeActions.changeVehicleType(vehicleTypeID)
          } else {
            window.localStorage.setItem(ID_NEW_VEHICLE_TYPE, vehicleTypeID)
            datePickerActions.showDatePicker(true)
          }

          // For case switching from 'quick' (no quick choice) to 'quick choice'
          if (timeType === NOW && newVehicleType.quick_choices && newVehicleType.quick_choices.length) {
            timeTypeActions.changeTimeType('')
          }
        }
      } else {
        window.localStorage.setItem(ID_NEW_VEHICLE_TYPE, vehicleTypeID)
        setIsShowPickupTimePopup(true)
      }
    }
  }

  isActiveVehicleType(vehicleTypeID) {
    const { selectedVehicleTypeID } = this.props
    return vehicleTypeID === selectedVehicleTypeID
  }

  verifyTimeTypeWhenChangeVehicle(vehicleType) {
    const {
      timeType,
      quickChoiceID,
    } = this.props
    return verifyTimeType(vehicleType, timeType, quickChoiceID)
  }

  verifyPickupTimeWhenChangeVehicle(settings) {
    const {
      pickupTime,
      timeType,
    } = this.props
    return verifyPickupTime(settings, pickupTime, timeType)
  }

  render() {
    const {
      currentServiceTypeProps,
      selectedServiceTypeID,
      vehicleTypes,
    } = this.props
    const isDisabledClick = isCustomerEditBooking()

    return !(currentServiceTypeProps?.is_package || vehicleTypes.length === 1) && (
      <div className={`Vehicles Vehicles-List flex-wrap block ${isDisabledClick ? 'Ceb-disable-field' : ''}`}>
        {vehicleTypes.map((vehicleType, index) => {
          let selected = this.isActiveVehicleType(vehicleType.id) ? 'selected' : ''
          let selectedText
          if (isDisabledClick) {
            selected = this.isActiveVehicleType(vehicleType.id) ? 'Hidden-bg White-icon-important Hidden-bg-important' : 'Gray-icon-important'
            selectedText = this.isActiveVehicleType(vehicleType.id) ? 'White-text-important' : 'Gray-text-important'
          }
          const hideBorderRight = index % 2 ? 'Hide-Border-Right' : ''
          const checkBorder = index > 1 ? 'Border-Top Border-Top-Color' : ''

          return (
            <div
              key={`${selectedServiceTypeID}_${vehicleType.id}`}
              className={`block-item m-w160 ${selected} ${hideBorderRight} ${checkBorder}`}
              onClick={() => this.onChangeVehicleType(vehicleType.id, isDisabledClick)}
            >
              <span className="Radio">
                <input
                  name="booking[vehicle_type_id]"
                  value={vehicleType.id}
                  checked={this.isActiveVehicleType(vehicleType.id)}
                  type="radio"
                  id={`booking_vehicle_type_id_${vehicleType.id}`}
                  onChange={() => this.onChangeVehicleType(vehicleType.id, isDisabledClick)}
                />
              </span>
              <label
                className={selectedText}
              >
                {vehicleType.name}
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

VehicleType.defaultProps = {
  assignedDriver: undefined,
  timeType: undefined,
  currentVehicleType: undefined
}

VehicleType.propTypes = {
  assignedDriver: PropTypes.shape({}),
  vehicleTypes: PropTypes.instanceOf(Array).isRequired,
  extraInfoActions: PropTypes.shape({}),
  currentVehicleType: PropTypes.shape({}),
  currentServiceTypeProps: PropTypes.shape({}).isRequired,
  selectedServiceTypeID: PropTypes.number.isRequired,
  selectedVehicleTypeID: PropTypes.number.isRequired,
  vehicleTypeActions: PropTypes.shape({}).isRequired,
  timeType: PropTypes.string,
  pickupTime: PropTypes.string.isRequired,
  quickChoiceID: PropTypes.number.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  quickChoiceActions: PropTypes.shape({}).isRequired,
  editModeTextActions: PropTypes.shape({}).isRequired,
  setIsShowPickupTimePopup: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleType)
