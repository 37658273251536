import React from 'react'
import PropTypes from 'prop-types'
import InputPhoneNumber from '../../common/InputPhoneNumber'
import I18n from 'i18n/i18n'
import $ from 'jquery';
import _ from 'lodash';

class ModalEditPhoneNumber extends React.Component {
  constructor(props) {
    super(props)
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
    this.handleRefNumber = this.handleRefNumber.bind(this)
  }

  componentDidMount() {
    const { countryCode } = this.props
    $("[name='customer[country_code]']").val(countryCode)
  }

  clickButtonConfirm() {
    const {
      submitFormSignUp,
      paramSignUp,
      ableBackToSignUpForm,
      editPhoneNumberAndResendSms,
      currentCustomer
    } = this.props
    if (ableBackToSignUpForm) {
      const params = { ...paramSignUp }
      _.assign(params, { countryCode: $("[name='customer[country_code]']").val() })
      submitFormSignUp(params)
    } else {
      editPhoneNumberAndResendSms(currentCustomer.authentication_token)
    }
  }

  handleCustomerPhoneChange(phone) {
    const { paramSignUp, updateCurrentParamSignUp } = this.props
    _.assign(paramSignUp, { phone })
    updateCurrentParamSignUp(paramSignUp)
  }

  handleRefNumber(signUpPhone) {
    if (signUpPhone) {
      this.signUpPhone = signUpPhone.props
    }
  }

  render() {
    const { paramSignUp, countryCode } = this.props
    return (
      <div className="Verification VerificationEditNumber">
        <div className="VerificationBox Custom-Scroll-Group">
          <div className="Box">
            <div className="Box-Head">
              <h3 className="larger-font default-medium-font">
                {I18n.t('webapp.label.edit_number')}
              </h3>
            </div>
            <div className="Box-Content">
              <div className="pr10 pl10 pt10 pb10">
                <InputPhoneNumber
                  isWebApp
                  customRef={this.handleRefNumber}
                  closestElement="verification-number"
                  telInputProps={{ tabIndex: 1 }}
                  phoneNumber={paramSignUp ? paramSignUp.phone : ''}
                  countryCode={countryCode}
                  phoneField="customer[phone]"
                  countryCodeField="customer[country_code]"
                  onPhoneChange={this.handleCustomerPhoneChange}
                  onPhoneBlur={this.handleCustomerPhoneChange}
                  onPhoneFocus={this.handleCustomerPhoneChange}
                />
              </div>
              <input type="hidden" name="customer[country_code]" id="customer_country_code" />
              <div className="Box-Actions">
                <button type="button" className="Button green larger-font default-medium-font" onClick={() => this.clickButtonConfirm()}>
                  {I18n.t('webapp.action.confirm')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ModalEditPhoneNumber.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  paramSignUp: PropTypes.shape({}).isRequired,
  submitFormSignUp: PropTypes.func.isRequired,
  updateCurrentParamSignUp: PropTypes.func.isRequired,
  editPhoneNumberAndResendSms: PropTypes.func.isRequired,
  ableBackToSignUpForm: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
}
export default ModalEditPhoneNumber
