// for EXTRA SERVICES
export const RECEIVE_SERVICE_TYPES = 'RECEIVE_SERVICE_TYPES'
export const RECEIVE_VEHICLE_TYPES = 'RECEIVE_VEHICLE_TYPES'
export const RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES = 'RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES'
export const RECEIVE_EXTRA_SERVICES = 'RECEIVE_EXTRA_SERVICES'
export const RECEIVE_REGULAR_BY_OPTION_EXTRAS = 'RECEIVE_REGULAR_BY_OPTION_EXTRAS'
export const RECEIVE_FULLDAY_BY_OPTION_EXTRAS = 'RECEIVE_FULLDAY_BY_OPTION_EXTRAS'
export const RECEIVE_ALL_CUSTOM_REIMBURSEMENTS = 'RECEIVE_ALL_CUSTOM_REIMBURSEMENTS'
export const RECEIVE_BY_OPTION_EXTRAS = 'RECEIVE_BY_OPTION_EXTRAS'
export const UPDATE_EXTRA_REQUIREMENTS = 'UPDATE_EXTRA_REQUIREMENTS'
export const UPDATE_EXTRA_REQUIREMENT = 'COMMON/UPDATE_EXTRA_REQUIREMENT'
export const UPDATE_EXTRA_REQUIREMENT_NEGATIVE_POSITION = 'UPDATE_EXTRA_REQUIREMENT_NEGATIVE_POSITION'
export const UPDATE_FULL_DAY_PRICING = 'UPDATE_FULL_DAY_PRICING'
export const UPDATE_BADGE = 'UPDATE_BADGE'
export const UPDATE_COMPANY_BADGE = 'UPDATE_COMPANY_BADGE'
export const UPDATE_COMPANY_SETTING = 'UPDATE_COMPANY_SETTING'
export const RECEIVE_BATCH_VEHICLE_TYPE_BADGES = 'RECEIVE_BATCH_VEHICLE_TYPE_BADGES'
export const RECEIVE_BATCH_COMPANY_BADGES = 'RECEIVE_BATCH_COMPANY_BADGES'
export const FULL_DAY_RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES = 'FULL_DAY_RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES'
export const GET_BATCH_BOOKING_EXTRA_REQUIREMENTS = 'GET_BATCH_BOOKING_EXTRA_REQUIREMENTS'
export const GET_BATCH_BOOKING_EXTRA_REQUIREMENTS_NEGATIVE_POSITION = 'GET_BATCH_BOOKING_EXTRA_REQUIREMENTS_NEGATIVE_POSITION'
export const EXTRA_REQUIREMENT_VEHICLE_TYPE_NORMAL = 'normal'
export const EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS = 'by_options'
export const EXTRA_BY_OPTION = 'by_options'
export const EXTRA_NORMAL = 'normal'
export const UPDATE_SELECTED_EXTRA_REQUIREMENT_FOR_PER_LOCATION = 'UPDATE_SELECTED_EXTRA_REQUIREMENT_FOR_PER_LOCATION'
export const UPDATE_CUSTOM_REIMBURSEMENT = 'UPDATE_CUSTOM_REIMBURSEMENT'
export const PREVIOUS_SELECTED_SERVICE_AND_VEHICLE_TYPE_ID = 'PREVIOUS_SELECTED_SERVICE_AND_VEHICLE_TYPE_ID'
export const LONG_HAUL_RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES = 'LONG_HAUL_RECEIVE_EXTRA_REQUIREMENT_VEHICLE_TYPES'
export const RECEIVE_LONG_HAUL_BY_OPTION_EXTRAS = 'RECEIVE_LONG_HAUL_BY_OPTION_EXTRAS'
export const CLEAR_VEHICLE_TYPE_SETTINGS = 'CLEAR_VEHICLE_TYPE_SETTINGS'
