import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const dataChartVehicleTypeSlice = createSlice({
  name: 'dataChartVehicleType',
  initialState,
  reducers: {
    receiveDataChartVehicleType: (state: any, action: PayloadAction<any>) => action.payload.dataChartVehicleType
  },
  extraReducers: () => {},
})

export const dataChartVehicleTypeActionsCreator = dataChartVehicleTypeSlice.actions

export default dataChartVehicleTypeSlice
