/* Utils for Jquery */
import CommonUtils from './common'
import $ from 'jquery'
import _ from 'lodash'

export const RecentLocationsUtils = {
  openRecentLocations: (placeContainerID, inputID, closestLocations, multipleLine) => {
    const placeContainer = $(`#${placeContainerID}`)
    const input = $(`#${inputID}`)
    const calcHeightInput = multipleLine ? 60 : 36
    const scaleZoom = CommonUtils.detectSmallLaptop() ? (window.innerHeight / 100) * 20 : -calcHeightInput
    placeContainer.css({
      top: input.offset().top + calcHeightInput,
      maxHeight: $(document).height() - input.offset().top + scaleZoom,
    })
    if (!_.isUndefined(closestLocations)) {
      placeContainer.css({
        left: $(`#${closestLocations}`).offset().left,
        maxHeight: $(document).height() - input.offset().top - 57 + scaleZoom,
      })
    }
  },
  handleListEventKeyDown: (closestID, id, e) => {
    const container = $(`#${closestID}`)
    const input = $(`#${id}`)
    const listElement = container.find('.Place-Item')
    const existElement = listElement.hasClass('pac-item-selected')
    const selectElement = container.find('.Place-Item.pac-item-selected')
    if (_.size(container)) {
      const key = e.which || e.keyCode || 0
      switch (key) {
        case 13:
          selectElement.click()
          break
        case 38:
          if (existElement) {
            input.val(selectElement.prev().find('span:last-child').html())
            listElement.removeClass('pac-item-selected')
            selectElement.prev().addClass('pac-item-selected')
          }
          break
        case 40:
          if (existElement) {
            listElement.removeClass('pac-item-selected')
            selectElement.next().addClass('pac-item-selected')
            input.val(selectElement.next().find('span:last-child').html())
          } else {
            container.children().first().addClass('pac-item-selected')
            input.val(container.children().first().find('span:last-child').html())
          }
          break
        default:
          break
      }
    }
  },
  handleListEventKeyClick: (closestID) => {
    const container = $(`#${closestID}`)
    const selectElement = container.find('.Place-Item.pac-item-selected')
    selectElement.click()
  },
}

export { RecentLocationsUtils as default }
