import firebaseInit from 'utils/firebaseInit'
import _ from 'lodash'
import { currentServiceType, currentVehicleType, shouldShowDriverMaker } from 'utils/new_booking/common'
import GeoFire from 'libs/geofire' // custom lib
import { driverMarkersActionsCreator } from 'store/toolkit/driverMarkers/driverMarkers.reducer'
import { geoQueryActionsCreator } from 'store/toolkit/geoQuery/geoQuery.reducer'

const convertBearingToDegree = (bearing) => {
  let degree
  degree = Math.round(bearing / 10)
  if (degree < 0) {
    degree += 36
  }
  return degree
}

export const addDriverMarker = driver => (dispatch, getState) => {
  const iconPath = `${currentServiceType(getState()).top_view_icon_url}?heading=${convertBearingToDegree(driver.bearing)}`
  const map = getState().googleMap.map
  const marker = new window.google.maps.Marker({
    position: new window.google.maps.LatLng(driver.l[0], driver.l[1]),
    map,
    optimized: false,
    icon: iconPath,
    visible: shouldShowDriverMaker(map, getState().extraInfos),
  })
  _.assign(driver, { marker })
  dispatch(driverMarkersActionsCreator.addDriver({ driver }))
}

export const removeDriverMarker = driver => (dispatch) => {
  driver.marker.setMap(null)
  dispatch(driverMarkersActionsCreator.removeDriver({ id: driver.id }))
}

export const updateDriverMarker = driver => (dispatch, getState) => {
  const iconPath = `${currentServiceType(getState()).top_view_icon_url}?heading=${convertBearingToDegree(driver.bearing)}`
  const driverMarker = _.find(getState().driverMarkers, { id: driver.id })
  if (driverMarker) {
    const marker = driverMarker.marker
    _.assign(driver, { marker })
    const lat = driver.l[0]
    const lng = driver.l[1]
    marker.animateTo(new window.google.maps.LatLng(lat, lng), { duration: 10000 })
    marker.setIcon(iconPath)
    marker.setVisible(shouldShowDriverMaker(getState().googleMap.map, getState().extraInfos))
    dispatch(driverMarkersActionsCreator.updateDriver({ driver }))
  }
}

export const renderDriverMarkers = () => (dispatch, getState) => {
  let geoQuery = getState().geoQuery
  if (!_.isEmpty(geoQuery)) {
    geoQuery.cancel()
    dispatch(geoQueryActionsCreator.setGeoQuery({}))
  }
  Promise.resolve(
    getState().driverMarkers.forEach((driver) => { dispatch(removeDriverMarker(driver)) })
  ).then(() => {
    const path = `vehicle_types/${getState().selectedVehicleTypeID}/online_drivers`
    const geoFire = new GeoFire(firebaseInit.listen(path))
    const vehicleType = currentVehicleType(getState())
    const latLng = getState().extraInfos.default_center_lat_lng.split(',')
    if (getState().locations[0].marker) {
      const position = getState().locations[0].marker.getPosition()
      geoQuery = geoFire.query({
        center: [position.lat(), position.lng()],
        radius: vehicleType.settings.available_map_radius
      }, vehicleType.settings.show_more_vehicles_on_map)
    } else {
      if (_.size(latLng) !== 2) {
        return
      }
      geoQuery = geoFire.query({
        center: [parseFloat(latLng[0]), parseFloat(latLng[1])],
        radius: vehicleType.settings.available_map_radius
      }, vehicleType.settings.show_more_vehicles_on_map)
    }
    dispatch(geoQueryActionsCreator.setGeoQuery(geoQuery))
    geoQuery.on('key_entered', (key, location, distance, driver) => {
      _.assign(driver, { id: parseInt(key, 10) })
      dispatch(addDriverMarker(driver))
    })
    // geoQuery.on('key_exited', (key, location, distance, driver) => {
    //   _.assign(driver, { id: parseInt(key, 10) })
    //   dispatch(removeDriverMarker(driver))
    // })
    // geoQuery.on('key_moved', (key, location, distance, driver) => {
    //   _.assign(driver, { id: parseInt(key, 10) })
    //   dispatch(updateDriverMarker(driver))
    // })
  })
}
