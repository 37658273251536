import { DELIVEREE_AUTHENTICATION_SERVICE_URL } from 'constants/appConstants'
import apiClient from 'services/axiosApp'

const AuthenticationAPI = {
  convertToken: (authenticationToken, companyID) => apiClient.post(`${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v1/authentication/convert_token`, {
    ...(companyID ? { company_id: companyID } : {}),
    token: authenticationToken,
  })
}

export default AuthenticationAPI
