/* eslint-disable import/prefer-default-export */
// ACTIONS
import { updateIfRoundTripDiscountShouldShowChanged } from './roundTripDiscountActionCreators'

// CONSTANTS

import vehicleTypeAPIs from 'api/vehicles'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { selectedVehicleTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedVehicleTypeID.reducer'
import { countNumberOnlineDriversActionsCreator } from 'store/toolkit/newBooking/countNumberOnlineDrivers.reducer'

export const changeVehicleType = vehicleTypeID => (dispatch, getState) => {
  Promise.all([
    dispatch(selectedVehicleTypeIDActionsCreator.setSelectedVehicleTypeId(vehicleTypeID))
  ]).then(() => {
    dispatch(updateIfRoundTripDiscountShouldShowChanged(vehicleTypeID))
    dispatch(dataChangesActionsCreator.updateDataChange(true))
  })
}

export const getNumberOfOnlineDrivers = vehicleTypeID => (dispatch, getState) => {
  const state = getState()
  vehicleTypeAPIs.getNumberOfOnlineDrivers(vehicleTypeID, state.locations[0], (response) => {
    dispatch(countNumberOnlineDriversActionsCreator.getOnlineDriversOnVehicleType(response))
  })
}

export default { changeVehicleType, getNumberOfOnlineDrivers }
