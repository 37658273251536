import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const perPageSlice = createSlice({
  name: 'perPage',
  initialState,
  reducers: {
    receivePerpage: (state, action: PayloadAction<any>) => action.payload.per_page,
  },
  extraReducers: () => {},
})

export const perPageActionsCreator = perPageSlice.actions

export default perPageSlice
