import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash-es'
//UTILS
import I18n from 'i18n/i18n'
// ASSETS
import { LUCKY_STAR } from 'constants/imageConstants'
import {
  NOT_SAME_PERCENT
} from '../../../constants/common/batchConstants'

// ACTIONS
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import commonUtils from '../../../utils/common'

const mapDispatchToProps = (dispatch) => ({
  currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
})

const mapStateToProps = state => ({
  extraInfos: state.extraInfos,
})

function CashBackExplain(
  {
    currentPopupIDActions,
    cashBackPercent,
    customClassName,
    extraInfos
  }
) {
  const {
    cashback_dynamic_texts: cashBackDynamicText,
  } = extraInfos
  const closeModal = () => {
    $('body').css('overflow', 'auto')
    currentPopupIDActions.updateCurrentPopupID({ id: '' })
  }

  const isEveryTitle = commonUtils.isMultiple() || commonUtils.isBatchEZ() || commonUtils.isSmartLoad()

  let contentCashBackPopup = ''
  let titleCashBackPopup = ''
  if (!_.isEmpty(cashBackDynamicText)) {
    contentCashBackPopup = isEveryTitle
      ? cashBackDynamicText?.cashback_popup_content_multiple?.text
      : cashBackDynamicText?.cashback_popup_content_single?.text
    titleCashBackPopup = isEveryTitle
      ? cashBackDynamicText?.cashback_popup_title_multiple?.text
      : cashBackDynamicText?.cashback_popup_title_single?.text
  }

  return (
    <div className={`popup-wrapper cashback-explain ${customClassName}`}>
      <div className="overlay" onClick={closeModal} />
      <div className="popup-inner">
        <div className="content-wrapper">
          <div className="icon">
            <img src={LUCKY_STAR} alt="icon-calendar" />
          </div>
          {
            (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
              ? <>
                <p className="title">
                  {I18n.t(`webapp.cashback_popup.${isEveryTitle ? 'title_every' : 'title'}`)}
                </p>
                <p className="content">
                  {I18n.t(`webapp.cashback_popup.${isEveryTitle ? 'content_every' : 'content'}`, { percent: cashBackPercent })}
                </p>
              </>
              : <>
                <p className="title">
                  {titleCashBackPopup}
                </p>
                <p className="content">
                  {contentCashBackPopup}
                </p>
              </>
          }
        </div>
        <div className="btn-wrapper">
          <button type="button" className="button" onClick={closeModal}>
            {I18n.t('webapp.cashback_popup.button_text')}
          </button>
        </div>
      </div>
    </div>
  )
}

CashBackExplain.propTypes = {
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  cashBackPercent: PropTypes.string.isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  customClassName: PropTypes.string
}

CashBackExplain.defaultProps = {
  customClassName: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBackExplain)
