import StorageKeys from 'constants/storage-keys'
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'
import PATHS from 'routers/paths'
import toastr from 'utils/toast'

// interface PrivateRouteProps {
//   component: React.ReactNode
//   redirectPath?: string
// }

export const PrivateRoute: any = ({ redirectPath, component: Component }: { redirectPath: string, component: React.ReactNode}) => {
  const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  const isAuthenticated = !!authenticationToken

  if (isAuthenticated) return <>{Component}</>
  // we need add unauthenticated params to BE handle show notify in old webapp
  // toastr.error('You must be logged in to continue.')
  window.location.href = `${PATHS.SIGN_IN}?unauthenticated=true`

  // TODO: We will use this when move to new repo
  // return <Navigate to={redirectPath || PATHS.NEW_BOOKING} />
}
