import React from 'react'
import PropTypes from 'prop-types'

// ACTIONS
// ASSETS
// API
// COMPONENTS
// CONSTANTS
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
// UTILS
import I18n from 'i18n/i18n'

class DriverPreferenceButton extends React.PureComponent {
  render() {
    const {
      bookingID,
      handleChangePreferencesType,
      buttonType,
      currentSelected,
    } = this.props
    const buttonContent = {
      all: I18n.t('enum.customer.account_type.all_members'),
      favorite: I18n.t('driver_card.icon_name.favorite'),
      assign: I18n.t('webapp.new_booking.step_2.assign_driver.assign')
    }
    return (
      <div
        className={`Driver-Preferences-Items Driver-Preferences-${bookingID}-${buttonType} flex-index flex-center ${currentSelected === buttonType ? 'cur-default active disabled' : 'cur-pointer'}`}
        onClick={() => handleChangePreferencesType(buttonType)}
      >
        <span className={`default-font ${currentSelected === buttonType ? 'White-text' : 'default-color'}`}>
          {buttonContent[buttonType]}
        </span>
      </div>
    )
  }
}

DriverPreferenceButton.propTypes = {
  bookingID: PropTypes.string,
  buttonType: PropTypes.string,
  currentSelected: PropTypes.string,
  handleChangePreferencesType: PropTypes.func,
}

DriverPreferenceButton.defaultProps = {
  bookingID: '',
  currentSelected: DRIVER_PREFERENCES.all,
  buttonType: DRIVER_PREFERENCES.all,
  handleChangePreferencesType: () => { },
}

export default DriverPreferenceButton
