import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false
const roundTripDiscountSlice = createSlice({
  name: 'roundTripDiscount',
  initialState,
  reducers: {
    updateRoundTripDiscount: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const roundTripDiscountActionsCreator = roundTripDiscountSlice.actions

export default roundTripDiscountSlice
