import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
// UTILS
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { DRIVER_STATUS } from 'constants/driverConstants'
import { FLEET_PARTNER_STATUS } from 'constants/fleetPartnerConstants'
import {
  FAVORITE_ICON,
  UN_FAVORITE_ICON,
  BANNED_ICON,
  UN_BANNED_ICON
} from 'constants/imageConstants'
// API
// ASSETS

class DriverStatus extends React.PureComponent {
  renderSuspendIcon(driver) {
    const { addToList } = this.props
    return (
      <div className="Preference-Driver-Icon">
        <span
          className="Gray-bg White-text Radius-thirty cur-pointer Driver-Status-Icon center"
          onClick={() => addToList(driver)}
        >
          {I18n.t('enum.driver.approve_status.suspended')}
        </span>
      </div>
    )
  }

  renderBanButton(driverStatus) {
    const {
      driver,
      inListFavorite,
      addToList,
    } = this.props

    return (
      <button
        className={`Button Button-Default default-font ${(_.isUndefined(driverStatus) || !driverStatus) ? 'default-color' : 'Red-text'}`}
        type="button"
        onClick={() => addToList(driver.id, true)}
      >
        <i className="b material-icons Icon mr10">
          {
            inListFavorite
              ? (
                <img
                  src={BANNED_ICON}
                  className="image w24"
                />
              ) : (
                <img
                  src={UN_BANNED_ICON}
                  className="image w24"
                />
              )
          }
        </i>
        {I18n.t('webapp.label.ban')}
      </button>
    )
  }

  renderNormalActionIcon(driver) {
    const {
      addToList,
      processingDrivers,
      showFavoriteText,
    } = this.props
    const favoriteText = I18n.t('webapp.label.favorite')
    const driverStatus = processingDrivers[`${driver.id}`]

    return (
      <div className="Preference-Driver-Icon">
        <button
          type="button"
          className={`Button Button-Default default-font ${(_.isUndefined(driverStatus) || driverStatus) ? 'default-color' : 'Green-text'}`}
          onClick={() => addToList(driver.id, false, driver.fleet_partner_id, driver.fleet_partner_name)}
        >
          <i className="b material-icons Icon mr10">
            {
              (_.isUndefined(driverStatus) || driverStatus) ? (
                <img
                  src={UN_FAVORITE_ICON}
                  className="image w24"
                />
              ) : (
                <img
                  src={FAVORITE_ICON}
                  className="image w24"
                />
              )
            }
          </i>
          {
            showFavoriteText ? favoriteText : null
          }
        </button>
        {this.renderBanButton(driverStatus)}
      </div>
    )
  }

  render() {
    const {
      driver, addToList, isOnline, inListFavorite, showFavoriteText, showSearchDrivers
    } = this.props
    if (!_.isNull(driver.fleet_partner_id) && driver.fleet_partner_approve_status === FLEET_PARTNER_STATUS.suspend) {
      return this.renderSuspendIcon(driver)
    }
    if (
      showSearchDrivers
      || !_.isNull(driver.fleet_partner_id)
      || isOnline
      || (showFavoriteText && inListFavorite && !isOnline)) {
      return this.renderNormalActionIcon(driver)
    }
    switch (driver.approve_status) {
      case DRIVER_STATUS.inactive:
        return (
          <div className="Preference-Driver-Icon">
            <span
              className="Gray-bg White-text Radius-thirty cur-pointer Driver-Status-Icon center"
              onClick={() => addToList(driver)}
            >
              {I18n.t('webapp.preference_driver.inactive')}
            </span>
          </div>
        )
      case DRIVER_STATUS.auto_deactive:
      case DRIVER_STATUS.cs_suspends_driver:
      case DRIVER_STATUS.red_card:
      case DRIVER_STATUS.suspend:
      case DRIVER_STATUS.suspend_competitor_app:
      case DRIVER_STATUS.suspend_receive_booking:
      case DRIVER_STATUS.suspend_security_bond:
      case DRIVER_STATUS.suspend_police_clearance:
      case DRIVER_STATUS.suspend_sticker:
        return this.renderSuspendIcon(driver)
      default:
        if (!isOnline) {
          return (
            <span
              className="Orange-bg White-text Radius-thirty cur-pointer Driver-Status-Icon center"
              onClick={() => addToList(driver)}
            >
              {I18n.t('enum.driver.is_available.offline')}
            </span>
          )
        }
        return this.renderNormalActionIcon(driver)
    }
  }
}

DriverStatus.propTypes = {
  driver: PropTypes.instanceOf(Object).isRequired,
  isOnline: PropTypes.bool.isRequired,
  showSearchDrivers: PropTypes.bool,
  addToList: PropTypes.func,
  showFavoriteText: PropTypes.bool,
  inListFavorite: PropTypes.bool,
  processingDrivers: PropTypes.instanceOf(Object)
}

DriverStatus.defaultProps = {
  showSearchDrivers: false,
  addToList: () => { },
  showFavoriteText: false,
  inListFavorite: false,
  processingDrivers: {}
}

export default connect()(DriverStatus)
