/* eslint-disable import/prefer-default-export */
import _, { isEmpty } from 'lodash'
import { changeVehicleType } from './vehicleTypeActionCreators'
import BookingAPI from 'api/bookings'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { selectedServiceTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedServiceTypeID.reducer'
import { serviceTypesCommonActionsCreator } from 'store/toolkit/serviceTypes/serviceTypesCommon.reducer'

export const changeServiceType = (serviceTypeID, vehicleTypeID) => (dispatch, getState) => {
  Promise.resolve(
    dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(serviceTypeID))
  ).then(() => {
    const state = getState()
    const serviceType = _.find(state.serviceTypes, { id: state.selectedServiceTypeID })
    if (serviceType) {
      const vehicleTypes = serviceType.vehicle_types
      if (_.isEmpty(vehicleTypes)) {
        dispatch(changeVehicleType(null))
      } else if (vehicleTypeID) {
        dispatch(changeVehicleType(vehicleTypeID))
      } else {
        dispatch(changeVehicleType(vehicleTypes[0].id))
      }
    } else {
      dispatch(changeVehicleType(null))
    }
    dispatch(dataChangesActionsCreator.updateDataChange(true))
  })
}

export const updateServiceTypes = serviceTypes => (dispatch) => {
  dispatch(serviceTypesCommonActionsCreator.setServiceTypes(serviceTypes))
}

const getServiceTypesInfo = (areaId, companyId) => async (dispatch, getState) => {
  const dataServiceTypes = await BookingAPI.getServiceTypes(areaId, companyId)
  if(!isEmpty(dataServiceTypes?.data)) {
    dispatch(updateServiceTypes(dataServiceTypes.data))
    
    if (!getState().selectedServiceTypeID && !getState().selectedVehicleTypeID) {
      const serviceDefault = dataServiceTypes.data.find(service => service.is_default) || dataServiceTypes.data[0]
      dispatch(changeServiceType(serviceDefault.id))
    }
  }
}
export { getServiceTypesInfo }
