import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import {
  V2_ICON_GREEN,
  V2_ICON_GREEN_TRANSPORTIFY,
  BUSINESS_DEFAULT_LOGO
} from 'constants/imageConstants';
import { BLOCKED_STATUS } from 'constants/companyConstants';
import _ from 'lodash';
// ASSETS
import $ from 'jquery';

class ModalChooseAccount extends React.Component {
  static propTypes = {
    currentCustomer: PropTypes.object.isRequired,
    switchAccountHandler: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.checkPosition()
  }

  checkPosition() {
    if ($(ReactDOM.findDOMNode(this.refs.form)).height() > $(ReactDOM.findDOMNode(this.refs.form)).parent().height()) {
      $(ReactDOM.findDOMNode(this.refs.form)).addClass('Box-Custom');
    } else {
      $(ReactDOM.findDOMNode(this.refs.form)).removeClass('Box-Custom');
    }
  }

  selectAccountHandler(id, isActive = true) {
    if (!isActive) {
      $(this.refs.blockedCompanyModal).addClass("visible")
      return
    }
    this.props.switchAccountHandler(id)
  }

  checkModalStatus() {
    if ($('#choose-account-modal').length === 1) {
      $('#choose-account-modal').addClass('visible')
    }
  }

  render() {
    const { currentCustomer, closeModal } = this.props
    this.checkModalStatus()

    return (
      <div>
        <div className="visible Modal Modal-ChooseAccount zoom-scale-8" ref="chooseAccountModal" id="choose-account-modal">
          <div className="SelectAccountBox">
            <div className="Box pt10 pr10 pb10 pl10 z-index-2" ref="form">
              <b className="White-text larger-font Modal-ChooseAccount-Title pt5 pb15">
                {I18n.t('webapp.label.billing_account')}
              </b>
              <div className="Box-Content Modal-ChooseAccount-Content White-bg Custom-Scroll-Group Radius-default">
                <div className="title Box-List-Item small-font">
                  {I18n.t('business.choose_account.personal_account')}
                </div>
                <div
                  className={`Box-List-Item ${currentCustomer.current_company_id === 0 && 'Selected'}`}
                  onClick={() => this.selectAccountHandler(0)}
                >
                  <div className="b rounded Avatar">
                    <img src={currentCustomer.avatar_square} />
                  </div>
                  <div className="TitleSubtitle Shorted-Text">
                    <div className={`TitleSubtitle-title TitleSubtitle-title-name Shorted-Text ${currentCustomer.current_company_id === 0 && 'Dark-Green-text-important'}`}>
                      {currentCustomer.name}
                    </div>
                    <div className="TitleSubtitle-title TitleSubtitle-title-email Shorted-Text default-smaller-font">
                      {currentCustomer.email}
                    </div>
                  </div>
                  <div className="Overlay-RadioBox Green-RadioBox With-Icon">
                    <input type="radio" checked={currentCustomer.current_company_id === 0} />
                    <label>
                      <i className="b.material-icons.Icon">fiber_manual_record</i>
                    </label>
                  </div>
                </div>
                {this.renderEmploys()}
              </div>
            </div>
          </div>
          <div
            className="close-modal-overlay fixed"
            onClick={() => closeModal()}
          />
        </div>
        {this.renderModalBlockedCompany()}
      </div>
    )
  }

  renderEmploys() {
    const {
      currentCustomer: {
        current_company_id,
        employs,
        companies,
      } = {}
    } = this.props
    return !_.isEmpty(employs) && (
      <div>
        <div className="title Box-List-Item small-font">
          {I18n.t('business.choose_account.business_accounts')}
        </div>
        {employs.map((employ) => {
          const companyOwn = _.find(companies, c => c.id === employ.company_id)
          return this.renderEmploy(
            employ,
            companyOwn,
            companyOwn,
            current_company_id
          )
        })
        }
      </div>
    )
  }

  renderEmploy(employ, company, photo, currentCompanyId) {
    const activeCompany = company.status !== BLOCKED_STATUS
    const selected = company.id === currentCompanyId
    return (
      <div
        className={`Box-List-Item ${selected && 'Selected'}`}
        key={employ.id}
        onClick={() => {
          if (!selected) {
            this.selectAccountHandler(employ.company_id, activeCompany)
          }
        }}
      >
        <div className="b rounded Avatar">
          <img src={photo.photo_url || BUSINESS_DEFAULT_LOGO} alt="business default logo" />
        </div>
        <div className="TitleSubtitle Shorted-Text">
          <div className={`TitleSubtitle-title TitleSubtitle-title-name Shorted-Text ${selected && 'Dark-Green-text-important'}`}>
            {company.name}
          </div>
          <div className="TitleSubtitle-title TitleSubtitle-title-email Shorted-Text default-smaller-font">
            {employ.invited_email}
          </div>
        </div>
        <div className="Overlay-RadioBox Green-RadioBox With-Icon">
          <input type="radio" checked={selected} />
          <label><i className="b.material-icons.Icon">fiber_manual_record</i></label>
        </div>
      </div>
    )
  }

  renderModalBlockedCompany() {
    return (
      <div className="vertical-scroll Modal" ref="blockedCompanyModal">
        <div className="SelectAccountBox vertical-scroll ChangeAccountBox">
          <div className="Box">
            <p dangerouslySetInnerHTML={{ __html: I18n.t("errors.company.blocked") }}></p>
            <a href="javascript:void(0)" className="green Button close-modal">
              {I18n.t("button.close")}
            </a>
          </div>
        </div>
      </div>
    )
  }

};

export default ModalChooseAccount;