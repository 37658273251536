import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import _ from 'lodash'

class MultipleLineAddress extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      widthEachCharacter: 8,
      maxheightInput: 64,
    }

    this.input = undefined
    this.handleOnPressEnter = this.handleOnPressEnter.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleClick = this.handleClick.bind(this)

    this.handleDebounce = _.debounce(() => {
      this.handleBlur()
    }, 500)
  }

  componentDidMount() {
    this.detectMultipleLine()
  }

  componentWillReceiveProps(nextProps) {
    const { updateLocationKeyPress, value } = this.props
    if (updateLocationKeyPress !== nextProps.updateLocationKeyPress) {
      this.detectMultipleLine(nextProps.updateLocationKeyPress)
    }
    if (value !== nextProps.value && nextProps.value) {
      $(this.input).val(nextProps.value)
    }
  }

  onChange(event) {
    const {
      handleChange, value,
    } = this.props
    const newValue = event.target.value

    if (handleChange) {
      if (value !== newValue) {
        window.locationNameChanged = true
      }
      handleChange(newValue)
    }
    this.detectMultipleLine()
    this.handleDebounce()
  }

  detectMultipleLine(value = '') {
    const { maxheightInput, widthEachCharacter } = this.state
    const { widthCharacter, config: { id } } = this.props
    const elem = document.getElementById(`booking-${id}_temp`)
    const newValue = value || $(this.input).val()
    $(this.tempInput).val(newValue)

    const widthCharacterToCalculate = widthCharacter || widthEachCharacter

    setTimeout(() => {
      const positionHeight = elem.scrollHeight - 20
      if (elem.scrollHeight > 0) {
        if (positionHeight > maxheightInput) {
          const fitWidth = parseInt(($(this.input).outerWidth() / widthCharacterToCalculate), 10)
          const isTruncate = fitWidth * 3 <= newValue.length
          const showDots = isTruncate ? 3 : 0
          const truncateValue = newValue.substring(0, fitWidth * 3 - showDots)
          $(this.input).val(`${truncateValue} ${isTruncate ? '...' : ''}`)
        }
        $(this.input).css({
          height: positionHeight,
          maxHeight: 54,
        })
      }
    }, 250)
  }

  handleClick(event) {
    const { onClick } = this.props
    if (onClick) {
      onClick(event)
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      $(this.input).blur()
    }

    const { onKeyDown } = this.props
    if (onKeyDown) {
      onKeyDown(event)
    } else {
      this.handleOnPressEnter(event)
    }
  }

  handleOnPressEnter(event) {
    const {
      handleChange
    } = this.props
    if (event.keyCode === 13 && handleChange) {
      handleChange($(this.input).val())
    }
  }

  handleBlur() {
    const {
      handleChange,
      isBlur,
    } = this.props
    if (handleChange && isBlur) {
      handleChange($(this.input).val())
    }
  }

  handleRef(ref) {
    const { onRef } = this.props
    if (onRef) {
      onRef(ref)
    }
    this.input = ref
  }

  render() {
    const {
      config: {
        classContainer = '',
        classBox = '',
        classTextarea = '',
        id = '',
        name = '',
        disabled = false,
        placeholder = '',
      },
      value,
      maxLength,
      children,
      onFocusTextArea
    } = this.props

    return (
      <div className={`${classContainer} Multiple-Line Default-TextArea-Placeholder`}>
        <div className={`Multiple-Line__Input ${!_.isUndefined(children) ? 'flex' : ''} ${classBox}`}>
          <textarea
            ref={ref => this.handleRef(ref)}
            id={id}
            name={name}
            maxLength={maxLength}
            className={`Resize-None flex flex-index Radius-default mt10 pr10 pl10 pt0 pb10 sortable-enable ${classTextarea}`}
            placeholder={placeholder}
            defaultValue={value}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            onChange={this.onChange}
            onClick={this.handleClick}
            onFocus={e => onFocusTextArea(e)}
            disabled={disabled}
            style={{
              height: 18,
              resize: 'none',
              overflow: 'hidden',
            }}
          />
          {!_.isUndefined(children) ? children : null}
        </div>
        <textarea
          ref={(ref) => { this.tempInput = ref }}
          className={`Custom-Scroll-Group Resize-None flex flex-index Radius-default pt10 pr10 pb10 pl10 ${classTextarea}`}
          defaultValue={value}
          id={`booking-${id}_temp`}
          style={{
            height: 18,
            resize: 'none',
            visibility: 'hidden',
            opacity: 0,
            position: 'absolute',
            zIndex: -1,
            left: 0,
            width: 'calc(100% - 12px)',
            fontSize: '15px',
            overflowY: 'scroll',
          }}
        />
      </div>
    )
  }
}

MultipleLineAddress.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onRef: PropTypes.func,
  onClick: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  config: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  isBlur: PropTypes.bool,
  widthCharacter: PropTypes.number,
  updateLocationKeyPress: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  index: PropTypes.number.isRequired,
  bookingId: PropTypes.string,
  onFocusTextArea: PropTypes.func,
}

MultipleLineAddress.defaultProps = {
  onRef: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  maxLength: 255,
  value: '',
  config: {},
  children: undefined,
  isBlur: false,
  widthCharacter: undefined,
  updateLocationKeyPress: undefined,
  bookingId: '',
  onFocusTextArea: () => { }
}

export default MultipleLineAddress
