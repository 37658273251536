import React from 'react'
import PropTypes from 'prop-types'
import { OldSocialLogin as SocialLogin } from 'react-social-login'
// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import {
  V2_ICON_GREEN,
  V2_ICON_GREEN_TRANSPORTIFY,
  FACEBOOK_ICON,
  GOOGLE_ICON,
} from 'constants/imageConstants'
import { FACEBOOK_ID, GOOGLE_KEY } from 'constants/appConstants'
// ASSETS
import $ from 'jquery';
import _ from 'lodash'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'

class ModalSignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      logInParams: {},
    }
    this.onSubmitHandler = _.debounce(this.onSubmitHandler.bind(this), 300)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
  }

  componentDidMount() {
    hideLoading()
    const { signInLogin } = this.props
    $(this.form).validate({
      rules: {
        'customer[login]': {
          required: true
        },
        'customer[password]': {
          required: true,
          minlength: 6
        }
      },
      messages: {
        'customer[login]': {
          required: I18n.t('validations.required')
        },
        'customer[password]': {
          required: I18n.t('validations.required'),
          minlength: I18n.t('fleet_partners.validations.minlength', { number: 6 })
        }
      },
      highlight: element => (
        // eslint-disable-next-line no-sequences
        $(element).parents('.FormGroup').addClass('error'),
        $(element).parents('.Input').addClass('error')
      ),
      unhighlight: element => (
        // eslint-disable-next-line no-sequences
        $(element).parents('.FormGroup').removeClass('error'),
        $(element).parents('.Input').removeClass('error')
      ),
      errorClass: 'FormGroup-explanation',
      errorPlacement: (error, element) => {
        error.insertAfter($(element).parents('.Input'))
      },
      submitHandler: (form, event) => {
        event.preventDefault()
        this.onSubmitHandler()
        return false
      }
    })

    this.checkPosition()
    if (signInLogin) {
      const params = {
        account: signInLogin
      }
      this.setState({ logInParams: params })
    }
  }

  onSubmitHandler() {
    const { submitFormSignin, countryCode, isLoadingSignIn } = this.props
    const params = {
      countryCode,
      login: this.signInLogin.value,
      password: this.signInPassword.value
    }
    if (!isLoadingSignIn) {
      submitFormSignin(params)
    }
  }

  onFieldChanges(e, key) {
    const { logInParams } = this.state
    logInParams[key] = e.target.value
    this.setState({ logInParams })
  }

  checkPosition() {
    if ($(this.form).height() > $(this.form).parent().height()) {
      $(this.form).addClass('Box-Custom')
    } else {
      $(this.form).removeClass('Box-Custom')
    }
  }

  toggleShowPassword() {
    this.setState(previousState => ({ showPassword: !previousState.showPassword }))
  }

  render() {
    const {
      closeModal, goToFogotPassword, goToSignUp, handleSocialLogin,
      customerSignInDirect
    } = this.props
    const { showPassword } = this.state
    const { logInParams } = this.state
    const LOGO_ICON = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    return (
      <div className="visible vertical-scroll Modal">
        <div className="LoginBox vertical-scroll">
          <form className="Box" ref={(rf) => { this.form = rf }}>
            {!customerSignInDirect && (
              <span className="LoginBoxCustom-Close" onClick={closeModal}>
                <i className="b material-icons Icon">
                  close
                </i>
              </span>)}
            <div className="Box-Head">
              <div className="Logo">
                <img src={LOGO_ICON} alt="deliveree" />
              </div>
            </div>
            <div className="Box-Content">
              <div className="Form">
                <div className="FormGroup">
                  <label className="FormGroup-label" htmlFor="customer_login">
                    {I18n.t('devise_template.email_address_or_phone_number')}
                  </label>
                  <div className="Input">
                    <input
                      type="text"
                      value={logInParams ? logInParams.account : ''}
                      ref={(rf) => { this.signInLogin = rf }}
                      name="customer[login]"
                      id="customer_login"
                      tabIndex="1"
                      onChange={e => this.onFieldChanges(e, 'account')}
                    />
                  </div>
                </div>
                <div className="FormGroup">
                  <label className="FormGroup-label">
                    {I18n.t('devise_template.password')}
                    <button
                      type="button"
                      tabIndex="3"
                      onClick={goToFogotPassword}
                    >
                      {I18n.t('devise_template.i_forgot')}
                    </button>
                  </label>
                  <div className="Input Custom">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      ref={(rf) => { this.signInPassword = rf }}
                      name="customer[password]"
                      autoComplete="off"
                      tabIndex="1"
                    />
                    <label className="Input-Icon">
                      <input
                        type="checkbox"
                        className="hidden"
                        onChange={this.toggleShowPassword}
                      />
                      <i className="b material-icons Icon">
                        visibility
                      </i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="Box-Actions">
              <input className="green Button" type="submit" value={I18n.t('devise_template.log_in')} />
            </div>
            <div className="Box-Actions">
              <button
                type="button"
                className="green-border green-text Button"
                onClick={goToSignUp}
              >
                {I18n.t('devise_template.create_an_account')}
              </button>
            </div>
            <div className="copyright Box-Actions" />
            <div className="Box-Content-Social-Line">
              <h5>
                {I18n.t('customers.sessions.new.or_login_with')}
              </h5>
              <div className="line" />
            </div>
            <div className="Box-Content">
              <div className="Box-Content-Social">
                <SocialLogin
                  provider="facebook"
                  autoCleanUri
                  appId={FACEBOOK_ID}
                  callback={handleSocialLogin}
                  className="facebook-login"
                >
                  <a className="Button social-facebook">
                    <img src={FACEBOOK_ICON} />
                    <span>
                      {I18n.t('customers.sessions.new.login_with_facebook')}
                    </span>
                  </a>
                </SocialLogin>
                <SocialLogin
                  provider="google"
                  autoCleanUri
                  appId={GOOGLE_KEY}
                  callback={handleSocialLogin}
                  className="google-login"
                >
                  <a className="Button social-google">
                    <img src={GOOGLE_ICON} />
                    <span>
                      {I18n.t('customers.sessions.new.login_with_google')}
                    </span>
                  </a>
                </SocialLogin>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ModalSignIn.defaultProps = {
  customerSignInDirect: false
}

ModalSignIn.propTypes = {
  signInLogin: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToFogotPassword: PropTypes.func.isRequired,
  submitFormSignin: PropTypes.func.isRequired,
  goToSignUp: PropTypes.func.isRequired,
  handleSocialLogin: PropTypes.func.isRequired,
  isLoadingSignIn: PropTypes.bool.isRequired,
  customerSignInDirect: PropTypes.bool,
}

export default ModalSignIn
