import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  routes: [],
  mapLoaded: false,
  mapInstance: null,
}

const optimizeRoutesSlice = createSlice({
  name: 'optimizeRoutes',
  initialState,
  reducers: {
    updateOptimizeRoutes: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        return {
          ...state,
          ...action.payload,
        }
      }
    },
    updateStatus: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        status: action.payload,
      }
    },
    updateOptimizeLocations: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        optimizedLocations: action.payload,
      }
    },
    updateErrors: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        bundle_errors: action.payload,
      }
    },
  },
  extraReducers: () => {},
})

export const optimizeRoutesActionsCreator = optimizeRoutesSlice.actions

export default optimizeRoutesSlice
