import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  temp_id: '',
  order: '',
  name: '',
  lat: undefined,
  lng: undefined,
  postcode: '',
  recipient_name: '',
  recipient_phone: '',
  location_note: '',
  need_pod: '',
  pod_note: '',
  need_cod: '',
  cod_note: '',
  cod_invoice_fees: '',
  verify: { name: true, recipient_name: true, recipient_phone: true },
  marker: undefined,
}

// todo: we must to remove this reducer, because we will define constant for newlocation
const newLocationSlice = createSlice({
  name: 'newLocation',
  initialState,
  reducers: {},
  extraReducers: () => {},
})

export const newLocationActionsCreator = newLocationSlice.actions

export default newLocationSlice
