import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const selectedLocationSlice = createSlice({
  name: 'selectedLocation',
  initialState,
  reducers: {
    setSelectedLocation: (state, action: PayloadAction<any>) => ({
      ...action.payload.location,
    }),
  },
  extraReducers: () => {},
})
export const selectedLocationActionsCreator = selectedLocationSlice.actions

export default selectedLocationSlice
