import PopupAPI from 'api/popups'
import {
  POPUP_TYPE_FULL_DAY_ETA,
  POPUP_TYPE_NON_FULL_DAY_ETA
} from 'constants/common/popupConstants'
import { FULL_DAY } from 'constants/newBookingConstants'
import { popupContentManagementActionsCreator } from 'store/toolkit/popupContentManagement/popupContentManagement.reducer'

export const fetchPopupContent = (areaId, timeType, currentCustomer) => dispatch => (
  new Promise((resolve) => {
    const params = {
      popup_type: timeType === FULL_DAY ? POPUP_TYPE_FULL_DAY_ETA : POPUP_TYPE_NON_FULL_DAY_ETA,
      area_id: areaId,
    }

    PopupAPI.getAreaTypePopup(currentCustomer.authentication_token, params, (result) => {

      resolve(dispatch(popupContentManagementActionsCreator.addPopupContent({
        data: {
          ...params,
          data: result?.data?.object,
        }
      })))
    })
  })
)

export { fetchPopupContent as default }
