import $ from 'jquery'
import * as common from '../common'
import I18n from 'i18n/i18n'
import toastr from "utils/toast";
import _ from 'lodash';

export function showError() {
  common.closeLoading()
  const ms = I18n.t('closures.confirm.rate_error')
  toastr.error(ms)
}

export function showPopup(result, element, needToReload) {
  $('#closure_modal_popup_content').html(result)
  common.closeLoading()
  if (needToReload) {
    window.ReactOnRails.reactOnRailsPageLoaded()
  }
  $(element).addClass('visible')
  $(`${element} .Popup`).css({ 'z-index': 2 })
  $(element).append('<span class="close-modal-common"></span>')

  common.checkHeightPopup($(`${element} .Popup`))
  common.closeModal_fix()
}

export function showBookingLocations(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/booking_locations',
    success(result) {
      showPopup(result, '.Modal.addresses-modal-content')
    },
    error: showError
  })
}

export function showBookingNote(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/booking_note',
    success(result) {
      showPopup(result, '.Modal.note-modal-content')
    },
    error: showError
  })
}

export function showBookingAttachments(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/booking_attachments',
    success(result) {
      showPopup(result, '.Modal.attachments-modal-content')
      common.showGalleryCustom()
    },
    error: showError
  })
}

export function showBookingDocsCod(bookingId, target = 'pod') {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { target },
    url: `/bookings/${bookingId}/pod_cod`,
    success(result) {
      showPopup(result, '.Modal.docs-cod-modal-content')
      common.showGalleryCustom()
      common.podShippingCourier()
    },
    error: showError
  })
}

export function showBookingProofOfDelivery(bookingId) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    url: `/bookings/${bookingId}/proof_of_delivery`,
    success(result) {
      showPopup(result, '.Modal.goods-modal-content')
      common.showGalleryCustom()
    },
    error: showError
  })
}

export function showBookingSignatures(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/booking_signatures',
    success(result) {
      showPopup(result, '.Modal.signatures-modal-content')
      common.showGalleryCustom()
    },
    error: showError
  })
}

export function showBookingDriverNote(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/booking_driver_note',
    success(result) {
      showPopup(result, '.Modal.driver-note-modal-content')
    },
    error: showError
  })
}

export function showDriverRate(bookingID) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    data: { booking_id: bookingID },
    url: '/bookings/driver_rate_dialog',
    success(result) {
      common.closeLoading()
      $('#closure_modal_popup_content').html(result)
      $('.Modal.rate_driver-modal-content').addClass('visible')

      const checkHeightPopup = $('.Modal.rate_driver-modal-content .Popup').height() + 120
      if ($('.Modal.rate_driver-modal-content').find('.Normal-Booking-Actions').length === 0) {
        $('.Modal.rate_driver-modal-content .Popup').css({ 'z-index': 2 })
        $('.Modal.rate_driver-modal-content').append('<span class="close-modal-common"></span>')

        if (checkHeightPopup > $('.Modal.rate_driver-modal-content .Popup').parent().height()) {
          $('.Modal.rate_driver-modal-content .Popup').addClass('top-align No-Seft')
          $('.close-modal-common').css({
            'min-height': '100%',
            height: checkHeightPopup
          })
        } else {
          $('.Modal.rate_driver-modal-content .Popup').removeClass('top-align No-Seft')
        }

        common.closeModal_fix()
      } else if (checkHeightPopup > $('.Modal.rate_driver-modal-content .Popup').parent().height()) {
        $('.Modal.rate_driver-modal-content .Popup').addClass('top-align No-Seft')
      } else {
        $('.Modal.rate_driver-modal-content .Popup').removeClass('top-align No-Seft')
      }
    },
    error: showError
  })
}

// submit booking rating
export function submitRating(params) {
  common.closeModal()
  common.openLoading()
  $.ajax({
    method: 'PUT',
    data: params,
    url: '/bookings/customer_rate_driver_booking',
    success(result) {
      common.closeLoading()
      if (result.status === true) {
        toastr.success(result.message)
      } else {
        toastr.error(result.message)
      }
    },
    error: showError
  })
}

function updateReferenceHtmlColorStatus(driverID, itemType, actionType) {
  const banDriverCss = `.ban-driver-${driverID}`
  const favoriteDriverCss = `.favorite-driver-${driverID}`
  if (actionType === 'add_reference') {
    // update active color for Favorite / Ban
    if (itemType === 'favorite') {
      $(banDriverCss).removeClass('Ban-Icon-active')
      $(favoriteDriverCss).addClass('Favorite-Icon-active')
    } else {
      $(favoriteDriverCss).removeClass('Favorite-Icon-active')
      $(banDriverCss).addClass('Ban-Icon-active')
    }
  } else if (itemType === 'favorite') {
    $(favoriteDriverCss).removeClass('Favorite-Icon-active')
  } else {
    $(banDriverCss).removeClass('Ban-Icon-active')
  }
}

export function updateCustonerDriverReference(bookingID, itemType, actionType, companyID) {
  common.openLoading()
  $.ajax({
    method: 'PUT',
    data: {
      booking_id: bookingID, item_type: itemType, action_type: actionType, company_id: companyID
    },
    url: '/bookings/update_customer_driver_reference',
    success(result) {
      common.closeLoading()
      updateReferenceHtmlColorStatus(result.driver_id, itemType, actionType)
    },
    error: showError
  })
}

//
export function getChatToken(callback) {
  common.openLoading()
  $.ajax({
    method: 'GET',
    url: '/customers/get_chat_token',
    success(result) {
      common.closeLoading()
      if (result.success) {
        // init chat function
        callback(result.data)
      } else {
        toastr.error(result.message)
      }
    },
    error() {
      common.closeLoading()
    }
  })
}

export function updateReimbursementFullday() {
  let subTotal = 0
  let freeFreeReimbursements = parseFloat($('#free_reimbursements').attr('reimbursement-max-cap'))
  $.each($('.reimbursement_checked_items:not(:checked)'), (index, element) => {
    subTotal += parseFloat($(element).attr('reimbursement-fee'))
  })
  freeFreeReimbursements = _.min([freeFreeReimbursements, subTotal])
  if (freeFreeReimbursements !== 0) {
    freeFreeReimbursements *= -1
  }
  const total = subTotal + freeFreeReimbursements
  const currency = $('#reimbursements_total').attr('currency-data')
  $('#reimbursements_sub_total').text(I18n.toCurrency(subTotal, { unit: currency, precision: 0 }))
  $('#free_reimbursements').text(I18n.toCurrency(freeFreeReimbursements, { unit: currency, precision: 0 }))
  $('#reimbursements_total').text(I18n.toCurrency(total, { unit: currency, precision: 0 }))
}

export function updateReimbursementNormal(reimbursementFee, isCheck) {
  let total = parseFloat($('#normal_reimbursments_total').attr('total-data'))
  const currency = $('#normal_reimbursments_total').attr('currency-data')
  if (isCheck) {
    total -= reimbursementFee
  } else {
    total += reimbursementFee
  }
  const totalText = currency + total.toLocaleString()
  $('#normal_reimbursments_total').attr('total-data', total)
  $('#normal_reimbursments_total').text(totalText)
}

export function deleteDraft(id) {
  const dataString = $('#search-draft-form').serialize()
  $.ajax({
    method: 'DELETE',
    url: `/draft_bookings/${id}`,
    data: dataString,
    success() {
      common.closeLoading()
    },
    error: showError
  })
}

export function deleteAllDrafts() {
  const dataString = $('#search-draft-form').serialize()
  $.ajax({
    method: 'DELETE',
    url: '/draft_bookings/destroy_all',
    data: dataString,
    success() {
      common.closeLoading()
    },
    error: showError
  })
}
