import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class DropdownActions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.renderSelectedClassName = this.renderSelectedClassName.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  onClick(onClick) {
    this.setState({
      expanded: false,
    }, () => onClick())
  }

  handleClickOutside(event) {
    if (this.setWrapperRef && !this.setWrapperRef.contains(event.target)) {
      this.setState({ expanded: false })
    }
  }

  handleClick() {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  renderSelectedClassName(item, key) {
    const {
      selectedItem
    } = this.props
    if ((!selectedItem && key === 0)
      || (selectedItem && selectedItem.id === item.id)) {
      return 'selected-item'
    }
    return null
  }

  renderExpanded() {
    const {
      isArrow, source, theme
    } = this.props
    const cloneSource = _.filter(source, { isShow: true })
    return (
      <div
        className="absoute pl10 pr10 pt15 pb15 Radius-two Dropdown-Actions"
        style={{
          backgroundColor: theme,
          right: 0,
          top: '100%',
          marginTop: isArrow ? -4 : 0,
          boxShadow: '1px 1px 6px 0 rgba(0, 0, 0, 0.15)',
          border: 'solid 1px #dbdbdb',
          zIndex: 10
        }}
      >
        {isArrow && (
          <span
            className="Dropdown-Actions-Arrow"
            style={{
              borderBottom: `10px solid ${theme}`,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              position: 'absolute',
              top: '-8px',
              right: '7px',
              zIndex: 2,
            }}
          />
        )}
        {isArrow && (
          <span
            className="Dropdown-Actions-Arrow"
            style={{
              borderBottom: '11px solid rgb(219, 219, 219)',
              borderLeft: '11px solid transparent',
              borderRight: '11px solid transparent',
              position: 'absolute',
              top: '-10px',
              right: '6px',
              zIndex: 1,
            }}
          />
        )}
        {cloneSource.map((session, key) => {
          const {
            id, title, icon, onClick,
          } = session

          return (
            <div
              key={`list-${id}`}
              className={`${(key + 1) !== cloneSource.length && 'mar15'} flex flex-center-algin cur-pointer Green-text-hover ${this.renderSelectedClassName(session, key)}`}
              onClick={() => this.onClick(onClick)}
            >
              <img src={icon} width="18" />
              <span className="ml10 default-color default-small-font Wrap-Text">
                {title}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const {
      className, classNameOnClick, renderLayout, id, source
    } = this.props
    const { expanded } = this.state
    const cloneSource = _.filter(source, { isShow: true })

    if (_.size(cloneSource) < 1) {
      return <div />
    }

    return (
      <div
        className={`Input-Hover-Group ${expanded && 'expanded'}`}
        ref={(ref) => { this.setWrapperRef = ref }}
      >
        <div
          id={`dropdown-action-${id}`}
          className={`dropdown-actions ${expanded && 'dropdown-actions--active'} ${className} ${expanded && classNameOnClick} cur-pointer`}
          onClick={() => this.handleClick()}
        >
          {renderLayout()}
        </div>
        {expanded && this.renderExpanded()}
      </div>
    )
  }
}

DropdownActions.propTypes = {
  renderLayout: PropTypes.func.isRequired,
  source: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  classNameOnClick: PropTypes.string,
  id: PropTypes.string,
  isArrow: PropTypes.bool,
  theme: PropTypes.string,
  selectedItem: PropTypes.shape({}),
}

DropdownActions.defaultProps = {
  className: '',
  classNameOnClick: '',
  id: undefined,
  isArrow: false,
  theme: '#FFF',
  selectedItem: {}
}

export default DropdownActions
