// UTILS
// API
import PlaceAPI from 'api/external/places'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { RADIUS_RECENT_LOCATION } from 'constants/common/locationConstants'
import { recentLocationsActionsCreator } from 'store/toolkit/recentLocations/recentLocations.reducer'
// ASSETS


export const loadRecentLocations = accessToken => (dispatch) => {
  PlaceAPI.loadRecentLocations(accessToken, (response) => {
    if (response?.status === 401) return
    dispatch(recentLocationsActionsCreator.updateRecentLocations(response?.data?.locations))
  })
}

export const setRecentLocation = (currentCustomer, location) => (dispatch) => {
  const accessToken = window.localStorage.getItem('access_token')
  if (accessToken) {
    const recentLocation = {
      latitude: location.lat,
      longitude: location.lng,
      name: location.name,
      countryCode: currentCustomer.country_code,
      radius: RADIUS_RECENT_LOCATION,
      pic_name: location.recipient_name,
      pic_number: location.recipient_phone,
      address_detail: location.description,
      location_name: location.location_name,
      contact_id: location.contact_id,
      address_components: location.address_components,
    }
    PlaceAPI.setRecentLocation(accessToken, recentLocation, (response) => {
      if (!response) return
      dispatch(loadRecentLocations(accessToken))
    })
  } else if (!_.isEmpty(currentCustomer.authentication_token)) {
    dispatch(setRecentLocation(currentCustomer, location))
  }
}


export const updateAddressComponentRecentLocation = (currentCustomer, location) => (dispatch) => {
  const accessToken = window.localStorage.getItem('access_token')
  if (accessToken) {
    const recentLocation = {
      address_components: location.address_components,
      id: location.recentLocationId
    }
    PlaceAPI.updateAddressComponent(accessToken, recentLocation, (response) => {
      if (!response) return
      dispatch(loadRecentLocations(accessToken))
    })
  } else if (!_.isEmpty(currentCustomer.authentication_token)) {
    dispatch(updateAddressComponentRecentLocation(currentCustomer, location))
  }
}
