import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js'
import _ from 'lodash'
import $ from 'jquery'

const countriesCode = ['vn', 'id', 'ph', 'th', 'af', 'dz', 'ad', 'ao', 'ag', 'ar', 'am', 'au', 'at', 'az', 'bs', 'bh', 'bd', 'bb', 'by', 'be', 'bz', 'bj', 'bt', 'bo', 'ba', 'bw', 'br', 'bn', 'bg', 'bf', 'bi', 'kh', 'cm', 'ca', 'cv', 'cf', 'cl', 'cn', 'co', 'km', 'cd', 'cg', 'cr', 'ci', 'hr', 'cu', 'cy', 'cz', 'dk', 'dj', 'dm', 'do', 'ec', 'eg', 'sv', 'gq', 'er', 'ee', 'et', 'fj', 'fi', 'fr', 'ga', 'gm', 'ge', 'de', 'gh', 'gr', 'gd', 'gt', 'gn', 'gw', 'gy', 'ht', 'hn', 'hk', 'hu', 'is', 'in', 'id', 'ir', 'iq', 'ie', 'il', 'it', 'jm', 'jp', 'jo', 'kz', 'ke', 'ki', 'xk', 'kw', 'kg', 'la', 'lv', 'lb', 'ls', 'lr', 'ly', 'li', 'lt', 'lu', 'mo', 'mk', 'mg', 'mw', 'my', 'mv', 'ml', 'mt', 'mh', 'mr', 'mu', 'mx', 'fm', 'md', 'mc', 'mn', 'me', 'ma', 'mz', 'mm', 'na', 'nr', 'np', 'nl', 'nz', 'ni', 'ne', 'ng', 'kp', 'no', 'om', 'pk', 'pw', 'ps', 'pa', 'py', 'pe', 'ph', 'pl', 'pt', 'qa', 'ro', 'ru', 'rw', 'kn', 'lc', 'vc', 'ws', 'sm', 'st', 'sa', 'sn', 'rs', 'sc', 'sl', 'sg', 'sk', 'si', 'sb', 'so', 'za', 'kr', 'ss', 'es', 'lk', 'sd', 'sr', 'sz', 'se', 'ch', 'sy', 'tw', 'tj', 'tz', 'th', 'tl', 'tg', 'to', 'tt', 'tn', 'tr', 'tm', 'tv', 'ug', 'ua', 'ae', 'gb', 'us', 'uy', 'uz', 'vu', 'va', 've', 'vn', 'ye', 'zm', 'zw']

const dialCodes = ['84', '62', '63', '66', '93', '213', '376', '244', '1268', '54', '374', '61', '43', '994', '1242', '973', '880', '1246', '375', '32', '501', '229', '975', '591', '387', '267', '55', '673', '359', '226', '257', '855', '237', '1', '238', '236', '56', '86', '57', '269', '243', '242', '506', '225', '385', '53', '357', '420', '45', '253', '1767', '1', '593', '20', '503', '240', '291', '372', '251', '679', '358', '33', '241', '220', '995', '49', '233', '30', '1473', '502', '224', '245', '592', '509', '504', '852', '36', '354', '91', '62', '98', '964', '353', '972', '39', '1876', '81', '962', '7', '254', '686', '383', '965', '996', '856', '371', '961', '266', '231', '218', '423', '370', '352', '853', '389', '261', '265', '60', '960', '223', '356', '692', '222', '230', '52', '691', '373', '377', '976', '382', '212', '258', '95', '264', '674', '977', '31', '64', '505', '227', '234', '850', '47', '968', '92', '680', '970', '507', '595', '51', '63', '48', '351', '974', '40', '7', '250', '1869', '1758', '1784', '685', '378', '239', '966', '221', '381', '248', '232', '65', '421', '386', '677', '252', '27', '82', '211', '34', '94', '249', '597', '268', '46', '41', '963', '886', '992', '255', '66', '670', '228', '676', '1868', '216', '90', '993', '688', '256', '380', '971', '44', '1', '598', '998', '678', '39', '58', '84', '967', '260', '263']

const ONLY_NUMBER_REGEX = /[^0-9]+/g
const NUMBER_WITH_PLUS_REGEX = /[^0-9+]+/g
const LEADING_ZEROS_REGEX = /^[0]+/

const phoneNumberUtils = {
  formatPhoneNumber(phoneNumber, countryCode, isContact = false, spaceAfterCountryCode = false) {
    let phoneCountryCode = countryCode
    if (
      _.size(phoneNumber) === 0
      || _.isUndefined(phoneNumber)
      || _.isEmpty(phoneNumber)
      || (
        !_.isUndefined(phoneNumber) && _.isUndefined(parsePhoneNumberFromString(phoneNumber.replace(/\s/g, ''))) && !isContact
      )) {
      return { countryCode: phoneCountryCode, phoneNumber: '' }
    }
    let phone = phoneNumber.replace(NUMBER_WITH_PLUS_REGEX, '')
    const isFirstPlus = phone.startsWith('+')
    phone = phone.replace(ONLY_NUMBER_REGEX, '')
    phone = phone.replace(LEADING_ZEROS_REGEX, '')
    if (isContact) {
      phone = isFirstPlus ? `+${phone}` : phone
    }
    const parsedPhone = parsePhoneNumberFromString(phone)
    if (!_.isUndefined(parsedPhone) || !_.isEmpty(parsedPhone)) {
      const parsedPhoneCountryCode = parsedPhone.country
      if (_.isUndefined(parsedPhoneCountryCode)) {
        phoneCountryCode = this.getCountryCodeByCallingCode(
          phone,
          phoneCountryCode
        )
      } else {
        phoneCountryCode = _.toLower(parsedPhoneCountryCode)
      }
      phone = parsedPhone.nationalNumber
    } else if (!(isContact && !isFirstPlus)) {
      // Remove dial code same as country
      const dialCode = this.getCallingCode(phone)
      phoneCountryCode = this.findCountryCodeByCallingCode(dialCode)
      if (_.size(dialCode) > 0) {
        phone = phone.substring(dialCode.length)
      }
      phone = phone.replace(LEADING_ZEROS_REGEX, '')
    }
    let countryCodeStr = null
    if (!_.isUndefined(phoneCountryCode) && !_.isEmpty(phoneCountryCode)) {
      countryCodeStr = getCountryCallingCode(_.toUpper(phoneCountryCode))
    }
    const countryCodeLength = _.isNull(countryCodeStr) ? 3 : countryCodeStr.length + 1
    phone = phone.charAt(0) === '+' ? phone.substr(countryCodeLength) : phone
    if (isContact) {
      phone = spaceAfterCountryCode
        ? `+${countryCodeStr} ${phone}`
        : ['+', countryCodeStr, phone].join('')
    }
    return { countryCode: phoneCountryCode, phoneNumber: phone }
  },
  findCountryCodeByCallingCode(callingCode) {
    const index = dialCodes.indexOf(callingCode)
    return index >= 0 ? countriesCode[index] : undefined
  },
  findDialCodeByCountryCode(countryCode) {
    const index = countriesCode.indexOf(countryCode)
    return index >= 0 ? dialCodes[index] : ''
  },
  getCountryCodeByCallingCode(phoneNumber, defaultCountry) {
    const phoneNumberFormat = phoneNumber.replace(ONLY_NUMBER_REGEX, '')
    let callingCode = phoneNumberFormat.substring(0, 1)
    let countryCode = this.findCountryCodeByCallingCode(callingCode)
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 2)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 3)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 4)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    return _.isUndefined(countryCode) ? defaultCountry : countryCode
  },
  initLoadedListCountry(closestElement, listCountryActive, onlyCountries = []) {
    $(`.${closestElement} .country-list .country`).each(function getCountry() {
      const dataCountryCode = $(this).data('country-code')
      const countryName = $(this).find('.country-name').html()
      const dialCode = $(this).find('.dial-code')

      if (
        (_.indexOf(listCountryActive, dataCountryCode) !== -1 && !$(this).hasClass('preferred'))
        || (_.indexOf(onlyCountries, dataCountryCode) === -1 && !$(this).hasClass('preferred'))
      ) {
        $(this).css('display', 'none')
      }
      if (!_.isUndefined(countryName) && countryName.indexOf('(') !== -1) {
        $(this).find('.country-name').html(countryName.slice(0, countryName.indexOf('(')))
      }
      dialCode.html(`(${dialCode.html()})`)
    })
  },
  disabledListCountry(closestElement, listCountryActive, onlyCountries = []) {
    $(`.${closestElement} .country-list .country`).each(function getCountry() {
      const dataCountryCode = $(this).data('country-code')
      if (
        (_.indexOf(listCountryActive, dataCountryCode) !== -1 && !$(this).hasClass('preferred'))
        || (_.indexOf(onlyCountries, dataCountryCode) === -1 && !$(this).hasClass('preferred'))
      ) {
        $(this).css('display', 'none')
      }
    })
  },
  actionLoadmoreCountry(closestElement, listCountryActive) {
    $(`.${closestElement} .load-all-country`).remove()
    $(`.${closestElement} .load-all-country-divider`).remove()
    $(`.${closestElement} .country-list .country`).each(function getCountry() {
      const dataCountryCode = $(this).data('country-code')
      $(this).css('display', (_.indexOf(listCountryActive, dataCountryCode) === -1 && !$(this).hasClass('preferred')) || $(this).hasClass('preferred') ? 'flex' : 'none')
    })
    $(`.${closestElement} .country-list`).animate({ scrollTop: 0 })
  },
  getCallingCode(phoneNumber) {
    const phoneNumberFormat = phoneNumber.replace(ONLY_NUMBER_REGEX, '')
    let callingCode = phoneNumberFormat.substring(0, 1)
    let countryCode = this.findCountryCodeByCallingCode(callingCode)
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 2)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 3)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    if (_.isUndefined(countryCode)) {
      callingCode = phoneNumberFormat.substring(0, 4)
      countryCode = this.findCountryCodeByCallingCode(callingCode)
    }
    return _.isUndefined(countryCode) ? undefined : callingCode
  },
  removeFirstCharacter(number, countryData = null) {
    const numbersWithPlus = number.replace(NUMBER_WITH_PLUS_REGEX, '')
    let formattedNumber = this.removePlusNotStartFirst(numbersWithPlus)
    if (formattedNumber.startsWith('+')) {
      const callingCode = this.getCallingCode(formattedNumber)
      if (_.isUndefined(callingCode)) {
        formattedNumber = formattedNumber.replace(ONLY_NUMBER_REGEX, '')
      } else {
        const currentCallingCode = _.isNull(countryData) ? undefined : countryData.dialCode
        if (formattedNumber.startsWith(`+${callingCode}`) && currentCallingCode === callingCode) {
          formattedNumber = formattedNumber.replace(`+${callingCode}`, '')
        }
      }
    }
    formattedNumber = formattedNumber.replace(LEADING_ZEROS_REGEX, '')
    return formattedNumber
  },
  removeCallingCodeWhenTypingSame(phoneNumber, countryCodeValue) {
    let phone = phoneNumber
    const countryCodePrefix = getCountryCallingCode(_.toUpper(countryCodeValue))
    phone = phone.startsWith(`+${countryCodePrefix}`) ? phone.replace(`+${countryCodePrefix}`, '') : phone
    return phone
  },
  addPlusToPhoneNumber(phoneNumber, hasPlus) {
    let phone = phoneNumber.replace(ONLY_NUMBER_REGEX, '')
    phone = phone.replace(LEADING_ZEROS_REGEX, '')
    phone = hasPlus && phone.length > 0 ? `+${phone}` : phone
    return phone
  },
  checkTypingSameCurentPhone(currentPhone, inputPhone, currentCountryCode) {
    const results = { isSameInput: false, isSameCountryCode: false, callingCode: '' }
    if (currentPhone === inputPhone) {
      results.isSameInput = !_.isEmpty(currentPhone)
    }
    if (inputPhone.startsWith('+')) {
      const callingCode = this.getCallingCode(inputPhone)
      const currentCallingCode = getCountryCallingCode(_.toUpper(currentCountryCode))
      results.isSameCountryCode = currentCallingCode === callingCode
      results.callingCode = callingCode
    }
    return results
  },
  removeZeroAfterDialCode(phoneNumber, callingCode, hasPlus) {
    let onlyPhoneNumber = phoneNumber.replace(ONLY_NUMBER_REGEX, '')
    onlyPhoneNumber = onlyPhoneNumber.substring(callingCode.length)
    onlyPhoneNumber = onlyPhoneNumber.replace(LEADING_ZEROS_REGEX, '')
    let phone = hasPlus ? ['+', callingCode, onlyPhoneNumber] : [callingCode, onlyPhoneNumber]
    phone = phone.join('')
    return phone
  },
  removePlusNotStartFirst(phoneNumber) {
    const firstIsPlus = phoneNumber.startsWith('+')
    const totalPlusInPhone = _.size(phoneNumber.match(/\++/g))
    if (totalPlusInPhone === 1 && firstIsPlus) {
      return phoneNumber
    }
    const onlyPhoneNumbers = phoneNumber.replace(ONLY_NUMBER_REGEX, '')
    return firstIsPlus ? `+${onlyPhoneNumbers}` : onlyPhoneNumbers
  }
}
export default phoneNumberUtils
