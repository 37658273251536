import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
// COMPONENTS
import AssignableDriver from './AssignableDriver'
import UnavailableDriver from '../../new_booking/driver_preferences/UnavailableDriver'
import UnavailableDriverMultiple from '../../multiple_bookings/booking/driver_preferences/UnavailableDriverMultiple'
import ModalDecision from './ModalDecision'
// CONSTANTS
import {
  ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER,
  NO_FAVORITE_DRIVERS,
  SEARCH_ICON,
  QUESTION_ICON,
  ICON_DRIVER_PREFERENCES_FULL,
  ICON_DRIVER_SCHEDULE,
  LOCATING_GIF_GREEN,
  IMAGE_PLACE_HOLDER,
  QUESTION_ICON_GRAY
} from 'constants/imageConstants'
import {
  FAVORITE_DRIVER_EN_URL
} from 'constants/appConstants'
import {
  POPUP_ID_UNVAILABLE_DRIVERS
} from 'constants/common/popupConstants'
import StorageKeys from 'constants/storage-keys'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
// API
// ASSETS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  currentPopupID: state.currentPopupID,
  extraInfos: state.extraInfos
})

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
  }
}

class ListAssignableDriversPopupContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currDriverSelected: {},
      showLoadingMore: false
    }
    this.handleUnvailablePopupAction = this.handleUnvailablePopupAction.bind(this)
    this.setCurrDriverSelected = this.setCurrDriverSelected.bind(this)
    this.handleCloseUnvailablePopup = this.handleCloseUnvailablePopup.bind(this)
    this.debounceScroll = _.debounce(() => this.handleScrollDriverList(), 250)
  }

  componentWillReceiveProps() {
    const { booking } = this.props
    if ((!_.isEmpty(booking) && (window.addToFavoriteDrivers === booking.id))
      || window.addToFavoriteDrivers === true) {
      window.addToFavoriteDrivers = undefined
      $(this.driverReferralCode).val('')
    }
  }

  setCurrDriverSelected(driver) {
    this.setState({ currDriverSelected: driver })
  }

  clickOnDriver(driver) {
    const {
      currentCustomer,
      stepActions,
      generateQuery,
      bookingActions,
      booking,
    } = this.props

    if (driver.is_requirement_met) return

    Utils.hideOverlayStep2()
    const query = generateQuery()

    const data = {
      authenticationToken: currentCustomer.authentication_token,
      ...query,
      driverID: driver.id,
      bookingID: _.get(booking, 'id', '')
    }

    this.setCurrDriverSelected(driver)

    bookingActions.getRequirementNotMet(data, stepActions)
  }

  clearInput() {
    const { clearSearchDriverResult } = this.props
    this.driverReferralCode.value = ''
    clearSearchDriverResult()
  }

  closeInvalidDriverPopup() {
    const { booking, bookingActions } = this.props

    Utils.hideOverlayStep2(false)
    bookingActions.updateReqNotMetReason(null, _.get(booking, 'id', ''))
  }

  handleUnvailablePopupAction(cb) {
    const { handleUpdateAssignDriver, assignDriver } = this.props

    Utils.hideOverlayStep2(false)
    handleUpdateAssignDriver({
      ...assignDriver,
      isUnvailable: true
    })

    if (typeof cb === 'function') {
      cb()
    }
  }

  handleCloseUnvailablePopup() {
    const { currentPopupIDActions } = this.props

    Utils.hideOverlayStep2(false)
    currentPopupIDActions.updateCurrentPopupID('')
  }

  sortWhenHaveAssignedDriver() {
    const { drivers, assignedDriver } = this.props
    let selectedDriver = null
    let notSelectedRivers = []

    _.forEach(drivers, (driver) => {
      if (driver.id === assignedDriver.id) {
        selectedDriver = driver
      } else {
        notSelectedRivers = [...notSelectedRivers, driver]
      }
    })

    return selectedDriver ? [selectedDriver, ...notSelectedRivers] : notSelectedRivers
  }

  handleScrollDriverList() {
    const {
      getParamsForLoadFavorite,
      stepActions,
      bookingActions,
      pagination: {
        page,
        per_page: perPage,
        total_count: totalDrivers
      }
    } = this.props
    const { showLoadingMore } = this.state
    const element = $('#driver-list')
    const driverRows = $('.driver-row')
    const totalPage = Math.floor((totalDrivers + perPage - 1) / perPage)

    if (showLoadingMore || (page === totalPage)) return

    const contentHeight = _.reduce(driverRows, (sum, driverRow) => (
      sum + driverRow.offsetHeight
    ), 0)

    if ((element.innerHeight() + element.scrollTop()) > (contentHeight - 2)) {
      const params = getParamsForLoadFavorite()
      this.setState({ showLoadingMore: true })

      bookingActions.loadFavoriteDrivers(params, stepActions, () => {
        this.setState({ showLoadingMore: false })
      })
    }
  }

  renderInvalidDriverPopup() {
    const { reasonReqNotMet } = this.props
    const { currDriverSelected: driver } = this.state
    const messageType = _.get(reasonReqNotMet, 'message_type', 'missing')
    const messages = _.get(reasonReqNotMet, 'messages', [])
    const totalReasons = messages.length
    let content = ''
    const driverName = driver.fleet_partner_name || Utils.driverFirstName(driver.name)

    if (totalReasons) {
      if (totalReasons === 1) {
        content = messages[0]
      } else if (totalReasons <= 3) {
        content = _.join(messages, ', ')
      } else {
        const firstThirdEl = _.slice(messages, 0, 3)
        const numItemLeft = totalReasons - 3
        const other = numItemLeft > 1 ? I18n.t('webapp.new_booking.step_2.others') : I18n.t('webapp.new_booking.step_2.other')
        content = `${_.join(firstThirdEl, ', ')} and ${numItemLeft} ${other}`
      }
    }

    return (
      <div
        ref={(node) => { this.invalidDriverPopup = node }}
        className="requirement-popup-wrapper visible"
      >
        <div className="Popup normal-driver z-index-2 requirement-popup">
          <div className="Box z-index-2">
            <div className="icon-popup">
              <img src={ICON_DRIVER_PREFERENCES_FULL} alt="deliveree" />
            </div>
            <div className="content-wrapper">
              <p className="title">
                {I18n.t('webapp.booking.requirement_not_met')}
              </p>
              <p className="content">
                {I18n.t('webapp.new_booking.step_2.assign_driver.not_met_requirement_driver', { driver_name: driverName })}
              </p>
              {
                content ? (
                  <div className="explain-content">
                    {`${I18n.t(`webapp.label.${messageType}`)}: ${content}.`}
                  </div>
                ) : null
              }
            </div>
            <button type="button" className="back-btn" onClick={() => this.closeInvalidDriverPopup()}>
              {I18n.t('webapp.action.back')}
            </button>
          </div>
        </div>
        <div
          className="close-modal-overlay"
          onClick={() => this.closeInvalidDriverPopup()}
        />
      </div>
    )
  }

  renderUnavailableDriver(driver) {
    const {
      blockedTimeInfos,
      dataUnavailablePopupEye,
      countryCode,
      maxPickupTimeDays,
      isMultiple,
      booking,
      estimatedWorkingTime,
      selectedAmountFullDay,
      assignToDriver,
      closePopup
    } = this.props
    return (
      isMultiple
        ? (
          <UnavailableDriverMultiple
            booking={booking}
            driver={driver}
            blockedTimeInfos={blockedTimeInfos}
            dataUnavailablePopupEye={dataUnavailablePopupEye}
            countryCode={countryCode}
            maxPickupTimeDays={maxPickupTimeDays}
            estimatedWorkingTime={estimatedWorkingTime}
            selectedAmountFullDay={selectedAmountFullDay}
            assignToDriver={assignToDriver}
            closePopup={closePopup}
          />)
        : (
          <UnavailableDriver
            driver={driver}
            blockedTimeInfos={blockedTimeInfos}
            dataUnavailablePopupEye={dataUnavailablePopupEye}
            countryCode={countryCode}
            maxPickupTimeDays={maxPickupTimeDays}
            assignToDriver={assignToDriver}
            closePopup={closePopup}
          />)
    )
  }

  renderEmptyDriver() {
    const { showSearchResult, showPopupRecentDrivers } = this.props

    return (
      <div className="empty-driver">
        {
          showSearchResult ? (
            <p className="empty-driver-content">
              {I18n.t('webapp.new_booking.step_2.assign_driver.driver_not_found')}
            </p>
          ) : (
            <div>
              <img src={NO_FAVORITE_DRIVERS} alt="no-favorite-drivers" />
              <p className="empty-driver-title">
                {I18n.t('webapp.new_booking.step_2.assign_driver.no_favorite_drivers')}
              </p>
              <p className="empty-driver-content">
                {I18n.t('webapp.new_booking.step_2.assign_driver.directly_assign')}
                <br />
                {I18n.t('webapp.new_booking.step_2.assign_driver.no_favorite_instruction')}
              </p>
              <button
                type="button"
                className="with-right-icon Button Green-text-important No-Border-Important reset p No-Hover-Important mt15"
                onClick={showPopupRecentDrivers}
              >
                {I18n.t('webapp.new_booking.step_2.assign_driver.recent_drivers')}
                <i className="xb material-icons Icon">
                  keyboard_arrow_right
                </i>
              </button>
            </div>
          )
        }
      </div>
    )
  }

  static renderPopupTitle() {
    return (
      <p key="popUp-title" className="center White-text reset mt uppercase Popover-Item-Driver-Title">
        {I18n.t('webapp.new_booking.step_2.assign_driver.assign_to_driver')}
      </p>
    )
  }

  renderExtraInfos(selectedDriver, driver) {
    const { assignToDriver } = this.props

    if (driver.is_requirement_met) {
      return (
        <div className="Popover-Item-Driver-Item-Actions flex flex-center">
          {
            selectedDriver && (
              <button
                type="button"
                onClick={() => assignToDriver(driver, 'selectedDriver')}
                className="Button capitalize Border-Default flex-index Selected"
              >
                {I18n.t('webapp.new_booking.step_2.assign_driver.selected')}
              </button>
            )
          }
          {!selectedDriver && (
            <button
              type="button"
              onClick={() => assignToDriver(driver, this.invalidDriverPopup)}
              className="Button capitalize Black-Gray-text-hover flex-index"
            >
              {I18n.t('webapp.new_booking.step_2.assign_driver.popup_assign_btn')}
            </button>)
          }
        </div>
      )
    }

    return (
      <p className="invalid-driver-text">
        {I18n.t('webapp.booking.requirement_not_met')}
        <img className="ml5 requirement-not-met-icon" src={QUESTION_ICON_GRAY} alt="cashback-info" />
      </p>
    )
  }

  renderDriver(driver) {
    const {
      assignedDriver,
      assignDriver,
      blockedTimeInfos
    } = this.props
    const driverAvatar = driver.fleet_partner_id
      ? ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER
      : driver.driver_image_url === 'missing.png' ? IMAGE_PLACE_HOLDER : driver.driver_image_url
    const driverName = driver.fleet_partner_name || Utils.driverFirstName(driver.name)
    const currentVehicle = driver.current_vehicle || driver.vehicles[0]
    let vehicleTypeName = ''
    if (!!currentVehicle && !!currentVehicle.vehicle_type_name) {
      vehicleTypeName = currentVehicle.vehicle_type_name
    }
    let driverPlateNumber = I18n.t('webapp.new_booking.step_2.assign_driver.fleet_plate_number')
    if (!!currentVehicle && !!currentVehicle.vehicle_attributes && _.isNull(driver.fleet_partner_id)) {
      driverPlateNumber = currentVehicle.vehicle_attributes.plate_number
    }
    let selectedDriver = false

    if (!_.isEmpty(assignedDriver)) {
      if (driver.fleet_partner_id) {
        selectedDriver = assignedDriver.fleet_partner_id === driver.fleet_partner_id
      } else {
        selectedDriver = assignedDriver.id === driver.id
      }
    }

    const specialClass = `mar10 ${driver.is_requirement_met ? '' : 'invalid-driver'}`

    return (
      <div className="Popover-Item-Driver-List" key={_.uniqueId()}>
        {((assignDriver && assignDriver.id === driver.id)
          && (assignDriver.isUnvailable) && _.isNull(driver.fleet_partner_id)
          && !_.isEmpty(blockedTimeInfos))
          ? this.renderUnavailableDriver(driver)
          : (
            <AssignableDriver
              specialClass={specialClass}
              driverInfos={{
                name: driverName,
                vehicleTypeName,
                driverPlateNumber,
                avatar: {
                  url: driverAvatar,
                  size: '',
                },
                specialClass: 'Black-text'
              }}
              isFleet={driver.fleet_partner_id}
              onClickHandler={() => this.clickOnDriver(driver)}
              extraInfos={() => this.renderExtraInfos(selectedDriver, driver)}
            />
          )
        }
      </div>
    )
  }

  renderRecentDriver(listDrivers) {
    const { recentDriver, assignedDriver } = this.props

    const assignedDriverFleetID = _.get(assignedDriver, 'fleet_partner_id')
    const isExistInRecentSection = assignedDriverFleetID
      ? _.get(recentDriver, 'fleet_partner_id') === _.get(assignedDriver, 'fleet_partner_id')
      : _.get(recentDriver, 'id') === _.get(assignedDriver, 'id')
    const isExistInAllSection = assignedDriverFleetID
      ? _.findIndex(listDrivers, ({ fleet_partner_id: fleetID }) => fleetID === _.get(assignedDriver, 'fleet_partner_id')) >= 0
      : _.findIndex(listDrivers, ({ id }) => id === _.get(assignedDriver, 'id')) >= 0
    const notShowCurrentAssignDriver = (isExistInRecentSection || isExistInAllSection) || _.isEmpty(assignedDriver)

    if (_.isEmpty(recentDriver)
      && notShowCurrentAssignDriver) {
      return null
    }


    return (
      <div className="driver-row">
        <p className="title">
          {I18n.t('webapp.label.recently_assigned')}
        </p>
        {
          notShowCurrentAssignDriver
            ? null
            : this.renderDriver(assignedDriver)
        }
        {
          _.isEmpty(recentDriver)
            ? null
            : this.renderDriver(recentDriver)
        }
      </div>
    )
  }

  renderDrivers(listDrivers) {
    const { driverFromSearch } = this.props
    const hasDrivers = listDrivers.length
    const { showLoadingMore } = this.state

    if (driverFromSearch && hasDrivers) {
      return this.renderDriver(listDrivers[0])
    }

    return (
      <div
        id="driver-list"
        className="driver-list-wrapper Custom-Scroll-Group"
        onScroll={this.debounceScroll}
      >
        {this.renderRecentDriver(listDrivers)}
        {
          hasDrivers ? (
            <div className="driver-row">
              <p className="title">
                {I18n.t('webapp.booking.all')}
              </p>
              {_.map(listDrivers, driver => this.renderDriver(driver))}
              {
                showLoadingMore && (
                  <img className="loading-more" src={LOCATING_GIF_GREEN} alt="loading-icon" />
                )
              }
            </div>
          ) : null
        }
      </div>
    )
  }

  renderPopupContent() {
    const {
      drivers,
      assignedDriver,
      onChangeReferralCode,
      handleKeyPressReferralCode,
      isMultiple,
      showSearchResult,
      driverFromSearch,
      showClearInput,
      countryCode,
      showPopupRecentDrivers,
      recentDriver,
      reasonReqNotMet,
      extraInfos
    } = this.props
    let listDrivers = [...drivers]
    const { driver_preference_blog_link: explanLink } = extraInfos
    const searchTextReferralCode = I18n.t('webapp.new_booking.step_2.assign_driver.non_driver_search_place_holder')
    const countryLink = explanLink[countryCode.toLowerCase()]
    let favoriteExplanation = FAVORITE_DRIVER_EN_URL
    const currentLang = I18n.language.toLowerCase()

    if (countryLink) {
      favoriteExplanation = countryLink[currentLang] || countryLink.local
    }

    if (showSearchResult && driverFromSearch) {
      listDrivers = [driverFromSearch]
    } else if (!_.isEmpty(assignedDriver)) {
      listDrivers = this.sortWhenHaveAssignedDriver()
    }

    const showListDrivers = showSearchResult
      ? driverFromSearch
      : (listDrivers.length
        || !_.isEmpty(recentDriver) || !_.isEmpty(assignedDriver))

    return [
      <div key="drivers-group" className="Popover-Item-Driver-Group flex-index">
        <div className="Popover-Item-Driver-Search flex flex-center">
          <div className="Input flex-index">
            <img src={SEARCH_ICON} alt="search-icon" />
            <input
              ref={(driverReferralCode) => { this.driverReferralCode = driverReferralCode }}
              placeholder={searchTextReferralCode}
              onChange={e => onChangeReferralCode(e)}
              onKeyPress={e => handleKeyPressReferralCode(e, $(this.driverReferralCode).val())}
              className={`${isMultiple ? 'small-font-important ' : ''}`}
            />
            {
              showClearInput
                ? (
                  <i className="b material-icons Icon" onClick={() => this.clearInput()}>
                    close
                  </i>
                ) : null
            }
          </div>
          <a className="question-icon" href={favoriteExplanation} target="_blank" rel="noopener noreferrer">
            <img src={QUESTION_ICON} alt="icon-question" />
          </a>
        </div>
        {
          showListDrivers
            ? this.renderDrivers(listDrivers)
            : this.renderEmptyDriver()
        }
        {
          showListDrivers && !showSearchResult
            ? (
              <div className="group-btn">
                <p className="reset p m">
                  {I18n.t('webapp.action.add_more_favorite_drivers')}
                </p>
                <button
                  type="button"
                  className="with-right-icon Button Green-text-important No-Border-Important reset p No-Hover-Important mt15"
                  onClick={showPopupRecentDrivers}
                >
                  {I18n.t('label.preference_driver.recent_drivers')}
                  <i className="xb material-icons Icon">
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            ) : null
        }
        {
          reasonReqNotMet
            ? this.renderInvalidDriverPopup()
            : null
        }
      </div>
    ]
  }

  render() {
    const {
      specialClass,
      closePopup,
      currentPopupID,
      assignDriver,
    } = this.props
    let driverName = ''

    if (assignDriver) {
      driverName = assignDriver.fleet_partner_name || Utils.driverFirstName(assignDriver.name)
    }

    return (
      <div className={specialClass}>
        <span id="close-popup-btn" className="BoxCustom-Close" onClick={closePopup}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
        {this.renderPopupContent()}
        {
          currentPopupID === POPUP_ID_UNVAILABLE_DRIVERS ? (
            <ModalDecision
              handleConfirmAction={this.handleUnvailablePopupAction}
              iconSrc={ICON_DRIVER_SCHEDULE}
              title={I18n.t('webapp.tracking.ceb_driver_not_available')}
              content={I18n.t('webapp.new_booking.step_2.assign_driver.unvailable_driver_warning', { driverName })}
              backText={I18n.t('webapp.action.back')}
              confirmText={I18n.t('webapp.action.schedule_tool')}
              className="schedule-conflict-modal"
              customClosePopup={this.handleCloseUnvailablePopup}
              usingCustomCloseFunc
            />
          ) : null
        }
      </div>
    )
  }
}

ListAssignableDriversPopupContent.propTypes = {
  assignDriver: PropTypes.shape({}),
  assignedDriver: PropTypes.shape({}),
  drivers: PropTypes.instanceOf(Array),
  driverFromSearch: PropTypes.shape({}),
  specialClass: PropTypes.string,
  blockedTimeInfos: PropTypes.shape({}),
  dataUnavailablePopupEye: PropTypes.shape({}),
  countryCode: PropTypes.string,
  maxPickupTimeDays: PropTypes.number,
  isMultiple: PropTypes.bool,
  showSearchResult: PropTypes.bool,
  assignToDriver: PropTypes.func,
  onChangeReferralCode: PropTypes.func,
  handleKeyPressReferralCode: PropTypes.func,
  booking: PropTypes.shape({}),
  estimatedWorkingTime: PropTypes.number,
  selectedAmountFullDay: PropTypes.number,
  showClearInput: PropTypes.bool,
  closePopup: PropTypes.func,
  clearSearchDriverResult: PropTypes.func,
  showPopupRecentDrivers: PropTypes.func,
  reasonReqNotMet: PropTypes.shape({}),
  currentCustomer: PropTypes.shape({}),
  stepActions: PropTypes.shape({}).isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  generateQuery: PropTypes.func,
  recentDriver: PropTypes.shape({}),
  currentPopupID: PropTypes.string,
  handleUpdateAssignDriver: PropTypes.func.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  getParamsForLoadFavorite: PropTypes.func.isRequired,
  pagination: PropTypes.shape({})
}

ListAssignableDriversPopupContent.defaultProps = {
  assignDriver: undefined,
  assignedDriver: undefined,
  drivers: [],
  driverFromSearch: undefined,
  specialClass: '',
  blockedTimeInfos: {},
  dataUnavailablePopupEye: undefined,
  countryCode: '',
  maxPickupTimeDays: undefined,
  isMultiple: false,
  showSearchResult: false,
  assignToDriver: undefined,
  onChangeReferralCode: undefined,
  handleKeyPressReferralCode: undefined,
  booking: undefined,
  estimatedWorkingTime: undefined,
  selectedAmountFullDay: undefined,
  showClearInput: false,
  closePopup: () => { },
  clearSearchDriverResult: () => { },
  showPopupRecentDrivers: () => { },
  reasonReqNotMet: null,
  currentCustomer: {},
  generateQuery: () => { },
  recentDriver: {},
  currentPopupID: '',
  pagination: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAssignableDriversPopupContent)
