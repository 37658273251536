import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import { findPopup, findPopupItem } from 'utils/common/popupable'
import PopupEye from './PopupEye'

class PopupIcon extends React.PureComponent {
  handleShowPopupEye(object, popupType, renderNode) {
    const popup = findPopup(object, popupType)
    if (popup) {
      const { popupStyle } = this.props
      const title = findPopupItem(popup, `${popupType}_title`)
      const subTitle = findPopupItem(popup, `${popupType}_subtitle`)
      const body = findPopupItem(popup, `${popupType}_content`)
      const longContent = findPopupItem(popup, `${popupType}_content_long`)
      const smallContent = findPopupItem(popup, `${popupType}_content_small`)
      ReactDOM.render(
        <PopupEye
          {...this.props}
          icon={popup.icon}
          title={title && title.content}
          subTitle={subTitle && subTitle.content}
          content={body && body.content}
          longContent={longContent && longContent.formated_content}
          smallContent={smallContent && smallContent.formated_content}
          renderNode={renderNode}
          popupStyle={popupStyle}
        />,
        renderNode
      )
    }
    return null
  }

  render() {
    const {
      popupObject,
      popupType,
      renderNode,
      icon,
      className,
    } = this.props
    const styledClass = className || 'b material-icons Icon cur-pointer large-icon'
    return (
      <i
        className={styledClass}
        onClick={() => this.handleShowPopupEye(popupObject, popupType, renderNode)}
      >
        {icon}
      </i>
    )
  }
}

PopupIcon.propTypes = {
  popupObject: PropTypes.shape({}),
  popupType: PropTypes.string,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  renderNode: PropTypes.shape({}).isRequired,
  popupStyle: PropTypes.shape({}),
}

PopupIcon.defaultProps = {
  popupObject: {},
  popupType: '',
  className: '',
  popupStyle: {},
}

export default PopupIcon
