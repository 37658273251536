// this file is check tracking Ezsheet

export const eventTrackingSmartloadplannerNonlogin = (customerSignedIn) => {
  if (!customerSignedIn) {
    window.dataLayer.push({
      event: 'smartloadplanner_non-login_clickSLPmenu',
    })
  } else {
    window.dataLayer.push({
      event: 'smartloadplanner_clickSLPmenu',
    })
  }
}

export const eventTrackingSmartloadplannerNonloginStep1 = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_non-login_step1',
  })
}

export const eventTrackingSmartloadplannerDownloadTemplate = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_downloadTemplate',
  })
}

export const eventTrackingSmartloadplannerStep1BlankFile = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_blankFile',
  })
}

export const eventTrackingSmartloadplannerStep1InvaildTemplate = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_invaildTemplate',
  })
}

export const eventTrackingSmartloadplannerStep1ExceedMaxOrder = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_exceedMaxOrder',
  })
}

export const eventTrackingSmartloadplannerStep1InvaildData = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_invaildData',
  })
}

export const eventTrackingSmartloadplannerStep1InvaildDataIgnoreAll = () => {
  window.dataLayer.push({
    event: 'smartloadplanner_step1_invaildData_ignoreAll',
  })
}
