import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = false

const isBookingSlice = createSlice({
  name: 'isBooking',
  initialState,
  reducers: {},
  extraReducers: () => {},
})

export const isBookingActionsCreator = isBookingSlice.actions

export default isBookingSlice
