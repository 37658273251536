import { assignedDriverActionsCreator } from 'store/toolkit/newBooking/assignDriver/assignedDriver.reducer'
import { estimatedWorkingTimeActionsCreator } from 'store/toolkit/newBooking/assignDriver/estimatedWorkingTime.reducer'
import { assignedPickupTimeActionsCreator } from 'store/toolkit/newBooking/assignDriver/assignedPickupTime.reducer'
import { tmpSelectedDateAssignDriverActionsCreator } from 'store/toolkit/newBooking/assignDriver/tmpSelectedDateAssignDriver.reducer'
import { tmpSelectedTimeAssignDriverActionsCreator } from 'store/toolkit/newBooking/assignDriver/tmpSelectedTimeAssignDriver.reducer'

export const changeAssignDriver = driver => assignedDriverActionsCreator.changeAssignDriver(driver)

export const updateEstimatedWorkingTime = estimatedWorkingTime => estimatedWorkingTimeActionsCreator.updateEstimatedWorkingTime(estimatedWorkingTime)

export const changeAssignedPickupTime = assignedPickupTime => assignedPickupTimeActionsCreator.changeAssignedPickupTime(assignedPickupTime)

export const changeTmpSelectedDateAssignDriver = tmpSelectedDateAssignDriver => tmpSelectedDateAssignDriverActionsCreator.changeTmpSelectedDateAssignDriver(tmpSelectedDateAssignDriver)

export const changeTmpSelectedTimeAssignDriver = tmpSelectedTimeAssignDriver => tmpSelectedTimeAssignDriverActionsCreator.changeTmpSelectedTimeAssignDriver(tmpSelectedTimeAssignDriver)
