import React from 'react'
import PropTypes from 'prop-types'
// UTILS
import I18n from 'i18n/i18n'

// API
// ACTIONS
// COMPONENTS
// CONSTANTS
import { BUSINESS_PROGRAM, PERSONAL } from 'constants/common/contactConstants'
// ASSETS

class HeaderTabs extends React.PureComponent {
  render() {
    const { isBusiness, currentTab, switchTabAction } = this.props
    const selectedBusiness = currentTab === BUSINESS_PROGRAM

    if (!isBusiness) { return null }

    return (
      <div className="Selectbox-Contact-Actions reset p">
        <div className="Popover-contact-tabs flex">
          <span
            onClick={() => switchTabAction(BUSINESS_PROGRAM)}
            className={`small-font flex-index center cur-pointer ${selectedBusiness && 'Popover-contact-tabs-active'}`}
          >
            {I18n.t('contacts.labels.business_program')}
          </span>
          <span
            onClick={() => switchTabAction(PERSONAL)}
            className={`small-font flex-index center cur-pointer ${!selectedBusiness && 'Popover-contact-tabs-active'}`}
          >
            {I18n.t('contacts.labels.personal')}
          </span>
        </div>
      </div>
    )
  }
}

HeaderTabs.propTypes = {
  isBusiness: PropTypes.bool.isRequired,
  switchTabAction: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
}

export default HeaderTabs
