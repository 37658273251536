import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const dataChartEmployeeSlice = createSlice({
  name: 'dataChartEmployee',
  initialState,
  reducers: {
    receiveDataChartEmployee: (state: any, action: PayloadAction<any>) => {
      return action.payload.dataChartEmployee
    },
  },
  extraReducers: () => {},
})

export const dataChartEmployeeActionsCreator = dataChartEmployeeSlice.actions

export default dataChartEmployeeSlice
