import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const checkSubAccountSlice = createSlice({
  name: 'checkSubAccount',
  initialState,
  reducers: {
    updateSubAccountCheckBox: (state, action: PayloadAction<any>) => {
      return action.payload.value
    },
  },
  extraReducers: () => {},
})

export const checkSubAccountActionsCreator = checkSubAccountSlice.actions

export default checkSubAccountSlice
