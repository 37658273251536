// ASSETS
// API
import CustomerAPI from 'api/customers'
import $ from 'jquery'
import _ from 'lodash'
// CONSTANTS
import { driversActionsCreator } from 'store/toolkit/drivers/drivers.reducer'
import { pagesActionsCreator } from 'store/toolkit/pages/pages'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import Utils from 'utils/Utils'
// UTILS

export const loadDriverPerTab = (params, callback) => (dispatch) => {
  const apiParams = {
    item_type: params.item_type,
    referral_code: params.referral_code,
    include_fleet_driver: true,
    'only': [
      'id',
      'approve_status', 'current_vehicle', 'driver_image_url',
      'fleet_partner_id', 'fleet_partner_name', 'fleet_partner_approve_status',
      'name', 'preferences_info', 'rating'],
    'include': params.include || []
  }
  if (params.company_id) {
    apiParams.company_id = params.company_id
  }
  CustomerAPI.getFavoriteOrBannedDrivers(
    params.authentication_token,
    apiParams,
    (response) => {
      if (!response.data.error) {
        const result =  Utils.orderDriversByName(response.data.data, params.item_type, true)
        dispatch(driversActionsCreator.updateDrivers(result))
        dispatch(pagesActionsCreator.updateCurrentPage(1))
        dispatch(driversActionsCreator.updateTotalDrivers(response.data.data.length))
        if (params.referral_code === '' || _.isUndefined(params.referral_code)) {
          $('#searchDriverByReferralCodeInput').val('')
        }
        $('#selectAllDriversCheckbox').prop('checked', false)
        callback()
        hideLoading()
      }
    }
  )
  dispatch(driversActionsCreator.changeTab({ tab: params.item_type }))
}

export const recentDrivers = (params, callback) => (dispatch) => {
  CustomerAPI.recentDrivers(
    params.authentication_token,
    {
      company_id: params.company_id,
      include_fleet_driver: true,
      show_driver_preferences_info: true,
      'only': ['id', 'name', 'rating', 'driver_image_url', 'fleet_partner_id', 'fleet_partner_name', 'approve_status', 'preferences_info', 'current_vehicle'],
      'include': params.include || []
    },
    (response) => {
      if (!response.data.error) {
        callback(response.data.data)
        hideLoading()
      }
    }
  )
}

export const updatePreferenceListDrivers = (params, callback) => async(dispatch) => {
  const apiParams = {
    'customer_drivers_attributes': params.customer_drivers_attributes,
    _destroy: params.destroy,
    item_type: params.item_type,
    'only': [
      'id',
      'approve_status', 'current_vehicle', 'driver_image_url',
      'fleet_partner_id', 'fleet_partner_name', 'fleet_partner_approve_status',
      'name', 'preferences_info', 'rating'],
    include: ['driver.current_vehicle']
  }
  if (params.company_id) {
    apiParams.company_id = params.company_id
  }
  CustomerAPI.updatePreferenceListDrivers(params.authentication_token, apiParams, (response)=>{
    if (!response.data.error) {
      if (!params.is_recent_drivers_for_booking) {
        const result =  Utils.orderDriversByName(response.data.data, params.item_type, true)
        dispatch(driversActionsCreator.updateDrivers(result))
        dispatch(pagesActionsCreator.updateCurrentPage(1))
        dispatch(driversActionsCreator.updateTotalDrivers(response.data.data.length))
        $('#searchDriverByReferralCodeInput').val('')
        $('#selectAllDriversCheckbox').prop('checked', false)
      }
      callback()
      hideLoading()
    }
  })
}
