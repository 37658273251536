import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = { isChanged: false, tempID: '', addressComponents: [] }

const locationIdChangeSlice = createSlice({
  name: 'locationIdChange',
  initialState,
  reducers: {
    updateAddressForLocation: (state, action: PayloadAction<any>) => {
      const { place, location } = action.payload
      const { temp_id: tempID } = location
      const { address_components: addressComponents } = place
      const newPlace = {
        isChanged: true,
        tempID,
        addressComponents,
      }
      return newPlace
    },
  },
  extraReducers: () => {},
})
export const locationIdChangeActionsCreator = locationIdChangeSlice.actions

export default locationIdChangeSlice
