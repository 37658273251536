import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''
const notesSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    updateNote: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const notesActionsCreator = notesSlice.actions

export default notesSlice
