import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = 0
const quickChoiceSlice = createSlice({
  name: 'quickChoice',
  initialState,
  reducers: {
    changeQuickChoice: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const quickChoiceActionsCreator = quickChoiceSlice.actions

export default quickChoiceSlice
