import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {
  locationMarker: [],
  driverMarker: [],
  driverInfoBox: [],
  activeMarker: {},
}

const batchTrackingMarkersSlice = createSlice({
  name: 'batchTrackingMarkers',
  initialState,
  reducers: {
    trackingUpdateBatchLocationMaker: (state, action: PayloadAction<any>) => {
      if (state.locationMarker[action.payload.bookingID]) {
        state.locationMarker[action.payload.bookingID].setMap(null)
      }
      state.locationMarker[action.payload.bookingID] = action.payload.marker
    },
    trackingUpdateBatchDriverMaker: (state, action: PayloadAction<any>) => {
      if (state.driverMarker[action.payload.bookingID]) {
        state.driverMarker[action.payload.bookingID].setMap(null)
      }
      state.driverMarker[action.payload.bookingID] = action.payload.marker
      if (!_.isEmpty(state.activeMarker)) {
        state.activeMarker.setMap(null)
      }
      state.activeMarker = action.payload.activeMarker
    },
    updateDriverInfoBox: (state, action: PayloadAction<any>) => {
      if (!state.driverInfoBox[action.payload.bookingID]) {
        state.driverInfoBox[action.payload.bookingID] = action.payload.infoBox
      }
    },
  },
  extraReducers: () => {},
})

export const batchTrackingMarkersActions = batchTrackingMarkersSlice.actions

export default batchTrackingMarkersSlice
