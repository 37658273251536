import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const pickupContactsMeFromLocationSlice = createSlice({
  name: 'pickupContactsMeFromLocation',
  initialState,
  reducers: {
    updatePickupContactsMeFromLocation: (state, action: PayloadAction<any>) => action.payload,
    deletePickupContactsMeFromLocation:  (state, action: PayloadAction<any>) => {
      state.data = state?.data.filter(({ id }: any) => id !== action.payload)
    },
  },
  extraReducers: () => {},
})

export const pickupContactsMeFromLocationActionsCreator = pickupContactsMeFromLocationSlice.actions

export default pickupContactsMeFromLocationSlice
