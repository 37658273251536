// ASSETS
// API
import CustomerAPI from 'api/customers'
import $ from 'jquery'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import BookingAPI from 'api/bookings'
import { CANCELED, DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import {
  SECTION_TYPE_PAYMENT_LIST, PAYMENT_VA_STATUS, CUSTOMER_PAYMENT, CUSTOMER_PAYMENT_STATUS,
} from 'constants/bookingConstants'
import { bookingsCardActionsCreators } from 'store/toolkit/myBooking/bookingsCard.reducer'
import { draftBookingsActionsCreator } from 'store/toolkit/draftBookings/draftBookings.reducer'
import { paginateValuesActionsCreator } from 'store/toolkit/pagination/paginateValues.reducer'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { currentPageActionsCreators } from 'store/toolkit/myBooking/currentPage.reduce'
// UTILS

// export const updateBookings = bookings => ({
//   type: UPDATE_BOOKINGS,
//   bookings
// })

export const updateCurrentPage = currentPage => currentPageActionsCreators.updateCurrentPage(currentPage)

// export const updateCurrentTab = currentTab => ({
//   type: UPDATE_CURRENT_TAB,
//   currentTab
// })

// export const updateCurrentViewAs = currentViewAs => ({
//   type: UPDATE_CURRENT_VIEW_AS,
//   currentViewAs
// })

// export const updateDraftBookings = draftBookings => ({
//   type: UPDATE_DRAFT_BOOKINGS,
//   draftBookings
// })

// export const updatePaginateValues = pagination => ({
//   type: UPDATE_PAGINATE_VALUES,
//   pagination
// })

// export const deleteDraftBooking = id => ({
//   type: DELETE_DRAFT_BOOKING,
//   id
// })

// export const updateCurrentCustomer = currentCustomer => ({
//   type: UPDATE_CURRENT_CUSTOMER,
//   currentCustomer
// })

export const getBookings = (params = {}) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  return new Promise((resolve) => {
    CustomerAPI.getBookings(
      currentCustomer.authentication_token,
      params,
      (response) => {
        if (!_.isEmpty(response?.data)) {
          dispatch(paginateValuesActionsCreator.updatePaginateValues({ pagination: response.data.pagination || {} }))
          dispatch(bookingsCardActionsCreators.updateBookings({ bookings: response.data.data || [] }))
          dispatch(draftBookingsActionsCreator.updateDraftBookings({ draftBookings: [] }))
          $('#loading-modal').removeClass('visible')
          resolve()
        }
      }
    )
  })
}

export const getDraftBookings = params => (dispatch, getState) => {
  const { currentCustomer } = getState()
  return new Promise((resolve) => {
    CustomerAPI.getDraftBookings(
      currentCustomer.authentication_token,
      params,
      (response) => {
        if (response?.data) {
          dispatch(paginateValuesActionsCreator.updatePaginateValues({ pagination: response.data.pagination || {} }))
          dispatch(draftBookingsActionsCreator.updateDraftBookings({ draftBookings: response.data.data || [] }))
          dispatch(bookingsCardActionsCreators.updateBookings({ bookings: [] }))
          $('#loading-modal').removeClass('visible')
          resolve()
        }
      }
    )
  })
}

export const deleteShipmentLTLUnList = (shipmentId, callback = () => { }) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  const defaultCountry = extraInfos.country_code.toLowerCase()
  return new Promise(() => {
    BookingAPI.deleteShipmentLTLUnList(
      shipmentId,
      defaultCountry,
      (response) => {
        if (response.data.isSuccess === true) {
          callback()
        }
      }
    )
  })
}

export const deleteDraft = id => (dispatch, getState) => {
  const { currentCustomer } = getState()
  CustomerAPI.deleteDraftBooking(
    currentCustomer.authentication_token,
    id,
    (response) => {
      if (response.data.success === true) {
        Promise.resolve(
          dispatch(draftBookingsActionsCreator.deleteDraftBooking({ id }))
        )
        $('#loading-modal')
          .removeClass('visible')
      }
    }
  )
}

export const updatePreferenceListDrivers = params => async (dispatch, getState) => {
  const { currentCustomer } = getState()
  const { favorite_driver_ids: favDriverIds, banned_driver_ids: banDriverIds } = currentCustomer
  const driverId = params.customer_drivers_attributes[0].driver_id
  const itemType = params.customer_drivers_attributes[0].item_type
  const apiParams = {
    'customer_drivers_attributes': params.customer_drivers_attributes
  }
  let updateFieldCustomer = {}
  const response = await CustomerAPI.addFavoriteDriver(params.authentication_token,apiParams)
  if (response.data.success === true) {
    const indexBanned = _.findIndex(banDriverIds, item => (item === driverId))
    const indexFavorite = _.findIndex(favDriverIds, item => (item === driverId))
    if (indexFavorite > -1) {
      favDriverIds.splice(indexFavorite, 1)
    }
    if (indexBanned > -1) {
      banDriverIds.splice(indexBanned, 1)
    }
    if (itemType === DRIVER_PREFERENCES.favorite) {
      const updateFavDriverIds = indexFavorite > -1 ? favDriverIds : _.concat(favDriverIds, [driverId])
      updateFieldCustomer = { favorite_driver_ids: updateFavDriverIds, banDriverIds }
    } else {
      const updateBanDriverIds = indexBanned > -1 ? banDriverIds : _.concat(banDriverIds, [driverId])
      updateFieldCustomer = { banned_driver_ids: updateBanDriverIds, favDriverIds }
    }
    dispatch(currentCustomerActionsCreator.updateCustomer(updateFieldCustomer))
    $('#loading-modal').removeClass('visible')
  }
}

const checkDataChange = (attrs) => {
  let flag = false
  const dataKeys = ['time_type', 'service_type_id', 'vehicle_type_id']
  _.forEach(dataKeys, (item) => {
    if (_.includes(Object.keys(attrs), item)) {
      flag = true
    }
  })
  return flag
}

export const updateBooking = (id, attrs) => (dispatch) => {
  if (checkDataChange(attrs)) {
    _.assign(attrs, { dataChange: true })
  }

  dispatch(bookingsCardActionsCreators.updateBooking({ id, attrs }))
}

export const updateSubAccountCheckBoxCardBooking = (bookingId, value) => (dispatch) => {
  const attrs = {
    isCheckSubAccount: value
  }
  if (bookingId) {
    if (checkDataChange(attrs)) {
      _.assign(attrs, { dataChange: true })
    }
    dispatch(bookingsCardActionsCreators.updateBooking({ id: bookingId, attrs }))
  }
}

export const addSubAccountCardBooking = (bookingId, data) => (dispatch) => {
  let attrs = {
    sub_account_tag: {
      sub_account_id: undefined,
      sub_account_name: undefined
    }
  }
  if (data) {
    attrs = {
      sub_account_tag: {
        sub_account_id: data.id,
        sub_account_name: data.name
      }
    }
  }
  if (bookingId) {
    dispatch(bookingsCardActionsCreators.updateBooking({ id: bookingId, attrs }))
  }
}

const handleDataPaymentDetail = (id, dispatch, bookingPaymentDetails, dataPayment) => {
  if (!_.isEmpty(dataPayment)) {
    dispatch(updateBooking(id, { payment: { ...bookingPaymentDetails?.payment } }))
  }
  if (_.isEmpty(bookingPaymentDetails) && !_.isNil(bookingPaymentDetails) && !_.isUndefined(bookingPaymentDetails)) {
    dispatch(updateBooking(id, {
      payment: {
        status: PAYMENT_VA_STATUS.virtualAccountTimeout,
      }
    }))
  }
}
export const getBookingCardPaymentDetailAction = (
  id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT]
) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const bookingPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
  const dataPayment = bookingPaymentDetails?.payment
  handleDataPaymentDetail(id, dispatch, bookingPaymentDetails, dataPayment)
}
export const getBookingCardPaymentDetailRemovedAction = (
  id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT_STATUS]
) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const bookingPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
  const dataPayment = bookingPaymentDetails?.payment
  if (bookingPaymentDetails?.canceled_by_customer && bookingPaymentDetails?.status === CANCELED) {
    dispatch(updateBooking(id, {
      status: CANCELED,
    }))
  } else handleDataPaymentDetail(id, dispatch, bookingPaymentDetails, dataPayment)
}