import React from 'react'
import PropTypes from 'prop-types'

// CONSTANT
import * as IMAGES from 'constants/imageConstants'
import I18n from 'i18n/i18n'

class NumberDrivers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      numberOfOnlineDrivers,
      unitNameOfVehicleType
    } = this.props
    return (
      <div className="number-driver">
        <div className="curved-div">
          <img src={IMAGES.NUMBER_DRIVER_LEFT} alt="Number Driver Left" />
        </div>
        <div className="number-driver__content">
          <div className="number-driver__id">
            <span>
              <img src={IMAGES.VEHICLE_ICON} height={16} width={16} />
            </span>
            <span>
              {numberOfOnlineDrivers}
            </span>
          </div>
          <div className="number-driver__name">
            {I18n.t('webapp.label.vehicle_type_unit', { unit: unitNameOfVehicleType })}
          </div>
        </div>
        <div className="curved-div">
          <img src={IMAGES.NUMBER_DRIVER_RIGHT} alt="Number Driver Right" />
        </div>
      </div>
    )
  }
}

NumberDrivers.propTypes = {
  numberOfOnlineDrivers: PropTypes.number.isRequired,
  unitNameOfVehicleType: PropTypes.string.isRequired
}

export default NumberDrivers
