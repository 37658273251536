import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { find } from 'lodash'

const initialState: any = {}
const selectedPickupZonesSlice = createSlice({
  name: 'selectedPickupZones',
  initialState,
  reducers: {
    updateSelectedPickupZones: (state: any, action: PayloadAction<any>) =>
      find(action.payload.pickupZones, (zone) => zone.id === action.payload.zoneID),
    resetSelectedPickupZones: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const selectedPickupZonesActionsCreator = selectedPickupZonesSlice.actions

export default selectedPickupZonesSlice
