import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const editModeTextSlice = createSlice({
  name: 'editModeText',
  initialState,
  reducers: {
    updateEditModeText: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const editModeTextActionsCreator = editModeTextSlice.actions

export default editModeTextSlice
