// API
import EventNotificationAPI from 'api/eventNotifications'

// get current event of current area
export const getCurrentEvent = (areaID, currentCustomer, callback) => () => {
  EventNotificationAPI.getCurrentEvent(
    areaID,
    currentCustomer,
    (response) => {
      callback(response)
    }
  )
}

export { getCurrentEvent as default }
