import React from 'react'
import routes from './routes'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'

const RouteList: React.FC = () => {
  const routeList = Object.keys(routes).map((key) => routes[key])
  if (routeList.length === 0) return null

  return (
    <Routes>
      {routeList.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          caseSensitive={route.caseSensitive}
          element={route.isPrivate ? <PrivateRoute component={route.element} /> : route.element}
        />
      ))}
    </Routes>
  )
}

export default RouteList
