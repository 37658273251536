import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex, remove } from 'lodash'

const initialState: any = []

const driverMarkersSlice = createSlice({
  name: 'driverMarkers',
  initialState,
  reducers: {
    addDriver: (state: any, action: PayloadAction<any>) => state.push(action.payload.driver),
    removeDriver: (state: any, action: PayloadAction<any>) => {
      remove(state, { id: action.payload.id })
    },
    updateDriver: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(state, (driver: any) => driver.id === action.payload.driver.id)
      if (index !== -1) {
        state[index] = action.payload.driver
      }
    },
  },
  extraReducers: () => {},
})

export const driverMarkersActionsCreator = driverMarkersSlice.actions

export default driverMarkersSlice
