import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const subCurrentPopupIDSlice = createSlice({
  name: 'subCurrentPopupID',
  initialState,
  reducers: {
    updateSubCurrentPopupID: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const subCurrentPopupIDActionsCreator = subCurrentPopupIDSlice.actions

export default subCurrentPopupIDSlice
