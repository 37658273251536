import apiClient from 'services/axiosApp'

const getUserProfileRequest = async ({
  authenToken,
  query,
}) => {
  try {
    const res = await apiClient.get('/api/v3/customers/me', { params: query })
    return res
  } catch (err) {
    throw new Error(err)
  }
}

const getUserSettingRequest = async ({
  authenToken,
  query,
}) => {
  try {
    const res = await apiClient.get('/api/v3/settings/customers', { params: query })
    return res
  } catch (err) {
    throw new Error(err)
  }
}

const updateCustomerSettingAPI = async ({
  authenToken,
  queryString,
}) => {
  try {
    const res = await apiClient.put(`/api/v3/settings/customers?${queryString}`)
    return res
  } catch (err) {
    throw new Error(err)
  }
}

export {
  getUserProfileRequest,
  getUserSettingRequest,
  updateCustomerSettingAPI,
}
