import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const assignedPickupTimeSlice = createSlice({
  name: 'assignedPickupTime',
  initialState,
  reducers: {
    changeAssignedPickupTime: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const assignedPickupTimeActionsCreator = assignedPickupTimeSlice.actions

export default assignedPickupTimeSlice
