import React from 'react'
import PropTypes from 'prop-types'

// CONTANSTS
import {
  POPUP_ID_CUSTOMER_DND_POPUP_EYE
} from 'constants/common/popupConstants'
import $ from 'jquery';

class EyeIcon extends React.Component {
  static checkHeightPopup(element) {
    if (element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  onClickEyeHandler() {
    const {
      areaId
    } = this.props
    $(`#booking-${areaId}-${POPUP_ID_CUSTOMER_DND_POPUP_EYE}`).addClass('visible')
    EyeIcon.checkHeightPopup($(`#booking-${areaId}-${POPUP_ID_CUSTOMER_DND_POPUP_EYE} .Normal-Booking`))
  }

  render() {
    const {
      icon,
      iconClass,
      labelName,
      hasPopUpEyes
    } = this.props
    return (
      <div className="customer-dnd-label cur-default">
        <label>
          {labelName}
          {
            hasPopUpEyes
            && (
              <a className="default-color-icon-green-hover" onClick={() => this.onClickEyeHandler()}>
                <i className={iconClass}>
                  {icon}
                </i>
              </a>
            )
          }
        </label>
      </div>
    )
  }
}

EyeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  areaId: PropTypes.number.isRequired,
  iconClass: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  hasPopUpEyes: PropTypes.bool.isRequired,
}

export default EyeIcon
