import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
// UTILS
// API
// ACTIONS
// COMPONENTS
import FormLocationPopup from '../my-location/FormLocationPopup'
// CONSTANTS
// ASSETS
import I18n from 'i18n/i18n'

class AddNewButton extends React.PureComponent {
  addNewContact() {
    const {
      currentCustomer,
      contactActions,
      currentTab,
      extraInfos,
      customClassAutoComplete,
    } = this.props
    const { handleContactsChange } = this.context
    const renderNode = document.getElementById('CommonPopup')

    ReactDOM.render(
      <FormLocationPopup
        contact={{}}
        shouldShow
        currentCustomer={currentCustomer}
        extraInfos={extraInfos}
        contactActions={contactActions}
        currentTab={currentTab}
        handleContactsChange={handleContactsChange}
        customClassAutoComplete={customClassAutoComplete}
        countryCode={extraInfos.country_code}
      />,
      renderNode
    )
  }

  render() {
    const { openSearch } = this.props

    return (
      <div
        className="flex flex-center-algin Selectbox-Contact-Actions__Add cur-pointer"
        onClick={() => this.addNewContact()}
      >
        <i className="material-icons xxs-large-icon Icon Green-text-important">
          add_circle
        </i>
        <span className={`ml5 Green-text small-font Wrap-Text ${openSearch ? 'Hidden-Field' : ''}`}>
          {I18n.t('contacts.labels.add_contact')}
        </span>
      </div>
    )
  }
}

AddNewButton.propTypes = {
  openSearch: PropTypes.bool.isRequired,
  contactActions: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  currentTab: PropTypes.string,
  customClassAutoComplete: PropTypes.string,
}

AddNewButton.defaultProps = {
  currentTab: '',
  customClassAutoComplete: '',
}

AddNewButton.contextTypes = {
  handleContactsChange: PropTypes.func,
}

export default AddNewButton
