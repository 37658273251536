import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const currentAreaSlice = createSlice({
  name: 'currentArea',
  initialState,
  reducers: {
    setCurrentArea: (state, action: PayloadAction<any>) => {
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentAreaActionsCreator = currentAreaSlice.actions

export default currentAreaSlice
