// BatchManagementReducer
import tabSlice from 'store/toolkit/tab/tab.reducer'
import batchesSlice from 'store/toolkit/batch/batches.reducer'
import selectedBatchSlice from 'store/toolkit/selectedBatch/selectedBatch.reducer'
import batchTrackingMarkersSlice from 'store/toolkit/trackingMarkers/batchTrackingMarkers.reducer'


const batchManagementReducer = {
 // ===== BatchManagementReducer
 tabBatchManagement: tabSlice.reducer,
 batches: batchesSlice.reducer,
 selectedBatch: selectedBatchSlice.reducer,
 batchTrackingMarkers: batchTrackingMarkersSlice.reducer,

}

export default batchManagementReducer
