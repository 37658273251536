import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const tmpSelectedTimeAssignDriverSlice = createSlice({
  name: 'tmpSelectedTimeAssignDriver',
  initialState,
  reducers: {
    changeTmpSelectedTimeAssignDriver: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const tmpSelectedTimeAssignDriverActionsCreator = tmpSelectedTimeAssignDriverSlice.actions

export default tmpSelectedTimeAssignDriverSlice
