import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const dataChartCostBreakdownSlice = createSlice({
  name: 'dataChartCostBreakdown',
  initialState,
  reducers: {
    receiveDataChartBookingCostBreakdown: (state: any, action: PayloadAction<any>) => {
      return action.payload.bookingCostBreakdown
    },
  },
  extraReducers: () => {},
})

export const dataChartCostBreakdownActionsCreator = dataChartCostBreakdownSlice.actions

export default dataChartCostBreakdownSlice
