import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// UTILS
// ACTIONS
// COMPONENTS
// CONSTANTS
// ASSETS
// API

class AssignableDriver extends React.PureComponent {
  onClick() {
    const { onClickHandler, noClickHandler } = this.props
    if (noClickHandler === false) {
      onClickHandler()
    }
  }

  render() {
    const {
      driverInfos,
      extraInfos,
      specialClass,
      isFleet
    } = this.props
    return (
      <div
        className={`Popover-Item-Driver-Item Radius-default pt10 pb10 pr10 flex ${specialClass} custom-driver-assign-selected`}
        onClick={() => this.onClick()}
      >
        <div className="flex-index o-hidden">
          <div className="flex">
            <div className="Popover-Item-Driver-Item-Avatar pl10 pt2 Big">
              <span
                style={{ backgroundImage: `url(${driverInfos.avatar.url})` }}
                className={`${isFleet ? 'avatar-fleet' : ''}`}
              />
            </div>
            <div className="Popover-Item-Driver-Item-Info ml10 mr10 flex flex-column flex-index flex-center-justify o-hidden">
              <p className={`default-font ${_.isUndefined(driverInfos.specialClass) ? 'default-color' : driverInfos.specialClass} reset m Popover-Item-Driver-Name-Text`}>
                {driverInfos.name}
              </p>
              <p className={`small-font pt2 ${_.isUndefined(driverInfos.specialClass) ? 'default-color' : driverInfos.specialClass} reset m`}>
                {driverInfos.vehicleTypeName}
              </p>
              <p className={`small-font pt2 ${_.isUndefined(driverInfos.specialClass) ? 'default-color' : driverInfos.specialClass} reset m`}>
                {driverInfos.driverPlateNumber}
              </p>
            </div>
          </div>
          <div className="pl10 pt5">
            {!_.isUndefined(driverInfos.pickupTime) && _.size(driverInfos.pickupTime().props.children)
              ? driverInfos.pickupTime()
              : null
            }
          </div>
        </div>
        {!_.isUndefined(extraInfos) && _.size(extraInfos().props.children) ? extraInfos() : null}
      </div>
    )
  }
}

AssignableDriver.propTypes = {
  driverInfos: PropTypes.shape({}).isRequired,
  onClickHandler: PropTypes.func,
  extraInfos: PropTypes.func,
  noClickHandler: PropTypes.bool,
  specialClass: PropTypes.string,
  isFleet: PropTypes.bool,
}

AssignableDriver.defaultProps = {
  extraInfos: undefined,
  onClickHandler: undefined,
  noClickHandler: false,
  specialClass: '',
  isFleet: false,
}

export default AssignableDriver
