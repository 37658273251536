import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const isLoginStep3Slice = createSlice({
  name: 'isLoginStep3',
  initialState,
  reducers: {
    setIsLoginStep3: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const isLoginStep3Creator = isLoginStep3Slice.actions

export default isLoginStep3Slice
