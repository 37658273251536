import React from 'react'
import PropTypes from 'prop-types'

import {
  MAX_CODE_VERIFY
} from 'constants/newBookingConstants'
// UTILS
import I18n from 'i18n/i18n'
class ModalVerificationCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      number: ''
    }
  }

  clickButtonResendCode() {
    const { currentCustomer, resendSmsTokenAfterSignUp } = this.props
    resendSmsTokenAfterSignUp(currentCustomer.authentication_token)
  }

  handleOnChange(e) {
    const {
      currentCustomer,
      verifySmsTokenAfterSignUp,
    } = this.props
    const valueCode = e.target.value
    if (valueCode.length >= MAX_CODE_VERIFY) {
      verifySmsTokenAfterSignUp(currentCustomer.authentication_token, valueCode)
      this.setState({
        number: '',
      })
    } else {
      this.setState({
        number: valueCode,
      })
    }
  }

  clickButtonEditPhoneNumber() {
    const { editPhoneNumberForm } = this.props
    editPhoneNumberForm()
  }

  renderCard() {
    const { number } = this.state
    const listNumberBox = []
    const arrNumber = number.split('')
    for (let i = 0; i < arrNumber.length; i += 1) {
      const value = arrNumber[i]
      listNumberBox.push(
        <span key={i} className="VerificationBox-code">
          {value}
        </span>
      )
    }
    for (let i = 0; i < (MAX_CODE_VERIFY - number.length); i += 1) {
      listNumberBox.push(
        <span key={i + MAX_CODE_VERIFY} />
      )
    }
    return listNumberBox
  }

  render() {
    const { number } = this.state
    return (
      <div className="Verification">
        <div className="VerificationBox Custom-Scroll-Group zoom-scale-8">
          <div className="Box">
            <div className="Box-Head">
              <h3 className="larger-font default-medium-font">
                {I18n.t('webapp.label.verification_code')}
              </h3>
            </div>
            <div className="Box-Content">
              <div className="VerificationBox-text">
                {I18n.t('webapp.label.check_verification_code_and_enter_it')}
              </div>
              <div className="pl10 pr10 pt10 pb10">
                <div className="VerificationBox-number">
                  <div className="VerificationBox-number-box">
                    {this.renderCard()}
                  </div>
                  <input
                    onChange={e => this.handleOnChange(e)}
                    value={number}
                  />
                </div>
              </div>
              <div className="Box-Actions">
                <button type="button" className="Button white-bg default-color larger-font" onClick={() => this.clickButtonEditPhoneNumber()}>
                  {I18n.t('webapp.label.edit_number')}
                </button>
                <button type="button" className="Button green larger-font" onClick={() => this.clickButtonResendCode()}>
                  {I18n.t('webapp.label.resend_code')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ModalVerificationCode.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  resendSmsTokenAfterSignUp: PropTypes.func.isRequired,
  verifySmsTokenAfterSignUp: PropTypes.func.isRequired,
  editPhoneNumberForm: PropTypes.func.isRequired,
}
export default ModalVerificationCode
