import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const analyticsEmailSlice = createSlice({
  name: 'analyticsEmail',
  initialState,
  reducers: {
    updateAnalyticsEmail: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload.analyticsEmail
      }
    },
  },
  extraReducers: () => {},
})

export const analyticsEmailActionsCreator = analyticsEmailSlice.actions

export default analyticsEmailSlice
