import React from 'react'
import PropTypes from 'prop-types'
// UTILS
// ACTIONS
// COMPONENTS
// CONSTANTS
import { POPUP_ID_CUSTOMER_DND_POPUP_EYE } from 'constants/common/popupConstants'
// ASSETS

const AreaTypePopup = (props) => {
  const { areaId, popupData, onClickCloseAreaTypePopup } = props
  return (
    <div className="closure-dnd z-index-100012">
      <div className="vertical-scroll closure Modal" id={`booking-${areaId}-${POPUP_ID_CUSTOMER_DND_POPUP_EYE}`}>
        <div className="Normal-Booking Popup-Normal-Booking">
          <div className="Box cur-auto">
            <div className="Box-Icon">
              <img src={popupData.icon} className="Box-Icon-Image" alt="deliveree" />
            </div>
            <div className="Box-Content">
              <div className="Normal">
                <h5 className="Normal-Booking-Title fullday" dangerouslySetInnerHTML={{ __html: popupData.title }} />
                <div className="Normal-Booking-Devide" />
                <p className="center fullday" dangerouslySetInnerHTML={{ __html: popupData.content }} />
              </div>
            </div>
          </div>
        </div>
        <span className="close-modal-common" onClick={onClickCloseAreaTypePopup}/>
      </div>
    </div>
  )
}

AreaTypePopup.propTypes = {
  areaId: PropTypes.number.isRequired,
  popupData: PropTypes.shape({}).isRequired,
}

export default AreaTypePopup
