import perPageSlice from 'store/toolkit/pagination/perPage.reducer'
import totalSlice from 'store/toolkit/pagination/total.reducer'
import paginateValuesSlice from 'store/toolkit/pagination/paginateValues.reducer'

const paginationReducer = {
  // pagination
  perPage: perPageSlice.reducer,
  total: totalSlice.reducer,
  paginateValues: paginateValuesSlice.reducer,
  paginateBookingCardValues: paginateValuesSlice.reducer,

}

export default paginationReducer
