import $ from 'jquery'
import * as common from '../common'
import toastr from "utils/toast";

// config basic popup
// Notes:
// Add trigger='open-modal' and modal-id='ID-of-your-modal' to the element you trigger to show the modal

function showPopup(result, element, needToReload) {
  $('body').append(result)
  common.closeLoading()
  if (needToReload) {
    window.ReactOnRails.reactOnRailsPageLoaded()
  }
  $(element).addClass('visible')
  $(`${element} .Popup`).css({ 'z-index': 2 })
  $(element).append('<span class="close-modal-common"></span>')

  common.checkHeightPopup($(`${element} .Popup`))
  common.closeModal_fix()
}

export const initBasicModal = () => {
  $(document).on("click", "[trigger='open-modal']", function () {
    toastr.remove();

    var modalID = $(this).attr("modal-id");
    $(`#${modalID}`).addClass("visible");
    $(`#${modalID} .Popup, #${modalID} .Box`).css({ 'z-index': 2 });
    $(`#${modalID}`).append('<span class="close-modal-common custom"></span>');

    var checkHeightPopup = $(`#${modalID} > .Popup`).height() + 120;
    if (checkHeightPopup > $(`#${modalID} > .Popup`).parent().height()) {
      $(`#${modalID} > .Popup`).addClass('top-align No-Seft');
      $('.close-modal-common.custom').css({
        'min-height': '100%',
        'height': checkHeightPopup
      });
    } else {
      $(`#${modalID} > .Popup`).removeClass('top-align No-Seft');
    }
  });

  $(document).on('click', '.close-modal-common.custom', function () {
    $(this).parent().removeClass('visible');
    $(this).parent().find('.Popup').removeAttr();
    $(this).remove();
  });

  $(document).on("click", ".Modal-Head i", function () {
    $(this).closest(".Modal").removeClass("visible");
  });

  $(document).on("click", ".close-modal", function () {
    $(this).closest(".Modal").removeClass("visible");
  });

  // close modal on click it's overlays
  $(document).on("click", ".visible.Modal.Modal-No-action", (e) => {
    if ($(e.target).hasClass('Modal')) {
      $(e.target).removeClass("visible");
    } else {
      $(e.target).parents('.Modal').removeClass("visible");
    }
  });

  $(document).ready(function () {
    if ($('#company_approved_popup').val() === 'true') {
      $.ajax({
        method: 'GET',
        url: '/customers/check_company_approved_notification',
        success(result) {
          showPopup(result, '.Modal.company-approved-modal-content')
        }
      })
    }
  })

  $(document).on('click', '.btn-seen-company-approved-popup', () => {
    common.closeModal()
    $.ajax({
      method: 'PUT',
      url: '/customers/clear_company_approved_notification'
    })
    $('.mark-company-approved').remove()
  })

  $(document).keydown((e) => {
    if (e.keyCode === 27) {
      $('.Modal.visible').each(function closeEachModal() {
        const attrId = $(this).attr('id')
        const attrClass = $(this).attr('class')
        if (attrClass.includes('escape_popup_visible')) {
          $(this).removeClass('visible')
        }
        if (attrClass.includes('escape_popup_no_action')) {
          return
        }
        if (attrClass.includes('escape_popup_remove')) {
          $(this).remove()
        }
        if (($(this).find('input').length === 0) && ($(this).find('.Normal-Booking-Actions').length === 0)
          && ($(this).find('button').length === 0) && ($(this).find('.Button').length === 0)) {
          if (attrId) {
            $(this).removeClass('visible')
          } else {
            $(this).remove()
          }
        }
      })
    }
  })
}