import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// ASSETS
// API
// ACTIONS
// COMPONENTS
// CONSTANTS
// UTILS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer
})

class CardRowInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderRightInfo() {
    const { rowInfo } = this.props
    if (rowInfo.hasSpan) {
      return (
        <div className="Right">
          <p>
            <span className={rowInfo.spanClass} id={rowInfo.spanID}>
              {rowInfo.value}
            </span>
          </p>
        </div>
      )
    }
    return (
      <div className="Right">
        <p>
          {rowInfo.value}
        </p>
      </div>
    )
  }

  render() {
    const { key, rowInfo } = this.props
    if (rowInfo.isShow) {
      return (
        <div className="Closure-List-Table-Row" key={key}>
          <div className="Left">
            <p>
              {rowInfo.label}
              :
            </p>
          </div>
          { this.renderRightInfo() }
        </div>
      )
    }
    return null
  }
}

CardRowInfo.propTypes = {
  key: PropTypes.string,
  rowInfo: PropTypes.shape({}).isRequired
}
CardRowInfo.defaultProps = {
  key: ''
}
export default connect(mapStateToProps, null)(CardRowInfo)
