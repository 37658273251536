import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const isNewDriverSlice = createSlice({
  name: 'isNewDriver',
  initialState,
  reducers: {
    setIsNewDriver: (state, action: PayloadAction<any>) => (action.payload)
  },
  extraReducers: () => {},
})

export const isNewDriverActionsCreator = isNewDriverSlice.actions

export default isNewDriverSlice
