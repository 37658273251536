import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import StorageKeys from 'constants/storage-keys'
const defaultLoadPlanEnabled = localStorage.getItem('smart_load_planner_enable') === 'true'
const initialState: any = {
  smart_load_planner_enable: defaultLoadPlanEnabled,
  hide_area_selector: true
}

const extraInfosSlice = createSlice({
  name: 'extraInfos',
  initialState,
  reducers: {
    updateExtraInfos: (state, action: PayloadAction<any>) => {
      const smarLoadEnable = action.payload?.smart_load_planner_enable
      const countryCode = action.payload?.country_code
      if(countryCode) {
        window.localStorage.setItem(StorageKeys.COUNTRY_CODE, countryCode.toLowerCase())
      }
      if (smarLoadEnable !== undefined) {
        localStorage.setItem('smart_load_planner_enable', action.payload?.smart_load_planner_enable)
      }
    
      return { ...state, ...action.payload }
    },
    resetCashbackWhenUpdateService: (state) => {
      return { ...state, cash_back_reward: '' }
    }
  },
})

export const extraInfosActionsCreator = extraInfosSlice.actions

export default extraInfosSlice
