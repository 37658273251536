import React from 'react'
import PropTypes from 'prop-types'
import ReimbursementItem from './ReimbursementItem'
import CustomReimbursementItem from './CustomReimbursementItem'
import _ from 'lodash'

import { UtilsHTML } from 'utils/html'
import I18n from 'i18n/i18n'

class ReimbursementList extends React.PureComponent {
  render() {
    const {
      isEnabledReimbursement, vehicleType, isFullday, isLonghaul,
      handleOnChange, handlePopupEye, reimbursementItems, customReimbursements,
      handleOnChangeCustom, handleOnBlurCustom
    } = this.props
    if (!isLonghaul && (!isEnabledReimbursement || _.every(reimbursementItems, { enabled: false }))) {
      return null
    }

    const arrReimbursement = vehicleType ? vehicleType.popups.map(x => x.popup_type) : []
    const reimbursementItemsEnable = _.some(reimbursementItems, { enabled: true })

    if (isFullday) {
      delete reimbursementItems.waitingTime
    } else if (isLonghaul) {
      delete reimbursementItems.tolls
      delete reimbursementItems.parking
    }

    return (
      <div className="mt15">
        {(!_.isEmpty(reimbursementItems) && reimbursementItemsEnable) || !_.isEmpty(customReimbursements) ? UtilsHTML.renderOptionTitle(I18n.t('webapp.pricing_display.reimbursements_to_driver')) : null}
        <div className="Reimbursements block">
          {_.map(reimbursementItems, (
            item => (
              <ReimbursementItem
                displayText={I18n.t(`webapp.new_booking.step_2.${_.snakeCase(item.key)}`)}
                isChecked={item.checked}
                isEnabled={item.enabled}
                isLocked={item.locked}
                popupKey={item.popupKey}
                handleOnChange={handleOnChange}
                handlePopupEye={handlePopupEye}
                arrReimbursement={arrReimbursement}
                itemKey={item.key}
                key={item.key}
              />
            )
          ))}
          {_.map(customReimbursements, (
            item => (
              <CustomReimbursementItem
                reimbursementCustom={item}
                handleOnChangeCustom={handleOnChangeCustom}
                handleOnBlurCustom={handleOnBlurCustom}
                handlePopupEye={handlePopupEye}
                key={item.id}
              />
            )
          ))}
        </div>
      </div>
    )
  }
}

ReimbursementList.propTypes = {
  isFullday: PropTypes.bool.isRequired,
  isLonghaul: PropTypes.bool.isRequired,
  isEnabledReimbursement: PropTypes.bool.isRequired,
  reimbursementItems: PropTypes.shape({
    tolls: PropTypes.shape({}),
    parking: PropTypes.shape({}),
    waitingTime: PropTypes.shape({})
  }).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handlePopupEye: PropTypes.func.isRequired,
  vehicleType: PropTypes.shape({}),

  customReimbursements: PropTypes.arrayOf(PropTypes.shape({})),
  handleOnChangeCustom: PropTypes.func,
  handleOnBlurCustom: PropTypes.func
}

ReimbursementList.defaultProps = {
  vehicleType: undefined,
  customReimbursements: [],
  handleOnChangeCustom: () => { },
  handleOnBlurCustom: () => {}
}

export default ReimbursementList
