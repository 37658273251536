import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const showRouteSlice = createSlice({
  name: 'showRoute',
  initialState,
  reducers: {
    updateShowRoute: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const showRouteActionsCreator = showRouteSlice.actions

export default showRouteSlice
