import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  recipient_name: '',
  recipient_phone: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  postal_code: '',
  latitude: null,
  longitude: null,
  notes: '',
  is_show: false,
  is_required: false,
  invalidKeys: [],
}

const documentReturnSlice = createSlice({
  name: 'documentReturn',
  initialState,
  reducers: {
    updateDocumentReturn: (state: any, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
  },
  extraReducers: () => {},
})

export const documentReturnActionsCreator = documentReturnSlice.actions

export default documentReturnSlice
