import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EZ_SHEET_UPLOAD } from 'constants/common/batchConstants'
import _ from 'lodash'

const initialState: any = {
  id: undefined,
  user_template_type: undefined,
  customer_id: undefined,
  company_id: undefined,
  contact_id: undefined,
  pickup_time: '',
  vehicle_type_id: undefined,
  same_pickup: false,
  same_pickup_datetime: false,
  same_vehicle_type: false,
  same_sub_account_tag: false,
  same_extra_requirements: false,
  same_custom_reimbursements: false,
  same_time_type: false,
  extra_requirements: [],
  consolidates: true,
  batch_type: EZ_SHEET_UPLOAD,
  // [IMPORTANT] batchTemplateActionCreators.currentBatchTemplate will set this mapping
  // mapping: {},
  customer_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    sub_account_tag: 2,
    vehicle_type: 3,
    time_type: 4,
    extra_requirements: 5,
    pickup_date_time: 6,
    name: 7,
    phone: 8,
    address_01: 9,
    address_02: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
    express_mail_to_name: 18,
    express_mail_to_phone: 19,
    express_mail_to_address: 20,
    express_mail_to_city: 21,
    express_mail_to_state: 22,
    express_mail_to_postal_code: 23,
    other_reimbursements: 24,
    note_to_driver: 25,
  },
  company_default_mapping: {
    consolidates: 0,
    job_order_number: 1,
    sub_account_tag: 2,
    vehicle_type: 3,
    time_type: 4,
    extra_requirements: 5,
    pickup_date_time: 6,
    name: 7,
    phone: 8,
    address_01: 9,
    address_02: 10,
    district: 11,
    city: 12,
    state_province: 13,
    postcode: 14,
    location_note: 15,
    latitude: 16,
    longitude: 17,
    express_mail_to_name: 18,
    express_mail_to_phone: 19,
    express_mail_to_address: 20,
    express_mail_to_city: 21,
    express_mail_to_state: 22,
    express_mail_to_postal_code: 23,
    need_pod: 24,
    pod_note: 25,
    need_cod: 26,
    cod_invoice_fees: 27,
    cod_note: 28,
    other_reimbursements: 29,
    note_to_driver: 30,
  },
  position: {
    company: {
      left_column: [
        'job_order_number',
        'sub_account_tag',
        'vehicle_type',
        'time_type',
        'extra_requirements',
        'pickup_date_time',
        'name',
        'phone',
        'address_01',
        'address_02',
        'district',
        'city',
      ],
      right_column: [
        'state_province',
        'postcode',
        'location_note',
        'latitude',
        'longitude',
        'need_pod',
        'pod_note',
        'need_cod',
        'cod_invoice_fees',
        'cod_note',
        'other_reimbursements',
        'note_to_driver',
        'express_mail_to_name',
        'express_mail_to_phone',
        'express_mail_to_address',
        'express_mail_to_city',
        'express_mail_to_state',
        'express_mail_to_postal_code',
      ],
    },
    individual: {
      left_column: [
        'job_order_number',
        'sub_account_tag',
        'vehicle_type',
        'time_type',
        'extra_requirements',
        'pickup_date_time',
        'name',
        'phone',
        'address_01',
        'address_02',
      ],
      right_column: [
        'district',
        'city',
        'state_province',
        'postcode',
        'location_note',
        'latitude',
        'longitude',
        'other_reimbursements',
        'note_to_driver',
        'express_mail_to_name',
        'express_mail_to_phone',
        'express_mail_to_address',
        'express_mail_to_city',
        'express_mail_to_state',
        'express_mail_to_postal_code',
      ],
    },
  },
}

const batchTemplateSlice = createSlice({
  name: 'batchTemplate',
  initialState,
  reducers: {
    updateBatchTemplate: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
  extraReducers: () => {},
})

export const batchTemplateActionsCreator = batchTemplateSlice.actions

export default batchTemplateSlice
