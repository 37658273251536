import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, findIndex } from 'lodash'

const initialState: any = []
const tmpLocationSlice = createSlice({
  name: 'updateTmpLocation',
  initialState,
  reducers: {
    updateTmpLocation: (state: any, action: PayloadAction<any>) => {
      const index = findIndex(state, (location: any) => parseInt(location.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        state[index] = {
          ...state[index],
          ...action.payload.locationAttrs
        }
      }
    },
    setTmpLocations: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const tmpLocationActionsCreator = tmpLocationSlice.actions

export default tmpLocationSlice
