import apiClient from 'services/axiosApp'

const EventNotificationAPI = {
  getCurrentEvent: async (areaId, currentCustomer, callback) => {
    const queryParam = {
      params: {
        company_id: currentCustomer ? currentCustomer.current_company_id : 0
      },
      headers: {
        'Area-Id': areaId,
        'Device-Type': 'Web'
      }
    }
    try {
      const res = await apiClient.get('/api/v3/event_notifications', queryParam,)
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default EventNotificationAPI
