import {
  ALLOW_DRIVER_TO_CALL,
  CHECK_CUSTOMER_MASK_PHONE,
  CLEAR_CUSTOMER_MASK_PHONE,
} from 'constants/newBookingConstants'
import _ from 'lodash'
import I18n from 'i18n/i18n'

const contactUtils = {
  showNameAndCompanyName(recipientName, companyName) {
    if (companyName && companyName.trim().length > 0) {
      return `${recipientName} - ${companyName}`
    }
    return recipientName
  },
  showLocationNameAndName(locationName, picName) {
    if (locationName && locationName.trim().length > 0) {
      return `${locationName} - ${picName}`
    }
    return picName
  },
  getPhoneMaskAndPhoneNumber(type, attrs = {}) {
    let phoneNumber
    let phoneMask
    switch (type) {
      case ALLOW_DRIVER_TO_CALL:
        return { phone_mask: I18n.t('contacts.labels.not_show_phone') }
      case CHECK_CUSTOMER_MASK_PHONE:
        phoneNumber = _.isEmpty(attrs.recipient_phone) ? attrs.customer_phone : attrs.recipient_phone
        phoneMask = _.isEmpty(attrs.recipient_phone) ? I18n.t('contacts.labels.not_show_phone') : ''
        return { phone_mask: phoneMask, recipient_phone: phoneNumber }
      case CLEAR_CUSTOMER_MASK_PHONE:
        phoneNumber = (attrs.recipient_phone === I18n.t('contacts.labels.not_show_phone')) ? '' : attrs.recipient_phone
        return { phone_mask: '', recipient_phone: phoneNumber }
      default:
        break
    }
    return true
  },
  isExistInputValue(input) {
    return _.trim(input?.value).length > 0
  }
}

export default contactUtils
