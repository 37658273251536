import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// ACTION
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
// API

// CONSTANT
import * as IMAGES from 'constants/imageConstants'
import { FULL_DAY } from 'constants/bookingConstants'
//UTILS
import I18n from 'i18n/i18n'
import { showRouteActionsCreator } from 'store/toolkit/showRoute/showRoute.reducer'

const mapStateToProps = state => ({
  showRoute: state.showRoute,
  buttonLink: state.buttonLink,
  booking: state.booking,
  timeType: state.timeType
})

function mapDispatchToProps(dispatch) {
  return {
    showRouteActions: bindActionCreators(showRouteActionsCreator, dispatch),
    locationActions: bindActionCreators(locationActionCreators, dispatch),
  }
}

class ShowRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleShowRoute() {
    const {
      showRoute,
      showRouteActions,
      locationActions,
      booking,
      timeType,
      extraInfos,
      fullLoadView
    } = this.props
    const isFulldayMegazone = timeType === FULL_DAY && extraInfos.enable_full_day_megazone && fullLoadView
    showRouteActions.updateShowRoute(!showRoute)
    locationActions.drawBookingLocationRoute('', isFulldayMegazone && booking.outsideList)
  }

  render() {
    const {
      showRoute,
    } = this.props
    const buttonImg = showRoute ? IMAGES.SHOW_ROUTE_BUTTON.active : IMAGES.SHOW_ROUTE_BUTTON.not_active
    return (
      <div
        id="show-route"
        className={`pl20 pr20 ${showRoute ? 'Active Green-bg' : 'White-bg'}`}
        onClick={() => this.handleShowRoute()}
      >
        <img
          src={buttonImg}
          type="button"
          className="show-route-button"
          height="28"
        />
        <span className={`larger-font ml10 ${showRoute ? 'White-text' : 'Green-text'}`}>
          {I18n.t('webapp.label.show_route')}
        </span>
      </div>
    )
  }
}

ShowRoute.propTypes = {
  showRoute: PropTypes.bool.isRequired,
  showRouteActions: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
  booking: PropTypes.shape({}).isRequired,
  timeType: PropTypes.string.isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  fullLoadView: PropTypes.bool,
}

ShowRoute.defaultProps = {
  fullLoadView: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowRoute)
