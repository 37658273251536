import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []
const dropOffZonesSlice = createSlice({
  name: 'dropOffZones',
  initialState,
  reducers: {
    updateDropOffZones: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const dropOffZonesActionsCreator = dropOffZonesSlice.actions

export default dropOffZonesSlice
