import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateContacts: (state, action: PayloadAction<any>) => action.payload.contacts,
    deleteContactLocal: (state, action: PayloadAction<any>) => {
      _.remove(state, { id: action.payload })
    },
    updateSingleContact: (state, action: PayloadAction<any>) => {
      const contact = _.find(state, { id: action.payload.id })
      if (!_.isUndefined(contact)) {
        _.assign(contact, action.payload.attrs)
      }
    },
    addNewContact: (state, action: PayloadAction<any>) => {
      state.push(action.payload)
    },
  },
  extraReducers: () => {},
})

export const contactsActionsCreator = contactsSlice.actions

export default contactsSlice
