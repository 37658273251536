/* eslint-disable import/prefer-default-export */
import Immutable from 'immutable'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { currentVehicleType, getLatLongLocation } from 'utils/new_booking/common'
import mapUtils from 'utils/common/map'
import { CPODUtils } from 'utils/booking/CPODUtils'
import { Utils } from 'utils/Utils'
import locationUtils from 'utils/common/location'
import I18n from 'i18n/i18n'
// import { Utils } from 'utils/Utils'
// API
import LocationAPI from 'api/locations'
// ACTIONS
import { getNumberOfOnlineDrivers } from './vehicleTypeActionCreators'

import {
  FULL_DAY,
  LONG_HAUL,
  BOOKING_UPDATE_COD_FEE,
  NOW,
  IMMEDIATE,
  SCHEDULE,
  iconTextColors,
  iconTextPositions,
} from 'constants/bookingConstants'
// ASSETS
import * as IMAGES from 'constants/imageConstants'
import { getCurentCompanyTypeId, isMarketingPage, setDefaultMaxLocation } from 'utils/booking/common'
import StorageKeys from 'constants/storage-keys'
import areaApi from 'api/Area'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { acceptableTimeTypesActionsCreator } from 'store/toolkit/newBooking/acceptableTimeTypes.reducer'
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer'
import { isOptimizedActionsCreator } from 'store/toolkit/newBooking/isOptimezed.reducer'
import { tmpLocationActionsCreator } from 'store/toolkit/newBooking/updateTmpLocation.reducer'
import { pickupZonesActionsCreator } from 'store/toolkit/pickupZones/pickupZones.reducer'
import { selectedPickupZonesActionsCreator } from 'store/toolkit/pickupZones/selectedPickupZone.reducer'
import { selectedDropOffZoneActionsCreator } from 'store/toolkit/pickupZones/selectedDropOffZone.reducer'
import { dropOffZonesActionsCreator } from 'store/toolkit/pickupZones/dropOffZones.reducer'
import { beforeOptimizedLocationsActionsCreator } from 'store/toolkit/newBooking/beforeOptimizedLocations.reducer'
import { bookingActionsCreator } from 'store/toolkit/bookings/booking.reducer'
import { polylineActionsCreator } from 'store/toolkit/polyline/polyline.reducer'
import { prevRouteActionsCreator } from 'store/toolkit/prevRoute/prevRoute.reducer'
import { prevLocationsActionsCreator } from 'store/toolkit/prevLocations/prevLocations.reducer'
import { prevOptimizeRouteActionsCreator } from 'store/toolkit/prevOptimizeRoute/prevOptimizeRoute.reducer'
import { waypointOrderActionsCreator } from 'store/toolkit/waypointOrder/waypointOrder.reducer'
// STATIC
const getIcon = (index, locationsLength, isRouteIcon, pinfrom, maxNumberOfLocation = 11, roundTripDiscount = false,
  indexRoundTrip = locationsLength - 2) => {
  const [
    fromImg, waypointImg, toImg, roundTripDiscountImg, roundTripDiscountPinNoneIcon, showRouteRoundTripPinImg
  ] = [
      IMAGES.PIN_BLUE,
      IMAGES.PIN_YELLOW,
      IMAGES.PIN_YELLOW,
      IMAGES.ROUND_TRIP_DISCOUNT_PIN_ICON,
      IMAGES.ROUND_TRIP_DISCOUNT_PIN_NONE_ICON,
      IMAGES.SHOW_ROUTE_ROUND_TRIP_PIN
    ]
  const zIndexPinLargerVehicleIcon = 10000
  const customIndex = maxNumberOfLocation - index + zIndexPinLargerVehicleIcon
  switch (index) {
    case 0:
      if (roundTripDiscount) {
        return {
          icon: { url: roundTripDiscountImg[I18n.language], labelOrigin: new window.google.maps.Point(44, 16) },
          label: { text: (locationsLength - 1).toString(), color: iconTextColors.black },
          zIndex: customIndex
        }
      }
      if (!_.isEmpty(pinfrom)) {
        return {
          icon: { url: pinfrom.icon, labelOrigin: new window.google.maps.Point(19, 17) },
          zIndex: customIndex
        }
      }
      return {
        icon: { url: fromImg, labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]) },
        label: { text: I18n.t('label.pin_from'), color: iconTextColors.white },
        zIndex: customIndex
      }
    case 1:
      if (locationsLength === 2) {
        return {
          icon: { url: toImg, labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]) },
          label: { text: I18n.t('label.pin_to'), color: iconTextColors.black },
          zIndex: customIndex
        }
      }
      if (isRouteIcon) {
        return { icon: { url: IMAGES[`ROUTE_DOT_YELLOW_${index}`] }, zIndex: customIndex }
      }
      return {
        icon: { url: waypointImg, labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]) },
        label: { text: index.toString(), color: iconTextColors.black },
        zIndex: customIndex
      }
    default: // waypointImg
      if (!roundTripDiscount && isRouteIcon) {
        return { icon: { url: IMAGES[`ROUTE_DOT_YELLOW_${index}`] }, zIndex: customIndex }
      }
      if (roundTripDiscount && index === indexRoundTrip) {
        return {
          icon: {
            url: showRouteRoundTripPinImg[I18n.language],
            labelOrigin: new window.google.maps.Point(174, 25),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(174, 55),
          },
          label: { text: index.toString(), color: iconTextColors.black },
          zIndex: customIndex
        }
      }
      if (roundTripDiscount && index === locationsLength - 1) {
        return {
          icon: { url: roundTripDiscountPinNoneIcon },
          zIndex: 1,
        }
      }

      return {
        icon: { url: waypointImg, labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]) },
        label: { text: index.toString(), color: iconTextColors.black },
        zIndex: customIndex
      }
  }
}

const getMarkerIcon = (
  index, locationsLength, pinfrom, maxNumberOfLocation, roundTripDiscount, indexRoundTrip
) => getIcon(index, locationsLength, false, pinfrom, maxNumberOfLocation, roundTripDiscount, indexRoundTrip)

// const getRouteIcon = (index, locationsLength, pinfrom) => getIcon(index, locationsLength, true, pinfrom)

const getIndexRoundTrip = (locations) => {
  let roundTrip = locations.length - 2
  if (!_.isUndefined(locations[roundTrip]) && locations[roundTrip].marker) {
    return roundTrip
  }
  for (let index = roundTrip - 1; index > 0; index -= 1) {
    if (locations[index].marker) {
      roundTrip = index
      break
    }
  }
  return roundTrip
}

const drawTrackingRoute = (state, pinfrom, callback) => {
  let routes = []
  const indexRoundTrip = getIndexRoundTrip(state.locations)
  _.forEach(state.locations, (location, index) => {
    if (location.marker) {
      location.marker.setOptions(getMarkerIcon(
        index,
        // _.filter(state.locations, lo => !_.isUndefined(lo.marker)).length,
        state.locations.length,
        pinfrom,
        setDefaultMaxLocation(state.currentCustomer, state.extraInfos),
        state.roundTripDiscount,
        indexRoundTrip
      ))
      // location.routeIcon = getRouteIcon(index, state.locations.length)
      if (state.roundTripDiscount && index === state.locations.length - 1) {
        return
      }
      routes.push({
        location: location.marker.getPosition(),
        stopover: false
      })
    }
  })
  if (routes.length < 2) {
    return
  }
  routes = Immutable.List(routes)
  const keyAvoidRoute = mapUtils.buildKeyAvoidRoute(currentVehicleType(state))
  const req = {
    origin: routes.first().location,
    destination: routes.last().location,
    waypoints: routes.splice(routes.size - 1, 1).splice(0, 1).toJS(),
    optimizeWaypoints: true,
    avoidHighways: keyAvoidRoute.avoidHighways,
    avoidTolls: keyAvoidRoute.avoidTolls,
    avoidFerries: keyAvoidRoute.avoidFerries,
    travelMode: currentVehicleType(state).settings.travel_mode.toUpperCase()
  }
  callback(req)
}

const drawTrackingPolyline = (state, pinfrom, callback) => {
  let routes = []
  const indexRoundTrip = getIndexRoundTrip(state.locations)
  _.forEach(state.locations, (location, index) => {
    if (location.marker) {
      location.marker.setOptions(getMarkerIcon(
        index,
        state.locations.length,
        pinfrom,
        setDefaultMaxLocation(state.currentCustomer, state.extraInfos),
        state.roundTripDiscount,
        indexRoundTrip
      ))
      if (state.roundTripDiscount && index === state.locations.length - 1) {
        return
      }
      routes.push({
        id: location.id,
        location: location.marker.getPosition()
      })
    }
  })
  if (routes.length < 2) {
    return
  }
  routes = Immutable.List(routes)
  callback(routes)
}

export const updateOptimizeState = isOptimized => isOptimizedActionsCreator.updateOptimizeState(isOptimized)

export const updateLocation = (id, locationAttrs) => locationsActionsCreator.updateLocation({
  id,
  locationAttrs
})
export const updateTmpLocation = (id, locationAttrs) => tmpLocationActionsCreator.updateTmpLocation({
  id,
  locationAttrs
})

export const removeBlurMarker = () => (dispatch) => dispatch(locationsActionsCreator.removeBlurMarker())

export const initTmpLocations = () => (dispatch, getState) => {
  let cloneLocations = [...getState().locations]
  // eslint-disable-next-line max-len
  cloneLocations = _.cloneDeep(cloneLocations.map(location => _.assign({}, location, { marker: {}, need_pod: location.pre_selected || location.need_pod })))
  dispatch(tmpLocationActionsCreator.setTmpLocations(cloneLocations))
}

export const updateLazyAddressLocation = (id, lazyAddressError) => locationsActionsCreator.updateLazyAddressLocationNewBooking({
  id,
  lazyAddressError
})

export const updateCODPODFees = (documentReturn = {}, resultCheckLocations = {}) => (dispatch, getState) => {
  let isSwitchLongHaul = false
  const { currentCustomer, extraInfos } = getState()
  const companyTypeId = getCurentCompanyTypeId(currentCustomer)

  if (_.size(resultCheckLocations) > 0) {
    const {
      long_haul_address_valid: longHaulValid = false,
      long_haul_pickup: { is_valid: isValidLH = false } = {}
    } = resultCheckLocations

    isSwitchLongHaul = longHaulValid && isValidLH
  }

  if (getState().timeType === LONG_HAUL || isSwitchLongHaul) {
    return true
  }

  const locations = getState().locations
  const cloneDocumentReturn = (documentReturn || { ...getState().documentReturn }) || {}
  const newGenPOD = CPODUtils.verifyNewGenCPOD({
    bookAgainDetails: getState().bookAgainDetails,
    checkLocations: getState().checkLocations
  })

  const params = CPODUtils.validateParamsCalculateFees({
    locations,
    companyTypeID: companyTypeId || undefined,
    timeType: getState().timeType,
    vehicleTypeID: getState().selectedVehicleTypeID,
    cloneDocumentReturn,
    areaID: extraInfos.area_id || currentCustomer.area_id,
    newGenPOD,
  })

  const validLocationsPOD = !!_.find(locations, ['need_pod', true])
  let verify = false
  const invalidKeys = CPODUtils.invalidDocumentReturnAddress({
    recipient_name: cloneDocumentReturn.recipient_name,
    recipient_phone: cloneDocumentReturn.recipient_phone,
    address_1: cloneDocumentReturn.address_1,
    city: cloneDocumentReturn.city,
    state: cloneDocumentReturn.state,
    postal_code: cloneDocumentReturn.postal_code,
    latitude: cloneDocumentReturn.latitude,
    longitude: cloneDocumentReturn.longitude,
  }, CPODUtils.keys())
  if (_.size(invalidKeys)) {
    verify = true
  }
  const shouldNotCallCPOD = newGenPOD && (!validLocationsPOD || verify)

  if ((params.locations && !params.company_type_id) || shouldNotCallCPOD) {
    return true
  }

  return LocationAPI.calculateCODPODFees(
    params,
    currentCustomer.authentication_token,
    (response) => {
      dispatch(bookingActionsCreator.bookingUpdateCODFee({
        value: { cod_pod_fees: response.fees }
      }))
    }
  )
}

export const resetBookingFees = () => (dispatch) => {
  dispatch(bookingActionsCreator.bookingUpdateCODFee({
    value: { cod_pod_fees: 0 }
  }))
}

export const addLocation = (attrs = {}) => (dispatch, getState) => {
  const state = getState()
  const extraInfos = state.extraInfos
  if (extraInfos.default_payer_is_destination === true) {
    _.assign(_.find(state.locations, { is_payer: true }), { is_payer: false })
  }
  const location = {
    id: attrs.id || Utils.uniqueId(),
    is_payer: extraInfos.default_payer_is_destination,
    marker: attrs.marker,
    lat: undefined || attrs.lat,
    lng: undefined || attrs.lng,
    name: undefined || attrs.name,
    recipient_name: '' || attrs.recipient_name,
    recipient_phone: '' || attrs.recipient_phone,
    extra_requirement_locations: [],
    need_cod: false,
    need_pod: (state.timeType === FULL_DAY
      && extraInfos.full_day_cod_pod_toogle_turn_off === true) ? false : extraInfos.check_pod_by_default,
    can_toggle_need_pod: extraInfos.check_pod_by_default ? extraInfos.can_toggle_pod : true,
    pod_note: '',
    cod_note: '',
    cod_invoice_fees: '',
    description: '' || attrs.description,
    phone_mask: attrs.phone_mask,
    address_components: attrs.address_components
  }
  if (state.roundTripDiscount) {
    dispatch(locationsActionsCreator.insertLocation(location))
  } else {
    dispatch(locationsActionsCreator.addLocation(location))
  }
  dispatch(dataChangesActionsCreator.updateDataChange(true))
}

export const drawBookingLocationRoute = (pinfrom, outsideList = []) => (dispatch, getState) => {
  const state = getState()
  let polylineRoute
  const locations = mapUtils.buildPrevLocations(state.locations, state.roundTripDiscount)
  if (window.google && window.google.maps) {
    state.googleMap.directionsDisplay.setMap(null)
    if (state.polyline.length > 0) {
      _.forEach(state.polyline, (polyline) => {
        polyline.setMap(null)
      })
    }
    if (state.showRoute) {
      if (state.prevRoute && state.prevLocations
        && !mapUtils.isChangeLocations(locations, state.prevLocations, {
          isOptimized: state.isOptimized,
          prevOptimizeRoute: state.prevOptimizeRoute,
        })) {
        drawTrackingPolyline(state, pinfrom, () => {
          const route = (state.isOptimized && state.prevOptimizeRoute) ? state.prevOptimizeRoute : state.prevRoute
          polylineRoute = mapUtils.drawPrevRouteByPolyLine(state.googleMap, route, outsideList)
          dispatch(polylineActionsCreator.updatePolyline(polylineRoute))
        })
      } else {
        drawTrackingRoute(state, pinfrom, (req) => {
          state.googleMap.directionsService.route(req, (response, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              state.googleMap.directionsDisplay.setDirections(response)
              state.googleMap.directionsDisplay.setMap(state.googleMap.map)
              if (state.isOptimized) {
                dispatch(prevOptimizeRouteActionsCreator.updatePrevOptimizeRoute(response))
              } else {
                dispatch(prevOptimizeRouteActionsCreator.updatePrevOptimizeRoute(false))
                dispatch(prevRouteActionsCreator.updatePrevRoute(response))
              }
              dispatch(prevLocationsActionsCreator.updatePrevLocations(locations))
            }
          })
        })
      }
    } else {
      drawTrackingPolyline(state, pinfrom, (req) => {
        polylineRoute = mapUtils.drawPolyLine(state.googleMap, req, outsideList)
        dispatch(polylineActionsCreator.updatePolyline(polylineRoute))
      })
    }
  }
}

export const actionUpdateLocation = (
  id,
  locationAttrs,
  pinfrom = undefined,
  flowBookAgain = false,
  outsideList = []
) => (dispatch, getState) => {
  const state = getState()
  Promise.resolve(
    dispatch(locationsActionsCreator.updateLocation({ id, locationAttrs })),
  ).then(() => {
    if (Object.prototype.hasOwnProperty.call(locationAttrs, 'marker')) {
      if (id === state.locations[0].id) {
        if (state.roundTripDiscount) {
          const lastLeg = _.last(state.locations)
          dispatch(
            locationsActionsCreator.updateLocation({
              id: lastLeg.id,
              locationAttrs: {
                lat: locationAttrs.lat,
                lng: locationAttrs.lng,
                name: locationAttrs.name,
                address_components: locationAttrs.address_components,
              }
            })
          )
          if (lastLeg?.marker?.setPosition) {
            const newPosition = new window.google.maps.LatLng(locationAttrs.lat, locationAttrs.lng)
            lastLeg.marker.setPosition(newPosition)
          }
        }
        if (locationAttrs.lat && locationAttrs.lng) dispatch(getNumberOfOnlineDrivers(state.selectedVehicleTypeID))
      }
      if (!flowBookAgain) {
        dispatch(beforeOptimizedLocationsActionsCreator.backupLocations({ locations: [] }))
      }
      if (!_.isEmpty(pinfrom)) {
        dispatch(drawBookingLocationRoute(pinfrom, outsideList))
      } else {
        dispatch(drawBookingLocationRoute('', outsideList))
      }
    }
  })
}

export const setLocations = locations => (dispatch, getState) => {
  const state = getState()

  if (_.size(locations) < _.size(state.locations)) {
    Promise.resolve(
      dispatch(locationsActionsCreator.setLocation(locations))
    ).then(() => {
      dispatch(drawBookingLocationRoute())
      dispatch(dataChangesActionsCreator.updateDataChange(true))
    })
  } else {
    dispatch(locationsActionsCreator.setLocation(locations))
  }
}

export const removeLocation = (location, outsideList = []) => (dispatch, getState) => {
  const state = getState()
  if (location.is_payer === true) {
    const payerIndex = state.extraInfos.default_payer_is_destination === true ? state.locations.length - 2 : 0
    dispatch(actionUpdateLocation(state.locations[payerIndex].id, {
      is_payer: true, name: state.locations[payerIndex].name
    }, undefined, false))
  }
  Promise.resolve(
    dispatch(locationsActionsCreator.removeLocation(location.id))
  ).then(() => {
    dispatch(beforeOptimizedLocationsActionsCreator.backupLocations({ locations: [] }))
    dispatch(drawBookingLocationRoute('', outsideList))
    dispatch(dataChangesActionsCreator.updateDataChange(true))
  })
}

const setUpForNew = (updateBookerForLocations = false) => (dispatch, getState) => {
  const state = getState()
  const { currentCustomer, extraInfos } = state
  const checkPODByDefault = state.timeType === FULL_DAY
    && extraInfos.full_day_cod_pod_toogle_turn_off === true ? false : extraInfos.check_pod_by_default
  const locations = [
    {
      id: Utils.uniqueId(),
      marker: undefined,
      lat: undefined,
      lng: undefined,
      name: undefined,
      recipient_name: currentCustomer.name,
      recipient_phone: currentCustomer.phone,
      extra_requirement_locations: [],
      is_payer: !extraInfos.default_payer_is_destination,
      need_cod: false,
      need_pod: checkPODByDefault,
      can_toggle_need_pod: extraInfos.check_pod_by_default ? extraInfos.can_toggle_pod : true,
      pod_note: '',
      cod_note: '',
      cod_invoice_fees: '',
      description: ''
    },
    {
      id: Utils.uniqueId(),
      is_payer: extraInfos.default_payer_is_destination,
      marker: undefined,
      lat: undefined,
      lng: undefined,
      name: undefined,
      recipient_name: updateBookerForLocations ? currentCustomer.name : '',
      recipient_phone: updateBookerForLocations ? currentCustomer.phone : '',
      extra_requirement_locations: [],
      need_cod: false,
      need_pod: checkPODByDefault,
      can_toggle_need_pod: extraInfos.check_pod_by_default ? extraInfos.can_toggle_pod : true,
      pod_note: '',
      cod_note: '',
      cod_invoice_fees: '',
      description: ''
    }
  ]
  dispatch(setLocations(locations))
}

// checkPODByDefault for editBooking and booking again base on setting and origin booking (start from step 3)
const setUpForEditBookingAgain = () => (dispatch, getState) => {
  const bookingDetails = getState().bookAgainDetails
  const extraInfos = getState().extraInfos
  const turnOffPODCODForFullDay = getState().timeType === FULL_DAY
    && extraInfos.full_day_cod_pod_toogle_turn_off === true
  const fulldayNoneDestinations = bookingDetails.time_type === FULL_DAY
    && bookingDetails.locations.length === 2
    && bookingDetails.locations[0].latitude === bookingDetails.locations[1].latitude
    && bookingDetails.locations[0].longitude === bookingDetails.locations[1].longitude
  const locations = _.map(bookingDetails.locations, (location, index) => {
    const correctedNeedPOD = turnOffPODCODForFullDay ? false : location.need_pod
    let correctedNeedCOD = false
    let correctedCODInvoiceFees = ''
    let correctedPODNote = ''
    let correctedCODNote = ''
    let latitude = location.latitude
    let longitude = location.longitude
    let name = location.name
    let phoneMask
    if (location.is_phone_mask) {
      phoneMask = I18n.t('contacts.labels.not_show_phone')
    }
    if (index !== 0 && fulldayNoneDestinations) {
      latitude = undefined
      longitude = undefined
      name = undefined
    } else if (correctedNeedPOD) {
      correctedNeedCOD = location.need_cod
      correctedCODInvoiceFees = location.cod_invoice_fees || ''
      correctedPODNote = location.pod_note
      correctedCODNote = location.cod_note
    }
    return _.assign({}, {
      id: location.id || Utils.uniqueId(),
      marker: undefined,
      lat: latitude,
      lng: longitude,
      name,
      recipient_name: location.recipient_name,
      recipient_phone: location.recipient_phone,
      extra_requirement_locations: location.extra_requirement_locations,
      is_payer: !isMarketingPage && location.is_payer,
      need_cod: correctedNeedCOD,
      need_pod: isMarketingPage ? extraInfos.check_pod_by_default : correctedNeedPOD,
      description: location.description,
      can_toggle_need_pod: extraInfos.check_pod_by_default ? extraInfos.can_toggle_pod : true,
      pod_note: correctedPODNote,
      cod_note: correctedCODNote,
      cod_invoice_fees: correctedCODInvoiceFees,
      phone_mask: phoneMask,
      address_components: location.address_components
    })
  })
  if (isMarketingPage && locations?.length > 0) {
    if (extraInfos?.default_payer_is_destination) {
      locations[locations.length - 1].is_payer = true
    } else locations[0].is_payer = true
  }
  dispatch(setLocations(locations))
}

export const setUpLocations = (updateBookerForLocations = false) => (dispatch, getState) => {
  if (_.isEmpty(getState().bookAgainDetails)) {
    dispatch(setUpForNew(updateBookerForLocations))
  } else {
    dispatch(setUpForEditBookingAgain())
  }
}


const rad = x => x * Math.PI / 180

const getDistance = (p1, p2) => {
  const R = 6378137
  const dLat = rad(p2.lat() - p1.lat())
  const dLong = rad(p2.lng() - p1.lng())
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

const farestIndex = (locations) => {
  let result = 0
  let distance = 0
  const originPosition = locations[0].marker.getPosition()
  _.forEach(locations, (route, index) => {
    const currentPosition = route.marker.getPosition()
    const currentDistance = getDistance(originPosition, currentPosition)
    if (currentDistance >= distance) {
      result = index
      distance = currentDistance
    }
  })
  return result
}

export const handleOptimizeLocations = (response, options = {}, outsideList = []) => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations
  const waypointOrder = _.isEmpty(response) || response.routes[0].waypoint_order.length === 0 ? state.waypointOrder
    : response.routes[0].waypoint_order
  const optimizedLocations = [options.locationsTemp[0]]
  _.forEach(waypointOrder, (orderNumber) => {
    optimizedLocations.push(options.locationsTemp[orderNumber + 1])
  })
  if (state.roundTripDiscount) {
    const lastLeg = _.last(options.locations)
    optimizedLocations.push(lastLeg)
    options.locationsTemp.push(lastLeg)
  }
  // check optimize route and return discount when checked not keep value
  if (!_.isEmpty(locations) && !_.isEmpty(optimizedLocations) && state.roundTripDiscount
    && _.isEqual(getLatLongLocation(locations), getLatLongLocation(optimizedLocations))) { return }
  Promise.resolve(
    dispatch(beforeOptimizedLocationsActionsCreator.backupLocations({ locations: options.locationsTemp }))
  ).then(() => {
    _.assign(_.find(optimizedLocations, { is_payer: true }), { is_payer: false })
    const payerIndex = state.extraInfos.default_payer_is_destination === true ? optimizedLocations.length - 1 : 0
    _.assign(optimizedLocations[payerIndex], { is_payer: true })
    dispatch(setLocations(optimizedLocations))
    dispatch(waypointOrderActionsCreator.updateWaypointOrder(waypointOrder))
    dispatch(prevLocationsActionsCreator.updatePrevLocations(options.prevLocations))
    dispatch(drawBookingLocationRoute('', outsideList))
  })
}

export const optimizeLocations = (locations, outsideList = []) => (dispatch, getState) => {
  let routes = []
  const state = getState()
  const prevLocations = mapUtils.buildPrevLocations(locations, state.roundTripDiscount)
  state.googleMap.directionsDisplay.setMap(null)
  const locationsTemp = [...locations]
  if (state.roundTripDiscount) {
    locationsTemp.pop()
  }
  _.forEach(locationsTemp, (route) => {
    if (route.marker) {
      routes.push({
        location: route.marker.getPosition()
      })
    }
  })
  routes = Immutable.List(routes)
  const keyAvoidRoute = mapUtils.buildKeyAvoidRoute(currentVehicleType(state))
  const req = {
    origin: routes.first().location,
    destination: routes.get(farestIndex(locationsTemp)).location,
    waypoints: routes.splice(0, 1).toJS(),
    optimizeWaypoints: true,
    avoidHighways: keyAvoidRoute.avoidHighways,
    avoidTolls: keyAvoidRoute.avoidTolls,
    avoidFerries: keyAvoidRoute.avoidFerries,
    travelMode: currentVehicleType(state).settings.travel_mode.toUpperCase()
  }
  Promise.resolve(
    dispatch(isOptimizedActionsCreator.updateOptimizeState(true))
  ).then(() => {
    if (state.waypointOrder && state.prevLocations
      && !mapUtils.isChangeLocations(prevLocations, state.prevLocations, {
        isOptimized: true,
        prevOptimizeRoute: state.prevOptimizeRoute,
      })) {
      dispatch(handleOptimizeLocations(state.prevOptimizeRoute, {
        locations,
        locationsTemp,
        prevLocations,
      }, outsideList))
    } else {
      state.googleMap.directionsService.route(req, (response) => {
        dispatch(handleOptimizeLocations(response, {
          locations,
          locationsTemp,
          prevLocations,
        }, outsideList))
      })
    }
  })
}

export const resetOptimizeLocations = (outsideList = []) => (dispatch, getState) => {
  const isOptimized = false
  if (getState().beforeOptimizedLocations.length !== 0) {
    dispatch(setLocations(getState().beforeOptimizedLocations))
  }
  dispatch(beforeOptimizedLocationsActionsCreator.backupLocations({ locations: [] }))
  dispatch(isOptimizedActionsCreator.updateOptimizeState(isOptimized))
  dispatch(drawBookingLocationRoute('', outsideList))
}

export const isAllValid = (flow = undefined) => (dispatch, getState) => {
  const timeType = getState().timeType
  const locations = getState().locations

  if (timeType === FULL_DAY) {
    const pickupLocation = locations[0]
    return !_.isEmpty(pickupLocation) && !_.isUndefined(pickupLocation.lat) && !_.isUndefined(pickupLocation.lng)
  }
  if (flow === 'OptimizeRoute') {
    return _.findIndex(locations, l => _.isUndefined(l.lat) || _.isUndefined(l.lng)) === -1
  }
  if (_.isUndefined(locations[0].lat) || _.isUndefined(locations[0].lng)) {
    return false
  }
  const invalidLocations = _.filter(locations, l => _.isUndefined(l.lat) || _.isUndefined(l.lng))
  return locations.length - invalidLocations.length >= 2
}

export const setUpForNewWhenChangeTimeType = timeType => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  if (_.isEmpty(state.bookAgainDetails)) {
    const checkPODByDefault = timeType === FULL_DAY
      && extraInfos.full_day_cod_pod_toogle_turn_off === true ? false : extraInfos.check_pod_by_default
    const locations = _.map(state.locations, location => _.assign(location, {
      need_pod: location.pre_selected || checkPODByDefault
    }))
    dispatch(setLocations(locations))
  }
}

export const updatePickupZones = pickupZones => (dispatch) => {
  dispatch(pickupZonesActionsCreator.updatePickupZones(pickupZones))
}

export const updateDropOffZones = dropOffZones => (dispatch) => {
  dispatch(dropOffZonesActionsCreator.updateDropOffZones(dropOffZones))
}

export const resetPickupZones = () => (dispatch) => {
  dispatch(pickupZonesActionsCreator.updatePickupZones([]))
}

export const resetDropOffZones = () => (dispatch) => {
  dispatch(dropOffZonesActionsCreator.updateDropOffZones([]))
}

export const resetSelectedPickupZones = zone => (dispatch) => {
  dispatch(selectedPickupZonesActionsCreator.resetSelectedPickupZones(zone))
}

export const updateSelectedPickupZones = (pickupZones, zoneID, callback = (() => { })) => (dispatch) => {
  Promise.resolve(
    dispatch(selectedPickupZonesActionsCreator.updateSelectedPickupZones({
      pickupZones,
      zoneID
    }))
  ).then(() => {
    callback()
  })
}

export const getPickupZones = (countryCode, areaID, callback = (() => { })) => (dispatch) => {
  areaApi.getServiceAreaLonghaulPickupZones(countryCode, (response) => {
    Promise.resolve(
      dispatch(pickupZonesActionsCreator.updatePickupZones(response.data.data))
    ).then(() => {
      dispatch(updateSelectedPickupZones(response.data.data, areaID))
    }).then(() => {
      callback()
    })
  })
}

export const updateSelectedDropOffZone = (zone, callback = (() => { })) => (dispatch) => {
  Promise.resolve(
    dispatch(selectedDropOffZoneActionsCreator.updateSelectedDropOffZone(zone))
  ).then(() => {
    callback()
  })
}

export const validateLocations = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations

  const filteredLocations = locations.filter(({ lat, lng }) => (lat && lng))
  if (!filteredLocations || filteredLocations.length < 2) {
    return Promise.resolve(
      dispatch(acceptableTimeTypesActionsCreator.setAcceptableTimeTypes([LONG_HAUL, NOW, IMMEDIATE, SCHEDULE, FULL_DAY]))
    )
  }

  const {
    // timeType,
    selectedServiceTypeID,
    selectedVehicleTypeID,
    currentCustomer,
    timeType,
    extraInfos
  } = state

  return new Promise((resolve) => {
    LocationAPI.validLocations(
      locations,
      { timeType, serviceTypeID: selectedServiceTypeID, vehicleTypeID: selectedVehicleTypeID },
      {
        countryCode: currentCustomer.country_code || extraInfos?.country_code,
        companyId: currentCustomer.current_company_id,
        authenticationToken: currentCustomer.authentication_token,
        isLogin: currentCustomer.id,
      },
      null,
      (res) => {
        const acceptableTimeTypes = locationUtils.determineAcceptableTimeTypes(res)
        Promise
          .resolve(dispatch(acceptableTimeTypesActionsCreator.setAcceptableTimeTypes(acceptableTimeTypes)))
          .then(() => resolve(res))
      },
    )
  })
}

export const clearExtraRequirementLocations = () => (dispatch, getState) => {
  const state = getState()
  let locations = state.locations
  const { extraRequirements = [] } = state.extraServices || {}
  locations = locations.map((location) => {
    const extraRequirementLocations = location.extra_requirement_locations
    if (extraRequirementLocations && extraRequirementLocations.length) {
      return {
        ...location,
        extra_requirement_locations: extraRequirementLocations.map((item) => {
          const extraRequirementById = extraRequirements.find(
            extraRequirement => (extraRequirement.id === item.extra_requirement_id)
          )
          return ({
            ...item, selected_amount: extraRequirementById && (extraRequirementById.selected_amount || 0)
          })
        }),
      }
    }

    return location
  })

  Promise.resolve(
    dispatch(locationsActionsCreator.setLocation(locations))
  )
}

export const resetLocations = () => (dispatch) => {
  dispatch(locationsActionsCreator.removeBlurMarker())
  dispatch(setUpForNew())
}
