import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}
const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    updateOthers: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.attrs
    }),
  },
  extraReducers: () => {},
})
export const othersActionsCreator = othersSlice.actions

export default othersSlice
