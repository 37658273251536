// PREFERENCE DRIVERS TAB CONST
export const UPDATE_PREFERENCE_DRIVERS = 'UPDATE_PREFERENCE_DRIVERS'
export const UPDATE_AVAILABLE_DRIVERS = 'UPDATE_AVAILABLE_DRIVERS'
export const FAVORITE_DRIVER = 'FAVORITE_DRIVER'
export const UNFAVORITE_DRIVER = 'UNFAVORITE_DRIVER'
export const UPDATE_BANNED_DRIVERS = 'UPDATE_BANNED_DRIVERS'
export const UPDATE_SELECTED_DRIVER_IDS = 'UPDATE_SELECTED_DRIVER_IDS'
export const DRIVERS_PER_PAGE = 10
export const DRIVER_STATUS = {
  auto_deactive: 'auto_deactive',
  cs_suspends_driver: 'cs_suspends_driver',
  inactive: 'inactive',
  red_card: 'red_card',
  suspend: 'suspend',
  suspend_competitor_app: 'suspend_competitor_app',
  suspend_receive_booking: 'suspend_receive_booking',
  suspend_security_bond: 'suspend_security_bond',
  suspend_police_clearance: 'suspend_police_clearance',
  suspend_sticker: 'suspend_sticker',
}
