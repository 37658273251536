import React from 'react'
import PropTypes from 'prop-types'
import { POPUP_ID_OUT_OF_SERVICE } from 'constants/newBookingConstants'
import { CHANGE_TIME_TYPE_ICON } from 'constants/imageConstants'
// UTILS
import I18n from 'i18n/i18n'
class ChangeTimeTypePopup extends React.Component {
  constructor(props) {
    super(props)

    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction(event) {
    const { setIsShowChangeTimeType } = this.props
    if (event.keyCode === 27) {
      setIsShowChangeTimeType(false)
    }
  }

  handleChangetoSchedule() {
    const { setIsShowChangeTimeType, setIsShowChangeTimeTypeClick } = this.props
    setIsShowChangeTimeTypeClick(true)
    setIsShowChangeTimeType(false)
  }

  renderButton() {
    const { handleCancelChangeTimeTypePopup } = this.props
    return (
      <div>
        <button onClick={() => handleCancelChangeTimeTypePopup()} type="button" className="dlvr-change-address-button">
          {I18n.t('webapp.new_booking.step_1.change_address_button')}
        </button>
        <button onClick={() => this.handleChangetoSchedule()} type="button" className="dlvr-change-schedule-button">
          {I18n.t('webapp.new_booking.step_1.change_to_schedule_button')}
        </button>
      </div>
    )
  }

  render() {
    const { setIsShowChangeTimeType } = this.props
    return (
      <div id={POPUP_ID_OUT_OF_SERVICE} className="visible vertical-scroll Modal modal-outOfService">
        <div className="dlvr-popup-change-time-type z-index-2">
          <div className="dlvr-popup-content flex-column">
            <div className="dlvr-box-time-type">
              <div className="dlvr-box-icon">
                <img src={CHANGE_TIME_TYPE_ICON} alt="icon" className="icon-modified-style" />
              </div>
              <div className="dlvr-popup-title">
                {I18n.t('webapp.new_booking.step_1.change_your_time_type_title')}
              </div>
              <div className="dlvr-popup-info">
                {I18n.t('webapp.new_booking.step_1.change_your_time_type_content')}
              </div>
            </div>
            {this.renderButton()}
          </div>
        </div>
        <div className="close-modal-common" onClick={() => setIsShowChangeTimeType(false)} />
      </div>
    )
  }
}

ChangeTimeTypePopup.propTypes = {
  setIsShowChangeTimeTypeClick: PropTypes.func.isRequired,
  setIsShowChangeTimeType: PropTypes.func.isRequired,
  handleCancelChangeTimeTypePopup: PropTypes.func.isRequired,
}

ChangeTimeTypePopup.defaultProps = {
}

export default ChangeTimeTypePopup
