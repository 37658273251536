import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// UTILS
import { Utils } from 'utils/Utils'
import { UtilsHTML } from 'utils/html'
import { isCustomerEditBooking } from 'utils/new_booking/common'
import I18n from 'i18n/i18n'
// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
// COMPONENTS
// CONSTANTS
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  badges: state.extraServices.vehicleTypeBadges,
  extraInfos: state.extraInfos,
})

function mapDispatchToProps(dispatch) {
  return {
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch),
  }
}

class Badge extends React.Component {
  constructor(props) {
    super(props)
  }

  onChangeCheckBox(id, event) {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    const selected = event.target.checked
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.onChangeCheckBoxAfterCheckBookingDetails.bind(this, id, selected),
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeCheckBoxAfterCheckBookingDetails(id, selected)
    }
  }

  onChangeCheckBoxAfterCheckBookingDetails(id, selected) {
    const { extraServiceActions } = this.props
    const selectedAmount = selected ? 1 : 0
    extraServiceActions.updateBadge(id, { selected, selected_amount: selectedAmount })
  }

  onClickCebDisableCheckbox() {
    const { editModeTextActions } = this.props
    editModeTextActions.updateEditModeText(I18n.t('webapp.new_booking.step_2.badges'))
  }

  render() {
    if (_.isEmpty(this.props.badges)) {
      return <span></span>
    }
    return (
      <div>
        {UtilsHTML.renderTitle(true, I18n.t('webapp.new_booking.step_2.badges'))}
        <div className="Badges block">
          {this.props.badges.map((badge) =>
            this.renderCheckbox(badge)
          )}
        </div>
      </div>
    )
  }

  renderCheckbox(badge) {
    const enabledCustomerEditBooking = isCustomerEditBooking()
    const disabledCss = enabledCustomerEditBooking ? 'Disable' : ''
    const fieldName = `booking[booking_badges_with_deleted_attributes][${badge.id}]`
    return (
      <div className="block-item" key={badge.id}>
        <label htmlFor={`${enabledCustomerEditBooking ? '' : `badge_${badge.id}`}`} className="default-font">
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title">
              {badge.name}
            </div>
            <div className="TitleSubtitle-subtitle">—</div>
          </div>
        </label>
        <div className="block-item-action">
          <div className={`Overlay-Checkbox With-Icon Green-Checkbox ${disabledCss}`}>
            {
              enabledCustomerEditBooking
              && (
                <div
                  className="Ceb-disable-field Disable-checkbox-overlay"
                  onClick={() => this.onClickCebDisableCheckbox()}
                />
              )
            }
            <input
              type="checkbox"
              name={`${fieldName}[selected_amount]`}
              id={`badge_${badge.id}`}
              value="1"
              checked={badge.selected}
              onChange={this.onChangeCheckBox.bind(this, badge.id)} />
            <label>
              <i className="b material-icons Icon">check</i>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

Badge.defaultProps = {
  assignedDriver: undefined,
}

Badge.propTypes = {
  assignedDriver: PropTypes.shape({}),
  extraServiceActions: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  editModeTextActions: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Badge)
