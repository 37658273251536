import { v4 as uuidv4 } from 'uuid';

export const cookie = {
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  },
  getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
};

export const getDeviceId = () => {
  let sessionId = cookie.getCookie('device_id');
  if (!sessionId) {
    sessionId = uuidv4();
    cookie.setCookie('device_id', sessionId);
  }
  return sessionId;
};