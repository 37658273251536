import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'

const initialState = {
  data: [],
  total: 0,
  page:1,
  tab: DRIVER_PREFERENCES.favorite
}

const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    updateDrivers: (state: any, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    updateTotalDrivers: (state: any, action: PayloadAction<any>) => {
      state.total = action.payload
    },
    updateCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.page = action.payload
    },
    changeTab: (state: any, action: PayloadAction<any>) => {
      state.tab = action.payload.tab
    },
  },
  extraReducers: () => {},
})

export const driversActionsCreator = driversSlice.actions

export default driversSlice
