import _ from 'lodash-es'
import AuthenticationAPI from 'api/external/authentication'
import { createIframeToSyncPTL } from 'assets/javascripts/webapp-v2/common'
import { setAccessToken } from 'utils/crossStorage';

export const convertToken = (authenticationToken, companyID, callback = () => {}) => async (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  const response = await AuthenticationAPI.convertToken(authenticationToken, companyID)
  const accessToken = response?.data?.access_token
  if (accessToken) {
    setAccessToken(accessToken)
    const countryCode = currentCustomer ? currentCustomer.country_code : 'id'
    createIframeToSyncPTL(`token=${accessToken}&countrycode=${countryCode}`, () => {
      callback()
    })
  } else {
    callback()
  }
}

export default convertToken
