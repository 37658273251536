import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
// ACTIONS
import * as recentLocationsActionCreators from 'store/actions/common/recentLocationsActionCreators'
// Utils
import { Utils } from 'utils/Utils'
import { RecentLocationsUtils } from 'utils/RecentLocationsUtils'

const mapStateToProps = state => ({
  recentLocations: state.recentLocations,
})

function mapDispatchToProps(dispatch) {
  return {
    recentLocationsActions: bindActionCreators(recentLocationsActionCreators, dispatch),
  }
}

class PlaceLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleBlur = this.handleBlur.bind(this)
    this.handleShowSaveLocation = this.handleShowSaveLocation.bind(this)
  }

  loadRecentLocations() {
    const {
      recentLocationsActions,
      recentLocations,
      currentCustomer,
    } = this.props
    const accessToken = window.localStorage.getItem('access_token')
    if (accessToken) {
      if (_.isEmpty(recentLocations)) recentLocationsActions.loadRecentLocations(accessToken)
    } else if (!_.isEmpty(currentCustomer.authentication_token)) {
      this.loadRecentLocations()
    }
  }

  handleOpen(event) {
    const {
      closestID,
      closestLocations,
      options,
      onClick,
      multipleLine,
    } = this.props
    onClick(event)
    Promise.resolve(
      this.loadRecentLocations()
    ).then(() => {
      Utils.addClass($(`#${closestID}`), 'Active')
      RecentLocationsUtils.openRecentLocations(closestID, options.id, closestLocations, multipleLine)
    })
  }

  handleKeyDown(e) {
    const { closestID, options } = this.props
    RecentLocationsUtils.handleListEventKeyDown(closestID, options.id, e)
  }

  handleBlur() {
    const { closestID } = this.props
    $(document).mouseup((e) => {
      const container = $(`#${closestID}`)
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(document).unbind('mouseup')
        Utils.removeClass(container, 'Active')
        RecentLocationsUtils.handleListEventKeyClick(closestID)
      }
    })
  }

  handleClickGetPlace(item) {
    const { closestID, onClickGetPlace } = this.props
    Promise.resolve(
      onClickGetPlace(item)
    ).then(() => {
      Utils.removeClass($(`#${closestID}`), 'Active')
    })
  }

  handleShowSaveLocation(location) {
    const nameLocation = (location.contact_id) ? `${location.location_name} ${location.name}` : `${location.name}`
    return (
      <div
        key={location.id}
        className="Place-Item"
        onClick={() => this.handleClickGetPlace(location)}
      >
        {(location.contact_id) && <span className="Place-Icon Place-Icon-Recent" />}
        <span className="Place-Item-Query Black-Gray-text">
          {nameLocation}
        </span>
      </div>
    )
  }

  dropdownRecentLocations() {
    const { recentLocations } = this.props
    return (
      <div className="Place-Container">
        {_.size(recentLocations) >= 1
          && recentLocations.map(item => (
            this.handleShowSaveLocation(item)
          ))
        }
      </div>
    )
  }

  render() {
    const {
      options,
      refNode,
      specialClass,
      isRoundTripLocation,
      multipleLine,
    } = this.props
    return (
      <div ref={refNode} className={`Place-Handler ${specialClass}`}>
        {multipleLine
          ? (
            <textarea
              {...options}
              onClick={event => this.handleOpen(event)}
              onKeyDown={e => this.handleKeyDown(e)}
              onBlur={this.handleBlur}
              disabled={isRoundTripLocation}
            />
          )
          : (
            <input
              {...options}
              onClick={event => this.handleOpen(event)}
              onKeyDown={e => this.handleKeyDown(e)}
              onBlur={this.handleBlur}
              disabled={isRoundTripLocation}
            />
          )
        }
        {!isRoundTripLocation && this.dropdownRecentLocations()}
      </div>
    )
  }
}

PlaceLocation.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  recentLocationsActions: PropTypes.shape({}).isRequired,
  authenticationActions: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  onClickGetPlace: PropTypes.func.isRequired,
  closestID: PropTypes.string,
  closestLocations: PropTypes.string,
  isRoundTripLocation: PropTypes.bool,
  multipleLine: PropTypes.bool,
  options: PropTypes.shape({}),
  recentLocations: PropTypes.instanceOf(Array),
  refNode: PropTypes.func,
  specialClass: PropTypes.string,
}

PlaceLocation.defaultProps = {
  closestID: '',
  closestLocations: undefined,
  isRoundTripLocation: false,
  multipleLine: false,
  options: {
    type: 'text',
  },
  recentLocations: [],
  refNode: undefined,
  specialClass: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceLocation)
