import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const customerDriverSlice = createSlice({
  name: 'customerDriver',
  initialState,
  reducers: {
    getCustomerDriver: (state, action: PayloadAction<any>) => {
      state.loading = true
    },
    getCustomerDriverSuccess: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload, loading: false }),
    customerDriverReferenceSuccess: (state, action: PayloadAction<any>) => {
      const { itemType, _destroy } = action.payload
      state.reference_type = !_destroy ? itemType : null
    },
    resetCustomerDriver: (state, action: PayloadAction<any>) => ({})
  },
  extraReducers: () => {},
})

export const customerDriverActionsCreator = customerDriverSlice.actions

export default customerDriverSlice