import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const popupContentManagementSlice = createSlice({
  name: 'popupContentManagement',
  initialState,
  reducers: {
    addPopupContent: (state, action: PayloadAction<any>) => {
      state.push(action.payload.data)
    },
  },
  extraReducers: () => {},
})

export const popupContentManagementActionsCreator = popupContentManagementSlice.actions

export default popupContentManagementSlice
