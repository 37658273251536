import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import usePortal from 'hooks/usePortal'
import useOutsideAlerter from 'hooks/commonHooks'
import "./popover.scss"

const Popover = ({
  positionRight, positionLeft, screenHeight, topContainerScroll,
  children, clickOutside, id, showPopover,
  rectChildren, anchorElRect,
  navbarHeight = 76, footerHeight = 0,
}) => {
  const portalEl = usePortal(id || 'popover')
  const wrapperRef = useRef(null)
  const { top, height, bottom } = anchorElRect
  const [topPopover, setTopPopover] = useState(top + (height / 2))
  const [positionAnchor, setPositionAnchor] = useState('50%')

  useOutsideAlerter(wrapperRef, clickOutside)

  const handleSetPosition = () => {
    const popoverHeight = rectChildren.height
    const positionCenter = top + (height / 2)

    const realTopPopover = positionCenter - popoverHeight / 2

    const bottomPopover = realTopPopover + popoverHeight

    let newTopPopover = realTopPopover
    if (newTopPopover < navbarHeight) {
      newTopPopover = navbarHeight
    } else if (bottomPopover > screenHeight) {
      newTopPopover = realTopPopover - (bottomPopover - screenHeight)
    }
    setTopPopover(newTopPopover)

    if (top < topContainerScroll) {
      const centerDisplay = top + (height - (topContainerScroll - top)) / 2 + (topContainerScroll - top)
      setPositionAnchor(centerDisplay - newTopPopover)
    } else if (bottom > (screenHeight - footerHeight)) {
      const bottomAnchor = (height - ((bottom - footerHeight) - screenHeight)) / 2
      setPositionAnchor((popoverHeight - bottomAnchor))
    } else {
      setPositionAnchor((top + height / 2) - newTopPopover)
    }
  }

  useEffect(() => {
    if (rectChildren.height) {
      handleSetPosition()
    }
  }, [anchorElRect, rectChildren.height])

  if (!children || !portalEl) return null

  return createPortal(
    <div className="popover-overlay">
      <div
        className="popover-wrapper"
        ref={wrapperRef}
        style={{
          top: topPopover || 0,
          visibility: showPopover ? 'visible' : 'hidden',
          ...(positionRight && { left: positionRight, right: 'unset' }),
          ...(positionLeft && { right: positionLeft, left: 'unset' })
        }}
      >
        <div className={`popover-anchor ${positionLeft ? 'right' : ''}`} style={{ top: positionAnchor || '50%' }} />
        {children}
      </div>
    </div>,
    portalEl
  )
}

Popover.propTypes = {
  children: PropTypes.element.isRequired,
  anchorElRect: PropTypes.shape({
    top: PropTypes.number,
    height: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired,
  showPopover: PropTypes.bool.isRequired,
  rectChildren: PropTypes.shape({
    height: PropTypes.number,
  }).isRequired,

  positionRight: PropTypes.number,
  positionLeft: PropTypes.number,
  screenHeight: PropTypes.number,
  topContainerScroll: PropTypes.number,

  clickOutside: PropTypes.func,
  id: PropTypes.string,
  navbarHeight: PropTypes.number,
  footerHeight: PropTypes.number,
}

Popover.defaultProps = {
  screenHeight: window.innerHeight,
  positionRight: 0,
  positionLeft: 0,
  topContainerScroll: 100,
  footerHeight: 56,
  navbarHeight: 76,
  id: null,
  clickOutside: () => {}
}

export default Popover
