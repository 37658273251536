import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import _ from 'lodash'
import I18n from 'i18n/i18n'

class CountdownRemaining extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      remainChars: props.value ? props.maxLength - props.value.length : props.maxLength,
    }

    this.input = undefined
    this.handleOnPressEnter = this.handleOnPressEnter.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    this.handleDebounce = _.debounce(() => {
      // Add new param for avoiding track moengage event when typing
      this.handleBlur(null, true)
    }, 500)
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props
    const nextValue = nextProps?.value || ''
    if (value !== nextValue && this.input) {
      this.input.value = nextValue
      this.remainingCharacters(nextValue)
    }
  }

  onChange(e) {
    const value = e.target.value
    this.remainingCharacters(value)
    this.handleDebounce()
  }

  handleOnPressEnter(event) {
    const {
      handleChange,
      onHandleEnter,
    } = this.props
    if (event.keyCode === 13) {
      handleChange($(this.input).val())
      onHandleEnter(event)
    }
  }

  handleBlur(event, isDisableTrackEvent = false) {
    const {
      handleChange,
      handleBlur,
    } = this.props
    const value = $(this.input).val()
    handleChange(value)
    if (handleBlur) {
      handleBlur(value, isDisableTrackEvent)
    }
  }

  remainingCharacters(value) {
    const { maxLength } = this.props
    const charCount = value.length
    const remainChars = maxLength - charCount

    this.setState({ remainChars })
  }

  handleRef(ref) {
    this.input = ref
  }

  render() {
    const { remainChars } = this.state
    const {
      className, classNameBox, classTextarea, placeholder, value, id, name, remainPercentToShow, maxLength,
    } = this.props
    const isShowText = remainChars <= Math.round(maxLength * (remainPercentToShow / 100))

    return (
      <div className={`${className} Countdown-Remaining Default-TextArea-Placeholder`}>
        <div className={`${classNameBox} Countdown-Remaining__Input flex ${isShowText ? 'Hide-Border-Bottom Radius-Top-Default' : 'Radius-default'}`}>
          <textarea
            id={id}
            name={name}
            maxLength={maxLength}
            className={`Resize-None flex flex-index Radius-default pt10 pr10 pb10 pl10 h52px ${classTextarea}`}
            placeholder={placeholder || I18n.t('webapp.label.optional')}
            ref={ref => this.handleRef(ref)}
            defaultValue={value}
            onKeyDown={this.handleOnPressEnter}
            onBlur={this.handleBlur}
            onChange={this.onChange}
          />
        </div>
        {isShowText ? (
          <p className="default-color small-font center Line-bg Radius-Bottom-Default default-color-icon Countdown-Remaining__Info reset m pt5 pl10 pr10 pb5">
            {`${remainChars} ${I18n.t('webapp.label.characters_remaining')}`}
          </p>
        ) : null}
      </div>
    )
  }
}

CountdownRemaining.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onHandleEnter: PropTypes.func,
  className: PropTypes.string,
  classNameBox: PropTypes.string,
  classTextarea: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  remainPercentToShow: PropTypes.number,
  maxLength: PropTypes.number,
  handleBlur: PropTypes.func,
}

CountdownRemaining.defaultProps = {
  onHandleEnter: () => { },
  className: '',
  classNameBox: '',
  classTextarea: '',
  placeholder: '',
  value: '',
  id: '',
  name: '',
  remainPercentToShow: 10,
  maxLength: 255,
  handleBlur: undefined,
}

export default CountdownRemaining
