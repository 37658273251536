import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// CONSTANTS
import { POPUP_CASHBACK_EXPLAIN } from 'constants/common/popupConstants'
import {
  QUESTION_ICON_GRAY,
  QUESTION_ICON_GREEN,
  LUCKY_STAR
} from 'constants/imageConstants'

import commonUtils from '../../utils/common'

// ACTIONS
import I18n from 'i18n/i18n'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'

const mapDispatchToProps = dispatch => ({
  currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
})
class CashbackInfo extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      showTooltip: false
    }
    this.toggleTooltip = this.toggleTooltip.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.showPopup = this.showPopup.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  toggleTooltip() {
    const { showTooltip } = this.state
    this.setState({ showTooltip: !showTooltip })
  }

  handleClickOutside(event) {
    const { showTooltip } = this.state
    if (this.tooltipWrapper && !this.tooltipWrapper.contains(event.target) && showTooltip) {
      this.setState({ showTooltip: false })
    }
  }

  showPopup(event) {
    const { enableShowPopup, currentPopupIDActions } = this.props

    if (enableShowPopup && this.tooltipWrapper && !this.tooltipWrapper.contains(event.target)) {
      currentPopupIDActions.updateCurrentPopupID(POPUP_CASHBACK_EXPLAIN)
    }
  }

  render() {
    const { cashbackAmount } = this.props
    const { showTooltip } = this.state

    const isEveryTitle = commonUtils.isMultiple() || commonUtils.isBatchEZ() || commonUtils.isSmartLoad()

    return (
      <div
        className="info-wrapper"
        onClick={this.showPopup}
      >
        <img src={LUCKY_STAR} alt="cashback-icon" />
        <p>
          {I18n.t(`webapp.label.${isEveryTitle ? 'cashback_info_batch' : 'cashback_info'}`, { cashbackAmount })}
        </p>
        <span
          className={`cashback-tooltip ${showTooltip ? 'show-tooltip' : ''}`}
          data-tooltip={I18n.t('webapp.label.cashback_explain')}
          data-tooltip-position="top"
          onClick={this.toggleTooltip}
          ref={(ref) => { this.tooltipWrapper = ref }}
        >
          <img src={showTooltip ? QUESTION_ICON_GREEN : QUESTION_ICON_GRAY} alt="cashback-info" />
        </span>
      </div>
    )
  }
}

CashbackInfo.propTypes = {
  cashbackAmount: PropTypes.string.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  enableShowPopup: PropTypes.bool
}

CashbackInfo.defaultProps = {
  enableShowPopup: false
}

export default connect(null, mapDispatchToProps)(CashbackInfo)
