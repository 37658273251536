import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []
const pickupZonesSlice = createSlice({
  name: 'pickupZones',
  initialState,
  reducers: {
    updatePickupZones: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const pickupZonesActionsCreator = pickupZonesSlice.actions

export default pickupZonesSlice
