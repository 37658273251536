import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  address: '',
  note: '',
  name: '',
  phone: '',
  map: undefined,
  marker: undefined,
  address_components: undefined,
  phone_mask: undefined,
  contact_id: undefined,
  address_detail: '',
}

const newContactSlice = createSlice({
  name: 'newContact',
  initialState,
  reducers: {
    updateNewContact: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
  extraReducers: () => {},
})

export const newContactActionsCreator = newContactSlice.actions

export default newContactSlice
