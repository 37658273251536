import $ from 'jquery'
import _ from 'lodash'
// import _ from 'lodash'
import * as buildBookings from './buildBookings'
// UTILS
import * as utilBookingLocations from './locations/bookingLocations'
import { Utils } from 'utils/Utils'

// CONSTANTS
import { FULL_DAY, SCHEDULE, LONG_HAUL } from 'constants/bookingConstants'
import { CREATED_FROM, ADD_FROM_DRAFT_BOOKING } from 'constants/ezSpreadSheetConstants'
import { EXTRA_NORMAL, EXTRA_BY_OPTION } from 'constants/extraServiceConstants'
import * as verifyVehicleLogic from './stepValidates/verifyLogicWhenChangeVehicle'
import { calcPriceRegularBooking } from './common'

const fulldayNegativeExtra = (booking, extraVehicleID) => {
  if (!_.isEmpty(booking.time_type_option) && booking.time_type_option.type_key === FULL_DAY) {
    const extraRequirements = booking.vehicle_type.extra_requirements_negative_position
    for (let i = 0; i < _.size(extraRequirements); i += 1) {
      if (!_.isEmpty(extraRequirements[i]) && extraRequirements[i].id === extraVehicleID) {
        return extraRequirements[i]
      }
    }
  }
  return {}
}

const buildUnitPriceBookingExtra = (timeType, dropOffCount, extra) => {
  let totalUnitPrice = 0
  if (timeType === FULL_DAY) {
    const pricing = extra.extra_requirement_vehicle_type_full_day_pricing
    if (!_.isEmpty(pricing)) {
      totalUnitPrice += pricing.fees
    }
  }
  if (timeType === SCHEDULE) {
    const pricings = extra.extra_requirement_vehicle_type_normal_pricings
    if (!_.isEmpty(pricings)) {
      _.map(pricings, (pricing) => {
        if (!_.isEmpty(pricing) && dropOffCount >= pricing.dropoff_number) {
          totalUnitPrice += pricing.fees
        }
      })
    }
  }
  return totalUnitPrice
}

export const buildUnitPriceBookingExtras = (booking) => {
  const tempBooking = booking
  const dropOffCount = _.size(tempBooking.locations) - 1
  if (!_.isEmpty(tempBooking.time_type_option)) {
    let extras = []
    const timeType = tempBooking.time_type_option.type_key
    _.map(tempBooking.extra_requirements, (extra) => {
      if (!_.isEmpty(extra)) {
        const tempExtra = extra

        tempExtra.unit_price = buildUnitPriceBookingExtra(timeType, dropOffCount, extra)
        extras = _.concat(extras, tempExtra)
      }
    })
    tempBooking.extra_requirements = extras
  }
  return tempBooking
}

export const convertExtraRequirementsFromText = (booking, textExtraRequirement, extraRequireVehicles) => {
  if (_.isEmpty(textExtraRequirement)) {
    return []
  }
  const importExtraRequirements = []
  const vehicleTypeID = booking.vehicle_type.id
  const extraIdAndSelectAmounts = _.split(textExtraRequirement, ',')
  _.forEach(extraIdAndSelectAmounts, (extraIdAndSelectAmount) => {
    if (!_.isEmpty(extraIdAndSelectAmount)) {
      const idAmount = _.split(extraIdAndSelectAmount, '-')
      const extraVehicleID = _.toInteger(idAmount[0])
      const inputAmount = _.toInteger(idAmount[1]) > 0 ? _.toInteger(idAmount[1]) : 1
      const extra = _.find(extraRequireVehicles, { vehicle_type_id: vehicleTypeID, id: extraVehicleID })
      let extraRequirement
      if (!_.isEmpty(extra)) {
        const selectedAmount = _.toInteger(extra.amount) > inputAmount ? inputAmount : _.toInteger(extra.amount)
        extraRequirement = { ...extra }
        extraRequirement.selected = true
        extraRequirement.selected_amount = selectedAmount
        importExtraRequirements.push(extraRequirement)
      } else {
        const negativeExtra = fulldayNegativeExtra(booking, extraVehicleID)
        if (!_.isEmpty(negativeExtra)) {
          const negativeExtraAmount = _.toInteger(negativeExtra.amount)
          const selectedAmount = negativeExtraAmount >= inputAmount ? inputAmount : negativeExtraAmount
          extraRequirement = { ...negativeExtra }
          extraRequirement.selected = true
          extraRequirement.selected_amount = selectedAmount
          importExtraRequirements.push(extraRequirement)
        }
      }
    }
  })
  return importExtraRequirements
}

export const checkExtraRequirementsFromSetting = (booking, importExtraRequirements, extraRequireVehicles) => {
  const settingExtraRequirements = _.filter(extraRequireVehicles,
    { vehicle_type_id: booking.vehicle_type.id, check_by_default: true })
  if (!_.isEmpty(settingExtraRequirements)) {
    _.forEach(settingExtraRequirements, (settingExtraRequirement) => {
      const extraImportIndex = _.findIndex(importExtraRequirements,
        (importExtraRequirement) => {
          return importExtraRequirement.id === settingExtraRequirement.id
        })
      if (extraImportIndex === -1) {
        const extraRequirement = _.assign(settingExtraRequirement,
          { selected: true, selected_amount: 1, isAutoSetting: true })
        importExtraRequirements.push(extraRequirement)
      }
    })
  }
  return importExtraRequirements
}

export const verifyExtraService = (booking) => {
  const tempBooking = booking
  if (!_.isEmpty(tempBooking.vehicle_type)) {
    tempBooking.verify.vehicle = true
    tempBooking.verify.extraRequirement = true
  }
  return tempBooking
}

const buildExtraByOptionForBooking = (booking, extra) => {
  // const tempBooking = booking
  const byOptionExtra = extra
  const timeType = booking.time_type_option.type_key
  const allowNoneOptions = byOptionExtra.allow_none_option_pricing_list
  const pricings = timeType === SCHEDULE ? byOptionExtra.regular_pricings : byOptionExtra.full_day_pricings
  let allowNone = false
  if (!_.isEmpty(allowNoneOptions)) {
    if (timeType === SCHEDULE && allowNoneOptions.regular_booking) {
      allowNone = true
    }
    if (timeType === FULL_DAY && allowNoneOptions.full_day) {
      allowNone = true
    }
    if (timeType === LONG_HAUL && allowNoneOptions.long_haul) {
      allowNone = true
    }
  }
  if (allowNone) {
    byOptionExtra.selectedPricing = undefined
    byOptionExtra.selected_amount = 0
    byOptionExtra.selected = false
  } else {
    byOptionExtra.selectedPricing = _.head(pricings) // default is free option
    byOptionExtra.selected_amount = 1
    byOptionExtra.selected = true
  }
  return byOptionExtra
}

export const setByOptionExtraToBooking = (booking, byOptionExtras) => {
  const tempBooking = booking
  let extraRequirements = _.isEmpty(booking.extra_requirements) ? [] : booking.extra_requirements
  _.remove(extraRequirements, { pricing_method: EXTRA_BY_OPTION })
  if (!_.isEmpty(byOptionExtras)) {
    _.forEach(byOptionExtras, (extra) => {
      let tempExtra = { ...extra }
      tempExtra = buildExtraByOptionForBooking(tempBooking, tempExtra)
      extraRequirements = _.concat(extraRequirements, tempExtra)
    })
  }
  tempBooking.extra_requirements = extraRequirements
  return tempBooking
}

export const getCustomReimbursementFromSetting = (booking, extraServices, changeTimeType) => {
  let customReimbursements = []
  if (!_.isEmpty(booking.vehicle_type)
    && !_.isEmpty(booking.time_type_option)
    && !_.isEmpty(extraServices.batchCustomReimbursements)
  ) {
    const allCustomReimbursements = extraServices.batchCustomReimbursements
    const vehicleId = booking.vehicle_type.id
    const timeTypeName = booking.time_type_option.type_key

    if (!_.isEmpty(allCustomReimbursements[vehicleId])
      && !_.isEmpty(allCustomReimbursements[vehicleId][timeTypeName])
    ) {
      customReimbursements = _.assign([], allCustomReimbursements[vehicleId][timeTypeName])
    }
  }

  return Utils.handleAndKeepDataCusomReimbursement(
    booking.customReimbursements, customReimbursements, changeTimeType
  )
}

export const updateByOptionSelectedPricing = (booking, tempByOptionExtra) => {
  const tempBooking = booking
  _.forEach(tempBooking.extra_requirements, (extra) => {
    if (extra.id === tempByOptionExtra.id) {
      _.assign(extra, { selectedPricing: tempByOptionExtra.selectedPricing })
    }
  })
  return tempBooking
}

export const buildByOptionExtras = (booking, extraServices) => {
  let tempBooking = booking
  if (!_.isEmpty(tempBooking.vehicle_type) && !_.isEmpty(tempBooking.time_type_option)) {
    let byOptionExtras
    if (tempBooking.time_type_option.type_key === FULL_DAY) {
      byOptionExtras = _.filter(extraServices.fullDayByOptionExtras, { vehicle_type_id: tempBooking.vehicle_type.id })
    } else if (tempBooking.time_type_option.type_key === LONG_HAUL) {
      byOptionExtras = _.filter(extraServices.longHaulByOptionExtras, { vehicle_type_id: tempBooking.vehicle_type.id })
    } else {
      byOptionExtras = _.filter(extraServices.regularByOptionExtras, { vehicle_type_id: tempBooking.vehicle_type.id })
    }
    tempBooking = setByOptionExtraToBooking(tempBooking, byOptionExtras)
  }
  return tempBooking
}

export const buildCustomReimbursements = (booking, extraServices, changeTimeType) => {
  const tempBooking = { ...booking }
  tempBooking.customReimbursements = getCustomReimbursementFromSetting(booking, extraServices, changeTimeType)
  return tempBooking
}

export const checkBookingByOptionExtra = (booking, extraServices) => {
  const tempBooking = booking
  let extraRequirements = booking.extra_requirements
  if (!_.isEmpty(tempBooking.vehicle_type) && !_.isEmpty(tempBooking.time_type_option)) {
    const isFullDay = booking.time_type_option.type_key === FULL_DAY
    const areaByOptionExtras = isFullDay ? extraServices.fullDayByOptionExtras : extraServices.regularByOptionExtras
    const extraByOptions = _.filter(booking.extra_requirements, { pricing_method: EXTRA_BY_OPTION })
    if (!_.isEmpty(extraByOptions)) {
      _.map(extraByOptions, (extraByOption) => {
        const areaByOptionExtra = _.find(areaByOptionExtras, { id: extraByOption.id })
        if (_.isEmpty(areaByOptionExtra)) {
          extraRequirements = _.reject(extraRequirements, { id: extraByOption.id })
        } else {
          const pricings = isFullDay ? areaByOptionExtra.full_day_pricings : areaByOptionExtra.regular_pricings
          const byOptionExtraPricing = _.find(pricings, { id: extraByOption.extra_requirement_pricing_id })
          const allowNoneExtra = areaByOptionExtra.allow_none_option_pricing_list
          const isAllowNone = isFullDay ? allowNoneExtra.full_day : allowNoneExtra.regular_booking
          if (booking.added_from === CREATED_FROM.draft_booking || booking.added_from === CREATED_FROM.draft_batchez) {
            // keep old extra
            const oldPricing = extraByOption.selectedPricing
            const pricing = _.isEmpty(oldPricing) ? byOptionExtraPricing : oldPricing
            _.assign(extraByOption, { selectedPricing: pricing })
          } else if (isAllowNone) {
            // reset new one as none option
            _.assign(extraByOption, {
              selectedPricing: undefined,
              selected: false,
              selected_amount: 0,
              extra_requirement_pricing_id: undefined
            })
          } else {
            // reset new one with the first pricing be default
            const firstPricing = _.head(pricings)
            const pricingId = _.isEmpty(firstPricing) ? undefined : firstPricing.id
            _.assign(extraByOption, {
              selectedPricing: firstPricing,
              selected: true,
              selected_amount: 1,
              extra_requirement_pricing_id: pricingId
            })
          }
          _.assign(extraByOption, {
            extra_requirement_id: areaByOptionExtra.extra_requirement_id,
            amount: areaByOptionExtra.amount,
            full_day_pricings: areaByOptionExtra.full_day_pricings,
            regular_pricings: areaByOptionExtra.regular_pricings,
            popups: areaByOptionExtra.popups,
            position: areaByOptionExtra.position,
            allow_none_option_pricing_list: allowNoneExtra,
          })
        }
      })
    }
    // reset new one as the frist options
    const newExtraByOptions = _.filter(areaByOptionExtras, { vehicle_type_id: tempBooking.vehicle_type.id })
    if (!_.isEmpty(newExtraByOptions)) {
      _.map(newExtraByOptions, (newExtraByOption) => {
        const isExisting = _.findIndex(extraRequirements, { id: newExtraByOption.id })
        if (isExisting === -1) {
          const pricings = isFullDay ? newExtraByOption.full_day_pricings : newExtraByOption.regular_pricings
          const allowNoneExtra = newExtraByOption.allow_none_option_pricing_list
          const isAllowNone = isFullDay ? allowNoneExtra.full_day : allowNoneExtra.regular_booking
          if (_.isUndefined(isAllowNone)) {
            const firstPricing = _.head(pricings)
            const pricingId = _.isEmpty(firstPricing) ? undefined : firstPricing.id
            _.assign(newExtraByOption, {
              selectedPricing: firstPricing,
              selected: true,
              selected_amount: 1,
              extra_requirement_pricing_id: pricingId
            })
          }
          extraRequirements = _.concat(extraRequirements, newExtraByOption)
        }
      })
    }
  }
  tempBooking.extra_requirements = _.compact(extraRequirements)
  return tempBooking
}

const areaExtraRequirementVehicleTypes = (booking, extraServices) => {
  let extraRequireVehicles = extraServices.extraRequirementVehicleTypes
  if (!_.isEmpty(booking.time_type_option)) {
    if (booking.time_type_option.type_key === FULL_DAY) {
      extraRequireVehicles = extraServices.fulldayExtraRequirementVehicleTypes
    }

    if (booking.time_type_option.type_key === LONG_HAUL) {
      extraRequireVehicles = extraServices.longHaulExtraRequirementVehicleTypes
    }
  }
  return extraRequireVehicles
}

export const buildExtraRequirementFromExcel = (booking, extraServices) => {
  let tempBooking = booking
  const extraRequireVehicles = areaExtraRequirementVehicleTypes(tempBooking, extraServices)
  const textExtraRequirement = _.trim(tempBooking.excel_extra_requirements)
  const importExtraRequirements = convertExtraRequirementsFromText(
    tempBooking, textExtraRequirement, extraRequireVehicles
  )
  tempBooking.extra_requirements = checkExtraRequirementsFromSetting(
    tempBooking, importExtraRequirements, extraRequireVehicles
  )
  tempBooking = buildUnitPriceBookingExtras(tempBooking)
  tempBooking = verifyExtraService(tempBooking)
  return tempBooking
}

const getNegativeExtraFromVehicle = (booking, extraServices) => {
  let negativeExtras = []
  if (!_.isEmpty(booking.vehicle_type)) {
    const vehicleType = _.find(extraServices.vehicleTypes, { id: booking.vehicle_type.id })
    if (!_.isEmpty(vehicleType) && !_.isEmpty(vehicleType.extra_requirements_negative_position)) {
      _.forEach(vehicleType.extra_requirements_negative_position, (extra) => {
        const extraTemp = { ...extra }
        extraTemp.vehicle_type_id = booking.vehicle_type.id
        negativeExtras = _.concat(negativeExtras, extraTemp)
      })
    }
  }
  return negativeExtras
}

export const buildExtraRequirementFromSaveAsDraft = (booking, extraServices) => {
  const tempBooking = { ...booking }
  let extraRequireVehicles = areaExtraRequirementVehicleTypes(tempBooking, extraServices)
  if (!_.isEmpty(tempBooking.time_type_option) && tempBooking.time_type_option.type_key === FULL_DAY) {
    const extraRequirementNegative = getNegativeExtraFromVehicle(tempBooking, extraServices)
    if (!_.isEmpty(extraRequirementNegative)) {
      extraRequireVehicles = _.concat(extraRequireVehicles, extraRequirementNegative)
    }
  }
  let extraVerify = tempBooking.verify.extraRequirement
  for (let i = 0; i < _.size(tempBooking.extra_requirements); i += 1) {
    const bkExtra = tempBooking.extra_requirements[i]
    if (!_.isEmpty(bkExtra) && bkExtra.pricing_method === EXTRA_NORMAL && bkExtra.selected) {
      const extra = _.find(extraRequireVehicles, {
        id: bkExtra.id,
        vehicle_type_id: bkExtra.vehicle_type_id
      })
      if (_.isEmpty(extra)) {
        _.remove(tempBooking.extra_requirements, { id: bkExtra.id })
        extraVerify = false
      }
    }
  }
  tempBooking.verify.extraRequirement = extraVerify
  return tempBooking
}

export const buildCustomReimbursementFromSaveAsDraft = (booking, extraServices, isRestoreDraftData) => {
  const tempBooking = { ...booking }
  let preCustomReimbursements = []
  if (booking.custom_reimbursements && booking.added_from === ADD_FROM_DRAFT_BOOKING) {
    preCustomReimbursements = [...(booking.custom_reimbursements || [])]
  } else {
    preCustomReimbursements = [...(booking.customReimbursements || [])]
  }
  const customReimbursementsFromSetting = getCustomReimbursementFromSetting(booking, extraServices)
  const customReimbursements = []
  // if (isRestoreDraftData) {
  //   preCustomReimbursements = [...booking.custom_reimbursements]
  // }
  customReimbursementsFromSetting.forEach((reimbursement) => {
    const preReimbursement = preCustomReimbursements.find(
      item => item.id === reimbursement.id && item.selected_amount
    )
    const validReimbursement = !_.isEmpty(preReimbursement)

    const { oldSelectedAmount, oldUserInputAmount } = Utils.buildCustomReimbursementsOfBookAgain(
      preCustomReimbursements,
      preReimbursement,
      reimbursement,
      validReimbursement
    )
    customReimbursements.push({
      ...reimbursement,
      ...(reimbursement.allow_user_to_enter_amount ? { user_input_amount: oldUserInputAmount } : {}),
      selected_amount: oldSelectedAmount
    })
  })
  tempBooking.customReimbursements = customReimbursements
  return tempBooking
}

export const buildVehicleTypeFromExcel = (booking, vehicleTypes) => {
  const tempBooking = booking
  const vehicleTypeID = tempBooking.excel_vehicle_type_id
  const vehicleType = _.find(vehicleTypes, { id: _.toInteger(vehicleTypeID) })
  if (vehicleType) {
    const validTimeTypes = [SCHEDULE, FULL_DAY]
    if (_.findIndex(validTimeTypes, timeType => vehicleType.settings[`${timeType}_enabled`]) !== -1) {
      tempBooking.vehicle_type = vehicleType
      tempBooking.verify.vehicle = true
    }
  }
  return tempBooking
}

const buildAutoSettingCompanyBadges = (booking, companyBadges, params = {}) => {
  const bookingTemp = booking
  const vhBadges = !_.isEmpty(bookingTemp.badges) ? _.filter(bookingTemp.badges, { badgeable_relation_type: 'VehicleTypeBadge' }) : []
  const keepVehicleTypeBadges = !_.isEmpty(params) ? params.keep_vehicle_type_badges : false
  const bookingBadges = keepVehicleTypeBadges ? vhBadges : []
  const timeType = !_.isEmpty(bookingTemp.time_type_option) ? bookingTemp.time_type_option.type_key : undefined
  _.forEach(companyBadges, (badge) => {
    const autoSetting = !_.isEmpty(badge.ez_company_setting) ? badge.ez_company_setting[timeType] : undefined
    if (!_.isEmpty(autoSetting) && autoSetting.check_by_default) {
      const tmpBadge = _.find(bookingBadges, { id: badge.id })
      if (_.isUndefined(tmpBadge)) {
        bookingBadges.push(badge)
      }
    }
  })
  bookingTemp.badges = bookingBadges
  return bookingTemp
}

export const buildBadgeFromVehicleType = (booking, vehicleTypeBadges, companyBadges, params = {}) => {
  let bookingTemp = booking
  bookingTemp.company_badges = companyBadges
  // build badges from from auto setting for company badges
  bookingTemp = buildAutoSettingCompanyBadges(bookingTemp, companyBadges, params)
  if (!_.isEmpty(bookingTemp.vehicle_type) && bookingTemp.vehicle_type.show_badge) {
    bookingTemp.vehicle_type_badges = _.filter(
      vehicleTypeBadges,
      { vehicle_type_id: bookingTemp.vehicle_type.id }
    )
  } else {
    bookingTemp.vehicle_type_badges = []
  }
  bookingTemp.vehicle_type_badges = _.differenceBy(bookingTemp.vehicle_type_badges, bookingTemp.company_badges, 'badge_id')
  return bookingTemp
}

export const buildBadgeExtraService = (booking, extraServices, params = {}) => {
  let tempBooking = booking
  if (!_.isEmpty(tempBooking.vehicle_type) && !_.isEmpty(booking.time_type_option)) {
    const vehicleTypeBadges = extraServices.batchVehicleTypeBadges
    const companyBadges = extraServices.batchCompanyBadges
    tempBooking = buildExtraRequirementFromExcel(tempBooking, extraServices)
    tempBooking = buildBadgeFromVehicleType(tempBooking, vehicleTypeBadges, companyBadges, params)
  }
  return tempBooking
}

export const buildVehicleExtraService = (booking, params) => {
  const vehicleTypes = params.extraServices.vehicleTypes
  let tempBooking = booking
  tempBooking = buildVehicleTypeFromExcel(tempBooking, vehicleTypes)
  tempBooking = buildBadgeExtraService(tempBooking, params.extraServices, params)
  tempBooking = buildByOptionExtras(tempBooking, params.extraServices)
  tempBooking = buildCustomReimbursements(tempBooking, params.extraServices)
  return tempBooking
}

const verifyTimeType = (booking, params) => {
  const result = verifyVehicleLogic.verifyTimeTypeWhenChangeVehicle(booking, params.settings)
  return result
}

export const buildBookingPickupTime = (booking, params) => {
  const tempBooking = booking
  if (_.size(tempBooking.vehicle_type) > 0 && !verifyTimeType(tempBooking, params)) {
    $('#batch-update-vehicle-type').addClass('visible')
  } else if (!verifyVehicleLogic.verifyPickupTimeWhenChangeVehicle(tempBooking, params.settings)) {
    tempBooking.pickup_date_time = params.pickupTime
    tempBooking.verify.pickupTime = true
  }
  return tempBooking
}

export const buildImpactBookingVehicleTypeFromSetting = (booking, params) => {
  let bookingTemp = booking
  const vehicleTypeBadges = params.extraServices.batchVehicleTypeBadges
  const companyBadges = params.extraServices.batchCompanyBadges
  const currentCustomer = params.currentCustomer
  bookingTemp = buildBadgeExtraService(bookingTemp, params.extraServices)
  bookingTemp = buildCustomReimbursements(bookingTemp, params.extraServices)
  bookingTemp = buildBadgeFromVehicleType(bookingTemp, vehicleTypeBadges, companyBadges)
  bookingTemp = buildByOptionExtras(bookingTemp, params.extraServices)
  bookingTemp = buildBookingPickupTime(bookingTemp, params)
  bookingTemp = utilBookingLocations.checkRoundTripDiscountBooking(bookingTemp, currentCustomer)
  return bookingTemp
}

export const buildImpactAllBookingsEmptyVehicleType = (bookings, updatedBooking, params, oldBookings = []) => {
  // const tempBookings = bookings
  const currentCustomer = params.currentCustomer
  const tempBookings = bookings.map((booking) => {
    let tempBooking = { ...booking }
    if (_.isEmpty(tempBooking.vehicle_type) || tempBooking.temp_id === updatedBooking.temp_id) {
      const matchBooking = oldBookings.find(item => item.id === tempBooking.id)
      const isChangeTimeType = matchBooking?.time_type_option?.type_key !== tempBooking.time_type_option?.type_key
      tempBooking.vehicle_type = updatedBooking.vehicle_type
      tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)
      tempBooking = buildImpactBookingVehicleTypeFromSetting(tempBooking, params)
      tempBooking = verifyExtraService(tempBooking)
      tempBooking = buildBookings.checkReimbursentSetting(
        tempBooking, { currentCustomer: params.currentCustomer }, isChangeTimeType,
      )
      tempBooking = utilBookingLocations.checkRoundTripDiscountBooking(tempBooking, currentCustomer)
    }

    return tempBooking
  })
  return tempBookings
}

export const buildImpactAllBookingsVehicleType = (bookings, updatedBooking, params, oldBookings = []) => {
  // const tempBookings = bookings
  const currentBooking = { ...updatedBooking }
  const currentVehicle = currentBooking.vehicle_type
  const currentCustomer = params.currentCustomer
  const tempBookings = bookings.map((booking) => {
    let tempBooking = { ...booking }
    const matchBooking = oldBookings.find(item => item.id === tempBooking.id)
    const isChangeTimeType = matchBooking?.time_type_option?.type_key !== tempBooking.time_type_option?.type_key
    tempBooking.vehicle_type = currentVehicle
    tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)
    tempBooking = buildImpactBookingVehicleTypeFromSetting(tempBooking, params)
    tempBooking = verifyExtraService(tempBooking)
    // check round trip discount
    tempBooking = utilBookingLocations.checkRoundTripDiscountBooking(tempBooking, currentCustomer)
    tempBooking = buildBookings.checkReimbursentSetting(
      tempBooking, { currentCustomer: params.currentCustomer }, isChangeTimeType
    )

    return tempBooking
  })
  return tempBookings
}

export const checkSameVehicleBatch = (batch) => {
  if (_.isEmpty(batch)) {
    return false
  }
  const bookingSize = _.size(batch.bookings)
  if (bookingSize < 2) {
    return false
  }
  const firstVehicle = batch.bookings[0].vehicle_type
  if (_.isEmpty(firstVehicle)) {
    return false
  }
  for (let i = 1; i < _.size(batch.bookings); i += 1) {
    const booking = batch.bookings[i]
    if (_.isEmpty(booking)) {
      return false
    }
    if (_.isEmpty(booking.vehicle_type)) {
      return false
    }
    if (booking.vehicle_type.id !== firstVehicle.id) {
      return false
    }
  }
  return true
}

export const checkSameTimeTypeBatch = (batch) => {
  if (_.isEmpty(batch)) {
    return false
  }
  const bookingSize = _.size(batch.bookings)
  if (bookingSize < 2) {
    return false
  }
  const firstTimeType = batch.bookings[0].time_type_option?.type_key
  if (_.isEmpty(firstTimeType)) {
    return false
  }
  for (let i = 1; i < _.size(batch.bookings); i += 1) {
    const booking = batch.bookings[i]
    if (_.isEmpty(booking)
      || _.isEmpty(booking.time_type_option)
      || booking.time_type_option?.type_key !== firstTimeType) {
      return false
    }
  }
  return true
}

export const applyExtraServiceToAllSameVehicleBookbings = (batch, batchTemplate, updatedBooking) => {
  const isSame = checkSameVehicleBatch(batch)
  let bookings = [...batch.bookings]
  if (isSame && batchTemplate.same_extra_requirements) {
    const updatedNormalExtras = _.filter(updatedBooking.extra_requirements, { pricing_method: EXTRA_NORMAL })
    bookings = _.map(batch.bookings, (booking) => {
      let tempBooking = _.cloneDeep(booking)
      const { locations } = tempBooking
      const timeType = _.get(tempBooking, 'time_type', '') || _.get(tempBooking, 'time_type_option.type_key', '')
      const tempByOptionExtras = _.filter(tempBooking.extra_requirements, { pricing_method: EXTRA_BY_OPTION })
      let tempNormalExtras = []
      if (!_.isEmpty(updatedNormalExtras)) {
        _.map(updatedNormalExtras, (updatedNormalExtra) => {
          const tempNormalExtra = { ...updatedNormalExtra }
          tempNormalExtras = _.concat(tempNormalExtras, tempNormalExtra)
        })
      }
      let bookingExtras = _.concat(tempNormalExtras, tempByOptionExtras)
      if (timeType === SCHEDULE && !_.isEmpty(locations)) {
        const dropOffCount = locations.length - 1
        bookingExtras = calcPriceRegularBooking(dropOffCount, bookingExtras)
      }
      tempBooking.extra_requirements = bookingExtras
      tempBooking.verify.extraRequirement = true
      tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)

      return tempBooking
    })
  }

  return {
    ...batch,
    bookings,
  }
}

export const applyExtraServiceToUndefinedSameVehicleBookbings = (batch, batchTemplate, updatedBooking) => {
  const isSame = checkSameVehicleBatch(batch)
  let bookings = [...batch.bookings]
  if (isSame && batchTemplate.same_extra_requirements) {
    const updatedNormalExtras = _.filter(updatedBooking.extra_requirements, { pricing_method: EXTRA_NORMAL })
    bookings = _.map(batch.bookings, (booking) => {
      let tempBooking = _.cloneDeep(booking)
      if (_.isEmpty(tempBooking.extra_requirements)) {
        const tempByOptionExtras = _.filter(tempBooking.extra_requirements, { pricing_method: EXTRA_BY_OPTION })
        let tempNormalExtras = []
        if (!_.isEmpty(updatedNormalExtras)) {
          _.map(updatedNormalExtras, (updatedNormalExtra) => {
            const tempNormalExtra = { ...updatedNormalExtra }
            tempNormalExtras = _.concat(tempNormalExtras, tempNormalExtra)
          })
        }
        const bookingExtras = _.concat(tempNormalExtras, tempByOptionExtras)
        tempBooking.extra_requirements = bookingExtras
        tempBooking.verify.extraRequirement = true
        tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)
      }

      return tempBooking
    })
  }

  return {
    ...batch,
    bookings,
  }
}

export const hasEmptyBookingVehicle = (batch) => {
  if (_.isEmpty(batch.bookings)) {
    return false
  }
  const bookingSize = _.size(batch.bookings)
  for (let i = 0; i < bookingSize; i += 1) {
    const booking = batch.bookings[i]
    if (_.isEmpty(booking.vehicle_type)) {
      return true
    }
  }
  return false
}

export const applyCustomReimbursementToAllSameVehicleBookings = (batch, batchTemplate, updatedBooking) => {
  const isSame = checkSameVehicleBatch(batch)
  let bookings = [...batch.bookings]
  if (isSame && batchTemplate.same_custom_reimbursements) {
    const updatedCustomReimbursements = updatedBooking.customReimbursements
    bookings = _.map(batch.bookings, (booking) => {
      let tempBooking = { ...booking }
      if (booking.time_type_option) {
        tempBooking.customReimbursements = Utils.handleAndKeepDataCusomReimbursementForEntrieBatch(
          updatedCustomReimbursements, tempBooking.customReimbursements
        )
      }
      tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)

      return tempBooking
    })
  }

  return {
    ...batch,
    bookings,
  }
}
