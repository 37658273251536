import apiClient from 'services/axiosApp'

const baseUrl = '/api/v3/vehicle_types'

const vehicleTypeAPIs = {
  getVehicleTypeInfo: async (vehicleTypeId, currentCustomer, callback) => {
    try {
      const res = await apiClient.get(`${baseUrl}/${vehicleTypeId}`)
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getNumberOfOnlineDrivers: async (vehicleTypeId, location, callback) => {
    const params = {
      'location[lat]': location.lat && location.lat.toString().trim(),
      'location[lng]': location.lng && location.lng.toString().trim()
    }
    try {
      const res = await apiClient.get(`/api/v3/vehicle_types/${vehicleTypeId}/count_number_of_driver`, { params })
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default vehicleTypeAPIs
