/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom'
import discountCodeAPI from 'api/discountCode'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'
import { calculate } from '../common/bookingActionCreators'
import _ from 'lodash'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { discountCodeActionsCreator } from 'store/toolkit/newBooking/discountCode.reducer'
import { showErrorPopup } from './stepActionCreators'
import { ICON_CANCEL_BOOKING } from 'constants/imageConstants'
import I18n from 'i18n/i18n'

export const checkDiscountCode = value => (dispatch, getState) => {
  const state = getState()
  const bookingAgainId = state.bookAgainDetails ? state.bookAgainDetails.id : 0

  return new Promise((resolve) => {
    if (_.isEmpty(value)) {
      resolve({ discount_invalid: true })
    } else {
      discountCodeAPI.checkDiscountCode(
        value,
        state.currentCustomer.current_company_id || 0,
        state.selectedServiceTypeID,
        state.locations,
        state.extraInfos.device_id,
        state.currentCustomer.authentication_token,
        bookingAgainId,
        (response) => {
          resolve(response)
        }
      )
    }
  })
}

export const setDiscountCode = () => (dispatch, getState) => {
  const extraInfos = getState().extraInfos
  // I don't know why we load discount_code from extraInfos
  const discountCode = _.isEmpty(extraInfos) ? '' : extraInfos.discount_code
  dispatch(discountCodeActionsCreator.updateDiscountCode({ value: discountCode }))
}

/*
TODO: remove old action functions after migrated to new discount code feature
 */
export const setDiscountCodeStatus = status => dispatch => (
  dispatch(discountCodeActionsCreator.updateDiscountCode({ status }))
)

export const applyDiscountCodeToBooking = discountCode => dispatch => (
  Promise
    .resolve(dispatch(discountCodeActionsCreator.updateDiscountCodeStatus(DISCOUNT_CODE_STATUS.checking)))
    .then(() => (dispatch(checkDiscountCode(discountCode))))
    .then((response) => {
      const status = response.discount_invalid ? DISCOUNT_CODE_STATUS.invalid : DISCOUNT_CODE_STATUS.valid
      if (status === DISCOUNT_CODE_STATUS.valid) {
        return Promise.resolve(
          dispatch(
            discountCodeActionsCreator.updateDiscountCode({ value: discountCode, status, isDiscountFirstTime: response.discount_is_first_time })
          )
        ).then(() => (
          new Promise((resolve) => {
            dispatch(calculate(resolve))
          })
        ))
      }
      return dispatch(
        discountCodeActionsCreator.updateDiscountCode({
          value: discountCode,
          status,
          invalidMessage: response.discount_invalid_message,
          isDiscountFirstTime: response.discount_is_first_time
        })
      )
    })
    .catch(() => dispatch(
      discountCodeActionsCreator.updateDiscountCode({ status: DISCOUNT_CODE_STATUS.pending })
    ))
)

export const removeDiscountCodeFromBooking = () => dispatch => (
  Promise
    .resolve(dispatch(discountCodeActionsCreator.updateDiscountCode({ value: '', status: DISCOUNT_CODE_STATUS.pending })))
    .then(() => (
      new Promise((resolve) => {
        dispatch(calculate(resolve))
      })
    ))
)

export const markDiscountCodeAsDraft = () => (dispatch) => {
  dispatch(setDiscountCodeStatus(DISCOUNT_CODE_STATUS.draft))
}

export const showPopupWhenDiscountInvalid = response => (dispatch) => {
  dispatch(calculate(() => {
    dispatch(discountCodeActionsCreator.updateDiscountCode({
      status: DISCOUNT_CODE_STATUS.invalid,
      invalidMessage: response.discount_invalid_message,
      isDiscountFirstTime: response.discount_is_first_time
    }))
    dispatch(showErrorPopup(
      ICON_CANCEL_BOOKING,
      I18n.t('errors.messages.discount_code_used_up'),
      {
        specialClass: 'mt10 flex-center',
        list: [
          {
            title: I18n.t('webapp.action.ok'),
            specialClass: 'Button white green-text flex-index',
            specialClick: () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup')),
          },
        ],
      }
    ))
  }))
}