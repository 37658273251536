import { filter, isEmpty } from 'lodash'
import defaultCountriesData from './countryData'
/**
 * Get list countriesData with format
 * @param {countriesData} countriesData
 */

export const formatCountriesData = countriesData => countriesData.map(country => ({
  name: country[0],
  iso2: country[1],
  dialCode: country[2],
  priority: country[3] || 0,
  areaCodes: country[4] || null,
}))

/**
 * Get dialCode with countryCode
 * @param {countryCode} countryCode
 */

export const getDialCodeWithCountryCode = (countryCode) => {
  const countryList = formatCountriesData(defaultCountriesData)
  const country = filter(countryList, item => item.iso2 === countryCode)
  return country
}

export const formatPhoneNumber = (phoneNumber, diaCode) => {
  const splitPhoneBumner = phoneNumber.split(`+${diaCode}`).join('')
  return splitPhoneBumner
}

export const combinePhoneNumber = (phoneNumber, diaCode) => `+${diaCode}${phoneNumber}`

