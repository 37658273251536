import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const pickupContactsSlice = createSlice({
  name: 'pickupContacts',
  initialState,
  reducers: {
    updatePickupContacts: (state, action: PayloadAction<any>) => {
      return action.payload || {}
    },
    deletePickupContactLocal: (state, action: PayloadAction<any>) => {
      _.remove(state, { id: action.payload })
    },
  },
  extraReducers: () => {},
})

export const pickupContactsActionsCreator = pickupContactsSlice.actions

export default pickupContactsSlice
