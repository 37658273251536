// UTILS
// API
import BatchesAPI from 'api/batches'
import PopupAPI from 'api/popups'
import BookingAPI from 'api/bookings'
// ACTIONS
// COMPONENTS
// CONSTANTS
import {
  FULL_DAY,
  STATUS_ASSIGNING_DRIVER_TIMEOUT,
  STATUS_CANCELLED,
  STATUS_CS_FINDING_DRIVER,
  STATUS_LOCATING_DRIVER,
} from 'constants/bookingConstants'
import _, { assign, isEmpty } from 'lodash'
import { selectedBatchActionsCreators } from 'store/toolkit/selectedBatch/selectedBatch.reducer';
import { batchesActionsCreators } from 'store/toolkit/batch/batches.reducer';
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
// ASSETS


export const updateBatches = batches => batchesActionsCreators.updateBatches({
  batches
})

export const updateSelectedBatch = selectedBatch => selectedBatchActionsCreators.updateSelectedBatch({
  selectedBatch
})

export const updateBatchBookings = bookings => selectedBatchActionsCreators.updateBatchBookings({
  bookings
})

export const updateBatchBookingAttrs = attrs => selectedBatchActionsCreators.updateBatchBookingAttrs({
  attrs
})

export const updateBatchBookingAttrsForBatches = (batchId, attrs) => batchesActionsCreators.updateBatchBookingAttrsForBatches({
  batch_id: batchId,
  attrs
})

export const updateBatchChatBookingAttrs = attrs => selectedBatchActionsCreators.updateBatchChatBookingAttrs({
  attrs
})

export const updateCurrentBatchChatBooking = bookingID => selectedBatchActionsCreators.updateCurrentBatchChatBooking({
  bookingID
})

export const updateBatchAttrs = (batchId, attrs) => batchesActionsCreators.updateBatchAttrs({
  batch_id: batchId,
  attrs
})


export const getBatch = (batchID, currentCustomer, callback) => () => {
  BatchesAPI.get(batchID, currentCustomer, (response) => {
    if (response) {
      callback(response.object)
    }
  })
}

export const getBookingForUpdateChat = (bookingID, currentCustomer, callback) => () => {
  BatchesAPI.getBookingForUpdateChat(bookingID, currentCustomer, (response) => {
    callback(response.object)
  })
}

export const getBooking = (bookingID, currentCustomer, callback) => () => {
  BookingAPI.get(bookingID, currentCustomer, (response) => {
    callback(response.object)
  })
}

export const cancelBooking = (booking, saveBooking = false, currentCustomer, callback = (() => { })) => () => {
  if (booking.status !== STATUS_CANCELLED) {
    const params = { id: booking.id, save_booking: saveBooking }
    BookingAPI.cancelBooking(booking.id, params, callback)
  }
}

export const favorite = (booking, saveBooking, currentCustomer, callback = (() => { })) => () => {
  BookingAPI.favoriteBooking(booking.id, saveBooking, callback, true)
}

export const favoriteBatch = (batch, saveBatch, currentCustomer = (() => { })) => (dispatch) => {
  const param = {
    id: batch.id,
    marked_as_favorite_batch: saveBatch
  }
  BatchesAPI.favoriteBatchBooking(batch.id, param, (res) => {
    dispatch(selectedBatchActionsCreators.updateSelectedBatch({
      selectedBatch: assign(batch, {
        marked_as_favorite_batch: res.data.object.marked_as_favorite_batch,
      })
    }))
  })
}

export const retryBooking = (bookingID, currentCustomer, callback = (() => { }), options = {}) => async (dispatch) => {
  const params = {
    id: bookingID,
    assign_driver_booking_attributes: options.driver_id
      ? {
        driver_id: options.driver_id,
        fleet_partner_id: options.fleet_partner_id
      }
      : undefined,
    driver_preference_type: options.driver_preference_type || undefined
  }
  const res = await BookingAPI.retry(bookingID, params)
  if (options.booking.status !== STATUS_ASSIGNING_DRIVER_TIMEOUT) {
    dispatch(updateBatchBookingAttrs({
      batch_tracking_token: options.booking.batch_tracking_token,
      status: STATUS_LOCATING_DRIVER
    }))
  }
  hideLoading()
  callback(res)
}

export const loadMoreBatches = (
  scope,
  page,
  onlyFields = [],
  currentCustomer,
  callback = (() => { }),
  searchString,
  areaID,
  includeFields,
  onlyFieldsBatchBookings
) => () => {
  BatchesAPI.paginate(
    scope,
    page,
    onlyFields,
    currentCustomer,
    searchString,
    areaID,
    includeFields,
    onlyFieldsBatchBookings,
    (response) => { callback(response) }
  )
}

export const bookFromDraft = (batchID, batchTrackingToken, currentCustomer, callback = (() => { })) => () => {
  BatchesAPI.bookFromDraft(batchID, batchTrackingToken, currentCustomer, (response) => {
    callback(response.object)
  })
}

export const retrySelectedBookings = (bookingIds, currentCustomer, callback = (() => { })) => (dispatch) => {
  BatchesAPI.retryBatchBookings(bookingIds, currentCustomer, (response) => {
    dispatch(selectedBatchActionsCreators.updateAttrListBooking({ bookingIds, attrs: { status: STATUS_CS_FINDING_DRIVER } }))
    callback(response)
  })
}

export const updateBookingPickupTime = (bookingID, pickupTime, currentCustomer, callback = (() => { })) => () => {
  BatchesAPI.updateTimeForOutOfDateBooking(bookingID, pickupTime, currentCustomer, (response) => {
    callback(response)
  })
}

export const confirmReimbursements = (batchId, bookings, currentCustomer, callback = (() => { })) => () => {
  const params = { id: batchId }
  const needConfirmedBookings = _.filter(bookings, { need_confirmation: true })
  params.bookings = needConfirmedBookings.map((booking) => {
    const { reimbursementInfo = {} } = booking
    const { location_reimbursements: locationReimbursements = [] } = reimbursementInfo

    const locationsAttributes = []
    const reimbursementsAttributes = []
    const bookingParams = { id: _.toInteger(booking.id), time_type: booking.time_type }
    if (booking.time_type === FULL_DAY) {
      locationReimbursements.forEach((locationReimbursement) => {
        const tolls = _.get(locationReimbursement, 'tolls', [])
        const parking = _.get(locationReimbursement, 'parking', [])
        const waitingTime = _.get(locationReimbursement, 'waiting_time', [])
        const custom = _.get(locationReimbursement, 'custom', [])

        const listReimbursement = tolls.concat(parking, waitingTime, custom)
        listReimbursement.forEach((reimbursement) => {
          reimbursementsAttributes.push({
            id: reimbursement.id,
            is_confirmed: reimbursement.is_confirmed
          })
        })
      })
      bookingParams.reimbursements_attributes = reimbursementsAttributes
    } else {
      locationReimbursements.forEach((locationReimbursement) => {
        const tolls = _.get(locationReimbursement, 'tolls[0]')
        const parking = _.get(locationReimbursement, 'parking[0]')
        const waitingTime = _.get(locationReimbursement, 'waiting_time[0]')
        const hasTolls = !_.isEmpty(tolls)
        const hasParking = !_.isEmpty(parking)
        const hasWaitingTime = !_.isEmpty(waitingTime)

        const custom = _.get(locationReimbursement, 'custom', [])
        locationsAttributes.push({
          id: locationReimbursement.id,
          tolls_confirmed: hasTolls ? !!tolls.is_confirmed : true,
          parking_confirmed: hasParking ? !!parking.is_confirmed : true,
          waiting_time_confirmed: hasWaitingTime ? !!waitingTime.is_confirmed : true
        })
        custom.map(reimbursement => (
          reimbursementsAttributes.push({
            id: reimbursement.id,
            is_confirmed: reimbursement.is_confirmed
          })))
      })
      bookingParams.locations_attributes = locationsAttributes
      bookingParams.reimbursements_attributes = reimbursementsAttributes
    }
    return bookingParams
  })
  BatchesAPI.confirmReimbursements(batchId, params, callback)
}

export const getDriverPreferencesPopupApi = (inputs, callback = (() => { })) => () => {
  const params = {
    area_id: inputs.area_id,
    popup_type: inputs.popup_type,
  }
  PopupAPI.getAreaTypePopup(inputs?.authentication_token, params, (result) => {
    const popupData = result?.data?.object
    if (result?.status === 200 && !_.isEmpty(popupData)) {
      callback(popupData)
    }
  })
}
