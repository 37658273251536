import { editModeTextActionsCreator } from 'store/toolkit/newBooking/editModeText.reducer'

let clearTextEdit = null

export const updateEditModeText = text => (dispatch) => {
  clearTimeout(clearTextEdit)
  dispatch(editModeTextActionsCreator.updateEditModeText(text))

  // after 2s then remove text error
  clearTextEdit = setTimeout(() => {
    dispatch(editModeTextActionsCreator.updateEditModeText(''))
  }, 2000)
}

export { updateEditModeText as default }
