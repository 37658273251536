import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  ITEM_TYPE_CR_PE_TITLE, ITEM_TYPE_CR_PE_SUBTITLE, ITEM_TYPE_CR_PE_CONTENT,
  ITEM_TYPE_CR_PE_CONTENT_LAST, ITEM_TYPE_CR_PE_CONTENT_FINAL

} from 'constants/common/popupConstants'
import { Utils } from 'utils/Utils'

class CustomReimbursementPopups extends React.PureComponent {
  static renderPopupItem(item, index) {
    switch (item.item_type) {
      case ITEM_TYPE_CR_PE_TITLE: {
        return (
          <h5 key={index} className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_CR_PE_SUBTITLE: {
        return (
          <span key={index} className="Normal-Booking-Sub-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_CR_PE_CONTENT: {
        return (
          <p key={index} className="center white-pre-wrap mb15" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_CR_PE_CONTENT_LAST: {
        return (
          <div key={index}>
            <p className="center white-pre-wrap" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
            <div className="Normal-Booking-Devide" />
          </div>
        )
      }
      case ITEM_TYPE_CR_PE_CONTENT_FINAL: {
        return (
          <p
            key={index}
            className="center custom-color mt-20-imp mb-10-imp white-pre-wrap"
            dangerouslySetInnerHTML={{ __html: item.formated_content }}
          />
        )
      }
      default:
        return <span />
    }
  }

  static renderPopupItems(items) {
    return items.map((item, index) => CustomReimbursementPopups.renderPopupItem(item, index))
  }

  static renderReimbursemmentPopup(customReimbursements) {
    if (_.isEmpty(customReimbursements)) return <span />

    const customReimbursementPopup = _.filter(customReimbursements, item => (
      item.popups && item.popups.popup_items && item.popups.popup_items.length
    ))

    if (_.isEmpty(customReimbursementPopup)) return <span />
    return customReimbursementPopup.map((reimbursement) => {
      const idPopupCustom = Utils.generateIdPopupRebursement(
        reimbursement.popups.popup_type, 0, 0, reimbursement.id
      )
      return CustomReimbursementPopups.renderPopup(reimbursement, idPopupCustom)
    })
  }

  static renderPopup(reimbursement, idPopupCustom) {
    return (
      <div
        className="vertical-scroll Modal Modal-No-action"
        id={`${idPopupCustom}`}
        key={idPopupCustom}
      >
        <div className="Normal-Booking">
          <div className="Box">
            <div className="Box-Icon">
              <div className="Logo">
                {reimbursement.popup_icon_url
                  && <img src={reimbursement.popup_icon_url} alt="deliveree" />
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal Normal-Hyperlink-Custom">
                {CustomReimbursementPopups.renderPopupItems(reimbursement.popups.popup_items)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { customReimbursements } = this.props
    return (
      <div>
        {CustomReimbursementPopups.renderReimbursemmentPopup(customReimbursements)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customReimbursements: state.extraServices.customReimbursements,
})

CustomReimbursementPopups.defaultProps = {
  customReimbursements: []
}

CustomReimbursementPopups.propTypes = {
  customReimbursements: PropTypes.arrayOf(PropTypes.shape({})),
}
export default connect(mapStateToProps, null)(CustomReimbursementPopups)
