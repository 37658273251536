import React from 'react'
import PropTypes from 'prop-types'

class ToolTipsSubAccount extends React.PureComponent {
  static scrollIntoView() {
    const element = document.querySelector('.sub-account-tooltip-content')
    if (element) {
      element.scrollIntoView()
    }
  }

  constructor() {
    super()
    this.state = {
      showToolTip: false
    }
    this.handleShow = this.handleShow.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    const { showToolTip } = this.state
    const { isFromBookingCard, isFromBookingDetail, isNotScroll } = this.props
    if (prevState.showToolTip !== showToolTip && showToolTip && !isFromBookingDetail
      && !isFromBookingCard && !isNotScroll) {
      ToolTipsSubAccount.scrollIntoView()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showToolTip: false })
    }
  }

  handleShow() {
    this.setState({ showToolTip: true })
  }

  render() {
    const { showToolTip } = this.state
    const {
      content, formEzSheet, isWhiteBackground,
      isPopupPrice, isBatchEzsheet
    } = this.props

    return (
      <div
        ref={this.setWrapperRef}
        className={`${isWhiteBackground && 'sub-account-tooltip'} ${isBatchEzsheet && 'ez-sheet-width-icon'} ${formEzSheet && 'ez-sheet-tooltip'} ${isPopupPrice && 'popup-price-thailand'}`}
        onClick={() => this.handleShow()}
      >
        {showToolTip && (
          <div className="sub-account-tooltip-content bottom" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        <i className={`${!isWhiteBackground && 'b'} material-icons Icon cur-pointer vertical-align-middle ${showToolTip ? 'green' : 'gray'} ${isWhiteBackground && 'White-bg'}`}>
          help_outline
        </i>
      </div>
    )
  }
}

ToolTipsSubAccount.propTypes = {
  content: PropTypes.string.isRequired,
  isFromBookingCard: PropTypes.bool,
  isFromBookingDetail: PropTypes.bool,
  formEzSheet: PropTypes.bool,
  isNotScroll: PropTypes.bool,
  isWhiteBackground: PropTypes.bool,
  isPopupPrice: PropTypes.bool,
  isBatchEzsheet: PropTypes.bool,
}

ToolTipsSubAccount.defaultProps = {
  isFromBookingCard: false,
  isFromBookingDetail: false,
  formEzSheet: false,
  isNotScroll: false,
  isWhiteBackground: false,
  isPopupPrice: false,
  isBatchEzsheet: false
}

export default ToolTipsSubAccount
