import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  data: [],
}

const calendarBookingSlice = createSlice({
  name: 'calendarBooking',
  initialState,
  reducers: {
    addIdCalendar: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data
    },
    removeIdCalendar: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data
    },
    resetCalendar: (state, action: PayloadAction<any>) => {
      state.data = []
    },
    updateCalendar: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data
    },
  },
  extraReducers: () => {},
})

export const calendarBookingActionsCreator = calendarBookingSlice.actions

export default calendarBookingSlice
