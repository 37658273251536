import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import _ from 'lodash'

import { Utils } from 'utils/Utils'

class PopupEye extends React.PureComponent {
  render() {
    const {
      configStyle,
      icon,
      title,
      subTitle,
      content,
      longContent,
      smallContent,
      renderNode,
      popupStyle,
    } = this.props
    return (
      <div
        className="vertical-scroll Modal visible"
        style={_.size(configStyle) ? Utils.modifyPopupMultiple(configStyle.scale, configStyle.id) : {}}
        data-bookingID={_.size(configStyle) ? configStyle.id : null}
      >
        <div className={`Normal-Booking z-index-2 ${!_.isEmpty(popupStyle) && popupStyle.width}`}>
          <div className="Box z-index-2">
            <div className="Box-Icon">
              <div className="Logo">
                {icon.length > 0
                  && (<img src={icon} alt="deliveree" />)
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal Normal-Hyperlink-Custom">
                {title.length > 0
                  && (<h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: title }} />)
                }
                {subTitle.length > 0
                  && (<p className="center italic" dangerouslySetInnerHTML={{ __html: subTitle }} />)
                }
                {content.length > 0
                  && (<p className="center" dangerouslySetInnerHTML={{ __html: content }} />)
                }
                {longContent.length > 0
                  && (<p className="center fullday" dangerouslySetInnerHTML={{ __html: longContent }} />)
                }
                {smallContent.length > 0
                  && (<p className="center yellow fullday-custom mt-20-imp mb-10-imp" dangerouslySetInnerHTML={{ __html: smallContent }} />)
                }
              </div>
            </div>
          </div>
          <div
            className="close-modal-overlay"
            onClick={() => ReactDOM.unmountComponentAtNode(renderNode)}
          />
        </div>
      </div>
    )
  }
}

PopupEye.propTypes = {
  configStyle: PropTypes.shape({}),
  icon: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.string,
  longContent: PropTypes.string,
  smallContent: PropTypes.string,
  renderNode: PropTypes.shape({}),
  popupStyle: PropTypes.shape({}),
}

PopupEye.defaultProps = {
  configStyle: {},
  icon: '',
  title: '',
  subTitle: '',
  content: '',
  longContent: '',
  smallContent: '',
  renderNode: undefined,
  popupStyle: {},
}

export default PopupEye
