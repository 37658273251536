/* Utils for Jquery */
import React from 'react'
import _ from 'lodash'

export const UtilsHTML = {
  renderTitle: (inRenderLine = false, title = '', config = {}) => {
    return [
      inRenderLine && <div key={`line-${title}`} className="h1px Line-bg ml15 mr15" />,
      <div key={`title-${title}`} className={`${inRenderLine ? 'pt15' : 'pt5'} ml15 mr15 flex flex-start`}>
        {_.size(config) ? (
          <div className={config.className}>
            <img src={config.icon} width={config.width} />
          </div>
        ) : null}
        <b className="default-small-font default-color">
          {title}
        </b>
      </div>
    ]
  },
  renderSectionTitle: title => (
    <div className="Title-bg Radius-Top-Default flex flex-start pt10 pb10 pl15 pr15">
      <b className="default-small-font default-color">
        {title}
      </b>
    </div>
  ),
  renderOptionTitle: (title, titleBg = true) => (
    <div className={`${titleBg ? 'Title-bg pt10 pb10' : 'pt5 pb5'} flex flex-start pl15 pr15`}>
      <div className="default-small-font default-color">
        {title}
      </div>
    </div>
  )
}

export { UtilsHTML as default }
