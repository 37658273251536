import React from 'react'
import PropTypes from 'prop-types'

import {
  ICON_ARROW_DOWN_DARK_GREEN,
} from 'constants/imageConstants'

class DropdownHandlingUnit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.setWrapperRef && !this.setWrapperRef.contains(event.target)) {
      this.setState({ expanded: false })
    }
  }

  handleExpanded() {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  isActiveServiceType(handlingUnitId) {
    const { handlingUnitValue } = this.props
    return handlingUnitId === handlingUnitValue.id
  }

  renderItem(
    handlingUnit,
    selectedHandlingUnit = true,
    options = {
      new_rule: true,
    }
  ) {
    const { new_rule: newRule } = options
    const selected = selectedHandlingUnit ? 'selected' : ''

    return (
      <div
        key={`${handlingUnit.id}-box`}
        className={`block-item ${newRule ? selected : 'Services-Block flex-index'}`}
        onClick={() => {
          if (newRule) {
            this.setState({ expanded: false }, () => {
              handlingUnit.onClick()
            })
          }
        }}
      >
        <label className={`cur-pointer Services-Icon Services-Icon-${handlingUnit.id} ${!newRule && 'Services-Icon-Image mr10'}`}>
          <img
            src={handlingUnit.icon}
            alt={handlingUnit.name}
          />
        </label>
        <label className={`cur-pointer ${!newRule && 'default-font default-color font-weight-500'}`}>
          {handlingUnit.name}
        </label>
      </div>
    )
  }

  renderList() {
    const { expanded } = this.state
    const { data } = this.props

    return (
      <div className={`Services Services-List Custom-Scroll-Group Default-Scroll-Services White-bg z-index-222 ${expanded ? 'block' : 'hidden'} list-view }`}>
        {data.map(handlingUnit => this.renderItem(
          handlingUnit,
          this.isActiveServiceType(handlingUnit.id),
          {
            new_rule: true,
          }
        ))}
      </div>
    )
  }

  render() {
    const { expanded } = this.state
    const {
      handlingUnitValue,
    } = this.props

    return (
      <div
        className="Radius-default White-bg mt20 mar20 relative"
        ref={(ref) => { this.setWrapperRef = ref }}
      >
        <div
          className={`${expanded ? 'Services-List-Border' : ''} flex flex-center-algin cur-pointer min-h52px`}
          onClick={() => this.handleExpanded()}
        >
          {this.renderItem(handlingUnitValue, false, { new_rule: false })}
          <img src={ICON_ARROW_DOWN_DARK_GREEN} className={`mr15 min-w-16 Services-Icon-Arrow ${expanded ? 'Up' : ''}`} />
        </div>
        {this.renderList()}
      </div>
    )
  }
}


DropdownHandlingUnit.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  handlingUnitValue: PropTypes.shape({}),
}

DropdownHandlingUnit.defaultProps = {
  handlingUnitValue: {},
}

export default DropdownHandlingUnit
