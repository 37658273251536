import { useState, useEffect, useRef } from 'react'
import { useResizeObserver } from './useElementSize'

const useElBoundingRect = () => {
  const ref = useRef(null)
  const [, { height }] = useResizeObserver(ref)
  const [elRect, setElRect] = useState({})
  const [forceRender, setForcedRender] = useState(false)

  const rerender = () => {
    setForcedRender(boolean => !boolean)
  }

  useEffect(() => {
    if (ref.current) {
      const rectObj = ref.current.getBoundingClientRect()
      setElRect(rectObj)
    }
  }, [height, forceRender])

  return [ref, elRect, rerender]
}

export default useElBoundingRect
