import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    updateCurrentPage: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const currentPageActionsCreators = currentPageSlice.actions

export default currentPageSlice
