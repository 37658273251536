import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
import I18n from 'i18n/i18n'

// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'

// COMPONENTS
import ReimbursementList from '../../common/reimbursement/ReimbursementList'
// CONSTANTS
import { FULL_DAY, LONG_HAUL } from 'constants/bookingConstants'
import * as newBookingUtil from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
// ASSETS

const mapStateToProps = state => ({
  companySettings: state.extraServices.companySettings,
  extraInfos: state.extraInfos,
  timeType: state.timeType,
  currentVehicleType: newBookingUtil.currentVehicleType(state),
  vehicleTypeSettings: state.extraServices.vehicleTypeSettings,
  customReimbursements: state.extraServices.customReimbursements,
  assignedDriver: state.assignedDriver,
})

function mapDispatchToProps(dispatch) {
  return {
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
  }
}

class Reimbursements extends React.PureComponent {
  static checkHeightPopup(element) {
    if (element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  }

  static reimbursementPopupEyeHandler(popupType, reimbursementId) {
    const idPopupCustom = Utils.generateIdPopupRebursement(popupType, 0, 0, reimbursementId)
    $(`#${idPopupCustom}`).addClass('visible')
    Reimbursements.checkHeightPopup($(`#${idPopupCustom} .Normal-Booking`))
  }

  constructor(props) {
    super(props)
    this.defaultCustomReimbursements = props.customReimbursements
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnChangeCustom = this.handleOnChangeCustom.bind(this)
    this.handleOnBlurCustom = this.handleOnBlurCustom.bind(this)
  }

  handleOnChange(event, updateKey) {
    const {
      extraServiceActions,
      extraInfos
    } = this.props
    extraServiceActions.updateCompanySetting({ [updateKey]: event.target.checked })
    if (event.target.checked) {
      window.Moengage.track_event('Reimbursement Selected', {
        'Reimbursement Name': I18n.t(`webapp.new_booking.step_2.${_.snakeCase(updateKey)}`),
        Amount: 0,
        Quantity: 1,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  handleUpdateCustomReimbursement(id, dataReimbursementChange) {
    const {
      extraServiceActions, customReimbursements, extraInfos
    } = this.props
    extraServiceActions.updateCustomReimbursement(id, dataReimbursementChange)
    const userInputAmount = dataReimbursementChange?.user_input_amount
    const selectedAmount = dataReimbursementChange?.selected_amount
    const customReimbursement = customReimbursements.find(item => item.id === id)
    // Handle case checkbox or selectbox
    if (customReimbursement && !userInputAmount && +selectedAmount !== 0) {
      const amount = customReimbursement.amount * selectedAmount
      window.Moengage.track_event('Reimbursement Selected', {
        'Reimbursement ID': customReimbursement.id,
        'Reimbursement Name': customReimbursement.name,
        Amount: amount,
        Quantity: selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  handleOnBlurCustom() {
    const {
      customReimbursements, extraInfos
    } = this.props
    const changes = _.differenceWith(customReimbursements, this.defaultCustomReimbursements, _.isEqual)
    // Handle case input field
    const itemChanged = changes[0]
    const userInputAmount = itemChanged?.user_input_amount
    const selectedAmount = itemChanged?.selected_amount
    // On track customReimbursement with user input amount
    if (!_.isEmpty(itemChanged) && userInputAmount && userInputAmount !== '') {
      window.Moengage.track_event('Reimbursement Selected', {
        'Reimbursement ID': itemChanged.id,
        'Reimbursement Name': itemChanged.name,
        Amount: userInputAmount,
        Quantity: selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
      this.defaultCustomReimbursements = customReimbursements
    }
  }

  handleOnChangeCustom(id, dataReimbursementChange) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props

    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.handleUpdateCustomReimbursement.bind(this, id, dataReimbursementChange) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.handleUpdateCustomReimbursement(id, dataReimbursementChange)
    }
  }

  render() {
    const {
      extraInfos, companySettings, currentVehicleType,
      timeType, vehicleTypeSettings, customReimbursements
    } = this.props
    const isLonghaul = LONG_HAUL === timeType
    const reimbursementItems = {
      tolls: {
        key: 'allow_tolls_fees',
        enabled: vehicleTypeSettings.enable_tolls,
        checked: companySettings.allow_tolls_fees,
        locked: companySettings.lock_check_tolls_fees,
        popupKey: 'rb_tl'
      },
      parking: {
        key: 'allow_parking_fees',
        enabled: vehicleTypeSettings.enable_parking,
        checked: companySettings.allow_parking_fees,
        locked: companySettings.lock_check_parking_fees,
        popupKey: 'rb_pk'
      },
      waitingTime: {
        key: 'allow_waiting_time_fees',
        enabled: vehicleTypeSettings.enable_waiting_time,
        checked: companySettings.allow_waiting_time_fees,
        locked: companySettings.lock_check_waiting_time_fees,
        popupKey: isLonghaul ? 'vt_rb_wt_lh' : 'rb_wt'
      },
    }
    return (
      <ReimbursementList
        isFullday={FULL_DAY === timeType}
        isLonghaul={isLonghaul}
        isEnabledReimbursement={extraInfos.enable_parking_tolls_feature}
        vehicleType={currentVehicleType}
        handleOnChange={this.handleOnChange}
        handlePopupEye={Reimbursements.reimbursementPopupEyeHandler}
        reimbursementItems={reimbursementItems}
        customReimbursements={customReimbursements}
        handleOnChangeCustom={this.handleOnChangeCustom}
        handleOnBlurCustom={this.handleOnBlurCustom}
      />
    )
  }
}

Reimbursements.propTypes = {
  extraInfos: PropTypes.shape({}).isRequired,
  companySettings: PropTypes.shape({}).isRequired,
  extraServiceActions: PropTypes.shape({}).isRequired,
  currentVehicleType: PropTypes.shape({}),
  vehicleTypeSettings: PropTypes.shape({}),
  timeType: PropTypes.string,
  customReimbursements: PropTypes.arrayOf(PropTypes.shape({})),
  assignedDriver: PropTypes.shape({}),
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
}

Reimbursements.defaultProps = {
  currentVehicleType: undefined,
  vehicleTypeSettings: {},
  timeType: undefined,
  customReimbursements: [],
  assignedDriver: undefined,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reimbursements)
