import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {}

const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    updateBatches: (state, action: PayloadAction<any>) => action.payload.batches,
    updateBatchAttrs: (state, action: PayloadAction<any>) => {
      const currentBatches = [...state]
      const badge = _.find(currentBatches, (b) => b.id === action.payload.batch_id)
      if (!_.isUndefined(badge)) {
        _.assign(badge, action.payload.attrs)
      }
      return currentBatches
    },
    updateBatchBookingAttrsForBatches: (state, action: PayloadAction<any>) => {
      const currentBatches = [...state]
      const currentBatch = _.find(currentBatches, { id: action.payload.batch_id })
      if (!currentBatch) {
        return currentBatches
      }
      const bookings = currentBatch.batch_bookings ? [...currentBatch.batch_bookings] : [...currentBatch.bookings]
      const booking = _.find(bookings, (b) => b.batch_tracking_token === action.payload.attrs.batch_tracking_token)
      if (!_.isUndefined(booking)) {
        _.assign(booking, action.payload.attrs)
      }
      return currentBatches
    },
  },
  extraReducers: () => {},
})

export const batchesActionsCreators = batchesSlice.actions

export default batchesSlice
