import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''
const timeTypeSlice = createSlice({
  name: 'timeType',
  initialState,
  reducers: {
    changeTimeType: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const timeTypeActionsCreator = timeTypeSlice.actions

export default timeTypeSlice
