import { actionUpdateLocation } from './locationActionCreators'
import _ from 'lodash'
import { googleMarkersActionsCreator } from 'store/toolkit/newBooking/googleMarkers.reducer'
import { isOptimizedActionsCreator } from 'store/toolkit/newBooking/isOptimezed.reducer'


export const setCenterMap = (map, extraInfos) => {
  if (_.size(extraInfos.default_center_lat_lng)) {
    const latLng = extraInfos.default_center_lat_lng.split(',')
    map.setCenter(new window.google.maps.LatLng(latLng[0], latLng[1]))
    map.setZoom(15)
  }
}

export const addMarker = (googleMarker, map) => (dispatch, getState) => {
  const { location: geometryLocation } = googleMarker.geometry
  const marker = new window.google.maps.Marker({
    position: new window.google.maps.LatLng(geometryLocation.lat(), geometryLocation.lng()),
    map,
    optimized: false,
    icon: {
      url: googleMarker.icon,
      scaledSize: new window.google.maps.Size(26, 26),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(0, 0),
    },
    draggable: false,
  })

  window.google.maps.event.addListener(marker, 'click', (e) => {
    if (getState().currentStep === 1) {
      const { latLng } = e
      const location = getState().locations[0]
      if (location.marker) {
        location.marker.setMap(null)
        window.google.maps.event.clearListeners(location.marker, 'dragend')
      }
      const localMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(latLng.lat(), latLng.lng()),
        map,
        animation: window.google.maps.Animation.DROP,
        draggable: false,
      })
      const locationAttrs = {
        lat: latLng.lat(),
        lng: latLng.lng(),
        name: googleMarker.name,
        marker: localMarker,
        contact_id: undefined,
        address_components: undefined,
        is_store: true,
      }
      dispatch(actionUpdateLocation(location.id, locationAttrs, undefined, false))
      dispatch(isOptimizedActionsCreator.updateOptimizeState(false))
    }
  })
  _.assign(googleMarker, { marker })
  dispatch(googleMarkersActionsCreator.addGoogleType({ googleMarker }))
}

export const renderNearbyMarker = (config, map) => (dispatch, getState) => {
  const service = new window.google.maps.places.PlacesService(map)
  service.nearbySearch(config, (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      _.forEach(results, (marker) => {
        dispatch(addMarker(marker, map))
      })
      const extraInfos = getState().extraInfos
      setCenterMap(map, extraInfos)
    }
  })
}

export const removeNearbyMarker = () => (dispatch, getState) => {
  getState().googleMarkers.forEach((googleMarker) => {
    googleMarker.marker.setMap(null)
    dispatch(googleMarkersActionsCreator.removeGoogleType({ id: googleMarker.id || googleMarker.place_id }))
  })
}
