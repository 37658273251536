import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
import { autoGenerateObjectNameWithDate } from 'utils/common/batch'
import * as multipleBookingsHelper from 'utils/multiple_bookings/common'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
import { CPODUtils } from 'utils/booking/CPODUtils'
import { Utils } from 'utils/Utils'
// API
import LocationAPI from 'api/locations'
import batchesAPI from 'api/batches'
import discountCodeAPI from 'api/discountCode'
import CustomerAPI from 'api/customers'
// ACTIONS
import { getExtraServices } from './extraServicesActionCreators'
// COMPONENTS
// CONSTANTS
import {
  FULL_DAY,
  LONG_HAUL,
  NOW,
  IMMEDIATE,
  SCHEDULE,
} from 'constants/bookingConstants'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import {
  BATCH_VALIDATE,
} from 'constants/draftBookingConstants'
import { TallyUtils } from 'utils/booking/TallyUtils'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'
import { UPDATE_LOCATION_ID } from 'constants/multipleBookingConstants'
import { bookingsActionsCreator } from 'store/toolkit/bookings/bookings.reducer'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
// ASSETS

export const addBooking = booking => bookingsActionsCreator.addBookingMultiple(booking)

export const updateBookings = bookings => bookingsActionsCreator.updateBookings(bookings)

export const deleteBooking = id => bookingsActionsCreator.deleteBooking({
  id,
})

const checkDataChange = (attrs) => {
  let flag = false
  const dataKeys = ['time_type', 'service_type_id', 'vehicle_type_id']
  _.forEach(dataKeys, (item) => {
    if (_.includes(Object.keys(attrs), item)) {
      flag = true
    }
  })
  return flag
}

export const updateBooking = (id, attrs) => (dispatch, getState) => {
  const bookings = getState().bookings
  const currentStep = _.find(bookings, { id }).currentStep
  if (checkDataChange(attrs)) {
    _.assign(attrs, { dataChange: true })
  }

  dispatch(bookingsActionsCreator.updateBooking({ id, attrs }))

  if (Object.prototype.hasOwnProperty.call(attrs, 'currentStep')) {
    const inprocessBooking = _.find(getState().bookings, booking => booking.currentStep !== 3)
    const isGoNext = attrs.currentStep > currentStep
    if (isGoNext && _.isUndefined(inprocessBooking)) {
      // all cards in step 3
      dispatch(currentStepActionsCreator.changeStep(3))
    } else {
      // one card go back -> set global current step to 1
      dispatch(currentStepActionsCreator.changeStep(1))
    }
  }
}

export const updateLocations = (bookingID, locations) => (dispatch) => {
  dispatch(bookingsActionsCreator.updateLocations({
    bookingID,
    locations,
  }))
  // location change need to re-check out of service area or not
  dispatch(updateBooking(bookingID, { proceedSurcharge: false }))
}

export const updateLocation = (bookingID, id, attrs, needToReCheck = true) => (dispatch, getState) => {
  let isCheck = needToReCheck
  const booking = _.find(getState().bookings, { id: bookingID })
  if (booking.proceedSurcharge) {
    isCheck = false
  }
  const tempLocations = booking.locations_attributes
  dispatch(bookingsActionsCreator.updateLocation({
    bookingID,
    id,
    attrs,
  }))
  dispatch({
    type: UPDATE_LOCATION_ID,
    bookingID,
    id,
    attrs,
  })
  const pickup = _.first(tempLocations)
  if (id === pickup.id && booking.round_trip_discount && _.isUndefined(attrs.is_payer)) {
    const roundTripDiscountLocation = _.last(tempLocations)
    // Contact name & phone of round trip location should not be updated accordingly
    // when user change pickup's contact name & phone.
    if (!_.isUndefined(roundTripDiscountLocation.recipient_name)) {
      _.assign(attrs, { recipient_name: roundTripDiscountLocation.recipient_name })
    }
    if (!_.isUndefined(roundTripDiscountLocation.recipient_phone)) {
      _.assign(attrs, { recipient_phone: roundTripDiscountLocation.recipient_phone })
    }
    dispatch(bookingsActionsCreator.updateLocation({
      bookingID,
      id: roundTripDiscountLocation.id,
      attrs,
    }))
  }
  // location change need to re-check out of service area or not
  if (isCheck) {
    dispatch(updateBooking(bookingID, { proceedSurcharge: false }))
  }
}

export const deleteLocation = (bookingID, id) => (dispatch, getState) => {
  const state = getState()
  const customer = state.currentCustomer
  const booking = _.find(state.bookings, { id: bookingID })
  if (!_.isUndefined(booking)) {
    const location = _.find(booking.locations_attributes, { id })
    if (!_.isUndefined(location) && location.is_payer === true) {
      let payerIndex = 0
      if (customer.default_payer_is_destination === true) {
        _.assign(_.find(booking.locations_attributes, { is_payer: true }), { is_payer: false })
        payerIndex = booking.locations_attributes.length - 2
      }
      dispatch(updateLocation(bookingID, booking.locations_attributes[payerIndex].id, { is_payer: true }))
    }
  }
  dispatch(bookingsActionsCreator.deleteLocation({
    bookingID,
    id,
    dataChange: true
  }))
}

export const updateLocationLazyAddressError = (bookingID, locationId, lazyAddressError) => (dispatch) => {
  dispatch(bookingsActionsCreator.updateLazyAddressLocationMultiple({
    bookingID,
    locationId,
    lazyAddressError
  }))
}

export const updateExtraService = (type, bookingID, id, attrs) => ({
  type,
  bookingID,
  id,
  attrs,
})

export const updateAttachment = ({ bookingID, id, attrs }) => bookingsActionsCreator.updateAttachment({
  bookingID,
  id,
  attrs,
})

export const updateDocumentReturn = (bookingID, attrs) => bookingsActionsCreator.updateDocumentReturn({
  bookingID,
  attrs,
})

export const addLocation = (bookingID, attrs = {}) => (dispatch, getState) => {
  const state = getState()
  const customer = state.currentCustomer
  const booking = _.find(state.bookings, { id: bookingID })
  if (customer.default_payer_is_destination === true) {
    if (!_.isUndefined(booking)) {
      _.assign(_.find(booking.locations_attributes, { is_payer: true }), { is_payer: false })
    }
  }
  const location = {
    // when re-edit booking, uniqueId will generate the same id with existing id. It will generate '1' again
    id: Utils.uniqueId(),
    is_payer: customer.default_payer_is_destination,
    marker: undefined || attrs.marker,
    lat: undefined || attrs.lat,
    lng: undefined || attrs.lng,
    name: undefined || attrs.name,
    recipient_name: '' || attrs.recipient_name,
    recipient_phone: '' || attrs.recipient_phone,
    need_cod: false,
    need_pod: customer.check_pod_by_default,
    can_toggle_need_pod: customer.check_pod_by_default ? customer.can_toggle_pod : true,
    pod_note: '',
    cod_note: '',
    cod_invoice_fees: '',
    description: '' || attrs.description,
    phone_mask: attrs.phone_mask,
    address_components: attrs.address_components
  }
  const dataChange = true
  if (booking.round_trip_discount) {
    dispatch(bookingsActionsCreator.insertLocation({
      bookingID,
      location,
      dataChange
    }))
  } else {
    dispatch(bookingsActionsCreator.addLocation({
      bookingID,
      location,
      dataChange
    }))
  }
}

export const addNewBooking = (config = {}) => (dispatch, getState) => {
  const state = getState()
  const { currentCustomer, serviceTypes } = state
  const newBookingID = _.uniqueId()
  const currentServiceType = serviceTypes.find(service => service.is_default) || serviceTypes[0]
  const currentVehicleType = currentServiceType.vehicle_types[0]
  let initSelectedServiceTypeID = currentServiceType.id
  let initSelectedVehicleTypeID = currentVehicleType.id
  if (!_.isEmpty(config)) {
    if (currentVehicleType[config?.keyVehicleRemove]) {
      const currentServiceTypeIndex = multipleBookingsHelper.serviceTypeIndex(
        serviceTypes,
        initSelectedServiceTypeID
      )
      const isFirstIndex = currentServiceTypeIndex === 0
      initSelectedServiceTypeID = serviceTypes[isFirstIndex ? 1 : 0].id
      initSelectedVehicleTypeID = serviceTypes[isFirstIndex ? 1 : 0].vehicle_types[0].id
    }
  }
  const booking = {
    // add id for save booking as draft
    draft_id: '',
    // client side attributes
    currentStep: 1,
    // end client side attributes
    id: newBookingID,
    cash_back_reward: null,
    name: autoGenerateObjectNameWithDate(false),
    service_type_id: initSelectedServiceTypeID,
    vehicle_type_id: initSelectedVehicleTypeID,
    time_type: '',
    quick_choice_id: 0,
    send_first_to_favorite: state.extraInfos.sendToFavoriteFirstSetting,
    // fees for summary
    after_hour_service_fees: 0,
    business_credit_amount: 0,
    cod_pod_fees: 0,
    company_type_discount: 0,
    credit_amount: 0,
    discount_amount: 0,
    distance_fees: 0,
    extraServices: {},
    first_time_discount_message: undefined,
    free_waiting_time: 0,
    full_day_selected_amount: 0,
    is_post_payment: 0,
    out_of_service_area_fee: 0,
    subtotal: 0,
    surcharge_pricing_percent: 0,
    surcharge_pricing_title: undefined,
    surcharges_fees: 0,
    surcharges_adjustments: 0,
    has_surcharge: false,
    country_code: '',
    total_distance: 0,
    total_fees: 0,
    way_point_fees: 0,
    locationBeforeOptimize: [],
    expanded: false,
    isOptimized: false,
    require_signatures: state.requireSignatures,
    // end fees for summary
    locations_attributes: [
      {
        id: Utils.uniqueId(),
        marker: undefined,
        lat: undefined,
        lng: undefined,
        name: undefined,
        recipient_name: currentCustomer.name,
        recipient_phone: currentCustomer.phone,
        is_payer: !currentCustomer.default_payer_is_destination,
        need_cod: false,
        // Allow using POD COD at pickup time
        need_pod: currentCustomer.check_pod_by_default,
        can_toggle_need_pod: currentCustomer.check_pod_by_default ? currentCustomer.can_toggle_pod : true,
        pod_note: '',
        cod_note: '',
        cod_invoice_fees: ''
      },
      {
        id: Utils.uniqueId(),
        is_payer: currentCustomer.default_payer_is_destination,
        marker: undefined,
        lat: undefined,
        lng: undefined,
        name: undefined,
        recipient_name: '',
        recipient_phone: '',
        need_cod: false,
        need_pod: currentCustomer.check_pod_by_default,
        can_toggle_need_pod: currentCustomer.check_pod_by_default ? currentCustomer.can_toggle_pod : true,
        pod_note: '',
        cod_note: '',
        cod_invoice_fees: ''
      }
    ],
    booking_extra_requirements_attributes: [
      // {
      //   id: Utils.uniqueId(),
      //   extra_requirement_id: 1,
      //   selected_amount: 1,
      // }
    ],
    booking_badges_attributes: [
      // {
      //   id: Utils.uniqueId(),
      //   extra_requirement_id: 1,
      //   selected_amount: 1,
      // }
    ],
    booking_attachments_attributes: [
      // {
      //   id: Utils.uniqueId(),
      //   extra_requirement_id: 1,
      //   selected_amount: 1,
      // }
    ],
  }
  dispatch(bookingsActionsCreator.addBookingMultiple(booking))
}

export const buildExtraServices = (booking) => {
  let extraServices = {}
  let fullDayPricing = {}
  const vehicleTypeBadges = _.filter(booking.booking_badges, {
    badgeable_relation_type: 'VehicleTypeBadge'
  }).map(badge => _.assign(badge, { badgeable_relation_id: badge.id }))
  const companyBadges = _.filter(booking.booking_badges, {
    badgeable_relation_type: 'CompanyBadge'
  }).map(badge => _.assign(badge, { badgeable_relation_id: badge.id }))
  const companySettings = booking.reimbursements
  fullDayPricing = _.assign(fullDayPricing, { selected_amount: booking.fullday_selected_amount })
  extraServices = _.assign(extraServices,
    {
      vehicleTypeBadges,
      companyBadges,
      companySettings,
      fullDayPricing,
      extraRequirements: [],
      extraRequirementsNegativePosition: [],
    })
  return extraServices
}

export const addFromDraftBookings = (ids, addedBookings, sendToFavoriteFirstSetting) => (dispatch, getState) => {
  const serviceType = _.find(getState().serviceTypes, { is_default: true })
  const vehicleType = serviceType && serviceType.vehicle_types[0]
  ids.forEach((id) => {
    const newBookingID = _.uniqueId()
    let booking = { ...(_.find(addedBookings, { id })) }
    let locationsAttributes = []
    const extraServices = buildExtraServices(booking)
    const extraRequirements = _.filter(booking.extra_requirements, ex => ex.position >= 0)
      .map(ex => _.assign(ex, { extra_requirement_id: ex.id }))
    const extraRequirementsNegativePosition = _.filter(booking.extra_requirements, ex => ex.position < 0)
      .map(ex => _.assign(ex, { extra_requirement_id: ex.id }))

    booking.locations.forEach((location) => {
      let description = location.description
      if (booking.step === BATCH_VALIDATE) {
        description = location.location_note
      }
      locationsAttributes = _.concat(locationsAttributes,
        {
          id: Utils.uniqueId(),
          is_payer: location.is_payer,
          marker: undefined,
          googleMap: undefined,
          recipient_name: location.recipient_name,
          recipient_phone: location.recipient_phone,
          need_cod: location.need_cod,
          need_pod: location.need_pod,
          can_toggle_need_pod: location.can_toggle_need_pod,
          pod_note: location.pod_note,
          cod_note: location.cod_note,
          cod_invoice_fees: location.cod_invoice_fees,
          address_components: location.address_components,
          description,
          lat: location.lat,
          lng: location.lng,
          name: location.name,
          phone_mask: location.phone_mask,
          extra_requirement_locations: location.extra_requirement_locations
        })
    })
    if (booking.step === BATCH_VALIDATE) {
      booking.booking_attachments.forEach((attach) => {
        _.assign(attach, {
          document_url: attach.documentURL,
          document_content_type: attach.documentType,
          allow_to_delete: attach.allowToDelete,
          tmp_id: attach.tmpID,
        })
      })
    }
    const vehicleTypeID = booking.vehicle_type_id || (vehicleType && vehicleType.id)
    booking = _.assign(
      {},
      booking,
      {
        currentStep: 1,
        id: newBookingID,
        draft_id: booking.id,
        name: booking.name,
        time_type: booking.time_type,
        pickup_time: booking.pickup_time,
        quick_choice_id: _.toInteger(booking.quick_choice_id),
        fullday_selected_amount: booking.fullday_selected_amount || 1,
        marked_as_favorite: booking.marked_as_favorite,
        send_first_to_favorite: sendToFavoriteFirstSetting,
        sendFirstToFavorite: booking.send_first_to_favorite,
        discount_code: booking.discount_code,
        note: booking.note,
        job_order_number: booking.job_order_number || '',
        locations: undefined,
        locations_attributes: locationsAttributes,
        locationBeforeOptimize: [],
        isOptimized: booking.is_optimized,
        service_type_id: booking.service_type_id || (serviceType && serviceType.id),
        vehicle_type_id: vehicleTypeID,
        booking_attachments_attributes: booking.booking_attachments || [],
        extraServices,
        useDraftData: true,
        // mapping like book batch again (bookings)
        // check reducer bookings RECEIVE_EXTRA_SERVICES
        booking_extra_requirements: extraRequirements,
        booking_extra_requirements_negative_position: extraRequirementsNegativePosition,
        allow_parking_fees: booking.reimbursements.allow_parking_fees || false,
        allow_tolls_fees: booking.reimbursements.allow_tolls_fees || false,
        allow_waiting_time_fees: booking.reimbursements.allow_waiting_time_fees || false,
        // keep round trip when booking again
        round_trip_discount: booking.round_trip_discount || false,
        require_signatures: booking.require_signatures,
        booking_time_type: booking.time_type,
        preVehicleTypeId: vehicleTypeID // used for receive extra service
      }
    )
    if (booking.discount_code) {
      booking.discountCode = { status: DISCOUNT_CODE_STATUS.draft, value: booking.discount_code }
      delete booking.discount_code
    }
    dispatch(bookingsActionsCreator.addBookingMultiple(booking))
  })
}

export const duplicateBooking = id => (dispatch, getState) => {
  let booking = _.find(getState().bookings, { id })
  if (!_.isUndefined(booking)) {
    booking = _.assign(
      {},
      booking,
      {
        id: _.uniqueId(id),
        draft_id: '',
        googleMap: undefined,
        proceedSurcharge: false,
        originBookingID: booking.id,
        checkingStep: undefined,
        assignedDriver: undefined,
        assignedPickupTime: '',
        tmpSelectedDateAssignDriver: '',
        tmpSelectedTimeAssignDriver: ''
      }
    )
    const cloneBooking = JSON.parse(JSON.stringify(booking))
    dispatch(bookingsActionsCreator.addBookingMultiple(cloneBooking))
  }
}

// ============================================================

export const getAllExtraServices = () => (dispatch, getState) => Promise.all(
  getState().bookings.map(booking => Promise.resolve(dispatch(getExtraServices(booking))))
)

// ============================================================
function generatePayloadRequestForCalculateBooking(booking, state) {
  const discountCode = booking.invalid_discount_code === true ? undefined : booking.discount_code
  const validLocations = _.filter(booking.locations_attributes,
    location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
  let pickupTime = booking.pickup_time
  if (booking.assignedDriver && !_.isEmpty(booking.assignedPickupTime)) {
    pickupTime = booking.assignedPickupTime
  }
  // send totalDistance make sure calculated bookings won't be recalculate distance DLVR-10944
  let totalDistance = _.toNumber(booking.total_distance)
  totalDistance = _.isNaN(totalDistance) || totalDistance === 0 ? undefined : totalDistance
  const {
    fullDayPricing = {},
    customReimbursements = []
  } = booking.extraServices

  const { locations_attributes: locationsAttrs, outOfServiceStatus } = booking
  const bookingTrackingAttr = CPODUtils.validateParamsDocumentReturn(locationsAttrs, outOfServiceStatus)
    ? CPODUtils.validParamsBeforeCallAPI(booking.documentReturn)
    : null

  const extraRequirementsNegativePosition = _.filter(
    booking.extraServices.extraRequirementsNegativePosition || [],
    { selected: true },
  )
  return {
    vehicleTypeID: booking.vehicle_type_id,
    locations: validLocations.map(location => ({
      latitude: location.lat,
      longitude: location.lng,
      need_cod: location.need_cod,
      need_pod: location.need_pod,
      cod_invoice_fees: location.cod_invoice_fees,
      name: location.name,
      extra_requirement_locations: location.extra_requirement_locations
    })),
    currentCustomer: state.currentCustomer,
    extraInfos: state.extraInfos,
    extraRequirements: _.filter(booking.extraServices.extraRequirements, { selected: true }),
    extraRequirementsNegativePosition,
    timeType: booking.time_type,
    pickupTime,
    fullDaySelectedAmount: fullDayPricing.selected_amount || 1,
    discountCode,
    roundTripDiscount: booking.round_trip_discount,
    totalDistance,
    ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),
    bookingTrackingAttr,
    customReimbursements,
    use_credit: _.isUndefined(booking.use_credit) ? null : booking.use_credit
  }
}

export const calculate = (id = undefined) => (dispatch, getState) => {
  const state = getState()
  const bookings = _.filter(state.bookings,
    bookingFilter => bookingFilter.currentStep === 3 || (!_.isUndefined(id) && bookingFilter.id === id))
  if (_.isEmpty(bookings)) {
    return Promise.resolve([])
  }
  const params = bookings.map(bookingMap => generatePayloadRequestForCalculateBooking(bookingMap, state))
  return new Promise(resolve => batchesAPI.calculate(params, (response) => {
    if (response?.error) {
      toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return null
    }
    const bookingsCalculated = response.bookings.data
    const footNote = response.footnote_for_batch
    Promise.all(
      bookings.map((bk, index) => {
        const totalFeesOld = !_.isEmpty(bk) ? bk.total_fees : 0
        const subtotalFeesOld = !_.isEmpty(bk) ? bk.subtotal : 0
        const totalFees = (!_.isEmpty(bookingsCalculated) && !_.isEmpty(bookingsCalculated[index]))
          ? bookingsCalculated[index].total_fees : 0
        const subtotalFees = (!_.isEmpty(bookingsCalculated) && !_.isEmpty(bookingsCalculated[index]))
          ? bookingsCalculated[index].subtotal : 0
        response.bookings.data[index].has_change_price = ((totalFees !== totalFeesOld
          || subtotalFees !== subtotalFeesOld) && bk.id === id)
        const attrs = {
          total_distance: bookingsCalculated[index].total_distance,
          distance_fees: bookingsCalculated[index].distance_fees,
          full_day_selected_amount: bookingsCalculated[index].full_day_selected_amount,
          way_point_fees: bookingsCalculated[index].way_point_fees,
          out_of_service_area_fee: bookingsCalculated[index].out_of_service_area_fee,
          cod_pod_fees: bookingsCalculated[index].cod_pod_fees,
          new_gen_pod: bookingsCalculated[index].new_gen_pod,
          surcharge_pricing_title: bookingsCalculated[index].surcharge_pricing_title,
          surcharge_pricing_percent: bookingsCalculated[index].surcharge_pricing_percent,
          surcharges_fees: bookingsCalculated[index].surcharges_fees,
          surcharges_adjustments: bookingsCalculated[index].surcharges_adjustments,
          has_surcharge: bookingsCalculated[index].has_surcharge,
          country_code: bookingsCalculated[index].country_code,
          after_hour_service_fees: bookingsCalculated[index].after_hour_service_fees,
          discount_amount: bookingsCalculated[index].discount_amount,
          credit_amount: bookingsCalculated[index].credit_amount,
          company_type_discount: bookingsCalculated[index].company_type_discount,
          business_credit_amount: bookingsCalculated[index].business_credit_amount,
          subtotal: bookingsCalculated[index].subtotal,
          first_time_discount_message: bookingsCalculated[index].first_time_discount_message,
          is_post_payment: bookingsCalculated[index].is_post_payment,
          total_fees: bookingsCalculated[index].total_fees,
          display_total_fees: bookingsCalculated[index].display_total_fees,
          free_waiting_time: bookingsCalculated[index].free_waiting_time,
          currency: bookingsCalculated[index].currency,
          discount_id: bookingsCalculated[index].discount_id,
          round_trip_discount_amount: bookingsCalculated[index].round_trip_discount_amount,
          footnote_for_batch: footNote,
          custom_reimbursements_info: bookingsCalculated[index].custom_reimbursements_info,
          custom_reimbursements: bookingsCalculated[index].custom_reimbursements,
          show_tbd: bookingsCalculated[index].show_tbd,
          distance_fee_details: bookingsCalculated[index].distance_fee_details,
          special_adjustment_dynamic_text: bookingsCalculated[index].special_adjustment_dynamic_text,
          demand_adjustment_dynamic_text: bookingsCalculated[index].demand_adjustment_dynamic_text,
          cash_back_amount: bookingsCalculated[index].cash_back_amount,
          cashback_credit_used: bookingsCalculated[index].cashback_credit_used,
          use_credit: _.get(bookingsCalculated[index], 'use_credit', false),
          free_reimbursement_max_cap: bookingsCalculated[index].free_reimbursement_max_cap,
          free_reimbursements: bookingsCalculated[index].free_reimbursements
        }
        return Promise.resolve(dispatch(updateBooking(bk.id, attrs)))
      })
    ).then(() => {
      resolve(response)
    })
    return true
  }))
}


export const calculateCODPODFees = (
  id,
  locations,
  {
    time_type: timeType,
    vehicle_type_id: vehicleTypeID,
    documentReturn: cloneDocumentReturn = {},
    outOfServiceStatus,
    bookAgainDetails,
  }
) => (dispatch, getState) => {
  let isSwitchLongHaul = false

  if (_.size(outOfServiceStatus) > 0) {
    const {
      long_haul_address_valid: longHaulValid = false,
      long_haul_pickup: { is_valid: isValidLH = false } = {}
    } = outOfServiceStatus

    isSwitchLongHaul = longHaulValid && isValidLH
  }

  if (timeType === LONG_HAUL || isSwitchLongHaul) {
    dispatch(updateBooking(id, { locations_attributes: locations }))
    return true
  }

  const newGenPOD = CPODUtils.verifyNewGenCPOD({
    bookAgainDetails,
    checkLocations: outOfServiceStatus
  })

  const params = CPODUtils.validateParamsCalculateFees({
    locations,
    companyTypeID: getState().currentCustomer.current_company_type_id || undefined,
    timeType,
    vehicleTypeID,
    cloneDocumentReturn,
    areaID: getState().extraInfos.area_id || getState().currentCustomer.area_id,
    newGenPOD,
  })

  const validLocationsPOD = !!_.find(locations, ['need_pod', true])
  let verify = false
  const invalidKeys = CPODUtils.invalidDocumentReturnAddress({
    recipient_name: cloneDocumentReturn.recipient_name,
    recipient_phone: cloneDocumentReturn.recipient_phone,
    address_1: cloneDocumentReturn.address_1,
    city: cloneDocumentReturn.city,
    state: cloneDocumentReturn.state,
    postal_code: cloneDocumentReturn.postal_code,
    latitude: cloneDocumentReturn.latitude,
    longitude: cloneDocumentReturn.longitude,
  }, CPODUtils.keys())
  if (_.size(invalidKeys)) {
    verify = true
  }
  const shouldNotCallCPOD = newGenPOD && (!validLocationsPOD || verify)

  if ((params.locations && !params.company_type_id) || shouldNotCallCPOD) {
    return true
  }

  return new Promise(resolve => (
    LocationAPI.calculateCODPODFees(
      params,
      getState().currentCustomer.authentication_token,
      (response) => {
        resolve(dispatch(updateBooking(
          id,
          {
            locations_attributes: locations,
            cod_pod_fees: response.fees
          }
        )))
      }
    )
  ))
}

export const resetBookingFees = (id, locations) => (dispatch) => {
  dispatch(updateBooking(
    id,
    {
      locations_attributes: locations,
      cod_pod_fees: 0
    }
  ))
}

export const resetStep1Confirmations = () => (dispatch, getState) => {
  _.each(getState().bookings, (booking) => {
    dispatch(updateBooking(
      booking.id,
      { proceedSurcharge: false, outOfServiceStatus: undefined, continueFullDay: false }
    ))
  })
}

export const markDiscountCodeAsDraft = booking => (dispatch) => {
  const { discountCode = {} } = booking
  dispatch(updateBooking(booking.id, { discountCode: { ...discountCode, status: DISCOUNT_CODE_STATUS.draft } }))
}

function prepareFulldayExtraServiceForBookingAgain(extraRequirementsNegativePosition) {
  if (!extraRequirementsNegativePosition) {
    return extraRequirementsNegativePosition
  }

  return extraRequirementsNegativePosition.map((extra) => {
    if (extra.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS) {
      const pricing = _.find(
        extra.pricings, { id: extra.extra_requirement_pricing_id }
      )

      return {
        ...extra,
        selectedPricing: pricing,
        selected: pricing !== undefined,
        selected_amount: extra.selected_amount
      }
    }

    return {
      ...extra,
      selected: true,
      selected_amount: extra.selected_amount,
    }
  })
}

export const buildBatchMultipleAgain = (
  bookings, extraInfos, currentStep, currentCustomer, oldBookings = []
) => (dispatch) => {
  const newBookings = []
  _.forEach(bookings, (booking) => {
    const newBooking = { ...booking }
    const newLocations = []
    const locations = newBooking.locations || newBooking.locations_attributes
    _.forEach(locations, (location) => {
      let phoneMask
      if (location.is_phone_mask) {
        phoneMask = I18n.t('contacts.labels.not_show_phone')
      }
      const newLocation = { ...location }
      _.assign(newLocation, {
        id: Utils.uniqueId(),
        lat: newLocation.latitude,
        lng: newLocation.longitude,
        can_toggle_need_pod: currentCustomer.check_pod_by_default ? currentCustomer.can_toggle_pod : true,
        phone_mask: phoneMask,
        extra_requirement_locations: location.extra_requirement_locations
      })
      newLocations.push(newLocation)
    })
    _.assign(newBooking, {
      id: _.uniqueId(),
      currentStep,
      locations_attributes: newLocations,
      booking_attachments_attributes: [],
      note: '',
      job_order_number: '',
      marked_as_favorite: false,
      send_first_to_favorite: extraInfos.sendToFavoriteFirstSetting,
      sendFirstToFavorite: booking.send_first_to_favorite,
      // We expect pickup_time to be either second-based unix timestamp or string datetime value
      pickup_time: _.isFinite(newBooking.pickup_time)
        ? moment.unix(newBooking.pickup_time).format()
        : moment(newBooking.pickup_time).format(),
      useDataAgain: multipleBookingsHelper.isBookMultipleAgain(),
      locationBeforeOptimize: [],
      extraServices: {},
      booking_time_type: booking.time_type,
      use_credit: null,
      preVehicleTypeId: booking.vehicle_type_id // used for receive extra service
    })
    if (newBooking.discount_code_info) {
      newBooking.discountCode = { value: newBooking.discount_code_info, status: DISCOUNT_CODE_STATUS.draft }
    }

    newBooking.booking_extra_requirements_negative_position = prepareFulldayExtraServiceForBookingAgain(
      newBooking.booking_extra_requirements_negative_position,
    )
    newBookings.push(newBooking)
  })
  dispatch(bookingsActionsCreator.updateBookings(oldBookings.concat(newBookings)))
}

export const updateRequireSignatures = (id, value) => (dispatch) => {
  const attrs = {
    require_signatures: value
  }
  dispatch(bookingsActionsCreator.updateBooking({ id, attrs }))
}

export const updateSubAccountCheckBoxMultiple = (id, value) => (dispatch) => {
  const attrs = {
    isCheckSubAccount: value
  }
  dispatch(bookingsActionsCreator.updateBooking({ id, attrs }))
}

export const addSubAccountMultiple = (id, data) => (dispatch) => {
  const attrs = {
    sub_account_tag: data
  }
  dispatch(bookingsActionsCreator.updateBooking({ id, attrs }))
}

/**
 * for step2 we get data from extra-service and calculate it locally.
 *  we don't want to trigger api call to reduce bandwidth
 * But there is an disadvantage here is we have to update webapp code
 *    whenever we need to change the related business logic
 */
export const calculateExtraServicesPrice = ({ booking, dispatch }) => {
  const {
    time_type: timeType,
    bookAgainDetails,
    outOfServiceStatus,
    locations_attributes: locations,
    extraServices,
  } = booking
  const price = TallyUtils.calculateExtraServicesPrice(
    {
      extraServices,
      booking,
      timeType,
      bookAgainDetails,
      outOfServiceStatus,
      locations,
    }
  )

  return Promise.resolve(dispatch(bookingsActionsCreator.updateTallyData({
    bookingID: booking.id,
    attrs: {
      step2Price: price,
    }
  })))
}

export const resetTallyDataStep2 = booking => dispatch => (
  Promise.all([
    dispatch(bookingsActionsCreator.updateTallyData({
      bookingID: booking.id,
      attrs: { step2Price: 0 }
    })),
    dispatch(bookingsActionsCreator.updateFullDayPricing({
      bookingID: booking.id,
      id: null,
      attrs: {
        selected_amount: 1
      },
    })),
    // TODO: reset extra service for LONG_HAUL, we will implement in Long Haul project
  ])
)

export const getTallyTransitTime = ({ booking, isValidLH = false }) => (dispatch, getState) => {
  if (isValidLH) return null
  const state = getState()
  const params = generatePayloadRequestForCalculateBooking(booking, state)

  return new Promise((resolve) => {
    batchesAPI.getTallyTransitTime({ bookingParams: params, state: getState(), isValidLH }, (response) => {
      const value = _.defaults(
        _.pick(response, ['transit_time', 'worst_transit_time']),
        {
          transit_time: null,
          worst_transit_time: null,
        },
      )

      resolve(dispatch(bookingsActionsCreator.updateTallyData({
        bookingID: booking.id,
        attrs: value
      })))
    })
  })
}

export const getTallyData = ({
  booking, isLocationChanged, specificStep, isValidLH = false
}, isGetRoutes = false, callback = () => { }) => (dispatch, getState) => {
  if (+specificStep === 2) {
    return calculateExtraServicesPrice({ booking, dispatch })
  }

  const state = getState()
  const params = generatePayloadRequestForCalculateBooking(booking, state)
  const only = isGetRoutes ? 'routes' : ''

  return new Promise(resolve => (
    batchesAPI.getTallyData({
      bookingParams: params,
      isLocationChanged,
      specificStep,
      isValidLH,
      only,
    }, (response) => {
      let numberLocation = 0
      // we need to store eta_locations_id into booking and use it to send back to server for server optimisation
      const value = _.defaults(
        _.pick(
          response.object,
          ['total_distance', 'transit_time', 'worst_transit_time', 'out_of_service_area_fee', 'is_out_of_service', 'eta_locations_id', 'routes'],
        ),
        {
          worst_transit_time: null,
          is_out_of_service: false,
          out_of_service_area_fee: 0,
        }
      )

      // for FULL_DAY, we get transit time from seperate api
      if (booking.time_type === FULL_DAY) {
        const { locations_attributes: locationsAttributes } = booking
        _.map(locationsAttributes, (item) => {
          if (!_.isUndefined(item.lat) && !_.isUndefined(item.lng) && item.name) {
            numberLocation += 1
          }
        })
        if (numberLocation > 1) {
          delete value.transit_time
          delete value.worst_transit_time
        }
      }

      // If we specify step, we are requesting for step-specific price
      // otherwise is for total price
      if (specificStep) {
        value[`step${specificStep}Price`] = response.object?.subtotal
        value[`step${specificStep}OutOfServiceAreaFee`] = response.object?.out_of_service_area_fee || 0
      } else {
        value.subtotal = response.object?.subtotal
      }
      if (isGetRoutes) {
        callback(value)
      } else {
        resolve(dispatch(bookingsActionsCreator.updateTallyData({
          bookingID: booking.id,
          attrs: value
        })))
      }
    })
  ))
}

export const validateLocations = (booking, currentCustomer) => (dispatch, getState) => {
  const state = getState()
  const locations = booking.locations_attributes

  const timeType = booking.time_type || booking.display_time_type

  const filteredLocations = locations.filter(({ lat, lng }) => (lat && lng))
  if ((!filteredLocations || filteredLocations.length < 2) && timeType !== FULL_DAY) {
    return Promise.resolve(
      dispatch(updateBooking(booking.id, { acceptableTimeTypes: [LONG_HAUL, NOW, IMMEDIATE, SCHEDULE, FULL_DAY] }))
    )
  }

  const {
    extraInfos
  } = state

  return new Promise((resolve) => {
    LocationAPI.validLocations(
      locations,
      { timeType: booking.time_type, serviceTypeID: booking.service_type_id, vehicleTypeID: booking.vehicle_type_id },
      {
        countryCode: currentCustomer.country_code || extraInfos?.country_code,
        companyId: currentCustomer.current_company_id,
        authenticationToken: currentCustomer.authentication_token,
        isLogin: currentCustomer.id,
      },
      null,
      (res) => {
        // const acceptableTimeTypes = locationUtils.determineAcceptableTimeTypes(res)
        // TODO: we temporarily disable auto hide time type according to locations until new LONG HAUL feature
        const acceptableTimeTypes = [LONG_HAUL, NOW, IMMEDIATE, SCHEDULE, FULL_DAY]
        Promise
          .resolve(
            dispatch(updateBooking(booking.id, { acceptableTimeTypes }))
          )
          .then(() => resolve(res))
      },
    )
  })
}

export const checkDiscountCode = (value, booking) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos, currentCustomer } = state
  return new Promise((resolve) => {
    if (_.isEmpty(value)) {
      resolve({})
    } else {
      discountCodeAPI.checkDiscountCode(
        value,
        // TODO:
        currentCustomer.current_company_id || 0,
        booking.service_type_id,
        booking.locations_attributes,
        extraInfos.device_id,
        currentCustomer.authentication_token,
        booking.id,
        (response) => {
          resolve(response)
        }
      )
    }
  })
}

export const setDiscountCodeStatus = (status, booking) => (dispatch) => {
  const { discountCode = {} } = booking
  dispatch(updateBooking(booking.id, { discountCode: { ...discountCode, status } }))
}

export const applyDiscountCodeToBooking = (discountCode, booking) => dispatch => (
  Promise
    .resolve(dispatch(updateBooking(booking.id, { discountCode: { status: DISCOUNT_CODE_STATUS.checking } })))
    .then(() => (dispatch(checkDiscountCode(discountCode, booking))))
    .then((response) => {
      const status = response.discount_invalid ? DISCOUNT_CODE_STATUS.invalid : DISCOUNT_CODE_STATUS.valid

      if (status === DISCOUNT_CODE_STATUS.valid) {
        return Promise.resolve(
          dispatch(
            updateBooking(booking.id, { discount_code: discountCode, discountCode: { value: discountCode, status } })
          )
        ).then(() => dispatch(calculate(booking.id)))
      }

      return dispatch(
        updateBooking(
          booking.id,
          {
            discount_code: discountCode,
            discountCode: { value: discountCode, status, invalidMessage: response.discount_invalid_message },
          }
        )
      )
    })
    .catch(() => dispatch(
      updateBooking(booking.id, { discountCode: { status: DISCOUNT_CODE_STATUS.pending } })
    ))
)

export const removeDiscountCodeFromBooking = booking => dispatch => (
  Promise
    .resolve(dispatch(
      updateBooking(booking.id, { discount_code: '', discountCode: { value: '', status: DISCOUNT_CODE_STATUS.pending } })
    ))
    .then(dispatch(calculate(booking.id)))
)

export const addMultipleFavDrivers = (assignDrivers, cb) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  Promise.all(
    assignDrivers.map((driver) => {
      const companyID = currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id
      const authenticationToken = currentCustomer.authentication_token
      let params = {
        'customer_drivers_attributes': [
          {
            item_type: DRIVER_PREFERENCES.favorite,
            driver_id: driver.id,
            company_id: companyID
          }
        ]
      }
      if (driver.fleet_partner_id) {
        params = {
          fleet_partner_id: driver.fleet_partner_id,
          driver_id: driver.id,
          company_id: companyID
        }
        return CustomerAPI.addMultipleFavoriteFleetDriver(authenticationToken, params)
      }

      return CustomerAPI.addMultipleFavoriteDriver(authenticationToken, params)
    })
  ).then(() => {
    if (typeof cb === 'function') {
      cb()
    }
  })
}

export const updatePopupIdBooking = ({ bookingID, popupID }) => bookingsActionsCreator.updatePopupIdBooking({
  bookingID,
  popupID
})
