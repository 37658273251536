import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const prevOptimizeRouteSlice = createSlice({
  name: 'prevOptimizeRoute',
  initialState,
  reducers: {
    updatePrevOptimizeRoute: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const prevOptimizeRouteActionsCreator = prevOptimizeRouteSlice.actions

export default prevOptimizeRouteSlice
