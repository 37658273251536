import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = null
const selectedVehicleTypeIDSlice = createSlice({
  name: 'selectedVehicleTypeID',
  initialState,
  reducers: {
    setSelectedVehicleTypeId: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const selectedVehicleTypeIDActionsCreator = selectedVehicleTypeIDSlice.actions

export default selectedVehicleTypeIDSlice
