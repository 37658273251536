import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'

// ASSETS
import {
  V2_ICON_GREEN,
  V2_ICON_GREEN_TRANSPORTIFY
} from 'constants/imageConstants'
import { MAX_SIZE_OF_FILE } from 'constants/newBookingConstants'
import { isUndefined } from 'lodash'

class AttachmentPopup extends React.PureComponent {
  render() {
    const { fixed } = this.props
    const icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    const iconSize = 'icon-modified-style'
    const extraStyle = !isUndefined(fixed) ? fixed : ''
    const fixedPopupStyle = !isUndefined(fixed) ? 'Popup-Normal-Booking' : ''
    const className = `${extraStyle} visible vertical-scroll Modal modal-outOfService Modal-No-action`
    const maxFileSize = MAX_SIZE_OF_FILE / 1000000
    const content = I18n.t('webapp.new_booking.step_2.max_file_size', { n: maxFileSize })
    return (
      <div className={className} id="attachment_popup_common">
        <div className={`Normal-Booking ${fixedPopupStyle}`}>
          <div className="Box Modified-Popup">
            <div className="Box-Icon">
              <img src={icon} alt="icon" className={iconSize} />
            </div>
            <div className="Box-Content">
              <div className="Normal">
                <div>
                  <p className="center pre-line" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AttachmentPopup.defaultProps = {
  fixed: undefined
}

AttachmentPopup.propTypes = {
  fixed: PropTypes.string
}

export default connect()(AttachmentPopup)
