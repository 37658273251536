import apiClient from 'services/axiosApp'

const SubAccountAPI = {
  getSubAccountTagList: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/sub_accounts', {
        params,
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(res || {})
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default SubAccountAPI
