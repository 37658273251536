import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// UTILS
// ACTIONS
// COMPONENTS

class DynamicPopupContent extends React.PureComponent {
  renderPopupItem(item, index) {
    const { popupConst } = this.props
    switch (item.item_type) {
      case popupConst.item_content: {
        return (
          <div key={index}>
            <p className="center fullday reset m pb15" key={index} dangerouslySetInnerHTML={{ __html: item.formated_content }} />
          </div>
        )
      }
      default:
        return <span key={index} />
    }
  }

  renderPopupItems(items) {
    return items.map((item, index) => this.renderPopupItem(item, index))
  }

  render() {
    const { popupData, popupConst } = this.props
    const selectItemTypes = [
      popupConst.item_title,
      popupConst.item_content,
    ]
    const normalItems = _.filter(
      popupData.popup_items,
      item => _.includes(selectItemTypes, item.item_type)
    )
    return (
      <div>
        {this.renderPopupItems(normalItems)}
      </div>
    )
  }
}

DynamicPopupContent.propTypes = {
  popupData: PropTypes.shape({}),
  popupConst: PropTypes.shape({}),
}

DynamicPopupContent.defaultProps = {
  popupData: {},
  popupConst: {},
}

export default DynamicPopupContent
