import _ from 'lodash'
// FOR POPUPS
export const findPopup = (popupableObject, popupType) => {
  if (_.isEmpty(popupableObject)) {
    return undefined
  }
  return _.find(popupableObject.popups, { popup_type: popupType })
}

export const havePopup = (popupableObject, popupType) => {
  if (_.isEmpty(popupableObject)) {
    return false
  }
  return !_.isEmpty(findPopup(popupableObject, popupType))
}

export const findPopupItem = (popup, popupItemType) => {
  if (_.isEmpty(popup)) {
    return undefined
  }
  return _.find(popup.popup_items, { item_type: popupItemType })
}
