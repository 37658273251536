import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState: any = {}

const popupCodSlice = createSlice({
  name: 'popupCod',
  initialState,
  reducers: {
    popupCOD: (state, action: PayloadAction<any>) => {
      return action.payload ? action.payload.cod_popup_content : []
    },
  },
  extraReducers: () => {},
})

export const popupCodActionsCreator = popupCodSlice.actions

export default popupCodSlice
