import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Util
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'

// Action
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
// Constant
import { renderIconTimeType } from 'utils/booking/common'
import ClosuresUtils from 'utils/booking/ClosuresUtils'
import StorageKeys from 'constants/storage-keys'
import CustomerAPI from 'api/customers'
import { withNavigate } from 'hocs/withNavigate'

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch)
  }
}

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer
})

class MiniTooltip extends React.Component {
  static isChangedTallyDataBooking(oldBooking, booking) {
    const isTallyEnable = Utils.isTallyEnable(booking)
    return (
      !_.isEqual(oldBooking, booking)
      && isTallyEnable
      && (
        _.isEmpty(oldBooking)
        || !_.isEqual(oldBooking.current_estimate_values, booking.current_estimate_values)
        || oldBooking.current_estimate_status !== booking.current_estimate_status
        || oldBooking.last_drop_off_arrived_at !== booking.last_drop_off_arrived_at
      )
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      booking: {},
    }
    this.setIntervalApi = null
  }

  componentDidMount() {
    const self = this
    self.updateBookingInterval()
    this.setIntervalApi = setInterval(() => {
      self.updateBookingInterval()
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.setIntervalApi)
  }

  updateBookingInterval() {
    const params = { 'include': ['reimbursements'], version: 2 }
    const { currentCustomer } = this.props
    const isAvailableCurrentCustomer = !_.isEmpty(currentCustomer.authentication_token)
    if (_.parseInt(currentCustomer.current_company_id) > 0) {
      _.assign(params, {
        'company_ids': [currentCustomer.current_company_id]
      })
    }
    if (isAvailableCurrentCustomer) {
      CustomerAPI.getBookingEarliest(params, (res) => {
        this.setState({ booking: res })
      })
    }
  }


  render() {
    const { booking } = this.state
    if (_.isEmpty(booking)) { return null }
    const timeTypeImage = renderIconTimeType(booking.time_type)
    const titleInfo = booking.is_shopping
      ? `${I18n.t('webapp.shopping.title')}`
      : `${ClosuresUtils.showBookingStatusText(booking)}`
    return (
      <div
        className="Block-MiniTooltip Box-Sizing Border-Box Radius-default absoute cur-pointer pt10 pb10 pr15 pl15 flex flex-index"
        onClick={() => { window.location.href = `/bookings/${booking.id}` }}
      >
        <div className="flex-index flex-start mr10">
          <img src={timeTypeImage} alt="block tooltip" height="30" />
          <div className="ml10 flex flex-column">
            <span className="default-font Yellow-text default-medium-font">
              {I18n.t('webapp.tally.ongoing_booking')}
            </span>
            <span className="default-font White-text default-medium-font">
              {booking.id}
              <span className="dot-separate" />
              {titleInfo}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

MiniTooltip.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(MiniTooltip))
