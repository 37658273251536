import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {}

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    bookingUpdate: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload.booking }),
    resetBooking: () => {},
    bookingUpdateTally: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload.value
      }
    },
    bookingUpdateCODFee: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload.value }
    },
    getHandlingUnitSuccess: (state, action: PayloadAction<any>) => {
      state.handlingUnitData = action.payload.value?.data
    },
    getConfigDataSuccess: (state, action: PayloadAction<any>) => {
      state.configLTLData = action.payload.value?.data
    },
    getShipmentLTLDataSuccess: (state, action: PayloadAction<any>) => {
      state.shipmentLTLDetail = action.payload.value?.data
    },
    updateStatusOutServicePartialLoad: (state, action: PayloadAction<any>) => {
      state.isOutServiceLTL = action.payload.value
    },
    updateRequirementNotMetReason: (state, action: PayloadAction<any>) => {
      state.reasonReqNotMet = action.payload
    },
    receiveFavoriteDrivers: (state: any, action: PayloadAction<any>) => {
      const { drivers, recentDriverID, pagination, loadingMore } = action.payload
      const favoriteDrivers = _.filter(drivers, ({ id }) => id !== recentDriverID)
      const stateFavoriteDriver = loadingMore ? state.favoriteDrivers : []
      const recentDriver = _.find(drivers, (driver) => driver.id === recentDriverID)
      let newState = {
        ...state,
        favoriteDrivers: [...stateFavoriteDriver, ...favoriteDrivers],
        pagination,
      }

      if (!loadingMore) {
        newState = {
          ...newState,
          recentDriver: recentDriver || null,
        }
      }

      return newState
    },
    updateOutSideList: (state, action: PayloadAction<any>) => {
      state.outsideList = action.payload.payload
    },
    bookingUpdateCashBackAmount: (state, action: PayloadAction<any>) => {
      state.cash_back_amount = action.payload
    },
    getBookingSuccess: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
    updateLocationSuccess: (state, action: PayloadAction<any>) => {
      const { locationId, payload } = action.payload
      state.locations = state.locations.map((location:any) => (location.id === locationId
        ? ({ ...location, description: payload }) : location))
    },
    signInProgressFinished: (state, action: PayloadAction<any>) => {
      state.isSignInFinished=action.payload
    },
  },
  extraReducers: () => {},
})

export const bookingActionsCreator = bookingSlice.actions

export default bookingSlice
