// UTILS
import I18n from 'i18n/i18n'
// API
import ContactsAPI from 'api/contacts'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { BUSINESS_PROGRAM, PERSONAL } from 'constants/common/contactConstants'
import { contactsActionsCreator } from 'store/toolkit/contacts/contacts.reducer'
import { personalContactsActionsCreator } from 'store/toolkit/personalContacts/personalContacts.reducer'
import { pickupContactsActionsCreator } from 'store/toolkit/pickupContacts/pickupContacts.reducer'
import { EZ_SHEET_UPLOAD } from 'constants/common/batchConstants'
import { pickupContactsMeFromLocationActionsCreator } from 'store/toolkit/pickupContactsMeFromLocation/pickupContactsMeFromLocation.reducer'
// ASSETS


export const addNewContact = contact => contactsActionsCreator.addNewContact(contact)

export const addNewPersonalContact = contact => personalContactsActionsCreator.addNewPersonalContact(contact)

export const updateContacts = contacts => contactsActionsCreator.updateContacts({ contacts })

export const updatePersonalContacts = personalContacts => personalContactsActionsCreator.updatePersonalContacts(personalContacts)

export const updatePickupContacts = pickupContacts => pickupContactsActionsCreator.updatePickupContacts(pickupContacts)

export const deleteContactLocal = id => contactsActionsCreator.deleteContactLocal(id)

export const deletePersonalContact = id => personalContactsActionsCreator.deletePersonalContact(id)

const deletePickupContactLocal = id => pickupContactsActionsCreator.deletePickupContactLocal(id)

export const updateSingleContact = (id, attrs) => contactsActionsCreator.updateSingleContact({
  id,
  attrs
})

export const updateSinglePersonalContact = (id, attrs) => personalContactsActionsCreator.updateSinglePersonalContact({
  id,
  attrs
})

export const buildPickupContacts = (showPhoneNumberToDriver, pickupContacts) => () => {
  pickupContacts.map((pickupContact) => {
    if (!showPhoneNumberToDriver && pickupContact.is_phone_mask) {
      _.assign(pickupContact, {
        phone_mask: I18n.t('contacts.labels.not_show_phone')
      })
    }
    return pickupContact
  })
  return pickupContacts
}

export const createPickupContact = callback => async (dispatch, getState) => {
  const contact = {
    name: getState().newContact.name,
    phone: getState().newContact.phone,
    address: getState().newContact.address,
    latitude: getState().newContact.marker.getPosition().lat(),
    longitude: getState().newContact.marker.getPosition().lng(),
    company_id: (getState().currentCustomer.current_company_id === 0)
      ? undefined
      : getState().currentCustomer.current_company_id,
    address_detail: getState().newContact.address_detail,
    contact_type: 'batch_pickup_contact',
    address_components: getState().newContact.address_components,
    is_phone_mask: !_.isEmpty(getState().newContact.phone_mask)
  }
  const res = await ContactsAPI.create(contact)
  callback(res)
}

export const createContact = (contact, currentTab, callback) => async (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  const action = currentTab === BUSINESS_PROGRAM ? contactsActionsCreator.addNewContact : personalContactsActionsCreator.addNewPersonalContact
  const companyID = (!currentCustomer.current_company_id || currentTab === PERSONAL)
    ? undefined
    : getState().currentCustomer.current_company_id
  const newContact = {
    ...contact,
    customer_id: currentCustomer.id,
    company_id: companyID,
    contact_type: 'normal',
  }

  const response = await ContactsAPI.create(newContact)
  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(
      dispatch(action(response.data.object))
    ).then(() => {
      callback(response)
    })
  }
}

export const updateContact = (contact, currentTab, callback) => async (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  const newContact = {
    ...contact,
    customer_id: currentCustomer.id
  }

  const response = await ContactsAPI.update(newContact)
  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(
      dispatch(currentTab === BUSINESS_PROGRAM ? contactsActionsCreator.updateSingleContact({ id: response.data.object.id, attrs: response.data.object }) : personalContactsActionsCreator.updateSinglePersonalContact({ id: response.data.object.id, attrs: response.data.object }))
    ).then(() => {
      callback(response)
    })
  }
}

export const getList = (callback, contactType = undefined) => async (dispatch, getState) => {
  const res = await ContactsAPI.getList(
    getState().currentCustomer.current_company_id,
    contactType
  )
  callback(res)
}

export const loadMoreContact = (callback, companyID, contactType = undefined, currentPage) => async (dispatch, getState) => {
  const res = await ContactsAPI.getList(
    companyID,
    contactType,
    currentPage
  )
  callback(res)
}

// export const getBatchContacts = (callback) => (dispatch, getState) =>{
export const getPickupContacts = callback => async (dispatch, getState) => {
  const res = ContactsAPI.getList(
    getState().currentCustomer.current_company_id,
    'batch_pickup_contact',
  )
  callback(res)
}


export const deleteContact = (id, currentTab, callback) => async (dispatch, getState) => {
  const action = (currentTab === BUSINESS_PROGRAM)
    ? contactsActionsCreator.deleteContactLocal
    : personalContactsActionsCreator.deletePersonalContact

  const response = await ContactsAPI.delete(id)

  if (response.data.error) {
    callback(response)
  } else {
    Promise.resolve(
      dispatch(action(id))
    ).then(() => {
      callback(response)
    })
  }
}

export const deletePickupContact = ({ contactId, batchType }, callback) => async (dispatch, getState) => {
  const res = ContactsAPI.delete(contactId)
  callback(res)
  if (batchType === EZ_SHEET_UPLOAD) {
    dispatch(pickupContactsActionsCreator.deletePickupContactLocal(contactId))
  } else {
    dispatch(pickupContactsMeFromLocationActionsCreator.deletePickupContactsMeFromLocation(contactId))
  }
}

export const updateContactInfo = (contact, callback = () => { }) => async (dispatch, getState) => {
  const response = await ContactsAPI.update(contact)
  Promise.resolve(
    dispatch(contactsActionsCreator.updateSingleContact({ id: contact.id, attrs: response.object }))
  ).then(() => {
    callback()
  })
}

export const search = (searchText, companyID, contactType = 'normal', only = [], callback) => (dispatch, getState) => {
  const customer = getState().currentCustomer
  ContactsAPI.search({
    authenticationToken: customer.authentication_token,
    searchText,
    companyID,
    contactType,
    only,
    callback
  })
}

export const getDetails = (id, only = [], callback) => async (dispatch, getState) => {
  const customer = getState().currentCustomer
  const res = await ContactsAPI.get({
    authenticationToken: customer.authentication_token, id, only
  })
  callback(res)
}
