import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { find } from 'lodash'

const initialState: any = {}
const selectedDropOffZoneSlice = createSlice({
  name: 'selectedDropOffZone',
  initialState,
  reducers: {
    updateSelectedDropOffZone: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const selectedDropOffZoneActionsCreator = selectedDropOffZoneSlice.actions

export default selectedDropOffZoneSlice
