import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import I18n from 'i18n/i18n'

const StepsHeader = (props) => {
  const { currentStep, configTitle, onClick } = props
  const handleActiveStep = (step, curStep) => (step === curStep ? 'selected' : '')
  const handleClick = (step, checkClickable, curStep) => {
    if (checkClickable && step !== curStep) onClick(step, curStep)
  }
  const steps = _.size(configTitle) ? configTitle : [
    { step: 1, title: I18n.t('webapp.new_booking.step_1_header') },
    { step: 2, title: I18n.t('webapp.new_booking.step_2_header') },
    { step: 3, title: I18n.t('webapp.new_booking.step_3_header') },
  ]

  return (
    <div className="Head">
      <div className="evenly Steps">
        {steps.map((session) => {
          const { step, title } = session
          const activeStep = handleActiveStep(step, currentStep)
          const checkClickable = step <= currentStep
          return (
            <div
              key={`step-${step}`}
              className={`${activeStep} ${checkClickable ? 'cur-pointer' : ''} Step`}
              onClick={() => handleClick(step, checkClickable, currentStep)}
            >
              <span className={`${checkClickable ? '' : 'Dark-Green-text'} default-font z-index-10`}>
                {title}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

StepsHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  configTitle: PropTypes.arrayOf(PropTypes.shape({})),
}

StepsHeader.defaultProps = {
  currentStep: 1,
  configTitle: [],
}

export default React.memo(StepsHeader)
