import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    updateAttachments: (state: any, action: PayloadAction<any>) => action.payload,
    updateAttachment: (state: any, action: PayloadAction<any>) => {
      const index = action.payload.index
      if(index >= 0){
        state[index] = {
          ...state[index],
          ...action.payload.attrs
        }
      }
    },
  },
  extraReducers: () => {},
})

export const attachmentsActionsCreator = attachmentsSlice.actions

export default attachmentsSlice
