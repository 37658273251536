import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
import { Utils } from 'utils/Utils'
import * as verifyVehicleLogic from 'utils/ez_spread_sheet/stepValidates/verifyLogicWhenChangeVehicle'
import * as buildBookings from 'utils/ez_spread_sheet/buildBookings'
import * as utilBookingExtraServices from 'utils/ez_spread_sheet/bookingExtraServices'
import { CPODUtils } from 'utils/booking/CPODUtils'
import I18n from 'i18n/i18n'
// API
import BatchesAPI from 'api/batches'
// ACTIONS
import * as batchActionCreators from 'store/actions/ez_spread_sheet/batchActionCreators'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'

// CONSTANTS
import {
  BOOKING_TIME_TYPE_POPUP,
  VEHICLE_TYPE,
  PICKUP_DATE_TIME,
  TIME_TYPE_OPTION,
  EXTRA_REQUIREMENTS,
  APPLY_ALL_FOR_ENTIRE_BATCH_POPUP,
  PICKUP_TIME_POPUP,
  SUB_POPUP_WHEN_DATA_IS_CHANGED,
  ANOTHER_IDEA,
  VEHICLE_TYPE_POPUP,
  CUSTOM_REIMBURSEMENT,
  SUB_ACCOUNT_TAG
} from 'constants/ezSpreadSheetConstants'
import {
  FULL_DAY,
  ONE_MIN_TO_MS
} from 'constants/bookingConstants'
import * as common from 'utils/booking/common'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { subCurrentPopupIDActionsCreator } from 'store/toolkit/subCurrentPopupID/subCurrentPopupID.reducer'
import { batchTemplateActionsCreator } from 'store/toolkit/batchTemplate/batchTemplate.reducer'

const mapStateToProps = state => ({
  batch: state.batch,
  batchTemplate: state.batchTemplate,
  selectedBooking: state.selectedBooking,
  extraServices: state.extraServices,
  extraInfos: state.extraInfos,
  currentPopupID: state.currentPopupID,
  subCurrentPopupID: state.subCurrentPopupID,
  currentCustomer: state.currentCustomer
})

function mapDispatchToProps(dispatch) {
  return {
    batchActions: bindActionCreators(batchActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    subCurrentPopupIDActions: subCurrentPopupIDActionsCreator,
    batchTemplateActions: bindActionCreators(batchTemplateActionsCreator, dispatch),
    batchActionsCreator: bindActionCreators(batchActionsCreator, dispatch)
  }
}

class ApplyForEntireBatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasAnotherIdea: false
    }
  }

  // eslint-disable-next-line react/sort-comp
  closePopup(goBack = false) {
    const {
      confirmType,
      subCurrentPopupIDActions
    } = this.props
    this.updateBatchTemplateBaseOnPopupType(goBack)
    subCurrentPopupIDActions.updateSubCurrentPopupID('')
    if (confirmType === EXTRA_REQUIREMENTS) {
      Utils.showToastrMessage('success', I18n.t('batches.messages.update_extra_success'))
    }
    if (confirmType === CUSTOM_REIMBURSEMENT) {
      Utils.showToastrMessage('success', I18n.t('batches.messages.update_custom_success'))
    }
  }

  buildImpactVehicleEntireBatch(forAllBks) {
    const {
      selectedBooking,
      extraServices,
      currentCustomer,
      batch,
      extraInfos,
      batchActions,
      batchActionsCreator: _batchActionsCreator
    } = this.props
    const params = {
      extraServices,
      currentCustomer,
      extraInfos,
    }
    let bookings = [...batch.bookings]
    if (forAllBks) {
      bookings = utilBookingExtraServices.buildImpactAllBookingsVehicleType(bookings, selectedBooking, params)
    } else {
      bookings = utilBookingExtraServices.buildImpactAllBookingsEmptyVehicleType(bookings, selectedBooking, params)
    }

    Promise.resolve(
      _batchActionsCreator.updateBatchBookings({ bookings })
    ).then(() => {
      // validate data call api check_locations
      if (_.size(bookings)) {
        _.forEach(bookings, (booking) => {
          CPODUtils.verifyDataBatchBookings(booking, result => CPODUtils.calculateCheckLocations({
            booking: result,
            batchActions,
            currentCustomer,
            BatchesAPI,
          }))
        })
      }
    })
  }

  updateAllBookingsPickupDateTime(forAllBks, pickupTime) {
    const { batchActions, batchTemplateActions, currentCustomer, batchActionsCreator: _batchActionsCreator } = this.props
    let popupType = this.props.confirmType
    let bookings = [...this.props.batch.bookings]
    bookings.map((booking, bookingIndex) => {
      if (!forAllBks && _.toString(booking[popupType]) == "" || forAllBks) {
        booking["pickup_date_time"] = pickupTime
        booking.verify.pickupTime = true
      }
      booking = buildBookings.checkReimbursentSetting(booking, { currentCustomer: this.props.currentCustomer })
    })

    Promise.resolve(
      _batchActionsCreator.updateBatchBookings({ bookings }),
      batchTemplateActions.updateBatchTemplate({ same_pickup_datetime: false })
    ).then(() => {
      // validate data call api check_locations
      if (_.size(bookings)) {
        _.forEach(bookings, (booking) => {
          CPODUtils.verifyDataBatchBookings(booking, result => CPODUtils.calculateCheckLocations({
            booking: result,
            batchActions,
            currentCustomer,
            BatchesAPI,
          }))
        })
      }
    })
  }

  updateExtraRequirementForEntireBatch(forAllBks) {
    const { batchActions, currentCustomer, batchActionsCreator: _batchActionsCreator } = this.props

    let { batch } = this.props
    const { batchTemplate, tempSelectedValue } = this.props
    // const booking = this.getBookingFromBatch(selectedBooking)
    if (forAllBks) {
      batch = utilBookingExtraServices.applyExtraServiceToAllSameVehicleBookbings(
        batch,
        batchTemplate,
        tempSelectedValue
      )
    } else {
      batch = utilBookingExtraServices.applyExtraServiceToUndefinedSameVehicleBookbings(
        batch,
        batchTemplate,
        tempSelectedValue
      )
    }

    Promise.resolve(
      _batchActionsCreator.updateBatch({ attrs: batch })
    ).then(() => {
      // validate data call api check_locations
      if (_.size(batch.bookings)) {
        _.forEach(batch.bookings, (getBooking) => {
          CPODUtils.verifyDataBatchBookings(getBooking, result => CPODUtils.calculateCheckLocations({
            booking: result,
            batchActions,
            currentCustomer,
            BatchesAPI,
          }))
        })
      }
    })
  }

  updateCustomReimbursementForEntireBatch() {
    const { batchActions, currentCustomer, batchActionsCreator: _batchActionsCreator } = this.props

    let { batch } = this.props
    const { batchTemplate, tempSelectedValue } = this.props
    // const booking = this.getBookingFromBatch(selectedBooking)
    batch = utilBookingExtraServices.applyCustomReimbursementToAllSameVehicleBookings(
      batch,
      batchTemplate,
      tempSelectedValue
    )

    Promise.resolve(
      _batchActionsCreator.updateBatch({ attrs: batch })
    ).then(() => {
      // validate data call api check_locations
      if (_.size(batch.bookings)) {
        _.forEach(batch.bookings, (getBooking) => {
          CPODUtils.verifyDataBatchBookings(getBooking, result => CPODUtils.calculateCheckLocations({
            booking: result,
            batchActions,
            currentCustomer,
            BatchesAPI,
          }))
        })
      }
    })
  }

  updateSubAccountTagForEntireBatch(forAllBks) {
    const {
      batchActions,
      currentCustomer,
      selectedSubAccount,
      checkUseSubAccount,
      batchActionsCreator: _batchActionsCreator
    } = this.props
    const { batch } = this.props

    const bookings = [...batch.bookings]
    bookings.forEach((booking) => {
      if ((!forAllBks && _.toString(booking.popupType) === '') || forAllBks) {
        // eslint-disable-next-line no-param-reassign
        booking.sub_account_tag = checkUseSubAccount ? selectedSubAccount : null
      }
    })

    Promise.resolve(
      _batchActionsCreator.updateBatchBookings({ bookings }),
    ).then(() => {
      // validate data call api check_locations
      if (_.size(batch.bookings)) {
        _.forEach(batch.bookings, (getBooking) => {
          CPODUtils.verifyDataBatchBookings(getBooking, result => CPODUtils.calculateCheckLocations({
            booking: result,
            batchActions,
            currentCustomer,
            BatchesAPI,
          }))
        })
      }
    })
  }

  updateTimeTypeForEntireBatch(forAllBks) {
    const {
      // batchActions, currentCustomer,
      batchTemplateActions,
      currentCustomer,
      selectedBooking,
      tempSelectedValue,
      batch,
      extraServices,
    } = this.props

    const timeTypeOpt = tempSelectedValue

    let bookings = forAllBks ? batch.bookings : _.filter(batch.bookings, booking => (
      _.isUndefined(booking.time_type_option)
      || _.size(booking.time_type_option) === 0
      || booking.temp_id === selectedBooking.temp_id
    ))

    bookings = bookings.map((booking) => {
      let tempBooking = _.cloneDeep(booking)
      tempBooking.time_type_option = timeTypeOpt
      tempBooking.verify.timeType = true
      tempBooking = utilBookingExtraServices.buildBadgeExtraService(tempBooking, extraServices)
      tempBooking = buildBookings.changeBookingNewEmptyRequire(tempBooking)
      tempBooking = buildBookings.checkReimbursentSetting(
        tempBooking,
        { currentCustomer }
      )
      if (!_.isEmpty(tempBooking.time_type_option) && tempBooking.time_type_option.type_key !== FULL_DAY) {
        if (_.size(booking.locations) === 1) {
          tempBooking = buildBookings.addNewEmptyBookingLocation(tempBooking)
        }
      }

      return tempBooking
    })

    batchTemplateActions.updateBatchTemplate({ same_time_type: false })

    return bookings
  }

  verifyAllBookingPickupTimeAndTimeType(settings) {
    const { batch } = this.props
    const bookings = batch.bookings
    for (let i = 0; i < _.size(bookings); i += 1) {
      if (!verifyVehicleLogic.verifyPickupTimeWhenChangeVehicle(bookings[i], settings)) {
        return false
      }
      if (!verifyVehicleLogic.verifyTimeTypeWhenChangeVehicle(bookings[i], settings)) {
        return false
      }
    }
    return true
  }

  buildImpactTimeTypeForAllBookings(pickupTime, settings, bookings) {
    const {
      extraServices, currentCustomer,
      // batchActions,
    } = this.props
    // let bookings = batch.bookings
    if (_.size(bookings) === 0) {
      return []
    }
    const params = {
      currentCustomer,
      extraServices,
      pickupTime,
      settings
    }
    const updatedBookings = bookings.map((booking) => {
      // prevent updating directly on the data source, to trigger data change
      let tempBooking = _.cloneDeep(booking)
      const pickupVerify = verifyVehicleLogic.verifyPickupTimeWhenChangeVehicle(tempBooking, settings)
      if (!pickupVerify) {
        tempBooking.pickup_date_time = pickupTime
        tempBooking.verify.pickupTime = true
      }
      tempBooking = utilBookingExtraServices.buildImpactBookingVehicleTypeFromSetting(tempBooking, params)

      return tempBooking
    })

    return updatedBookings
  }

  getBookingFromBatch(selectedBooking) {
    const {
      batch: { bookings = [] } = {},
    } = this.props

    return bookings.find(booking => (booking.temp_id === selectedBooking.temp_id))
  }

  hasDifferentAttrSelected() {
    const { confirmType, selectedBooking, batch } = this.props
    const { attribute } = confirmType
    const { updatedBooking } = selectedBooking
    const { bookings } = batch.bookings
    // const selectedAttr = updatedBooking[attribute]
    for (let i = 0; i < _.size(bookings); i += 1) {
      const compareAttr = bookings[i][attribute]
      if (!_.isEmpty(compareAttr) && updatedBooking.temp_id !== bookings[i].temp_id) {
        if (_.size(compareAttr) > 0) {
          return true
        }
      }
    }
    return false
  }

  hasAnyEmptyData() {
    const { confirmType, batch } = this.props || {}
    const { attribute } = confirmType
    const { bookings } = batch.bookings
    for (let i = 0; i < _.size(bookings); i += 1) {
      const compareAttr = bookings[i][attribute]
      if (_.isEmpty(compareAttr)) return true
    }
    return false
  }

  updateBatchTemplateBaseOnPopupType(goBack) {
    const { confirmType, batchTemplateActions } = this.props
    const { popupType } = confirmType
    if (popupType === VEHICLE_TYPE) {
      batchTemplateActions.updateBatchTemplate({ same_vehicle_type: goBack })
    } else if (popupType === PICKUP_DATE_TIME) {
      batchTemplateActions.updateBatchTemplate({ same_pickup_datetime: goBack })
    } else if (popupType === EXTRA_REQUIREMENTS) {
      batchTemplateActions.updateBatchTemplate({ same_extra_requirements: goBack })
    } else if (popupType === CUSTOM_REIMBURSEMENT) {
      batchTemplateActions.updateBatchTemplate({ same_custom_reimbursements: goBack })
    } else if (popupType === SUB_ACCOUNT_TAG) {
      batchTemplateActions.updateBatchTemplate({ same_sub_account_tag: goBack })
    } else {
      batchTemplateActions.updateBatchTemplate({ same_time_type: goBack })
    }
  }

  applyForAllBookings(forAllBks) {
    const {
      confirmType,
      selectedBooking,
      subCurrentPopupIDActions,
      currentPopupIDActions,
      batch,
      extraServices,
      tempSelectedValue,
      extraInfos,
    } = this.props
    const popupType = confirmType
    const booking = this.getBookingFromBatch(selectedBooking)
    const settings = verifyVehicleLogic.vehicleTypeSettings(booking.vehicle_type)

    if (popupType === VEHICLE_TYPE) {
      this.buildImpactVehicleEntireBatch(forAllBks)
      if (!this.verifyAllBookingPickupTimeAndTimeType(settings)) {
        subCurrentPopupIDActions.updateSubCurrentPopupID(PICKUP_TIME_POPUP)
      }
      if (_.size(verifyVehicleLogic.bookingsHaveInvalidDataInFullDayTimeType(batch, extraServices.vehicleTypes)) > 0) {
        currentPopupIDActions.updateCurrentPopupID(VEHICLE_TYPE_POPUP)
        subCurrentPopupIDActions.updateSubCurrentPopupID(SUB_POPUP_WHEN_DATA_IS_CHANGED)
      } else {
        this.closePopup()
        currentPopupIDActions.updateCurrentPopupID('')
      }
    }

    if (popupType === PICKUP_DATE_TIME) {
      const pickupTime = this.props.pickupTime
      this.updateAllBookingsPickupDateTime(forAllBks, pickupTime)
      this.closePopup()
      currentPopupIDActions.updateCurrentPopupID('')
    }

    if (popupType === EXTRA_REQUIREMENTS) {
      this.updateExtraRequirementForEntireBatch(forAllBks)
      this.closePopup()
      currentPopupIDActions.updateCurrentPopupID('')
    }

    if (popupType === CUSTOM_REIMBURSEMENT) {
      this.updateCustomReimbursementForEntireBatch(forAllBks)
      this.closePopup()
      currentPopupIDActions.updateCurrentPopupID('')
    }

    if (popupType === TIME_TYPE_OPTION) {
      let updatedBookings = this.updateTimeTypeForEntireBatch(forAllBks)
      if (forAllBks === false && verifyVehicleLogic.verifyPickUpTimeWhenChangeTimeType(booking, settings)) {
        this.closePopup()
      } else if (!_.isEmpty(tempSelectedValue)) {
        const isFullDay = tempSelectedValue.type_key === FULL_DAY
        const pickupTime = moment(verifyVehicleLogic.rightPickUpTimeForTimeType(settings, isFullDay)).format()
        updatedBookings = this.buildImpactTimeTypeForAllBookings(pickupTime, settings, updatedBookings)
        this.closePopup()
        currentPopupIDActions.updateCurrentPopupID('')
      }

      // I have revised the logic in updateTimeTypeForEntireBatch and buildImpactTimeTypeForAllBookings
      //    with using immutability approach and we only trigger change of bookings once via updateBatchBookings
      const { batchActions, currentCustomer, batchActionsCreator: _batchActionsCreator } = this.props
      const newBooking = _.map(updatedBookings, item => common.getValidLocationForBooking(item))
      const bookingParams = common.getParamsForCalBatchEZ(currentCustomer, newBooking, extraInfos, CPODUtils)
      batchActions.calculate(bookingParams, newBooking, (response) => {
        if (response && response.bookings && !_.isEmpty(response.bookings.data)) {
          const { bookings: bookingsResponse } = response
          const { data } = bookingsResponse
          const { finalBooking, isValid } = common.checkErrorDistanceTable(data, updatedBookings)
          if (isValid) {
            Promise.resolve(
              _batchActionsCreator.updateBatchBookings({ bookings: updatedBookings })
            ).then(() => {
              // validate data call api check_locations
              if (_.size(updatedBookings)) {
                _.forEach(updatedBookings, (getBooking) => {
                  CPODUtils.verifyDataBatchBookings(getBooking, result => CPODUtils.updateTimeTypeBaseOnLocation({
                    booking: result,
                    batchActions,
                    currentCustomer,
                    BatchesAPI,
                    extraServices
                  }))
                })
              }
            })
          } else {
            const newBatch = _.assign({}, batch, { bookings: finalBooking })
            _batchActionsCreator.updateBatch({ attrs: newBatch })
          }
        }
        return true
      })
    }

    if (popupType === SUB_ACCOUNT_TAG) {
      this.updateSubAccountTagForEntireBatch(forAllBks)
      this.closePopup()
      currentPopupIDActions.updateCurrentPopupID('')
    }
  }

  applyForEntireBatchAfterChangePickupTime(forAllBks) {
    const { selectedBooking, currentPopupIDActions } = this.props
    const booking = this.getBookingFromBatch(selectedBooking)
    const settings = verifyVehicleLogic.vehicleTypeSettings(booking.vehicle_type)
    if (verifyVehicleLogic.verifyPickupTimeWhenChangeVehicle(booking, settings)) {
      currentPopupIDActions.updateCurrentPopupID('')
      this.closePopup()
    } else {
      const bufferTime = _.toInteger(settings.buffer_time_for_pick_time_multiple_batch) * ONE_MIN_TO_MS
      if (_.size(settings) > 0) {
        let current = (new Date()).getTime()
        const bkTimeType = booking.time_type_option.type_key
        let addingTime = _.toInteger(settings.minimum_pickup_time_schedule) * ONE_MIN_TO_MS
        if (bkTimeType == FULL_DAY) { addingTime = _.toInteger(settings.minimum_pickup_time_fullday) * ONE_MIN_TO_MS }
        current += addingTime + bufferTime
        // const pickupTime = moment(current).format()
        this.buildImpactVehicleEntireBatch(forAllBks)
      }
      this.props.currentPopupIDActions.updateCurrentPopupID(APPLY_ALL_FOR_ENTIRE_BATCH_POPUP)
      this.props.subCurrentPopupIDActions.updateSubCurrentPopupID(PICKUP_TIME_POPUP)
    }
  }

  renderContentOfPickupTimeFollowVehicleLogic() {
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">
          {I18n.t('batches.mapping_key.vehicle_type')}
        </div>
        <div className="Modal-Content Box-Content">
          <div
            className="Modal-SplitView Modal-SplitView-Default-Font"
            dangerouslySetInnerHTML={{ __html: I18n.t('batches.messages.vehicle_pickuptime_popup') }}
          />
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button
              type="button"
              className="green Button flex-index"
              onClick={() => this.applyForEntireBatchAfterChangePickupTime(true)}
            >
              {I18n.t('batches.button.ok_option')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  closePopupWithoutChangingData() {
    const { subCurrentPopupIDActions } = this.props
    subCurrentPopupIDActions.updateSubCurrentPopupID('')
  }

  renderTimeTypeContentFollowVehicleLogic() {
    const { selectedBooking } = this.props
    const settings = _.isUndefined(selectedBooking.vehicle_type) ? {} : selectedBooking.vehicle_type.settings
    let showConfirmBtn = true
    if (!_.isEmpty(settings)) {
      if (!settings?.schedule_enabled && !settings?.full_day_enabled) {
        showConfirmBtn = false
      }
    }
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">
          {I18n.t('batches.mapping_key.vehicle_type')}
        </div>
        <div className="Modal-Content Box-Content">
          <div
            className="Modal-SplitView Modal-SplitView-Default-Font"
            dangerouslySetInnerHTML={{ __html: I18n.t('batches.messages.vehicle_timetype_popup') }}
          />
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right Modal-Actions Box-Actions w100">
            <button
              type="button"
              className="close-modal gray Button Button-Default"
              onClick={() => this.closePopupWithoutChangingData()}
            >
              {I18n.t('batches.button.go_back')}
            </button>
            {showConfirmBtn && (
              <button
                type="button"
                className="green Button Button-Default"
                onClick={() => this.changeToTimeTypePopups()}
              >
                {I18n.t('batches.button.ok_option')}
              </button>)}
          </div>
        </div>
      </div>
    )
  }

  renderRightTextForEachPopupType(popupType) {
    const confirmType = this.props.confirmType
    if (confirmType === VEHICLE_TYPE) {
      const vehicleType = this.props.selectedBooking.vehicle_type
      const typeName = _.isUndefined(vehicleType) ? '' : vehicleType.name
      if (popupType === ANOTHER_IDEA) {
        return I18n.t('batches.messages.vehicle_another_idea_content', { type_name: typeName })
      }
      return I18n.t('batches.messages.apply_all_vehicle_type_content', { type_name: typeName })
    }
    if (confirmType === PICKUP_DATE_TIME) {
      if (popupType === ANOTHER_IDEA) {
        return I18n.t('batches.messages.pickup_time_another_idea_content')
      }
      return I18n.t('batches.messages.apply_all_pickup_time_content')
    }
    if (confirmType === TIME_TYPE_OPTION) {
      const timeType = this.props.selectedBooking.time_type_option
      const typeName = _.isUndefined(timeType) ? '' : I18n.t(`batches.mapping_key.${timeType.type_key}`)
      if (popupType === ANOTHER_IDEA) {
        return I18n.t('batches.messages.time_type_another_idea_content', { type_name: typeName })
      }
      return I18n.t('batches.messages.apply_all_time_type_content', { type_name: typeName })
    }
    if (popupType === ANOTHER_IDEA) {
      return I18n.t('batches.messages.extra_service_another_idea_content')
    }
    if (confirmType === CUSTOM_REIMBURSEMENT) {
      return I18n.t('batches.messages.apply_all_custom_reimbursement_content')
    }
    if (confirmType === SUB_ACCOUNT_TAG) {
      const { selectedSubAccount, checkUseSubAccount } = this.props
      return I18n.t('batches.messages.apply_all_sub_account_tag',
        {
          tag_name: checkUseSubAccount
            ? selectedSubAccount && selectedSubAccount.sub_account_name
            : I18n.t('batches.messages.no_tag')
        })
    }
    return I18n.t('batches.messages.apply_all_extra_service_content')
  }

  handleAnotherIdea(hasAnotherIdea) {
    this.setState({
      hasAnotherIdea
    }, () => {
      if (!this.state.hasAnotherIdea) {
        this.props.subCurrentPopupIDActions.updateSubCurrentPopupID('')
      }
    })
  }

  renderNormalPopup(showThirdBtn) {
    const textContent = this.renderRightTextForEachPopupType()
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">
          {I18n.t('batches.label.confirm_title')}
        </div>
        <div className="Modal-Content Box-Content">
          <div className="Modal-SplitView Modal-SplitView-Default-Font">
            {textContent}
          </div>
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button
              type="button"
              className={`close-modal gray Button-Default Button ${showThirdBtn ? 'flex-index' : ''}`}
              onClick={() => this.closePopup(true)}
            >
              {I18n.t('batches.button.go_back')}
            </button>
            {showThirdBtn && (
              <button
                type="button"
                className="green-text green-border Button Button-Default flex-index"
                onClick={() => this.handleAnotherIdea(true)}
              >
                {I18n.t('batches.button.another_idea')}

              </button>
            )}
            <button
              type="button"
              className={`green Button Button-Default ${showThirdBtn ? 'flex-index' : ''}`}
              onClick={() => this.applyForAllBookings(true)}
            >
              {I18n.t('webapp.action.proceed')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  anotherIdeaPopup() {
    const textContent = this.renderRightTextForEachPopupType(ANOTHER_IDEA)
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">
          {I18n.t('batches.label.confirm_title')}
        </div>
        <div className="Modal-Content Box-Content">
          <div className="Modal-SplitView Modal-SplitView-Default-Font">
            {textContent}
          </div>
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button className="close-modal Button-Default gray Button" onClick={() => this.handleAnotherIdea(false)}>
              {I18n.t('batches.button.go_back')}
            </button>
            <button className="green Button Button-Default" onClick={() => this.applyForAllBookings(false)}>
              {I18n.t('webapp.action.proceed')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  changeToTimeTypePopups() {
    // Need to build vehicle type for entire batch here before show cofirmed
    this.buildImpactVehicleEntireBatch(true)
    $('#batch-update-vehicle-type').addClass('visible')
    this.props.subCurrentPopupIDActions.updateSubCurrentPopupID(BOOKING_TIME_TYPE_POPUP)
  }

  verifyTimeType(settings, timeTypeOpt) {
    const { batch } = this.props
    if (_.size(settings) === 0 || _.isUndefined(timeTypeOpt)) { return true }
    const bookings = batch.bookings
    for (let i = 0; i < _.size(bookings); i += 1) {
      const verify = verifyVehicleLogic.verifyTimeTypeWhenChangeVehicle(bookings[i], settings)
      if (!verify) { return false }
    }
    return true
  }

  render() {
    const {
      subCurrentPopupID,
      selectedBooking,
      confirmType
    } = this.props
    const { hasAnotherIdea } = this.state
    if (subCurrentPopupID !== APPLY_ALL_FOR_ENTIRE_BATCH_POPUP) {
      return (<span key={`empty-apply-vehicle-${APPLY_ALL_FOR_ENTIRE_BATCH_POPUP}`} />)
    }
    const showThirdBtn = this.hasDifferentAttrSelected() && this.hasAnyEmptyData()
    let currentTemplate = this.renderNormalPopup(showThirdBtn)
    const booking = { ...selectedBooking }
    const settings = verifyVehicleLogic.vehicleTypeSettings(booking.vehicle_type)
    const timeTypeOpt = booking.time_type_option
    if (confirmType === VEHICLE_TYPE) {
      if (!this.verifyTimeType(settings, timeTypeOpt)) {
        currentTemplate = this.renderTimeTypeContentFollowVehicleLogic()
      } else if (!verifyVehicleLogic.verifyPickupTimeWhenChangeVehicle(booking, settings)) {
        currentTemplate = this.renderContentOfPickupTimeFollowVehicleLogic()
      }
    }
    if (hasAnotherIdea) {
      currentTemplate = this.anotherIdeaPopup()
    }
    return (
      <div className="vertical-scroll Modal Modal-Basic visible" id={APPLY_ALL_FOR_ENTIRE_BATCH_POPUP}>
        {currentTemplate}
      </div>
    )
  }
}

ApplyForEntireBatch.propTypes = {
  batch: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  extraServices: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  batchActions: PropTypes.shape({}).isRequired,
  batchTemplate: PropTypes.shape({}).isRequired,
  subCurrentPopupIDActions: PropTypes.shape({}).isRequired,
  subCurrentPopupID: PropTypes.string,
  tempSelectedValue: PropTypes.shape({}),
  batchTemplateActions: PropTypes.shape({}).isRequired,
  selectedSubAccount: PropTypes.shape({}),
  checkUseSubAccount: PropTypes.bool,
}

ApplyForEntireBatch.defaultProps = {
  subCurrentPopupID: undefined,
  tempSelectedValue: null,
  selectedSubAccount: undefined,
  checkUseSubAccount: false
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForEntireBatch)
