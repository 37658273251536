// CONSTANTS
import { DRIVER_PREFERENCES, FULL_DAY, NOW, } from 'constants/newBookingConstants'
import { POPUP_ID_ASSIGN_DRIVERS } from 'constants/common/popupConstants'
import _ from 'lodash'
// API
import CustomerAPI from 'api/customers'

export const getExtraRequirementsListID = (extraServices) => {
  const list = []
  _.map(extraServices.extraRequirements, (requirement) => {
    if (requirement.selected) {
      list.push(requirement.id)
    }
  })
  return list
}

export const getBadgesListID = (extraServices) => {
  const list = []
  _.map(extraServices.vehicleTypeBadges, (badge) => {
    if (badge.selected) {
      list.push({
        badgeable_relation_id: badge.id, badgeable_relation_type: badge.badgeable_relation_type
      })
    }
  })
  _.map(extraServices.companyBadges, (badge) => {
    if (badge.selected) {
      list.push({
        badgeable_relation_id: badge.id, badgeable_relation_type: badge.badgeable_relation_type
      })
    }
  })
  return list
}

export const getReimbursementListID = (extraServices) => {
  const list = []
  _.map(extraServices.customReimbursements, (reimbursement) => {
    if (reimbursement.id && reimbursement.selected_amount >= 1) {
      list.push(reimbursement.id)
    }
  })
  return list
}

export const getExtraRequirementsLocationsListID = (locations) => {
  const list = []
  const extraRequirementLocations = locations ? locations.extra_requirement_locations : []
  _.map(extraRequirementLocations, (requirement) => {
    if (requirement.selected) {
      list.push(requirement.id)
    }
  })
  return list
}

export const getFavoriteDriversAmount = (data) => {
  const customer = data.currentCustomer
  const params = {
    company_id: customer.current_company_id === 0 ? undefined : customer.current_company_id
  }
  CustomerAPI.favoriteDriversAmount(customer.authentication_token, params, (result) => {
    data.callBackAfterGetFavoriteDriversAmount(result)
  })
}

export const loadAssignableDriversStep6 = (data) => {
  const customer = data.currentCustomer
  if (data.callAPI || _.isEmpty(data.assignableDrivers)) {
    const params = {
      vehicle_type_id: data.selectedVehicleTypeID,
      time_type: data.timeType,
      company_id: customer.current_company_id === 0 ? undefined : customer.current_company_id,
      country_code: data.countryCode,
      is_need_pod_cod: data.isNeedPODCOD,
      'extra_requirement_ids': data.extraRequirementIDs,
      'extra_requirement_locations_ids': [],
      'badges': data.badgeIDs,
      'include': ['driver.vehicles', 'driver.current_vehicle'],
      include_fleet_driver: true,
      'only': ['id', 'referral_code', 'name', 'driver_image_url', 'driver_type', 'badges', 'fleet_partner_id', 'fleet_partner_name', 'current_vehicle'],
      'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name']
    }
    CustomerAPI.getAssignableDrivers(customer.authentication_token, params, (result) => {
      data.callBackAfterGetAssignableDrivers(result)
    })
  } else {
    data.actionWithoutCallAPI()
  }
}

export const noticePopupStatus = (params) => {
  let currentState
  const customer = params.currentCustomer
  if (params.value !== DRIVER_PREFERENCES.all) {
    if (params.timeType === NOW && !_.isEmpty(customer.authentication_token)) {
      currentState = POPUP_ID_ASSIGN_DRIVERS.popup.favorite_schedule.item_type
    } else if ((_.isEmpty(customer.authentication_token)
      && [DRIVER_PREFERENCES.favorite, DRIVER_PREFERENCES.assign].includes(params.value))) {
      currentState = POPUP_ID_ASSIGN_DRIVERS.popup.favorite_active.item_type
    }
  }
  return currentState
}

export const generateObjectFromApi = (currentState, popupData, popupAction) => {
  if (_.isEmpty(popupData)) {
    return null
  }
  let popupConstant
  switch (currentState) {
    case POPUP_ID_ASSIGN_DRIVERS.popup.favorite_schedule.item_type: {
      popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup.favorite_schedule
      break
    }
    default: popupConstant = POPUP_ID_ASSIGN_DRIVERS.popup.favorite_active
  }
  popupAction(popupConstant)
  let obj = {}
  const selectItemTypes = [
    popupConstant.item_title,
    popupConstant.item_content,
  ]
  const titleItems = _.filter(
    popupData.popup_items,
    item => _.includes(selectItemTypes, item.item_type)
  )
  const popupTitle = _.isEmpty(titleItems[0]) ? '' : titleItems[0].formated_content
  obj = {
    icon: decodeURIComponent(popupData.icon),
    title: popupTitle,
    subTitle: '',
  }
  return obj
}

export const getSelectedDriverPreferenceButton = (booking, reachingFavoriteAmount) => {
  if (booking.time_type === NOW) {
    return DRIVER_PREFERENCES.all
  }
  if (!_.isEmpty(booking.assignedDriver)) {
    return DRIVER_PREFERENCES.assign
  }

  if (_.isUndefined(booking.sendFirstToFavorite)
    && (booking.useDataAgain || _.isEmpty(booking.assignedDriver))) {
    return reachingFavoriteAmount ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
  }

  return booking.sendFirstToFavorite ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
}

export const addToFavoriteDrivers = (data) => {
  const assignDriver = data.assignDriver
  let params = {}
  const companyID = data.companyID === 0 ? undefined : data.companyID
  const authenticationToken = data.authenticationToken
  if (assignDriver.fleet_partner_id) {
    params = {
      fleet_partner_id: assignDriver.fleet_partner_id,
      driver_id: assignDriver.id,
      company_id: companyID
    }
    CustomerAPI.addFavoriteFleetDriver(authenticationToken, params)
  } else {
    params = {
      'customer_drivers_attributes': [
        {
          item_type: DRIVER_PREFERENCES.favorite,
          driver_id: assignDriver.id,
          company_id: companyID
        }
      ]
    }
    CustomerAPI.addFavoriteDriver(authenticationToken, params)
  }
  return assignDriver
}

export const getExtraRequirementIds = extraServices => (
  _.isEmpty(extraServices) || _.isEmpty(extraServices.extraRequirements)
    ? []
    :  _.compact(
      _.map([
        ...extraServices.extraRequirements,
        ...extraServices.extraRequirementsNegativePosition
      ], extra => (extra?.selected ? extra?.id : false))
    )
)
