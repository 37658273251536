export const SYNC_EXPORT_TEMPLATE = 'sync_export_template'
export const UPDATE_EXPORT_TEMPLATE = 'update_export_template'

export const FROM_DATE = 'from-date'
export const TO_DATE = 'to-date'
export const DATE_FILTER = {
  last_30_days: 30,
  last_60_days: 60,
  last_90_days: 90,
  last_180_days: 180,
}
export const EXPORT_KEY = ['bookingInfo', 'fees', 'codPod']
export const YESTERDAY = 1
export const ONE_MONTH = 30
export const TODAY = 0