import StorageKeys from 'constants/storage-keys';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Component
import resources from './resources';

const TRANSPORTIFY_URL = 'webapp.transportify.com.ph'
const isTransportify = window.location.hostname.includes(TRANSPORTIFY_URL);
const languageCode = isTransportify ? 'tl' : 'en';

const supportedLngs = ['en', 'id', 'th', 'tl', 'en_id']
const urlParams = new URLSearchParams(window.location.search)
const lang = urlParams.get('lang') && urlParams.get('lang').toLowerCase()
const langFromURL = lang && supportedLngs.includes(lang) ? lang : ''
if (langFromURL){
  // save to localstorage
  localStorage.setItem(StorageKeys.LANGUAGE, langFromURL)
}

const localLang = localStorage.getItem(StorageKeys.LANGUAGE)

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: langFromURL || localLang || languageCode,
    fallbackLng: 'en',

    supportedLngs,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
