import { SHOWROUTE_CURVE, SHOWROUTE_SECTION } from 'constants/appConstants'
import _ from 'lodash'
import $ from 'jquery'
import I18n from 'i18n/i18n'

const mapUtils = {
  reCenterAndZoom(googleMap, location, zoomLevel) {
    if (_.isUndefined(location)) { return }
    if (location.lat && location.lng && location.name) {
      googleMap.map.setZoom(zoomLevel)
      googleMap.map.setCenter(new window.google.maps.LatLng(location.lat, location.lng))
    }
  },
  buildKeyAvoidRoute(currentVehicleType) {
    const settingsAvoidRoute = currentVehicleType.settings.route_options_avoid
    const keyHighWays = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.highways').toLowerCase()
    const keyTolls = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.tolls').toLowerCase()
    const keyFerries = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.ferries').toLowerCase()
    return {
      avoidHighways: _.includes(settingsAvoidRoute, keyHighWays) || false,
      avoidTolls: _.includes(settingsAvoidRoute, keyTolls) || false,
      avoidFerries: _.includes(settingsAvoidRoute, keyFerries) || false
    }
  },
  drawPolyLine(googleMap, req, outsideList = []) {
    const polylineList = []
    const bounds = new window.google.maps.LatLngBounds()
    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 4
    }
    req.map(point => bounds.extend(point.location))
    let curvature = SHOWROUTE_CURVE
    for (let i = 0; i < req.size - 1; i += 1) {
      const outSideFullDay = _.findIndex(outsideList,
        location => _.toString(req.get(i + 1).id) === _.toString(location))
      const path = mapUtils.getLatLngPath({ lat: req.get(i).location.lat(), lng: req.get(i).location.lng() },
        { lat: req.get(i + 1).location.lat(), lng: req.get(i + 1).location.lng() }, curvature)
      curvature = -curvature
      const options = {
        path,
        geodesic: false,
        strokeColor: outSideFullDay !== -1 ? '#f04433' : 'rgba(14,115,15,1)',
        strokeOpacity: 0,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '20px'
        }],
        map: googleMap.map
      }
      const polyline = new window.google.maps.Polyline(options)
      polylineList.push(polyline)
    }
    googleMap.map.fitBounds(bounds)

    return polylineList
  },
  larp(a, b, t) {
    return { lat: a.lat + (b.lat - a.lat) * t, lng: a.lng + (b.lng - a.lng) * t }
  },
  getBezier(points, t) {
    const ab = mapUtils.larp(points[0], points[1], t)
    const bc = mapUtils.larp(points[1], points[2], t)
    const cd = mapUtils.larp(points[2], points[3], t)
    const abbc = mapUtils.larp(ab, bc, t)
    const bccd = mapUtils.larp(bc, cd, t)

    return mapUtils.larp(abbc, bccd, t)
  },
  getBezierHandles(p0, p1, curvature) {
    const q2 = { lat: (p0.lat + p1.lat) / 2, lng: (p0.lng + p1.lng) / 2 }
    const q1 = { lat: (p0.lat + q2.lat) / 2, lng: (p0.lng + q2.lng) / 2 }
    const q3 = { lat: (q2.lat + p1.lat) / 2, lng: (q2.lng + p1.lng) / 2 }

    const dLat = p1.lat - p0.lat
    const dLng = p1.lng - p0.lng

    if (p0.lng > p1.lng) {
      return {
        a: { lat: q1.lat + (-dLng * curvature), lng: q1.lng + (dLat * curvature) },
        b: { lat: q3.lat + (-dLng * curvature), lng: q3.lng + (dLat * curvature) }
      }
    }
    return {
      a: { lat: q1.lat - (-dLng * curvature), lng: q1.lng + (dLat * curvature) },
      b: { lat: q3.lat - (-dLng * curvature), lng: q3.lng + (dLat * curvature) }
    }
  },
  getLatLngPath(p0, p1, curvature = SHOWROUTE_CURVE) {
    const handles = mapUtils.getBezierHandles(p0, p1, curvature)

    const points = [p0, handles.a, handles.b, p1]

    const path = []

    const sections = SHOWROUTE_SECTION

    for (let t = 0; t <= sections; t += 1) {
      const b = mapUtils.getBezier(points, t / sections)

      path.push(new window.google.maps.LatLng(b.lat, b.lng))
    }

    return path
  },
  drawPrevRouteByPolyLine(googleMap, response, outsideList) {
    const polyline = new window.google.maps.Polyline({
      path: [],
      strokeColor: outsideList.length > 0 ? '#f04433' : 'rgba(14,115,15,1)',
      strokeWeight: 6,
    })
    const bounds = new window.google.maps.LatLngBounds()
    const legs = response.routes[0].legs
    for (let i = 0; i < legs.length; i += 1) {
      const steps = legs[i].steps
      for (let j = 0; j < steps.length; j += 1) {
        const nextSegment = steps[j].path
        for (let k = 0; k < nextSegment.length; k += 1) {
          polyline.getPath().push(nextSegment[k])
          bounds.extend(nextSegment[k])
        }
      }
    }

    polyline.setMap(googleMap.map)
    googleMap.map.fitBounds(bounds)
    return [polyline]
  },
  buildPrevLocations(locations, isRoundTrip) {
    const prevLocations = []
    const locationLatLng = _.filter(locations, lo => !_.isUndefined(lo.lat) && !_.isUndefined(lo.lng))
    const size = locationLatLng.length
    for (let index = 0; index < size; index += 1) {
      if (!isRoundTrip || (isRoundTrip && index !== size - 1)) {
        prevLocations.push(JSON.stringify([locationLatLng[index].lat, locationLatLng[index].lng]))
      }
    }
    return prevLocations
  },
  isChangeLocations(locations, prevLocations, options = {}) {
    let isChange = (JSON.stringify(locations) !== JSON.stringify(prevLocations))
    if (options.prevOptimizeRoute && locations.length === prevLocations.length) {
      isChange = !_.isEmpty(locations.filter(lo => !prevLocations.includes(lo)))
    }
    return isChange
  },
  handleDurationResult(value) {
    const result = { src: '', duration: '', unitTime: '' }
    let duration = 0
    let unitTime
    if (value / 86400 >= 1) {
      duration = Math.round(value / 86400)
      unitTime = I18n.t('webapp.booking.day')
    } else if (value / 3600 >= 1) {
      duration = Math.round(value / 3600)
      unitTime = I18n.t('webapp.booking.hr')
    } else {
      duration = Math.round(value / 60) || 1
      unitTime = I18n.t('webapp.booking.min')
    }

    $('.infobox_offline_driver').html('')
    result.duration = duration
    result.unitTime = unitTime
    result.value = value

    return result
  }
}

export default mapUtils
