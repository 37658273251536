import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { Utils } from 'utils/Utils'
import {
  findPopup,
  findPopupItem
} from 'utils/common/popupable'
import I18n from 'i18n/i18n'
// ACTIONS
import * as updateExtraRequirement from 'store/actions/new_booking/extraServiceActionCreators'

// CONSTANTS
import {
  POPUP_TYPE_EXTRA_POPUP_OPTIONS,
  ITEM_TYPE_EXTRA_PO_TITLE,
  ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX
} from 'constants/common/popupConstants'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'

const mapStateToProps = state => ({
  extraInfos: state.extraInfos
})

function mapDispatchToProps(dispatch) {
  return {
    updateExtraRequirementActions: bindActionCreators(updateExtraRequirement, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
  }
}

class ExtraRequirementPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPricing: undefined,
      isPreviousSelectedPricing: undefined,
      selectedExtraRequirement: false,
      agree: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleSelectPricing = this.handleSelectPricing.bind(this)
    this.handleChangeAgree = this.handleChangeAgree.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidMount() {
    const {
      extraRequirement,
      isSelectPricing,
      allowNonePricing
    } = this.props
    this.setDefaultAgree()
    this.setState({
      selectedPricing: isSelectPricing || !allowNonePricing ? extraRequirement.selectedPricing : undefined,
      isPreviousSelectedPricing: isSelectPricing || !allowNonePricing ? extraRequirement.selectedPricing : undefined,
      selectedExtraRequirement: extraRequirement.selected,
    })
  }

  setDefaultAgree() {
    const { extraRequirement } = this.props
    const popup = findPopup(extraRequirement, POPUP_TYPE_EXTRA_POPUP_OPTIONS)
    const popupItem = findPopupItem(popup, ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX)
    this.setState({
      agree: (_.isUndefined(popupItem) ? false : popupItem.checkbox_value)
    })
  }

  handleSelectPricing(pricing) {
    this.setState({
      selectedPricing: pricing,
      selectedExtraRequirement: true
    })
  }

  handleSelectNonePricing() {
    this.setState({
      selectedPricing: undefined,
      selectedExtraRequirement: false
    })
  }

  handleClose() {
    const { handleClose, extraRequirement } = this.props
    const { isPreviousSelectedPricing } = this.state
    Utils.removeClass($('.Popover-Insurance-Policy-Agree'), 'select-error')
    this.setState({
      selectedPricing: isPreviousSelectedPricing,
      selectedExtraRequirement: extraRequirement.selected
    })
    this.setDefaultAgree()
    handleClose()
  }

  handleChangeAgree(agree) {
    Utils.removeClass($('.Popover-Insurance-Policy-Agree'), 'select-error')
    this.setState({ agree })
  }

  handleConfirm() {
    const {
      assignedDriver, extraRequirement, handleClose, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    const { selectedExtraRequirement, selectedPricing } = this.state
    if (assignedDriver
      && (selectedExtraRequirement !== extraRequirement.selected
        || (selectedExtraRequirement === true && !_.isEqual(extraRequirement.selectedPricing, selectedPricing)))) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.handleConfirmAfterCheckBookingDetails.bind(this),
          backFunc: this.handleClose.bind(this)
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
      handleClose()
    } else {
      this.handleConfirmAfterCheckBookingDetails()
    }
  }

  handleConfirmAfterCheckBookingDetails() {
    const {
      updateExtraRequirementActions,
      extraRequirement,
      handleClose,
      extraInfos
    } = this.props
    const { selectedPricing } = this.state
    if (!this.validConfirmAction()) {
      return
    }
    updateExtraRequirementActions.updateExtraRequirement(
      extraRequirement.id,
      {
        selected: !_.isUndefined(selectedPricing),
        selected_amount: _.isUndefined(selectedPricing) ? 0 : 1,
        selectedPricing
      }
    )
    this.setState({
      isPreviousSelectedPricing: selectedPricing
    })
    this.setDefaultAgree()
    handleClose()
    let extraAmount = 0
    if (selectedPricing?.fees) {
      if (selectedPricing.display_fees === 'Free') {
        extraAmount = 0
      } else {
        extraAmount = selectedPricing.fees
      }
    }
    if (!_.isUndefined(selectedPricing)) {
      window.Moengage.track_event('Extra Services Selected', {
        'Extra Service ID': extraRequirement.id,
        'Extra Service Name': extraRequirement.name,
        Amount: extraAmount,
        Quantity: 1,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  validConfirmAction() {
    const { extraRequirement } = this.props
    const { agree } = this.state
    const popup = findPopup(extraRequirement, POPUP_TYPE_EXTRA_POPUP_OPTIONS)
    // do NOT need to check a agree checkbox
    if (_.isUndefined(findPopupItem(popup, ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX))) {
      return true
    }
    // check a agree checkbox
    if (agree) {
      return true
    }
    Utils.addClass($('.Popover-Insurance-Policy-Agree'), 'select-error')
    return false
  }

  renderPopupItem(item) {
    const { agree } = this.state
    if (_.isUndefined(item)) {
      return (<span />)
    }
    switch (item.item_type) {
      case ITEM_TYPE_EXTRA_PO_TITLE: {
        return (
          <div className="Popover-Insurance-Title uppercase" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        )
      }
      case ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX: {
        return (
          <div className="Popover-Insurance-Policy">
            <div className="Overlay-Checkbox White-Checkbox">
              <input
                className="Popover-Insurance-Policy-Agree"
                type="checkbox"
                checked={agree}
                onChange={e => this.handleChangeAgree(e.target.checked)}
              />
              <label>
                <i className="b material-icons Icon">
                  check
                </i>
              </label>
            </div>
            <div className="Popover-Insurance-Policy-Link" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
          </div>
        )
      }
      default:
        break
    }
    return (null)
  }

  render() {
    const { allowNonePricing, extraRequirement = {} } = this.props
    const { selectedPricing } = this.state
    const popup = findPopup(extraRequirement, POPUP_TYPE_EXTRA_POPUP_OPTIONS)
    return !_.isEmpty(popup) && (
      <div className="Info Popover custom booking_insurance_popup" id={`booking_insurance_${extraRequirement.id}`} key={extraRequirement.id}>
        <span className="BoxCustom-Close" onClick={() => this.handleClose()}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
        <div className="Popover-Arrow" />
        <div className="Popover-Item Popover-Insurance">
          <div className="Popover-Insurance-Title uppercase">
            {this.renderPopupItem(findPopupItem(popup, ITEM_TYPE_EXTRA_PO_TITLE))}
          </div>
          <div className="Popover-Insurance-Line" />
          <div className="Popover-Insurance-Group-Header">
            <div>
              {I18n.t('webapp.label.coverage')}
            </div>
            <div>
              {I18n.t('webapp.label.fee')}
            </div>
          </div>
          <div className="Popover-Insurance-Group">
            {allowNonePricing
              && (
                <div
                  onClick={() => this.handleSelectNonePricing()}
                  className={`Popover-Insurance-Box cur-pointer ${_.isUndefined(selectedPricing) ? 'Dark-Green-bg Popover-Insurance-Box-Selected' : ''}`}
                >
                  <div>
                    {I18n.t('batches.label.none')}
                  </div>
                  <div />
                </div>
              )
            }
            {extraRequirement.pricings.map((pricing) => {
              const checked = !_.isUndefined(selectedPricing) && selectedPricing.id === pricing.id
              return (
                <div
                  onClick={() => this.handleSelectPricing(pricing)}
                  key={pricing.id}
                  className={`Popover-Insurance-Box cur-pointer ${checked ? 'Dark-Green-bg Popover-Insurance-Box-Selected' : ''}`}
                >
                  <div>
                    {pricing.display_level_price}
                  </div>
                  <div>
                    {pricing.display_fees}
                  </div>
                </div>
              )
            })}
          </div>
          {this.renderPopupItem(findPopupItem(popup, ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX))}
          <div className="Popover-Insurance-Action">
            <button
              type="button"
              onClick={() => this.handleClose()}
              className="white-text white-border Button Button-Default"
            >
              {I18n.t('webapp.action.cancel')}
            </button>
            <button
              type="button"
              onClick={() => this.handleConfirm()}
              className="green Button Button-Default"
            >
              {I18n.t('webapp.action.confirm')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ExtraRequirementPopover.defaultProps = {
  assignedDriver: undefined,
}

ExtraRequirementPopover.propTypes = {
  assignedDriver: PropTypes.shape({}),
  extraRequirement: PropTypes.shape({}).isRequired,
  updateExtraRequirementActions: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  allowNonePricing: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  isSelectPricing: PropTypes.bool.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraRequirementPopover)
