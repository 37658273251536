import StorageKeys from 'constants/storage-keys'
import apiClient from 'services/axiosApp'

let originDomain = window.location.origin
if (['lex.deliveree.com', 'localhost'].includes(window.location.hostname)) {
  originDomain = 'https://webapp.test.deliveree.com'
}

const areaApi = {
  getServiceArea: async (areaID) => {
    try {
      const res = await apiClient.get(`/api/v3/service_areas/${areaID}`)
      return res?.data || {}
    } catch (err) {
      throw new Error(err)
    }
  },
  getAreas: async (params) => {
    try {
      const res = await apiClient.get(`/api/v3/service_areas`, { params })
      return res?.data
    } catch (err) {
      throw new Error(err)
    }
  },
  getCurrentArea: async (params) => {
    try {
      const res = await apiClient.get('/api/v3/service_areas/current_area', { params })
      const areaId = res?.data?.id
      if (areaId) window.localStorage.setItem(StorageKeys.AREA_ID, areaId)
      return areaId || null
    } catch (err) {
      throw new Error(err)
    }
  },
  getServiceAreaLonghaulPickupZones: async (countryCode, callback) => {
    try {
      const res = await apiClient.get('/api/v3/service_areas/long_haul_pickup_zones', { params: { country_code: countryCode } })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  actionReasons: async (areaID, params, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/service_areas/${areaID}/action_reasons`, {
        params
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  setNewCurrentAreaSession: (areaId) => apiClient.get(`${originDomain}/business/set_new_current_area_session?area_id=${areaId}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),
  setNewCurrentLangCodeSession: (lang) => apiClient.get(`${originDomain}/business/set_new_language_session?lang=${lang}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
  }),
  getCurrentLangCodeSession: () => apiClient.get(`${originDomain}/business/get_current_language_session`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
  })
}

export default areaApi
