import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const isOptimizedSlice = createSlice({
  name: 'isOptimized',
  initialState,
  reducers: {
    updateOptimizeState: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const isOptimizedActionsCreator = isOptimizedSlice.actions

export default isOptimizedSlice
