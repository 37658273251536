import React from 'react'
import PropTypes from 'prop-types'
// UTILS
import { OldSocialLogin as SocialLogin } from 'react-social-login'
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'

// ACTIONS
// COMPONENTS
import InputPhoneNumber from '../../common/InputPhoneNumber'
// CONSTANTS
import {
  FACEBOOK_ICON,
  GOOGLE_ICON,
} from 'constants/imageConstants'
import { FACEBOOK_ID, GOOGLE_KEY } from 'constants/appConstants'
// ASSETS
import $ from 'jquery';
import _ from 'lodash'
import { Link } from 'react-router-dom'

class ModalSignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: true,
    }
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.handleRefNumber = this.handleRefNumber.bind(this)
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
  }

  componentDidMount() {
    const { countryCode } = this.props

    $.validator.addMethod('phoneNumber', value => /^\+?\d+$/.test(value), I18n.t('validations.digits_only'))

    $(this.form).validate({
      rules: {
        'customer[email]': {
          required: true,
          email: true
        },
        'customer[phone]': {
          required: true,
          phoneNumber: true
        },
        'customer[first_name]': {
          required: true
        },
        'customer[last_name]': {
          required: true
        },
        'customer[password]': {
          required: true,
          minlength: 6
        },
      },
      messages: {
        'customer[email]': {
          required: I18n.t('validations.required_email'),
          email: I18n.t('validations.email')
        },
        'customer[phone]': {
          required: I18n.t('validations.required_phone'),
          // number: I18n.t('validations.digits_only')
        },
        'customer[first_name]': {
          required: I18n.t('validations.required')
        },
        'customer[last_name]': {
          required: I18n.t('validations.required')
        },
        'customer[password]': {
          required: I18n.t('validations.required_password'),
          minlength: I18n.t('validations.minlength_password', { number: 6 })
        },
      },
      highlight: element => (
        // eslint-disable-next-line no-sequences
        $(element).parents('.FormGroup').addClass('error'),
        $(element).parents('.Input').addClass('error')
      ),
      unhighlight: element => (
        // eslint-disable-next-line no-sequences
        $(element).parents('.FormGroup').removeClass('error'),
        $(element).parents('.Input').removeClass('error')
      ),
      errorClass: 'FormGroup-explanation',
      errorPlacement: (error, element) => {
        error.insertAfter($(element).parents('.Input'))
      },
      submitHandler: (form, event) => {
        event.preventDefault()
        this.onSubmitHandler()
        return false
      }
    })

    this.checkPosition()
    $("[name='customer[country_code]']").val(countryCode)
  }

  onSubmitHandler() {
    const {
      checkAfterSignedUp,
      submitFormSignUp,
      paramSignUp,
      extraInfos,
    } = this.props
    const params = {
      country_code: $("[name='customer[country_code]']").val(),
      email: this.signUpEmail.value,
      phone: paramSignUp.phone,
      first_name: this.signUpFirstName.value,
      last_name: this.signUpLastName.value,
      business_name: this.businessName.value,
      referred_by: this.signUpReferredBy.value,
      password: this.signUpPassword.value,
      access_token: paramSignUp.accessToken || paramSignUp.access_token,
      provider: paramSignUp.provider,
      invitation_token: extraInfos.invitation_token
    }
    if (extraInfos?.enable_sms_verification) {
      checkAfterSignedUp(params)
    }
    if (extraInfos.flowSignUpSocial) {
      params.identity = extraInfos.identity
    }
    submitFormSignUp(params)
  }

  onFieldChanges(e, key) {
    const {
      paramSignUp,
      updateCurrentParamSignUp,
    } = this.props
    paramSignUp[key] = e.target.value
    updateCurrentParamSignUp(paramSignUp)
  }

  checkPosition() {
    if ($(this.form).height() > $(this.form).parent().height()) {
      $(this.form).addClass('Box-Custom')
    } else {
      $(this.form).removeClass('Box-Custom')
    }
  }

  toggleShowPassword() {
    this.setState(previousState => ({ showPassword: !previousState.showPassword }))
  }

  handleRefNumber(signUpPhone) {
    if (signUpPhone) {
      this.signUpPhone = signUpPhone.props
    }
  }

  handleCustomerPhoneChange(phone) {
    const { paramSignUp, updateCurrentParamSignUp } = this.props
    _.assign(paramSignUp, { phone })
    updateCurrentParamSignUp(paramSignUp)
  }

  render() {
    const { showPassword } = this.state
    const {
      countryCode,
      termsAndConditionsLink,
      privacyPolicyLink,
      paramSignUp,
      closeModal,
      customerSignUpDirect,
      goToSignIn,
      extraInfos,
      socialSignUpRedirect,
      handleSocialLogin
    } = this.props
    return (
      <div className="visible vertical-scroll Modal" ref={(rf) => { this.signupModal = rf }}>
        <div className="SignupBox vertical-scroll LoginBoxCustom">
          <form className="Box" ref={(rf) => { this.form = rf }}>
            {!customerSignUpDirect && !socialSignUpRedirect && <span className="LoginBoxCustom-Close" onClick={() => closeModal()}>
              <i className="b material-icons Icon">
                close
              </i>
            </span>}
            <div className="Box-Head">
              <p className="SignupBox-line">
                {I18n.t('devise_template.already_a_member', { app_name: commonUtils.appName() })}
                &nbsp;
                {customerSignUpDirect ? <Link to='/customers/sign_in'>{I18n.t('devise_template.log_in_here')}</Link>:
                <a
                  className="cur-pointer"
                  onClick={goToSignIn}
                >
                  {I18n.t('devise_template.log_in_here')}
                </a>}
              </p>
            </div>
            <div className="Box-Content">
              <div className="Form">
                <div className="flex w100">
                  <div className="flex-index mr10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.email_address')}
                      </label>
                      <div className={`Input ${extraInfos.flowSignUpSocial && 'disabled'}`}>
                        <input
                          type="text"
                          tabIndex="1"
                          value={paramSignUp ? paramSignUp.email : ''}
                          name="customer[email]"
                          ref={(signUpEmail) => { this.signUpEmail = signUpEmail }}
                          onChange={e => this.onFieldChanges(e, 'email')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.sign_up_mobile_phone')}
                      </label>
                      <InputPhoneNumber
                        isWebApp
                        customRef={this.handleRefNumber}
                        closestElement="signup-number"
                        telInputProps={{ tabIndex: 1 }}
                        phoneNumber={paramSignUp ? paramSignUp.phone : ''}
                        countryCode={countryCode}
                        phoneField="customer[phone]"
                        countryCodeField="customer[country_code]"
                        onPhoneChange={this.handleCustomerPhoneChange}
                        onPhoneBlur={this.handleCustomerPhoneChange}
                        onPhoneFocus={this.handleCustomerPhoneChange}
                      />
                    </div>
                    <input type="hidden" name="customer[country_code]" id="customer_country_code" />
                  </div>
                </div>
                <div className="flex w100">
                  <div className="flex-index mr10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.first_name')}
                      </label>
                      <div className={`Input ${extraInfos.flowSignUpSocial && 'disabled'}`}>
                        <input
                          type="text"
                          tabIndex="1"
                          name="customer[first_name]"
                          value={paramSignUp ? paramSignUp.firstName : ''}
                          ref={(signUpFirstName) => { this.signUpFirstName = signUpFirstName }}
                          onChange={e => this.onFieldChanges(e, 'firstName')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.last_name')}
                      </label>
                      <div className={`Input ${extraInfos.flowSignUpSocial && 'disabled'}`}>
                        <input
                          type="text"
                          tabIndex="1"
                          name="customer[last_name]"
                          value={paramSignUp ? paramSignUp.lastName : ''}
                          ref={(signUpLastName) => { this.signUpLastName = signUpLastName }}
                          onChange={e => this.onFieldChanges(e, 'lastName')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w100">
                  <div className="flex-index mr10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.business_name')}
                      </label>
                      <div className="Input">
                        <input
                          type="text"
                          tabIndex="1"
                          name="customer[business_name]"
                          value={paramSignUp ? paramSignUp.businessName : ''}
                          ref={(businessName) => { this.businessName = businessName }}
                          onChange={e => this.onFieldChanges(e, 'businessName')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-index ml10">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.referred_by')}
                      </label>
                      <div className="Input">
                        <input
                          type="text"
                          tabIndex="1"
                          name="customer[referred_by]"
                          value={paramSignUp ? paramSignUp.referredBy : ''}
                          ref={(signUpReferredBy) => { this.signUpReferredBy = signUpReferredBy }}
                          onChange={e => this.onFieldChanges(e, 'referredBy')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w100">
                  <div className="flex-index">
                    <div className="FormGroup">
                      <label className="FormGroup-label">
                        {I18n.t('devise_template.sign_up_password')}
                      </label>
                      <div className="Input Custom">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          tabIndex="1"
                          name="customer[password]"
                          value={paramSignUp ? paramSignUp.password : ''}
                          ref={(signUpPassword) => { this.signUpPassword = signUpPassword }}
                          onChange={e => this.onFieldChanges(e, 'password')}
                        />
                        <label className="Input-Icon">
                          <input
                            type="checkbox"
                            className="hidden"
                            onChange={this.toggleShowPassword}
                          />
                          <i className={`b material-icons Icon ${showPassword ? 'active-eye-icon' : ''}`}>
                            visibility
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Box-Actions">
              <input className="green Button" type="submit" value={I18n.t('devise_template.create_my_account')} />
            </div>
            <div className="copyright Box-Actions">
              <div className="w400">
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('devise_template.by_clicking',
                      {
                        term_link: termsAndConditionsLink,
                        privacy_link: privacyPolicyLink,
                        app_name: commonUtils.appName()
                      })
                  }}
                />
              </div>
              <div className="line10" />
            </div>
            <div className="Box-Content-Social-Line">
              <h5>
                {I18n.t('customers.sessions.new.or_sign_up_with')}
              </h5>
              <div className="line" />
            </div>
            <div className="Box-Content">
              <div className="Box-Content-Social">
                <SocialLogin
                  provider="facebook"
                  autoCleanUri
                  appId={FACEBOOK_ID}
                  callback={handleSocialLogin}
                  className="facebook-login"
                >
                  <a className="Button social-facebook">
                    <img src={FACEBOOK_ICON} />
                    <span>
                      {I18n.t('customers.sessions.new.login_with_facebook')}
                    </span>
                  </a>
                </SocialLogin>
                <SocialLogin
                  provider="google"
                  autoCleanUri
                  appId={GOOGLE_KEY}
                  callback={handleSocialLogin}
                  className="google-login"
                >
                  <a className="Button social-google">
                    <img src={GOOGLE_ICON} />
                    <span>
                      {I18n.t('customers.sessions.new.login_with_google')}
                    </span>
                  </a>
                </SocialLogin>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ModalSignUp.defaultProps = {
  customerSignUpDirect: false,
  socialSignUpRedirect: false
}

ModalSignUp.propTypes = {
  paramSignUp: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
  goToSignIn: PropTypes.func.isRequired,
  submitFormSignUp: PropTypes.func.isRequired,
  checkAfterSignedUp: PropTypes.func.isRequired,
  updateCurrentParamSignUp: PropTypes.func.isRequired,
  termsAndConditionsLink: PropTypes.string.isRequired,
  privacyPolicyLink: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  handleSocialLogin: PropTypes.func.isRequired,
  customerSignUpDirect: PropTypes.bool,
  socialSignUpRedirect: PropTypes.bool,
}

export default ModalSignUp
