import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = null

const estimatedWorkingTimeSlice = createSlice({
  name: 'estimatedWorkingTime',
  initialState,
  reducers: {
    updateEstimatedWorkingTime: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const estimatedWorkingTimeActionsCreator = estimatedWorkingTimeSlice.actions

export default estimatedWorkingTimeSlice
