import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UP_DOWN_ICON } from 'constants/imageConstants'

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
  }
}
class CustomSelect extends Component {
  constructor(props) {
    super(props)
    const { itemList } = this.props

    this.state = {
      openDropdown: false,
      selection: itemList[0],
      itemList
    }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.toggleShowDropdown = this.toggleShowDropdown.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ openDropdown: false })
    }
  }

  toggleShowDropdown() {
    const { openDropdown } = this.state
    this.setState({
      openDropdown: !openDropdown
    })
  }

  handleSelect(item) {
    const { onChange } = this.props
    if (onChange) {
      onChange(item.value)
    }
    this.setState({
      selection: item,
      openDropdown: false
    })
  }

  render() {
    const {
      openDropdown,
      selection,
      itemList
    } = this.state

    return (
      <div ref={this.setWrapperRef} className="custom-select-wrapper">
        <div
          className="custom-select-header"
          role="button"
          onClick={this.toggleShowDropdown}
        >
          <div className="custom-select-header__title">
            <p className="custom-select-header__title--bold">
              <span
                className="vertical-align-top"
                style={{ fontSize: 15, fontWeight: 'normal' }}
              >
                {selection && selection.name}
              </span>
            </p>
          </div>
          <div className="custom-select-header__action flex-center">
            <img src={UP_DOWN_ICON} />
          </div>
        </div>
        {openDropdown && (
          <ul className="custom-select-list vertical-scroll">
            {itemList.map(item => (
              <li
                key={item.value}
                className={`custom-select-list-item
                  ${selection.value === item.value ? 'active-selected-row' : ''}`}
              >
                <div
                  className="Shorted-Text"
                  style={{ width: '100%' }}
                  title={item.name}
                  onClick={() => this.handleSelect(item)}
                >
                  <span className="custom-select-list-item__name">
                    {item.name}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

CustomSelect.propTypes = {
  itemList: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired
}

CustomSelect.defaultProps = {
}

export default connect(mapStateToProps, null)(CustomSelect)
