import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  shouldGoToStep2: false,
}

const stepNewLongHaulSlice = createSlice({
  name: 'stepNewLongHaul',
  initialState,
  reducers: {
    shouldGoToStep2: (state, action: PayloadAction<any>) => {
      state.shouldGoToStep2 = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => state)
  },
})
export const stepNewLongHaulActionsCreator = stepNewLongHaulSlice.actions

export default stepNewLongHaulSlice
