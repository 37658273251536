import React from 'react'
import { useNavigate } from 'react-router-dom'

interface WithNavigateProps {}

export const withNavigate = <P extends object>(Component: React.ComponentType<P>) => (
  props: React.ComponentType<P & WithNavigateProps>
) => {
  const navigate = useNavigate()
  return <Component {...(props as P)} navigate={navigate} />
}
