import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const recentLocationsSlice = createSlice({
  name: 'recentLocations',
  initialState,
  reducers: {
    updateRecentLocations: (state, action: PayloadAction<any>) => {
      return _.map(action.payload, (location) =>
        _.assign(location, {
          recentLocationId: location.id,
        })
      )
    },
  },
  extraReducers: () => {},
})

export const recentLocationsActionsCreator = recentLocationsSlice.actions

export default recentLocationsSlice
