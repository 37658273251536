import CommonUtils from './common'
import $ from 'jquery'
import _ from 'lodash'
import I18n from 'i18n/i18n'

export const getLocationDisplayInfos = (length, index, className = '') => {
  let label = ''
  let newClassName = ''
  if (index === 0) {
    label = I18n.t('label.pin_from')
    newClassName = `${className} blue Pin cur-pointer`
  } else if (length === 2) {
    label = I18n.t('label.pin_to')
    newClassName = `${className} yellow Pin cur-pointer`
  } else {
    label = index
    newClassName = `${className} yellow Pin cur-pointer`
  }
  return { label, className: newClassName }
}
export const hideCountryOnAddress = (addressComponents, name) => {
  const countryObj = _.find(addressComponents, (comp => _.includes(comp.types, 'country')))
  if (countryObj && _.includes(name, countryObj.long_name) && name.includes(',')) {
    return name.split(',').slice(0, -1).join(',')
  }
  return name
}

/* eslint-disable no-param-reassign */
export const compareValidLocations = (locations, otherLocations, fields = ['lat', 'lng']) => {
  locations = locations
    .filter(({ lat, lng }) => (lat && lng))
    .map(item => (_.pick(item, fields)))

  otherLocations = otherLocations
    .filter(({ lat, lng }) => (lat && lng))
    .map(item => (_.pick(item, fields)))

  return _.isEqual(locations, otherLocations)
}
/* eslint-enable no-param-reassign */

export const LocationPopover = {
  init: (input, popover, box, config, elementConfig, isOSA = false, isErrorFDMegazone = false) => {
    const offset = input?.getBoundingClientRect() || {}
    const popoverH = popover + config.popover
    let pageH = window.innerHeight
    if (CommonUtils.detectSmallLaptop()) {
      pageH /= 0.8
    }
    if (config.type === 'modal') {
      const calPopup = pageH - box
      const top = offset.top - (calPopup / 2) - config.top
      const isBottom = offset.top > (pageH / 2)
      const arrowTop = (popoverH - (pageH - top) + calPopup + config.arrow)
      LocationPopover.show(isBottom, pageH, popoverH, top, offset, arrowTop, elementConfig)
    }
    if (config.type === 'new-booking' || config.type === 'no-select-input' || config.type === 'long-haul') {
      const top = offset.top - config.top
      const isBottom = offset.top > (pageH / 2)
      const arrowTop = popoverH - (pageH - top) + config.arrow
      LocationPopover.show(isBottom, pageH, popoverH, top, offset, arrowTop, elementConfig)
    }
    if (config.type === 'multiple' || config.type === 'multiple-no-select-input') {
      const target = _.isUndefined(config.eventTarget) ? input : config.eventTarget.target
      const top = offset.top - config.top
      const indexOffset = $(target).closest('.BookingWizard').offset().left + $(target).closest('.BookingWizard').width() + 20
      const popoverW = config.popoverw + 36
      const overflowXScroll = config.checkScroll
      const isBottom = (offset.top > (pageH / 2) + config.arrow_position)
      const arrowTop = (popoverH - (pageH - top) + config.arrow_position + overflowXScroll)
      let checkOnlyMultipleArrow = false
      const newElementConfig = elementConfig
      if (window.innerWidth - indexOffset < popoverW) {
        newElementConfig.element_left_position = isErrorFDMegazone
          ? elementConfig.element_left_position_custom - 5 : elementConfig.element_left_position_custom
        newElementConfig.arrow_element_top = isErrorFDMegazone
          ? elementConfig.arrow_element_top_custom + 5 : elementConfig.arrow_element_top_custom
        checkOnlyMultipleArrow = config.type === 'multiple-no-select-input'
      }
      let checkOnlyMultiple = offset.top + popoverH > $('.MultipleBookingLayout').offset().top + $('.MultipleBookingLayout').height() + 30
      if (isOSA || isErrorFDMegazone) {
        checkOnlyMultiple = true
      }
      LocationPopover.show(
        isBottom, pageH, popoverH, top, offset, arrowTop,
        newElementConfig, checkOnlyMultiple, checkOnlyMultipleArrow, isErrorFDMegazone,
      )
    }
    if (config.type !== 'no-select-input' && config.type !== 'long-haul' && config.type !== 'multiple-no-select-input') {
      LocationPopover.select(input)
    }
  },
  select: (input) => {
    input.select()
  },
  show: (
    isBottom, pageH, popoverH, top, offset, arrowTop,
    elementConfig, checkOnlyMultiple, checkOnlyMultipleArrow,
  ) => {
    const element = elementConfig.element
    const arrowElement = elementConfig.arrow_element
    if (checkOnlyMultipleArrow) {
      element.find('.BoxCustom-Close').css('left', '-12px')
    }
    function showElementTop() {
      element
        .addClass('visible')
        .css('bottom', 'auto')
        .css('left', elementConfig.element_left_position)
        .css('z-index', elementConfig.element_index_position)
        .css('top', `${top}px`)
    }
    function showElementBottom() {
      element
        .addClass('visible')
        .css('top', 'auto')
        .css('left', elementConfig.element_left_position)
        .css('z-index', elementConfig.element_index_position)
        .css('bottom', '0')
    }
    function showArrowTop(type) {
      arrowElement.css('top', elementConfig.arrow_element_position)
      if (type === 'multiple' || checkOnlyMultipleArrow) {
        arrowElement.css('left', elementConfig.arrow_element_top)
      }
    }
    function showArrowBottom(type) {
      arrowElement.css('top', `${arrowTop}px`)
      if (type === 'multiple' || checkOnlyMultipleArrow) {
        arrowElement.css('left', elementConfig.arrow_element_top)
      }
    }
    if (checkOnlyMultiple) {
      if (
        (!isBottom && (pageH / 2 > popoverH) && !checkOnlyMultiple)
        || (pageH - offset.top > popoverH && !checkOnlyMultiple)
        || popoverH < 250
      ) {
        showElementTop()
        showArrowTop('multiple')
      } else {
        showElementBottom()
        showArrowBottom('multiple')
      }
    } else if ((!isBottom && (pageH / 2 > popoverH)) || (pageH - offset.top > popoverH)) {
      showElementTop()
      showArrowTop()
    } else {
      showElementBottom()
      showArrowBottom()
    }
  },
  closeClass: (element, stepClass) => {
    element.removeClass(stepClass)
  },
  addClass: (element, className) => {
    element.addClass(className)
  },
  toggleClass: (element, className) => {
    element.toggleClass(className)
  },
  closeModalMultiple: () => {
    LocationPopover.closeClass($('.Info.Popover'), 'visible')
    $('.MultipleBookingLayout .overlay-multiple, .MultipleBookingLayout .BookingWizard:not(".Custom-Step"), .Services-PopupEye').attr('style', '')
  },
  closeModalSingle: (selector) => {
    LocationPopover.closeClass($(`${selector} .Info.Popover`), 'visible')
    $(`.MultipleBookingLayout .overlay-multiple, ${selector}, ${selector} .Services-PopupEye`).attr('style', '')
  },
  checkboxStep06: (tooltip) => {
    // Reset
    $('.Batch-Left-Toastr').removeClass('Show')
    // Element Config
    $(tooltip).addClass('Show')
    $(tooltip).css({
      // 'margin-top': -20 - ($(tooltip).height()/3),
      top: $('.Batch-Left-Toastr.Show').closest('li.Custom-Scroll-List').position().top - 15
    })
    LocationPopover.setTimeOutRemoveClass(tooltip, 'Show', 3000)
  },
  checkboxStepError: (tooltip) => {
    $(tooltip).addClass('Show')
    LocationPopover.setTimeOutRemoveClass(tooltip, 'Show', 3000)
  },
  setTimeOutRemoveClass: (tooltip, className, timeout) => {
    setTimeout(() => {
      $(tooltip).removeClass(className)
    }, timeout)
  },
  customizeAutocompleteModal: (boolean) => {
    if (boolean) {
      $('body').removeClass('Step-AutoComplete-Multiple')
      $('body').addClass('Step-AutoComplete-Modal')
    } else {
      $('body').removeClass('Step-AutoComplete-Modal')
      $('body').addClass('Step-AutoComplete-Multiple')
    }
  },
  customizeAutocompleteClass: (input, boolean, className) => {
    if (boolean) {
      $('body').addClass(className)
      $(document).mouseup((e) => {
        if (!input.is(e.target) && input.has(e.target).length === 0) {
          $('body').removeClass(className)
        }
      })
    }
  },
  toggleContactPopovers: (config) => {
    let element = null
    let checkContactPopoversOpen = false

    if (config.case === 'modal') {
      if ($(`.MultipleBookingLayout .BookingWizard-Popup #${config.bookingID}-locations-popup`).find('.Info.Popover.visible').length !== 0) {
        element = $(`.MultipleBookingLayout .BookingWizard-Popup #${config.bookingID}-locations-popup .location-sortable .Locations-Customs-SortableList > div:last-child .Input > input`)
        checkContactPopoversOpen = true
      }
    }
    if (config.case === 'modal-BatchEZ') {
      if ($(`#${config.bookingID}`).find('.Info.Popover.visible').length !== 0) {
        element = $(`#${config.bookingID} .location-sortable-modal.ui-sortable > div:last-child .Input > input`)
        checkContactPopoversOpen = true
      }
    }
    if (config.case === 'new-booking') {
      if ($('.BookingWizard .Locations-Customs').find('.Info.Popover.visible').length !== 0) {
        element = $(`.BookingWizard .Locations-Customs .Locations-Customs-SortableList > div:last-child input#booking_locations_attributes_${config.bookingID}_name`)
        checkContactPopoversOpen = true
      }
    }
    if (config.case === 'multiple') {
      if ($(`.MultipleBookingLayout #BookingWizard-${config.bookingID}`).find('.Info.Popover.visible').length !== 0) {
        element = $(`.MultipleBookingLayout #BookingWizard-${config.bookingID} .Locations-Customs .Locations-Customs-SortableList > div:last-child .Input > input`)
        checkContactPopoversOpen = true
      }
    }

    if (checkContactPopoversOpen) {
      setTimeout(() => {
        element.click()
      }, config.timeout)
    }
  },
  updateAddFromContactBatchEZ: (location) => {
    const closestElement = $(`#booking_locations_attributes_icon_${location.temp_id}`).offset().top
    const menuElements = $('.Menu').outerHeight() || 0
    const pageHeadElements = $('.PageHead').outerHeight() || 0
    const customStepElements = $('.Custom-Step').outerHeight() || 0
    const batchTitleElements = $('.Batch-Custom .BatchUpload-Title').outerHeight() || 0
    const calcHeight = closestElement - menuElements - pageHeadElements - customStepElements - batchTitleElements
    $(`#booking_locations_attributes_${location.temp_id}`).css({
      left: $(`#booking_locations_attributes_icon_${location.temp_id}`).offset().left + 5,
      top: calcHeight + $('.ReactVirtualized__Grid').scrollTop() - 10
    })
  },
  checkScroll: (config) => {
    const calHeight = config.calc + config.padding_margin
    $(config.element).css({
      'max-height': `calc(100vh - ${calHeight}px)`,
    })
  },
  setFocus: (element, timeout) => {
    setTimeout(() => {
      element.focus()
      element.select()
    }, timeout)
  },
  showLocationContactPopup: (tempID) => {
    const closestElement = $(`#booking_locations_attributes_${tempID}`)
    const iconElement = $(`#booking_locations_attributes_icon_${tempID}`)

    LocationPopover.showPopup({
      closestElement,
      iconElement,
    })
  },
  showLocationCODPODPopup: (tempID) => {
    const closestElement = $(`#booking_locations_codpod_${tempID}`)
    const iconElement = $(`#booking_locations_cod_pod_icon_${tempID}`)

    LocationPopover.showPopup({
      closestElement,
      iconElement,
    })
  },
  showPopup: ({ closestElement, iconElement }) => {
    if (!_.size(closestElement)) return

    const buttonElement = $('.BatchUploadBook-actions')
    const scaleZoom = CommonUtils.detectSmallLaptop() ? (window.innerHeight / 100) * 15 : 0
    const locationPopup = closestElement.offset().top + closestElement.outerHeight()
    const bottomScreen = $(window).height() - buttonElement.outerHeight() + scaleZoom

    if (locationPopup > bottomScreen) {
      closestElement.css({
        marginTop: bottomScreen - locationPopup - 35
      })
      closestElement.find('.Popover-Arrow').css({
        top: iconElement.offset().top - closestElement.offset().top - 5,
      })
    }
  },
  checkPositionAutoComplete(getTopPosition, input) {
    return $('body').find(`.pac-container[style*="top: ${getTopPosition}px"] .pac-item`).length
      || $('body').find(`.pac-container[style*="top: ${getTopPosition - 1}px"] .pac-item`).length
      || $('body').find(`.pac-container[style*="top: ${getTopPosition + 1}px"] .pac-item`).length
      || input.val() === ''
  },
  isDislayOptimizeRoute(locations, roundTripDiscount) {
    if (_.size(locations) === 0) {
      return false
    }
    let validNumber = 0
    if (_.isUndefined(locations[0].lat) || _.isUndefined(locations[0].lng)) {
      return false
    }
    if (_.size(locations) < 3) {
      return false
    }
    if (_.size(locations) === 3 && roundTripDiscount) {
      return false
    }
    for (let i = 0; i < _.size(locations); i += 1) {
      if (locations[i].lat && locations[i].lng && _.size(_.trim(locations[i].name)) > 0) {
        validNumber += 1
      }
      if (validNumber >= 3) {
        return true
      }
    }
    return false
  },
  isDisableOptimizeRoute(locations) {
    if (_.size(locations) === 0) {
      return true
    }
    for (let i = 0; i < _.size(locations); i += 1) {
      const l = locations[i]
      if (_.size(l.name) > 0 && (_.isUndefined(l.lng) || _.isUndefined(l.lat))) {
        return true
      }
    }
    return false
  },
  isValidOptimizeLocations(locations) {
    if (_.size(locations) < 3) {
      return false
    }
    if (_.findIndex(locations, l => _.isUndefined(l.lat) && _.isUndefined(l.lng) && _.size(l.name) > 0) !== -1) {
      return false
    }
    if (_.size(_.filter(locations, l => !_.isUndefined(l.lat) && !_.isUndefined(l.lng) && _.size(l.name) > 0)) < 3) {
      return false
    }
    return true
  },
  resetInput(id) {
    $(`#booking_locations_attributes_${id}_name`).val('')
    $(`#booking_locations_attributes_${id}_name`).css({ height: 18 })
  }
}

export const hasApplyAutocomplete = (keyCode) => {
  // keycodes:
  // ctrl key: 17
  // cmd key: 91 (mac)
  // "c" key: 67
  const hasSkipKeyCodes = _.includes([17, 91], keyCode)
  if (hasSkipKeyCodes || (keyCode === 67 && !window.locationNameChanged)) {
    return false
  }
  window.locationNameChanged = false
  return true
}
