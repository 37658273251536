import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const paginateValuesSlice = createSlice({
  name: 'paginateValues',
  initialState,
  reducers: {
    updatePaginateValues: (state, action: PayloadAction<any>) => action.payload.pagination,
  },
  extraReducers: () => {},
})

export const paginateValuesActionsCreator = paginateValuesSlice.actions

export default paginateValuesSlice
