import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const totalSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {
    receiveTotalCount: (state, action: PayloadAction<any>) => action.payload.total_count,
  },
  extraReducers: () => {},
})

export const totalActionsCreator = totalSlice.actions

export default totalSlice
