import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// UTILS
import { LocationPopover } from 'utils/LocationUtil'
import { havePopup } from 'utils/common/popupable'
// API
// ACTIONS
// COMPONENTS
import PopupIcon from './popup/PopupIcon'
// CONSTANTS
import { POPUP_VEHICLE_TYPE_OPTIMIZE_ROUTE } from 'constants/common/popupConstants'
// ASSETS
import I18n from 'i18n/i18n'

class OptimizeRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  currentRoundTripDiscount() {
    const { booking, roundTripDiscount } = this.props
    if (!_.isUndefined(roundTripDiscount)) {
      return roundTripDiscount
    }
    return booking.round_trip_discount || false
  }

  renderOptimize() {
    const {
      locations,
      isChecked,
      handleOptimize,
    } = this.props
    const isDisable = LocationPopover.isDisableOptimizeRoute(locations)
    const disableCss = isDisable ? 'Disable' : ''
    const disableOption = isDisable ? 'disabled' : ''
    return (
      <div className={`Overlay-Checkbox Green-Checkbox ${disableCss}`}>
        <input
          id="optimize-route"
          type="checkbox"
          checked={isChecked}
          disabled={disableOption}
          onChange={e => handleOptimize(e.target.checked)}
        />
        <label htmlFor="optimize-route">
          <i className="b material-icons Icon">
            check
          </i>
          {I18n.t('webapp.new_booking.step_1.optimize_route')}
        </label>
      </div>
    )
  }

  render() {
    const {
      locations,
      vehicleType,
      isShowIcon,
      popupStyle
    } = this.props
    const isDisplay = LocationPopover.isDislayOptimizeRoute(locations, this.currentRoundTripDiscount())
    if (isDisplay === false) {
      return (null)
    }
    return (
      <div className="optimize-route">
        <div>
          {this.renderOptimize()}
        </div>
        {isShowIcon && havePopup(vehicleType, POPUP_VEHICLE_TYPE_OPTIMIZE_ROUTE)
          && (
            <div className="ml10 flex flex-center">
              <PopupIcon
                {...this.props}
                popupObject={vehicleType}
                popupType={POPUP_VEHICLE_TYPE_OPTIMIZE_ROUTE}
                renderNode={document.getElementById('BookingPopupEye')}
                icon="visibility"
                popupStyle={popupStyle}
              />
            </div>)}
      </div>
    )
  }
}

OptimizeRoute.propTypes = {
  handleOptimize: PropTypes.func.isRequired,
  booking: PropTypes.shape({}),
  vehicleType: PropTypes.shape({}),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  isChecked: PropTypes.bool,
  isShowIcon: PropTypes.bool,
  popupStyle: PropTypes.shape({}),
  roundTripDiscount: PropTypes.bool,
  errorDistanceList: PropTypes.arrayOf(PropTypes.string),
  outsideList: PropTypes.arrayOf(PropTypes.string)
}

OptimizeRoute.defaultProps = {
  booking: {},
  vehicleType: {},
  locations: [],
  isChecked: false,
  isShowIcon: false,
  popupStyle: {},
  roundTripDiscount: undefined,
  errorDistanceList: [],
  outsideList: []
}

export default OptimizeRoute
