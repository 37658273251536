import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = null

const isSavePreviousBookingIDSlice = createSlice({
  name: 'isSavePreviousBookingID',
  initialState,
  reducers: {},
  extraReducers: () => {},
})

export const isSavePreviousBookingIDActionsCreator = isSavePreviousBookingIDSlice.actions

export default isSavePreviousBookingIDSlice
