import React from 'react'
import PropTypes from 'prop-types'
// UTILS
import I18n from 'i18n/i18n'
class ModalWelcomeRevolution extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  clickButtonOk() {
    const { goToVerificationCode } = this.props
    goToVerificationCode()
  }

  clickButtonEditPhoneNumber() {
    const { editPhoneNumberForm } = this.props
    editPhoneNumberForm()
  }

  render() {
    const { currentCustomer } = this.props
    return (
      <div className="Verification">
        <div className="VerificationBox Custom-Scroll-Group zoom-scale-8">
          <div className="Box">
            <div className="Box-Head">
              <h3 className="larger-font default-medium-font">
                {I18n.t('webapp.label.welcome_to_revolution')}
              </h3>
            </div>
            <div className="Box-Content">
              <div
                className="VerificationBox-text"
                dangerouslySetInnerHTML={{ __html: I18n.t('webapp.label.send_sms_verification_code', { phone: currentCustomer.phone }) }}
              />
              <div className="Box-Actions">
                <button type="button" className="Button white-bg default-color" onClick={() => this.clickButtonEditPhoneNumber()}>
                  {I18n.t('webapp.label.edit_number')}
                </button>
                <button type="button" className="Button green" onClick={() => this.clickButtonOk()}>
                  {I18n.t('webapp.action.ok')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ModalWelcomeRevolution.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  editPhoneNumberForm: PropTypes.func.isRequired,
  goToVerificationCode: PropTypes.func.isRequired
}
export default ModalWelcomeRevolution
