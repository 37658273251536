import _ from 'lodash'
import { attachmentsActionsCreator } from 'store/toolkit/newBooking/attachments.reducer'

export const updateAttachments = attachments =>dispatch => dispatch(attachmentsActionsCreator.updateAttachments(attachments))
export const updateAttachment = (index, attrs) =>dispatch => dispatch(attachmentsActionsCreator.updateAttachment({
  index,
  attrs
}))


export const setAutoAttachments = bookingAttachments => (dispatch) => {
  const attachments = []
  const autoAttachments = bookingAttachments || []
  _.forEach(_.range(3), (index) => {
    let autoAttachment = autoAttachments[index]
    if (_.isEmpty(autoAttachment) || (!_.isEmpty(autoAttachment) && _.isEmpty(autoAttachment.document_url))) {
      autoAttachment = {
        id: undefined,
        document_url: undefined,
        document_content_type: undefined,
        document_file_name: undefined,
        document_type: undefined,
        allow_to_delete: true,
        file: undefined
      }
    } else {
      _.assign(autoAttachment, { file: undefined })
    }
    attachments.push(autoAttachment)
  })
  dispatch(attachmentsActionsCreator.updateAttachments(attachments))
}
