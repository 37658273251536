import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''
const selectedServiceTypeIDSlice = createSlice({
  name: 'selectedServiceTypeID',
  initialState,
  reducers: {
    setSelectedServiceTypeId: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const selectedServiceTypeIDActionsCreator = selectedServiceTypeIDSlice.actions

export default selectedServiceTypeIDSlice
