import React, { useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PaymentIntegrationAPI from 'api/payment-integration'
import { isCustomerEditBooking } from 'utils/new_booking/common'
import { calculate, updateBookingAction } from 'store/actions/common/bookingActionCreators'
import { paymentMethodSelected } from 'utils/eventTrackOnlinePayment'
import './PaymentMethod.scss'


const PaymentMethod = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const booking = useSelector(state => state.booking)
  const extraInfos = useSelector(state => state.extraInfos)
  const [paymentSettings, setPaymentSettings] = useState([])
  const { bankTransfer, bookingPaymentDetails } = booking

  const options = [
    {
      title: t('booking_details.label.pay_cash_to_driver'),
      content: t('booking_details.label.payment_select_text'),
      logoURL: null,
    }
  ]

  useEffect(() => {
    handleGetPaymentSettingAction()
  }, [])

  useEffect(() => {
    if (isCustomerEditBooking() && !_isEmpty(bookingPaymentDetails)) {
      const matchIndex = paymentSettings.findIndex(item => item.bankCode === bookingPaymentDetails.bank_code)
      if (matchIndex !== -1) {
        dispatch(updateBookingAction({ bankTransfer: paymentSettings[matchIndex] }))
      }
    }
  }, [bookingPaymentDetails?.id, paymentSettings])

  const handleGetPaymentSettingAction = async () => {
    const dataAPI = await PaymentIntegrationAPI.getPaymentSetting(extraInfos?.country_code)
    if (dataAPI.status === 200) {
      dataAPI.data.map((item) => {
        const newItem = {
          ...item,
          title: item.bankName,
          content: t('booking_details.label.payment_item_selected_text'),
        }
        return options.push(newItem)
      })
      setPaymentSettings(options)
    }
  }

  const handleClickPayment = (event, item) => {
    event.stopPropagation()
    paymentMethodSelected(item?.bankName ? 'Virtual Account' : 'Cash', item?.bankName)
    if (item?.bankCode) dispatch(updateBookingAction({ bankTransfer: item }))
    else dispatch(updateBookingAction({ bankTransfer: {}, payment_service_fee: 0 }))
    dispatch(calculate())
  }

  const renderBankItem = () => {
    return (
      <div className="bank-item-section">
        {paymentSettings.map((item, index) => {
          const checked = (_isEmpty(bankTransfer) && index === 0) || bankTransfer?.bankCode === item?.bankCode
          return (
            <div className={index < paymentSettings.length - 1 ? "bank-item-border-bottom" : "bank-item"} key={index}>
              <label className={`${isCustomerEditBooking() ? 'checkbox-circle-ceb' : 'checkbox-circle'}`}>
                <input
                  type="radio"
                  checked={checked}
                  onClick={(event) => handleClickPayment(event, item)}
                />
                <span className="checkmark" />
              </label>
              <div className="bank-info">
                <div className="bank-name-logo">
                  <div className={`bank-name ${checked ? "selected-bank" : ""}`}>
                    {item.title}
                  </div>
                  {item.logoURL && (
                    <div className="bank-logo">
                      <img src={item.logoURL} alt="bank-logo" />
                    </div>
                  )}
                </div>
                <div className="bank-content">
                  {item.content}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  
  if (_isEmpty(paymentSettings)) return null

  return (
    <div className="select-payment-container">
      <div className="header">
        {t('webapp.label.payment_method')}
      </div>
      {renderBankItem()}
    </div>
  )
}

export default PaymentMethod
