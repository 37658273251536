import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { remove } from 'lodash'

const initialState: any = []

const googleMarkersSlice = createSlice({
  name: 'googleMarkers',
  initialState,
  reducers: {
    addGoogleType: (state, action: PayloadAction<any>) => state.push(action.payload.googleMarker),
    removeGoogleType: (state, action: PayloadAction<any>) => {
      remove(state, { id: action.payload.id })
    },
  },
  extraReducers: () => {},
})
export const googleMarkersActionsCreator = googleMarkersSlice.actions

export default googleMarkersSlice
