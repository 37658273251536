import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = { data: '' }
const preTimeTypeSlice = createSlice({
  name: 'preTimeType',
  initialState,
  reducers: {
    changePrevTimeType: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
  extraReducers: () => {},
})
export const preTimeTypeActionsCreator = preTimeTypeSlice.actions

export default preTimeTypeSlice
