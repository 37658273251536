import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState: any = []

const dropoffIndexsSlice = createSlice({
  name: 'dropoffIndexs',
  initialState,
  reducers: {
    dropoffIndexs: (state, action: PayloadAction<any>) => {
      return action.payload || []
    },
  },
  extraReducers: () => {},
})

export const dropoffIndexsActionsCreator = dropoffIndexsSlice.actions

export default dropoffIndexsSlice
