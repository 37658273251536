import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = 'upcoming'

const tabsSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<any>) => action.payload.tab,
  },
  extraReducers: () => {},
})

export const tabsActionsCreator = tabsSlice.actions

export default tabsSlice
