import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

//UTILS
import I18n from 'i18n/i18n'
import commonUtils from 'utils/common'
// ACTIONS
// COMPONENTS
// CONSTANTS
import {
  POPUP_PICKUP_TIME_WARNING,
} from 'constants/common/popupConstants'

// ASSETS
import {
  V2_ICON_GREEN,
  V2_ICON_GREEN_TRANSPORTIFY,
} from 'constants/imageConstants'

function getPopupId(popupIdPrefix) {
  return `${popupIdPrefix}-${POPUP_PICKUP_TIME_WARNING}`
}

function closePopup(popupId) {
  $(`#${popupId}`).removeClass('visible')
}

class PickupTimeWarningPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  static getPopupContent(iconDefault) {
    return (
      <div className="Box Modified-Popup">
        <div className="Box-Icon">
          <img src={iconDefault} alt="icon" className="icon-modified-style" />
        </div>
        <div className="Box-Content">
          <div className="Normal">
            <h5 className="Normal-Booking-Title">
              {I18n.t('webapp.new_booking.time_type_not_available')}
            </h5>
            <div>
              <p className="center pre-line">
                {I18n.t('webapp.new_booking.reset_time_type_popup_content')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { popupIdPrefix } = this.props
    const icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    const loadPopup = PickupTimeWarningPopup.getPopupContent(icon)
    const popupId = getPopupId(popupIdPrefix)
    return (
      <div id={popupId} className="vertical-scroll Modal">
        <div className="Normal-Booking">
          {loadPopup}
          <div className="Normal-Booking-Actions Custom">
            <a className="White no-hover cur-pointer" onClick={() => closePopup(popupId)}>
              {I18n.t('webapp.action.ok')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

PickupTimeWarningPopup.propTypes = {
  popupIdPrefix: PropTypes.string,
}

PickupTimeWarningPopup.defaultProps = {
  popupIdPrefix: 'single-booking'
}

export default PickupTimeWarningPopup
