import { DELIVEREE_PLACES_SERVICE_URL } from 'constants/appConstants'
import apiClient from 'services/axiosApp'

const PlaceAPI = {
  loadRecentLocations: async (accessToken, callback) => {
    try {
      const res = await apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v1/locations`)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  setRecentLocation: async (accessToken, location, callback) => {
    const dataSend = {
      latitude: location.latitude,
      longitude: location.longitude,
      name: location.name,
      country_code: location.countryCode,
      radius: location.radius,
      pic_name: location.pic_name,
      pic_number: location.pic_number,
      address_detail: location.address_detail,
      contact_id: location.contact_id,
      location_name: location.location_name,
      address_components: location.address_components,
    }
    try {
      const res = await apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v1/locations`, dataSend)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  updateAddressComponent: async (accessToken, location, callback) => {
    const dataSend = {
      address_components: location.address_components,
      id: location.id
    }
    try {
      const res = await apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v1/locations`, dataSend)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default PlaceAPI
