import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { PICKUP_OPTIMISED_TIME } from 'constants/ez_spread_sheet/variables/stepUploadTabsDataConstants'

const initialState: any = {
  fileName: '',
  name: '',
  desc: '',
  bookings: [],
  batch_type: '',
  has_geocoder: false,
  keep_data: false,
  by_option_extra: {
    item_length: 0,
    item_names: [],
  },
  verify: {
    name: true,
    uploadedFiles: true,
    batchBookingsNumber: true,
    minRowNumber: true,
  },
  invalidRows: [],
  ignoredRows: [],
  loadingTally: true,
  pickup_time_mode: PICKUP_OPTIMISED_TIME,
  gridColumns: [],
  isSignInFinished: false,
}

const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    updateBatch: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload.attrs }),
    resetBatchBooking: (state, action: PayloadAction<any>) => {
      state.bookings = []
    },
    updateBatchBookings: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      let bookings = [...action.payload.bookings]
      bookings = _.sortBy(bookings, [(bk) => _.toInteger(bk.order)])
      bookings = bookings.map((booking) => ({
        ...booking,
        locations: _.sortBy(booking.locations, ['order']),
      }))
      _.assign(currentBatch, { bookings })

      return currentBatch
    },
    addNewBookings: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      return _.assign(currentBatch, { bookings: _.concat(currentBatch.bookings, { ...action.payload.booking }) })
    },
    deleteSelectedBookings: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      const bookings = [...action.payload.bookings]
      _.remove(bookings, { is_selected: true })
      _.assign(currentBatch, { bookings })
      return currentBatch
    },
    updateBooking: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      const newBookings = [...currentBatch.bookings]
      const bookingIndex = _.findIndex(newBookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex !== -1) {
        newBookings[bookingIndex] = { ...newBookings[bookingIndex], ...action.payload.booking }
        currentBatch.bookings = newBookings
      }

      return currentBatch
    },
    updateBookingLocation: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      const bookingIndex = _.findIndex(currentBatch.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex !== -1) {
        const booking = { ...currentBatch.bookings[bookingIndex] }
        const newLocations = [...booking.locations]
        const locationIndex = _.findIndex(
          booking.locations,
          (location: any) => location.temp_id === action.payload.location.temp_id
        )

        if (locationIndex !== -1) {
          newLocations[locationIndex] = { ...action.payload.location }
        }
        booking.locations = newLocations
        currentBatch.bookings[bookingIndex] = booking
      }
      return currentBatch
    },
    deleteBookingLocation: (state, action: PayloadAction<any>) => {
      const booking = _.find(state.bookings, { temp_id: action.payload.booking.temp_id })
      if (!_.isUndefined(booking)) {
        _.remove(booking.locations, { temp_id: action.payload.location.temp_id })
        // re-index order
        _.forEach(booking.locations, (loc, locIndex) => {
          _.assign(loc, { order: locIndex })
        })
      }
    },
    receiveDraftBookingIds: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      const responseIDs = action.payload.responseIDs
      _.map(responseIDs, (responseID) => {
        const tempBooking = _.find(currentBatch.bookings, { temp_id: responseID.temp_id })
        if (!_.isEmpty(tempBooking)) {
          _.assign(tempBooking, { draft_id: responseID.id })
        }
      })
      return currentBatch
    },
    updateTransitTime: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state, bookings: [...state.bookings] }
      const bookingIndex = _.findIndex(currentBatch.bookings, { temp_id: action.payload.booking.temp_id })
      if (bookingIndex > -1) {
        let booking = currentBatch.bookings[bookingIndex]
        // TODO: mutating directly original data source is not a good way.
        //  but atm I don't have time to refactor code for ezsheet such as BookingTimeTypePopup, VehicleTypePopup
        booking = {
          ...booking,
          ...action.payload.bookingAttrs,
        }
        if (booking.worst_transit_time === undefined) {
          delete booking.worst_transit_time
        }

        currentBatch.bookings[bookingIndex] = booking
      }

      return currentBatch
    },
    updateAllBookingsTransitTime: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state }

      return {
        ...currentBatch,
        bookings: _.map(currentBatch.bookings, (booking) => {
          const tallyData = _.find(action.payload, { tmp_id: booking.temp_id })

          return {
            ...booking,
            ...tallyData,
          }
        }),
      }
    },
    signInProgressFinished: (state, action: PayloadAction<any>) => {
      return { ...state, isSignInFinished: action.payload }
    },
  },
  extraReducers: () => {},
})

export const batchActionsCreator = batchSlice.actions

export default batchSlice
