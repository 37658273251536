import apiClient from 'services/axiosApp'

const BookingSubAccountTagAPI = {
  update: async (authenticationToken, bookingID, subAccountID, callback) => {
    try {
      const dataSend = {
        sub_account_tag_attributes: {
          sub_account_id: subAccountID
        }
      }
      const response = await apiClient.put(`/api/v3/bookings/${bookingID}/sub_account_tag`, dataSend, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(response)
    } catch (err) {
      throw new Error(err)
    }
  },
  delete: async (authenticationToken, bookingID, callback) => {
    try {
      const response = await apiClient.delete(`/api/v3/bookings/${bookingID}/sub_account_tag`, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(response)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default BookingSubAccountTagAPI
