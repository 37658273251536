import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  selectedVehicles: [],
}

const pagesSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    updateCurrentPage: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const pagesActionsCreator = pagesSlice.actions

export default pagesSlice
