import CustomerAPI from "api/customers";
import { PUBLIC_URL } from "constants/appConstants";
import StorageKeys from "constants/storage-keys";
import { CrossStorageClient } from "cross-storage";
import jwt_decode from 'jwt-decode'

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken)
  try {
    const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, {});
    storage.onConnect().then(function() {
      return storage.set(StorageKeys.ACCESS_TOKEN, accessToken);
    })
  } catch (error) {
    console.debug(error)
  }
}

export const parseJwt = (token: string) => {
  return token && jwt_decode(token)
}

// switch account from old webapp
const checkSessionToConvertToken = async () => {
  const result = await CustomerAPI.reConvertToken()
  if(result.status === 200 && result?.data?.access_token) {
    setAccessToken(result.data.access_token)
  }
}

export const handleConnectCrossStorage = () => {
  const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, {})
  storage
    .onConnect()
    .then(function () {
      return storage.get(StorageKeys.ACCESS_TOKEN)
    })
    .then(function (accessToken) {
      if(accessToken) {
        setAccessToken(accessToken)
        CustomerAPI.updateAuthenticationToken()
      } else {
        checkSessionToConvertToken()
      }
    })
}

export const handleInitToken = () => {
   // sync jwt token to FTL token
   const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
   const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, {})
   storage
     .onConnect()
     .then(function () {
       return storage.get(StorageKeys.ACCESS_TOKEN)
     })
     .then(function (accessToken) {
       let token = authenticationToken
       if (accessToken && authenticationToken && accessToken !== authenticationToken) {
         const assetToken: any = parseJwt(accessToken)
         const authToken: any = parseJwt(authenticationToken)
         if(assetToken?.iat > authToken?.iat) token = accessToken
       }
       setAccessToken(token)
       CustomerAPI.updateAuthenticationToken()
     }).catch(() => {
      CustomerAPI.updateAuthenticationToken()
     })
}

export const handleListenMessage = (event: { origin: any; data: string }) => {
  const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  if (event.origin === PUBLIC_URL) {
    let accessToken: any = typeof event.data === 'string' && event.data?.split(':')
    if (accessToken && accessToken?.length && accessToken[0] === 'auth') {
      accessToken = accessToken[1] || ''
      if(accessToken === authenticationToken) return
      setAccessToken(accessToken)
      window.location.reload()
    }
  }
}
