import apiClient from 'services/axiosApp'

const PopupAPI = {
  getAreaTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/area', {
        params,
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getVehicleTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/vehicle_type', {
        params,
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getServiceTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/service_type', {
        params,
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default PopupAPI
