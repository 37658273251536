import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const prevRouteSlice = createSlice({
  name: 'prevRoute',
  initialState,
  reducers: {
    updatePrevRoute: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const prevRouteActionsCreator = prevRouteSlice.actions

export default prevRouteSlice
