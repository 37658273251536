import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const polylineSlice = createSlice({
  name: 'polyline',
  initialState,
  reducers: {
    updatePolyline: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const polylineActionsCreator = polylineSlice.actions

export default polylineSlice
