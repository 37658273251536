import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// UTILS
import I18n from 'i18n/i18n'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'

class TextEditMode extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      textEdit: props.editModeText || ''
    }
    this.clearTextEditLocal = null
  }

  componentWillReceiveProps(nextProps) {
    const { editModeText } = this.props
    const { editModeText: newEditModeText } = nextProps
    if (editModeText !== newEditModeText) {
      if (!!editModeText && !newEditModeText) {
        const self = this
        this.clearTextEditLocal = setTimeout(() => {
          self.setState({ textEdit: '' })
        }, 1000)
      } else {
        clearTimeout(this.clearTextEditLocal)
        this.setState({ textEdit: newEditModeText })
      }
    }
  }

  render() {
    const { textEdit } = this.state
    const { editModeText } = this.props
    return (
      <div className="booking-edit-mode__container">
        <div className={`booking-edit-mode_block ${editModeText ? 'edit-mode__animation' : ''}`}>
          <div className="booking-edit-mode">
            {I18n.t('webapp.booking.you_in_editing_mode')}
          </div>
          <div className="booking-edit-mode error">
            {I18n.t('webapp.booking.field_can_not_be_edited', { name: textEdit })}
          </div>
        </div>
      </div>
    )
  }
}

TextEditMode.propTypes = {
  editModeText: PropTypes.string,
}

TextEditMode.defaultProps = {
  editModeText: ''
}

const mapStateToProps = state => ({
  editModeText: state.editModeText,
})

const mapDispatchToProps = dispatch => ({
  editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TextEditMode)
