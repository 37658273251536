import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'

const initialState = {
  status: DISCOUNT_CODE_STATUS.pending,
  value: '',
  invalidMessage: '',
}

const discountCodeSlice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    updateDiscountCode: (state: any, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
    updateDiscountCodeStatus: (state: any, action: PayloadAction<any>) => ({ ...state, status: action.payload }),
  },
  extraReducers: () => {},
})

export const discountCodeActionsCreator = discountCodeSlice.actions

export default discountCodeSlice
