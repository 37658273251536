export const RECEIVE_DRAFT_BOOKING_IDS = 'receive_draft_booking_ids'
export const STEP_OPTIONS = {
  single_route: 1,
  single_service: 2,
  single_price: 3,
  multiple_route: 4,
  multiple_service: 5,
  multiple_price: 6,
  batch_upload: 7,
  batch_match_columns: 8,
  batch_validate: 9,
  batch_book: 10
}
// add platform
export const DRAFT_BOOKING_PLATFORM_VAR_SINGLE = 'single'
export const DRAFT_BOOKING_PLATFORM_VAR_MULTIPLE = 'multiple'
export const DRAFT_BOOKING_PLATFORM_VAR_EZ = 'ez'
// step
export const MULTIPLE_ROUTE = 'multiple_route'
export const MULTIPLE_SERVICE = 'multiple_service'
export const MULTIPLE_PRICE = 'multiple_price'
export const BATCH_VALIDATE = 'batch_validate'
