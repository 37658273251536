import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const pickupTimeSlice = createSlice({
  name: 'pickupTime',
  initialState,
  reducers: {
    changePickupTime: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const pickupTimeActionsCreator = pickupTimeSlice.actions

export default pickupTimeSlice
