// DashBoardAnalyticsReducer
import dataChartBookingsSlice from './dataChartBookings.reducer'
import dataChartCostBreakdownSlice from './dataChartCostBreakdown.reducer'
import dataChartVehicleTypeSlice from './dataChartVehicleType.reducer'
import dataChartEmployeeSlice from './dataChartEmployee.reducer'
import dataChartDriversSlice from './dataChartDrivers.reducer'
import arrayDiffDaysSlice from './arrayDiffDays.reducer'


const dashBoardAnalyticsReducer = {
  // ===== DashBoardAnalyticsReducer
  dataChartBookings: dataChartBookingsSlice.reducer,
  dataChartVehicleType: dataChartVehicleTypeSlice.reducer,
  dataChartEmployee: dataChartEmployeeSlice.reducer,
  dataChartDrivers: dataChartDriversSlice.reducer,
  dataChartCostBreakdown: dataChartCostBreakdownSlice.reducer,
  arrayDiffDays: arrayDiffDaysSlice.reducer,

}

export default dashBoardAnalyticsReducer
