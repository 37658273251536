import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FULL_DAY, IMMEDIATE, LONG_HAUL, NOW, SCHEDULE } from 'constants/bookingConstants'

const initialState = [NOW, IMMEDIATE, SCHEDULE, FULL_DAY, LONG_HAUL]

const acceptableTimeTypesSlice = createSlice({
  name: 'acceptableTimeTypes',
  initialState,
  reducers: {
    setAcceptableTimeTypes: (state, action: PayloadAction<any>) => [...action.payload],
  },
  extraReducers: () => {},
})
export const acceptableTimeTypesActionsCreator = acceptableTimeTypesSlice.actions

export default acceptableTimeTypesSlice
