import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = ''

const jobOrderNumberSlice = createSlice({
  name: 'jobOrderNumber',
  initialState,
  reducers: {
    updateJobOrderNumber: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const jobOrderNumberActionsCreator = jobOrderNumberSlice.actions

export default jobOrderNumberSlice
