import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const serviceTypesCommonSlice = createSlice({
  name: 'serviceTypesCommon',
  initialState,
  reducers: {
    setServiceTypes: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const serviceTypesCommonActionsCreator = serviceTypesCommonSlice.actions

export default serviceTypesCommonSlice
