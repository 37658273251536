import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}
const subAccountTagPickedSlice = createSlice({
  name: 'subAccountTagPicked',
  initialState,
  reducers: {
    subAccountTagPicked: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const subAccountTagPickedActionsCreator = subAccountTagPickedSlice.actions

export default subAccountTagPickedSlice
