export const INDEX_0 = 0
export const INDEX_1 = 1
export const PASSWORD = 'password'
export const PASSWORD_CONFIRM = 'passwordConfirm'
export const NUMBER_6 = 6

// Modal type
export const CONTROL_MODAL = 'CONTROL_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const CONFIRM_PASSWORD_MODAL = 'CONFIRM_PASSWORD_MODAL'
export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL'
export const MERGE_MODAL = 'MERGE_MODAL'
export const SIGN_UP_MODAL = 'SIGN_UP_MODAL'
export const SWITCH_ACCOUNT_MODAL = 'SWITCH_ACCOUNT_MODAL'
export const SUB_ACCOUNT_MODAL = 'SUB_ACCOUNT_MODAL'
export const SIGN_IN_MODAL = 'SIGN_IN_MODAL'
export const REVOLUTION_MODAL = 'REVOLUTION_MODAL'
export const VERIFICATION_CODE_MODAL = 'VERIFICATION_CODE_MODAL'
export const EDIT_PHONE_NUMBER_MODAL = 'EDIT_PHONE_NUMBER_MODAL'
export const GO_TO_STEP1_MODAL = 'GO_TO_STEP1_MODAL'