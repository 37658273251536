import {
  useEffect, useMemo, useRef, useState
} from 'react'

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

export function useResizeObserver() {
  const [rect, setRect] = useState(defaultState)
  const frameId = useRef(0)
  const ref = useRef(null)

  const observer = useMemo(
    () => (typeof window !== 'undefined'
      ? new ResizeObserver((entries) => {
        const entry = entries[0]
        if (entry) {
          cancelAnimationFrame(frameId.current)

          frameId.current = requestAnimationFrame(() => {
            if (ref.current) {
              setRect(entry.contentRect)
            }
          })
        }
      })
      : null),
    []
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()

      if (frameId.current) {
        cancelAnimationFrame(frameId.current)
      }
    }
  }, [ref.current])

  return [ref, rect]
}

export function useElementSize() {
  const [ref, { width, height }] = useResizeObserver()
  return { ref, width, height }
}
