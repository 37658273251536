import React from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App.jsx'
import store from 'store/store'
import 'jquery-ui'
import 'jquery-ui-bundle'
import 'jquery-ujs'
import 'jquery-validation'
import 'jquery-ui-bundle/jquery-ui.css';
import './index.scss'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { REACT_APP_SENTRY, REACT_APP_SENTRY_ENV } from 'constants/appConstants'
import MetaTag from 'components/nav_bar/MetaTag.jsx';

if(REACT_APP_SENTRY) {
  Sentry.init({
    dsn: REACT_APP_SENTRY,
    environment: REACT_APP_SENTRY_ENV,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <MetaTag />
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


// TODO: deliveree_ror_webapp/src/assets/styles/share/new-tracking-booking/index.scss