import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  last_login_employ_id: null,
  isShow: false
}

const currentCustomerSlice = createSlice({
  name: 'currentCustomer',
  initialState,
  reducers: {
    updateCustomer: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    toggleProfilePanel: (state, action: PayloadAction<any>) => {
      state.isShow = action.payload
    },
  },
  extraReducers: () => {},
})

export const currentCustomerActionsCreator = currentCustomerSlice.actions

export default currentCustomerSlice
