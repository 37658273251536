import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''
const timeTypeUISlice = createSlice({
  name: 'timeTypeUI',
  initialState,
  reducers: {
    changeTimeTypeUI: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const timeTypeUIActionsCreator = timeTypeUISlice.actions

export default timeTypeUISlice
