import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const draftBookingsSlice = createSlice({
  name: 'draftBookings',
  initialState,
  reducers: {
    updateDraftBookings: (state: any, action: PayloadAction<any>) => action.payload.draftBookings,
    deleteDraftBooking: (state: any, action: PayloadAction<any>) => {
      _.remove(state, { id: action.payload.id })
    },
  },
  extraReducers: () => {},
})

export const draftBookingsActionsCreator = draftBookingsSlice.actions

export default draftBookingsSlice
