import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import _ from 'lodash'

import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'

class DynamicPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    const { isNotClickOutside } = this.props
    if (!isNotClickOutside) {
      document.addEventListener('keydown', this.escFunction, false)
      document.addEventListener('click', this.escFunction)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
    document.removeEventListener('click', this.escFunction)
  }

  escFunction(event) {
    if (event.keyCode === 27 || event.keyCode === 13) {
      this.handleOnclick()
    }
  }

  handleOnclick() {
    const {
      handleClickOutside, renderNode
    } = this.props
    if (!_.isUndefined(handleClickOutside)) {
      handleClickOutside()
    }
    Utils.hideCloseBtn(false)
    ReactDOM.unmountComponentAtNode(renderNode)
  }

  renderButtons() {
    const {
      renderDynamicButton
    } = this.props

    return !_.isUndefined(renderDynamicButton) && _.size(renderDynamicButton()?.props?.children)
      ? renderDynamicButton()
      : (
        <div
          className="close-modal-overlay"
          onClick={() => {
            this.handleOnclick()
          }}
        />
      )
  }

  render() {
    const {
      configStyle,
      isDefaultPopup,
      icon,
      title,
      maxHeightIcon,
      subTitle,
      specialClass,
      specialClassContent,
      renderDynamicContent,
    } = this.props

    let customHeight = ''

    switch (title) {
      case I18n.t('popup.driver_canceled.pay_cancel_fee'):
        customHeight = 'min-height-159'
        break
      case I18n.t('webapp.label.customer_cancel_title'):
        customHeight = 'height-219'
        break
      default:
        customHeight = ''
    }

    if (isDefaultPopup) {
      return (
        <div className={`vertical-scroll Modal Modal-Basic visible ${specialClass}`}>
          <div className="Box">
            <div className="Modal-Head Box-Head">
              {title}
            </div>
            <div className="Modal-Content Box-Content">
              <div className="Modal-SplitView">
                <div className="Normal">
                  {renderDynamicContent()}
                </div>
              </div>
            </div>
            {this.renderButtons()}
          </div>
        </div>
      )
    }

    return (
      <div
        className="vertical-scroll Modal visible"
        style={_.size(configStyle) ? Utils.modifyPopupMultiple(configStyle.scale, configStyle.id) : {}}
        data-bookingID={_.size(configStyle) ? configStyle.id : null}
      >
        <div className={`Popup normal-driver z-index-2 ${specialClass} ${subTitle.length > 0 ? 'with-icon' : ''}`}>
          <div className={`Box z-index-2 ${customHeight}`}>
            <div className="Box-Icon Box-Icon-Popup">
              <div className="Logo">
                {icon.length > 0
                  && (<img src={icon} alt="deliveree" className={maxHeightIcon ? 'm-h32px' : ''} />)
                }
              </div>
              {subTitle.length > 0
                && (
                  <div className="Box-Icon-Plate">
                    {subTitle}
                  </div>
                )
              }
            </div>
            <div className="Box-Content">
              <div className="Normal">
                {title.length > 0
                  && (
                    <div className="Popup-Booking-Normal">
                      <h5 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                  )
                }
                <div className={`Popup-Booking-Custom ${specialClassContent}`}>
                  {renderDynamicContent()}
                </div>
              </div>
            </div>
          </div>
          {this.renderButtons()}
        </div>
      </div>
    )
  }
}

DynamicPopup.propTypes = {
  configStyle: PropTypes.shape({}),
  isDefaultPopup: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  maxHeightIcon: PropTypes.bool,
  subTitle: PropTypes.string,
  specialClass: PropTypes.string,
  specialClassContent: PropTypes.string,
  renderNode: PropTypes.shape({}),
  renderDynamicContent: PropTypes.func.isRequired,
  renderDynamicButton: PropTypes.func,
  handleClickOutside: PropTypes.func,
  isNotClickOutside: PropTypes.bool
}

DynamicPopup.defaultProps = {
  configStyle: {},
  isDefaultPopup: false,
  icon: '',
  title: '',
  maxHeightIcon: false,
  subTitle: '',
  specialClass: '',
  specialClassContent: '',
  renderNode: undefined,
  renderDynamicButton: undefined,
  handleClickOutside: undefined,
  isNotClickOutside: false
}

export default DynamicPopup
