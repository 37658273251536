import _ from 'lodash'

import {
  SCHEDULE,
  FULL_DAY,
  ONE_MIN_TO_MS,
  ONE_HOUR_TO_MS
} from 'constants/bookingConstants'


export const vehicleTypeSettings = (vehicleType) => {
  if (_.size(vehicleType) === 0) {
    return {}
  }
  return vehicleType.settings
}

export const verifyPickupTimeWhenChangeVehicle = (booking, settings) => {
  if (_.isUndefined(booking.pickup_date_time) || _.isEmpty(settings)) {
    return true
  }
  const pickupTime = new Date(booking.pickup_date_time)
  if (_.isEmpty(booking.time_type_option)) {
    return true
  }
  const bkTimeType = booking.time_type_option.type_key
  const now = new Date()
  let minimumTime = _.toInteger(settings.minimum_pickup_time_schedule)
  let comparingTime = pickupTime - now
  comparingTime = Math.round(comparingTime / ONE_MIN_TO_MS)
  if (bkTimeType === FULL_DAY) {
    minimumTime = _.toInteger(settings.minimum_pickup_time_fullday)
  }
  if (comparingTime > minimumTime) {
    return true
  }
  return false
}

export const verifyPickUpTimeWhenChangeTimeType = (booking, settings) => {
  if (_.isEmpty(booking.time_type_option) || _.isUndefined(booking.pickup_date_time) || _.isEmpty(settings)) {
    return true
  }
  const pickupTime = new Date(booking.pickup_date_time)
  const bkTimeType = booking.time_type_option.type_key
  const now = new Date()
  let minimumTime = _.toInteger(settings.minimum_pickup_time_schedule)
  let comparingTime = pickupTime - now
  comparingTime = Math.round(comparingTime / ONE_MIN_TO_MS)
  if (bkTimeType === FULL_DAY) {
    minimumTime = _.toInteger(settings.minimum_pickup_time_fullday)
  }
  if (comparingTime > minimumTime) {
    return true
  }
  return false
}

export const verifyTimeTypeWhenChangeVehicle = (booking, settings) => {
  if (_.size(booking.time_type_option) === 0) {
    return true
  }
  const bkTimeType = booking.time_type_option.type_key
  const enableKey = `${bkTimeType}_enabled`
  if (_.size(settings) === 0 || _.isUndefined(settings[enableKey])) {
    return false
  }
  return settings[enableKey]
}

export const checkSameVehicleType = (batch) => {
  const bookings = batch.bookings
  if (_.size(bookings) < 1) {
    return false
  }
  const allVehicleTypes = []
  _.map(bookings, (booking) => {
    if (_.size(booking.vehicle_type) > 0) {
      allVehicleTypes.push(booking.vehicle_type.name)
    }
  })
  const bookingVehicleTypes = allVehicleTypes.filter((v, i, a) => a.indexOf(v) === i)
  if (_.size(bookingVehicleTypes) > 1) {
    return false
  }
  return true
}

export const rightPickUpTimeForTimeType = (settings, isFullDay) => {
  const now = Date.now()
  const minimumTime = isFullDay ? settings.minimum_pickup_time_fullday : settings.minimum_pickup_time_schedule
  const bufferTime = settings.buffer_time_for_pick_time_multiple_batch
  return now + (minimumTime + bufferTime) * ONE_MIN_TO_MS
}

export const verifyForFullDayWhenChangeVehicle = (booking, vehicleTypes) => {
  const timeType = booking.time_type_option
  if (_.isEmpty(timeType)) {
    return true
  }
  const timeTypeName = timeType.type_key
  if (timeTypeName === FULL_DAY) {
    const vehicleType = booking.vehicle_type
    if (_.isEmpty(vehicleType)) {
      return true
    }
    const currentvehicleType = _.find(vehicleTypes, { id: vehicleType.id })
    if (currentvehicleType && currentvehicleType.full_day_pricing.amount < timeType.fullday_selected_amount) {
      return false
    }
  }
  return true
}

export const bookingsHaveInvalidDataInFullDayTimeType = (batch, vehicleTypes) => {
  const bookings = batch.bookings
  const invalidBookingIndex = []
  _.forEach(bookings, (booking, index) => {
    if (!_.isEmpty(booking.time_type_option)) {
      if (booking.time_type_option.type_key === FULL_DAY) {
        if (!verifyForFullDayWhenChangeVehicle(booking, vehicleTypes)) {
          invalidBookingIndex.push(index)
        }
      }
    }
  })
  return invalidBookingIndex
}
