import apiClient from 'services/axiosApp'
import commonUtils from '../utils/common'
import { API_GATEWAY, PAYMENT_API } from '../constants/appConstants'


const PaymentIntegrationAPI = {
  getAddingFundSettings: async (
    countryCode,
    authenticationToken,
  ) => apiClient.get('/api/v3/top_up/settings', {
    params: {
      country_code: countryCode
    },
    headers: {
      'App-Name': `${commonUtils.appName()} Webapp`,
      'Device-Type': 'Web'
    }
  }),
  getPresignedPayment: (
    customerId,
    countryCode,
    amount,
    transactionId,
    companyId
  ) => {
    const params = {
      action: 'payment',
      streamId: customerId,
      transactionId,
      content: {
        amount,
        remark: 'Payment to 2C2P',
        ...(!!companyId && { companyId })
      }
    }
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiPresign = `${API_GATEWAY}/v1/payment-gateway/card/${country}/presign`
    if (PAYMENT_API) {
      urlApiPresign = `${PAYMENT_API}/api/v1/card/${country}/presign`
    }
    return apiClient.post(urlApiPresign, { params })
  },
  getStatusPayment: (
    customerId,
    countryCode,
    transactionId,
  ) => {
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiStatusPayment = `${API_GATEWAY}/v1/payment-gateway/card/${country}/${customerId}/transaction/${transactionId}`
    if (PAYMENT_API) {
      urlApiStatusPayment = `${PAYMENT_API}/api/v1/card/${country}/${customerId}/transaction/${transactionId}`
    }
    return apiClient.get(urlApiStatusPayment)
  },
  removeFirebaseChannel: (channelPath) => apiClient.delete(`${API_GATEWAY}/v1/notification`, {
    params: {
      xPath: channelPath,
      timeOut: null // able null
    }
  }),
  preSignAPIPayment: (params, customerId, countryCode) => {
    // const country = countryCode && countryCode.toLowerCase() !== 'vn' ? countryCode.toLowerCase() : 'id'
    return apiClient.post(`${API_GATEWAY}/v1/payment-gateway/virtual-account/id/presign`, params, {
    headers: {
      'x-owner': 'customer',
      'x-owner-id': customerId,
      'x-use-case': 'bookings',
    } })
  },
  getPaymentInstructionAPI: (params, countryCode) => {
    // const country = countryCode && countryCode.toLowerCase() !== 'vn' ? countryCode.toLowerCase() : 'id'
    return apiClient.get(`${API_GATEWAY}/v3/payment-gateway/virtual-account/id/instructions`, { params })
  },
  createVAAPI: (preSignedData) => apiClient.post(preSignedData.host, preSignedData.requestBody, {
      headers: preSignedData.headers,
    }),
  getPaymentSetting: (countryCode) => {
    // const country = countryCode && countryCode.toLowerCase() !== 'vn' ? countryCode.toLowerCase() : 'id'
    return apiClient.get(`${API_GATEWAY}/v1/payment-gateway/virtual-account/id/banks`, {
      headers: {
        Authorization: '',
      }
    })
  },
}

export default PaymentIntegrationAPI
