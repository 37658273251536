import $ from 'jquery'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// IMAGES
import * as IMAGES from 'constants/imageConstants'
// UTILS
import { getLocationDisplayInfos } from 'utils/LocationUtil'
import contactUtils from 'utils/common/contact'
import phoneNumberUtils from 'utils/common/phoneNumber'
import { UtilsHTML } from 'utils/html'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
// ACTIONS
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
import * as contactActionCreators from 'store/actions/common/contactActionCreators'
import * as locationsActionsCreator from 'store/actions/new_booking/locationActionCreators'
// COMPONENTS
import MyLocations from '../../common/contact/MyLocations'
import InputPhoneNumber from '../../common/InputPhoneNumber'
import CountdownRemaining from '../../common/CountdownRemaining'
import RequireSignatures from '../../common/RequireSignatures'
// CONSTANTS
import {
  ID_RECIPIENT_NAME,
  ID_RECIPIENT_PHONE,
  ALLOW_DRIVER_TO_CALL,
  CHECK_CUSTOMER_MASK_PHONE,
  CLEAR_CUSTOMER_MASK_PHONE,
} from 'constants/newBookingConstants'
import { isPaymentBooking } from 'utils/booking/common'
// ASSETS
require('jquery-ui')
require('jquery-ui-bundle')

const mapStateToProps = state => ({
  locations: state.locations,
  contacts: state.contacts,
  personalContacts: state.personalContacts,
  timeType: state.timeType,
  googleMap: state.googleMap,
  extraInfos: state.extraInfos,
  currentCustomer: state.currentCustomer,
  roundTripDiscount: state.roundTripDiscount,
  booking: state.booking,
})

function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationsActionsCreator, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    contactActions: bindActionCreators(contactActionCreators, dispatch),
  }
}

class LocationContacts extends React.Component {
  static clearErrorInputs(type, id) {
    const input = document.querySelector(`#${type}${id}`)
    if (contactUtils.isExistInputValue(input)) {
      input.closest('.Input').classList.remove('error')
    }
  }


  static selectContact(index, bool, locationId) {
    LocationContacts.clearErrorInputs(ID_RECIPIENT_NAME, locationId)
    LocationContacts.clearErrorInputs(ID_RECIPIENT_PHONE, locationId)
    if (!bool) {
      $(`#Selectbox-Contact-${index}`).removeClass('active')
      $(`#show-selectbox-contact-${index}`).toggleClass('green green-border green-text')
    }
  }

  constructor(props) {
    super(props)
    const { extraInfos } = this.props
    const countryCodeValue = extraInfos.country_code?.toLowerCase()

    this.state = { checkPhoneMask: undefined, countryCode: countryCodeValue }

    this.addMe = this.addMe.bind(this)
    this.setContact = this.setContact.bind(this)
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
    this.handleCustomerPhoneBlur = this.handleCustomerPhoneBlur.bind(this)
    this.handleCustomerPhoneFocus = this.handleCustomerPhoneFocus.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const {
      locations,
      isDisabledContact: initShopping,
      currentCustomer,
      roundTripDiscount,
      extraInfos,
      locationActions
    } = this.props
    if (initShopping) {
      _.forEach(locations, (location) => {
        locationActions.updateLocation(
          location.id,
          {
            recipient_name: currentCustomer.name,
            recipient_phone: currentCustomer.phone,
          }
        )
      })
    }
    if (currentCustomer.id !== undefined) {
      this.addMe(0, false, locations) // init value default for current customer
      if (roundTripDiscount) {
        this.addMe(locations.length - 1, false, locations) // init value for the last item
      }
    }
    if (extraInfos.default_payer_is_destination) {
      locationActions.updateLocation(locations[locations.length - 1].id, { is_payer: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { roundTripDiscount, locations } = this.props
    const { currentCustomer: prevCustomer } = prevProps
    const { currentCustomer } = this.props
    if (prevCustomer.id === undefined && currentCustomer.id !== undefined) {
      this.addMe(0, false, locations) // init value default for current customer
      if (roundTripDiscount) {
        this.addMe(locations.length - 1, false, locations) // init value for the last item
      }
    }
  }


  setContact({ contact, index, bool }) {
    const {
      locationActions, locations, currentCustomer, extraInfos
    } = this.props
    const validLocations = _.filter(locations, location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
    const result = phoneNumberUtils.formatPhoneNumber(
      contact.phone, currentCustomer.country_code || extraInfos.country_code?.toLowerCase(), true
    )
    const newContactPhone = result.phoneNumber
    const locationId = validLocations[index].id
    $(`#${ID_RECIPIENT_NAME + locationId}`).val(this.combineNameAndCompanyName(contact))
    $(`#${ID_RECIPIENT_PHONE + locationId}`).val(newContactPhone)
    const contactId = (contact === currentCustomer) ? undefined : contact.id
    locationActions.updateLocation(
      validLocations[index].id,
      {
        recipient_name: this.combineNameAndCompanyName(contact),
        recipient_phone: newContactPhone,
        contact_id: contactId,
        description: contact.address_detail || validLocations[index].description,
        phone_mask: contact.phone_mask
      }
    )
    LocationContacts.selectContact(index, bool, locationId)
  }

  handleCustomerPhoneChange(locationID, phone) {
    const { locationActions } = this.props
    locationActions.updateLocation(locationID, { recipient_phone: phone })
  }

  handleCustomerPhoneFocus(location, phone) {
    const { locationActions, currentCustomer } = this.props
    let attrs = { phone_mask: location.phone_mask, recipient_phone: phone }
    let shouldClear = !currentCustomer.show_phone_number_to_driver
    shouldClear = _.isUndefined(location.contact_id) ? shouldClear && !_.isUndefined(location.phone_mask) : shouldClear
    if (shouldClear) {
      attrs = contactUtils.getPhoneMaskAndPhoneNumber(CLEAR_CUSTOMER_MASK_PHONE, {
        recipient_phone: phone,
        customer_phone: currentCustomer.phone,
      })
    }
    locationActions.updateLocation(
      location.id,
      {
        phone_mask: attrs.phone_mask,
        recipient_phone: attrs.recipient_phone
      }
    )
  }

  handleCustomerPhoneBlur(location, phone) {
    const { locationActions, currentCustomer } = this.props
    const timeout = currentCustomer.show_phone_number_to_driver ? 0 : 250
    let attrs = { phone_mask: location.phone_mask, recipient_phone: phone }
    let shouldClear = !currentCustomer.show_phone_number_to_driver
    shouldClear = _.isUndefined(location.contact_id) ? shouldClear && !_.isUndefined(location.phone_mask) : shouldClear
    const checkPhoneMask = setTimeout(() => {
      if (shouldClear) {
        attrs = contactUtils.getPhoneMaskAndPhoneNumber(CHECK_CUSTOMER_MASK_PHONE, {
          recipient_phone: phone,
          customer_phone: currentCustomer.phone,
        })
      }
      locationActions.updateLocation(
        location.id,
        {
          phone_mask: attrs.phone_mask,
          recipient_phone: attrs.recipient_phone
        }
      )
    }, timeout)
    this.setState({ checkPhoneMask })
    LocationContacts.clearErrorInputs(ID_RECIPIENT_PHONE, location.id)
  }

  getContactResult(e) {
    var dom = $(e.target);
    var keyValue = dom.val().toLowerCase();
    var domId = dom.closest(".Popover-Item").attr("id");
    if (keyValue.length == 0) {
      $("#" + domId).find("div.Popover-contact-list-item").show();
    }
    $("#" + domId).find("div.Popover-contact-list-item").each(function (index, dom) {
      var currentDom = $(dom);
      var contactName = currentDom.find(".TitleSubtitle-title-name").text().toLowerCase();
      var contactNumber = currentDom.find(".TitleSubtitle-title-phone").text();
      if (!isNaN(keyValue)) {
        if (!contactNumber.toString().includes(keyValue)) {
          currentDom.hide();
        } else {
          currentDom.show();
        }
      } else {
        if (!contactName.includes(keyValue)) {
          currentDom.hide();
        } else {
          currentDom.show();
        }
      }
    })
  }

  addMe(index, bool, _locations = []) {
    const { currentCustomer, locations, locationActions } = this.props
    const { checkPhoneMask } = this.state
    if (!_.isUndefined(checkPhoneMask)) {
      clearTimeout(checkPhoneMask)
    }
    const results = contactUtils.getPhoneMaskAndPhoneNumber(ALLOW_DRIVER_TO_CALL)
    const validLocations = _.filter(locations, location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
    const locationId = validLocations[index].id
    const currentCustomerID = currentCustomer.id
    const recipientName = document.querySelector(`#${ID_RECIPIENT_NAME + locationId}`)
    const recipientPhone = document.querySelector(`#${ID_RECIPIENT_PHONE + locationId}`)
    if (recipientName && recipientPhone) {
      recipientName.value = _locations[index]?.recipient_name || currentCustomer.name
      recipientPhone.value = _locations[index]?.recipient_phone || currentCustomer.phone
    }
    locationActions.updateLocation(
      validLocations[index].id,
      {
        recipient_name: _locations[index]?.recipient_name || currentCustomer.name,
        recipient_phone: _locations[index]?.recipient_phone  || currentCustomer.phone,
        currentCustomerID,
        description: currentCustomer.note || validLocations[index].description,
        phone_mask: results.phone_mask
      }
    )
    LocationContacts.selectContact(index, bool, locationId)
  }

  pinZoomInPickup(location) {
    const { googleMap, extraInfos } = this.props
    if (location.lat && location.lng && location.name) {
      googleMap.map.setZoom(extraInfos.default_pickup_zoom)
      googleMap.map.setCenter(new window.google.maps.LatLng(location.lat, location.lng))
    }
  }

  combineNameAndCompanyName(contact) {
    return contact.company_name && contact.company_name.trim().length > 0 ? contact.name + " - " + contact.company_name : contact.name
  }

  handleChange(description, location) {
    const { locationActions } = this.props
    locationActions.updateLocation(location.id, { description })
  }

  renderLocation(location, index, shouldRenderIsPayerField) {
    const {
      locationActions,
      currentCustomer,
      contacts,
      personalContacts,
      isDisabledContact,
      extraInfos,
      stepActions,
      contactActions,
      locations,
      booking,
    } = this.props
    const { checkPhoneMask, countryCode } = this.state
    const locationDisplayInfos = getLocationDisplayInfos(
      _.filter(locations, l => !_.isUndefined(l.marker)).length, index
    )
    const useLocationPhoneMask = !_.isEmpty(location.phone_mask) && !currentCustomer.show_phone_number_to_driver
    const autoFocus = _.isEmpty(location.phone_mask) && !_.isUndefined(location.phone_mask)
    const disabled = _.isEqual(location.recipient_name, currentCustomer.name)
      && _.isEqual(location.recipient_phone, currentCustomer.phone)
    const showMePrefix = !!_.get(currentCustomer, 'name', '') && disabled

    return location && !_.isEmpty(location.marker) && (
      <div key={location.id} className="relative LocationContacts-dotline">
        <div className="block-item">
          <div
            className={locationDisplayInfos.className}
            onClick={() => this.pinZoomInPickup(location)}
          >
            {locationDisplayInfos.label}
          </div>
          <label className="default-font">
            {
              Utils.displayAddress(
                location,
                extraInfos.address_component_for_displaying,
                extraInfos.enable_display_address_component_configurations
              )
            }
          </label>
        </div>
        <div className="block-sub-item block-sub-item-custom block-sub-item-flex-start mt15">
          <img src={IMAGES.PATH_COMMON_ICON} />
          <CountdownRemaining
            className="pl10 flex-index"
            classTextarea="small-font"
            placeholder={I18n.t('contacts.labels.location_details')}
            remainPercentToShow={extraInfos.remaining_characters_in_note_fields}
            value={location.description}
            handleChange={description => this.handleChange(description, location)}
          />
        </div>
        {currentCustomer.id && !isDisabledContact
          && (
            <div className="block-sub-item block-sub-item-custom block-sub-item-flex-start block-sub-item-button">
              <div className="mr5">
                <button
                  type="button"
                  className={`Button default-small-font ${disabled ? 'disabled' : ''}`}
                  onClick={() => this.addMe(index, false)}
                  disabled={disabled}
                >
                  <i className="b material-icons Icon">
                    add_circles
                  </i>
                  {I18n.t('contacts.labels.add_me')}
                </button>
              </div>
              <MyLocations
                currentCustomer={currentCustomer}
                extraInfos={extraInfos}
                stepActions={stepActions}
                contactActions={contactActions}
                buttonText={I18n.t('contacts.labels.add_from_contact_step_3')}
                buttonClass="Button default-small-font"
                emptyMessage={I18n.t('booking_details.notify.no_contacts')}
                handleSelectContact={this.setContact}
                optionalData={{ index, bool: true }}
                contacts={contacts}
                personalContacts={personalContacts}
                className="Popover-Item Selectbox-Contact Selectbox-Contact-Customs active"
                searchPlaceholder={`${I18n.t('contacts.labels.search_contact')}`}
                onRef={(ref) => { this.childMyLocations = ref }}
                isContactView
                renderIcon={() => (
                  <i className="b material-icons Icon">
                    add_circles
                  </i>
                )}
              />
            </div>
          )
        }

        <div className="block-sub-item block-sub-item-custom">
          <i className="b material-icons Icon">
            person
          </i>
          <div className={`Input ${isDisabledContact ? 'Input-Disabled' : ''} ${showMePrefix ? ' me-prefix-container' : ''}`}>
            <span className="me-prefix">
              {I18n.t('webapp.new_booking.step_3.me_name_prefix')}
            </span>
            <input
              id={`${ID_RECIPIENT_NAME}${location.id}`}
              className={`${ID_RECIPIENT_NAME}${index}`}
              placeholder={index === 0 ? I18n.t('webapp.new_booking.step_3.sender_name') : I18n.t('webapp.new_booking.step_3.recipient_name')}
              tabIndex="1"
              value={location.recipient_name}
              onChange={(e) => {
                locationActions.updateLocation(location.id, { recipient_name: e.target.value })
                LocationContacts.clearErrorInputs(ID_RECIPIENT_NAME, location.id)
              }}
              disabled={isDisabledContact}
            />
          </div>
        </div>
        <div className="block-sub-item block-sub-item-custom">
          <i className="b material-icons Icon">
            phone_in_talk
          </i>
          {useLocationPhoneMask ? (
            <div className={`Input ${isDisabledContact ? 'Input-Disabled' : ''}`}>
              <input
                id={`${ID_RECIPIENT_PHONE}${location.id}`}
                className={`${ID_RECIPIENT_PHONE}${index}`}
                placeholder={I18n.t('webapp.new_booking.step_3.phone_number')}
                tabIndex="1"
                value={location.phone_mask}
                onBlur={(e) => {
                  this.handleCustomerPhoneBlur(location, e.target.value)
                  LocationContacts.clearErrorInputs(ID_RECIPIENT_PHONE, location.id)
                }}
                onFocus={e => this.handleCustomerPhoneFocus(location, e.target.value)}
                disabled={isDisabledContact}
              />
            </div>
          ) : (
            <InputPhoneNumber
              isWebApp
              debounce
              closestElement={`location-contact-${location.id}-number`}
              fieldId={`${ID_RECIPIENT_PHONE}${location.id}`}
              inputClassName={`${ID_RECIPIENT_PHONE}${index}`}
              phoneField={`${ID_RECIPIENT_PHONE}${index}`}
              telInputProps={{ tabIndex: 1 }}
              phoneNumber={location.recipient_phone}
              countryCode={countryCode}
              onPhoneChange={phone => this.handleCustomerPhoneChange(location.id, phone)}
              onPhoneBlur={(phone) => {
                this.handleCustomerPhoneBlur(location, phone)
                LocationContacts.clearErrorInputs(ID_RECIPIENT_PHONE, location.id)
              }}
              onPhoneFocus={phone => this.handleCustomerPhoneFocus(location, phone)}
              checkPhoneMask={checkPhoneMask}
              autoFocus={autoFocus}
              disabled={isDisabledContact}
            />
          )}
        </div>
        {shouldRenderIsPayerField && !isDisabledContact && !isPaymentBooking(booking?.payment_method_for_non_bp) && (
          <div className="block-sub-item block-sub-item-custom block-sub-item-custom">
            <div className="Overlay-RadioBox Green-RadioBox With-Icon">
              <input
                type="radio"
                name="location_is_payer"
                checked={location.is_payer}
                onChange={e => locationActions.updateLocation(location.id, { is_payer: e.target.checked })}
              />
              <label>
                <i className="b material-icons Icon">
                  fiber_manual_record
                </i>
              </label>
            </div>
            <label className="default-font">
              {I18n.t('webapp.new_booking.step_2.responsible_for_payment')}
            </label>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      locations, currentCustomer, requireSignaturesComponent, showResponsibleForPayment
    } = this.props
    const validLocations = _.filter(locations, location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
    const shouldRenderIsPayerField = showResponsibleForPayment
      && !currentCustomer.current_company_id && validLocations.length > 1

    return (
      <div className="LocationContacts-Container mt20 mr10 ml10 White-bg Radius-default">
        {UtilsHTML.renderSectionTitle(I18n.t('webapp.new_booking.step_2.location_contacts'))}
        {requireSignaturesComponent}
        <div className="LocationContacts block-reset block">
          {validLocations.map((location, index) => this.renderLocation(location, index, shouldRenderIsPayerField))}
        </div>
      </div>
    )
  }
}

LocationContacts.propTypes = {
  locationActions: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  locations: PropTypes.instanceOf(Array),
  contacts: PropTypes.instanceOf(Array),
  personalContacts: PropTypes.instanceOf(Array),
  extraInfos: PropTypes.shape({
    default_payer_is_destination: PropTypes.bool
  }),
  isDisabledContact: PropTypes.bool,
  showResponsibleForPayment: PropTypes.bool,
  requireSignaturesComponent: PropTypes.oneOfType([
    () => null,
    PropTypes.instanceOf(RequireSignatures)
  ]),
  roundTripDiscount: PropTypes.bool
}

LocationContacts.defaultProps = {
  locations: [],
  contacts: [],
  personalContacts: [],
  extraInfos: {},
  isDisabledContact: false,
  requireSignaturesComponent: null,
  showResponsibleForPayment: true,
  roundTripDiscount: false
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationContacts)
