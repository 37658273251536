import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const checkLocationsSlice = createSlice({
  name: 'checkLocations',
  initialState,
  reducers: {
    updateCheckLocationInfos: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const checkLocationsActionsCreator = checkLocationsSlice.actions

export default checkLocationsSlice
