import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

// UTILS
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
// ASSETS
const mapStateToProps = state => ({
  bookAgainDetails: state.bookAgainDetails
})

class ContainerSize extends React.PureComponent {
  render() {
    const { bookAgainDetails } = this.props
    const containerSize = _.isEmpty(bookAgainDetails) ? null : bookAgainDetails.container_size
    if (_.isNull(containerSize)) {
      return (<div />)
    }
    const containerSizeText = I18n.t(`enum.booking.container_size.${containerSize}`)
    return (
      <div className="ContainerSize block block-disabled">
        <div className="Input Hide-Border Input-Disabled">
          <input disabled name="booking[container_size]" placeholder={containerSizeText} value={containerSizeText} />
        </div>
      </div>
    )
  }
}

ContainerSize.propTypes = {
  bookAgainDetails: PropTypes.shape({})
}

ContainerSize.defaultProps = {
  bookAgainDetails: undefined
}

export default connect(mapStateToProps)(ContainerSize)
