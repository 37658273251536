import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []
const beforeOptimizedLocationsSlice = createSlice({
  name: 'beforeOptimizedLocations',
  initialState,
  reducers: {
    backupLocations: (state, action: PayloadAction<any>) => action.payload.locations,
  },
  extraReducers: () => {},
})
export const beforeOptimizedLocationsActionsCreator = beforeOptimizedLocationsSlice.actions

export default beforeOptimizedLocationsSlice
