import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const dataChartBookingsSlice = createSlice({
  name: 'dataChartBookings',
  initialState,
  reducers: {
    receiveDataChartBookings: (state: any, action: PayloadAction<any>) => {
      return action.payload.dataChartBookings
    },
  },
  extraReducers: () => {},
})

export const dataChartBookingsActionsCreator = dataChartBookingsSlice.actions

export default dataChartBookingsSlice
