import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// ASSETS
import { RIBBON_TAIL } from 'constants/imageConstants'
import { PERCENT } from 'constants/common/popupConstants'
// UTILS
import I18n from 'i18n/i18n'
class FirstTimeDiscountPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { firstTimeDiscount } = this.props
    if (_.isEmpty(firstTimeDiscount)) {
      return <div />
    }

    const discountType = firstTimeDiscount.type
    const discountTextCustom = (discountType !== PERCENT) ? 'Discount-Text-Custom' : ''
    return (
      <div className="visible vertical-scroll Modal Modal-No-action modal-discount">
        <div className="First-Time-Discount">
          <div className="Discount-Message">
            <div className="Discount-Content">
              <div className="Discount-Row">
                <div
                  className={`Discount-Text Discount-Text-En ${discountTextCustom}`}
                  dangerouslySetInnerHTML={{ __html: I18n.t('settings.discount.first_time_html', firstTimeDiscount) }}
                />
              </div>
              <div className="Discount-Footer">
                <div className="Discount-Footer-Left">
                  <img src={RIBBON_TAIL} alt="Ribbon tai" />
                </div>
                <div
                  className="Discount-Footer-Mid"
                  dangerouslySetInnerHTML={{ __html: I18n.t('settings.discount.first_time_footer_1') }}
                />
                <div className="Discount-Footer-Right">
                  <img src={RIBBON_TAIL} alt="Ribbon tai" />
                </div>
              </div>
            </div>
            <div className="Discount-Info">
              <p className="default-font default-color reset m">
                {I18n.t('settings.discount.first_time_footer_2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FirstTimeDiscountPopup.propTypes = {
  firstTimeDiscount: PropTypes.shape({})
}

FirstTimeDiscountPopup.defaultProps = {
  firstTimeDiscount: undefined
}

export default FirstTimeDiscountPopup
