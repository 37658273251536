import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false

const showDatePickerSlice = createSlice({
  name: 'showDatePickerTypes',
  initialState,
  reducers: {
    showDatePicker: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const showDatePickerActionsCreator = showDatePickerSlice.actions

export default showDatePickerSlice
