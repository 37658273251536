import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import $ from 'jquery'

import DynamicPopup from './DynamicPopup'
import { Utils } from 'utils/Utils'

class DynamicPopupHandler extends React.PureComponent {
  static checkHeightPopup(element) {
    if (element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  }

  constructor(props) {
    super(props)
    this.beforeHandleShowPopup = this.beforeHandleShowPopup.bind(this)
  }

  beforeHandleShowPopup(e) {
    const {
      renderNode,
      correctHeight
    } = this.props
    this.handleShowPopup(renderNode)
    if (correctHeight) {
      DynamicPopupHandler.checkHeightPopup($(renderNode).find('.Popup'))
    }
    e.stopPropagation()
  }

  handleShowPopup(renderNode) {
    const { renderInfo, renderDynamicContent } = this.props
    if (renderInfo && renderNode) {
      Utils.hideCloseBtn()
      ReactDOM.render(
        <DynamicPopup
          {...this.props}
          icon={renderInfo.icon}
          title={renderInfo.title}
          subTitle={renderInfo.subTitle}
          renderNode={renderNode}
          renderDynamicContent={renderDynamicContent}
        />,
        renderNode
      )
    }
  }

  render() {
    const {
      specialClass,
      renderChild,
    } = this.props
    return (
      <div
        className={specialClass}
        onClick={e => this.beforeHandleShowPopup(e)}
      >
        {renderChild && renderChild()}
      </div>
    )
  }
}

DynamicPopupHandler.propTypes = {
  renderDynamicContent: PropTypes.func.isRequired,
  renderChild: PropTypes.func.isRequired,
  renderNode: PropTypes.shape({}),
  renderInfo: PropTypes.shape({}),
  specialClass: PropTypes.string,
  correctHeight: PropTypes.bool
}

DynamicPopupHandler.defaultProps = {
  renderNode: undefined,
  renderInfo: undefined,
  correctHeight: false,
  specialClass: ''
}

export default DynamicPopupHandler
