import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import I18n from 'i18n/i18n'
import { requireSignaturesActionsCreator } from 'store/toolkit/requireSignatures/requireSignatures.reducer'

const mapStateToProps = state => ({
  requireSignatures: state.requireSignatures
})

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    requireSignaturesActions: bindActionCreators(requireSignaturesActionsCreator,dispatch)
  }
}

class RequireSignatures extends React.Component {
  handleRequireSignatures(value) {
    const { requireSignaturesActions, handleRequireSignaturesCheckbox } = this.props
    if (_.isUndefined(handleRequireSignaturesCheckbox)) {
      requireSignaturesActions.updateRequireSignatures(value)
    } else {
      handleRequireSignaturesCheckbox(value)
    }
  }

  render() {
    const {
      fieldName,
      fieldId,
      requireSignatures,
      value
    } = this.props
    const isChecked = _.isUndefined(value) ? requireSignatures : value
    return (
      <div className="Require-Sinatures">
        <label>
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title">
              {I18n.t('webapp.booking.require_signatures_title')}
            </div>
            <div className="TitleSubtitle-title">
              {I18n.t('webapp.booking.require_signatures_subtitle')}
            </div>
          </div>
        </label>
        <div className="block-item-action">
          <div className="Overlay-Checkbox With-Icon Green-Checkbox">
            <input
              type="checkbox"
              name={fieldName}
              id={fieldId}
              checked={isChecked}
              onChange={e => this.handleRequireSignatures(e.target.checked)}
            />
            <label>
              <i className="b material-icons Icon">
                check
              </i>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

RequireSignatures.defaultProps = {
  value: undefined,
  handleRequireSignaturesCheckbox: undefined
}

RequireSignatures.propTypes = {
  value: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  requireSignatures: PropTypes.bool.isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  handleRequireSignaturesCheckbox: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequireSignatures)
