import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Card = ({
  image, className, onClick, title, content, classContent, isNewTag
}) => {
  const { t } = useTranslation()
  return (
    <div className={`${className} card-container`} onClick={onClick}>
      <img width={88} height={82} src={image} alt="" />
      <div>
        <p className='flex align-items-center'>
          {title}
          {isNewTag && <span className='new-tag'>{t('lcl.new_tag')}</span>}
        </p>
        <p className={classContent}>
          {content}
        </p>
      </div>
    </div>
  )
}

export default Card
Card.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  classContent: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}
Card.defaultProps = {
  className: '',
  classContent: '',
}
