import _ from 'lodash-es'
import { LONG_HAUL, FULL_DAY } from '../constants/bookingConstants'

const filterExtraRequirements = (extraRequirements, timeType) => extraRequirements.filter((extraRequirement) => {
  if (timeType === LONG_HAUL) {
    return extraRequirement.selected === true && extraRequirement.is_flat_per_location === false
  }
  return extraRequirement.selected === true
})
export const extraRequirementsAttributesForCalculate = (
  extraRequirements, extraRequirementsNegativePosition, timeType
) => {
  const combinedExtraRequirements = [...extraRequirements, ...extraRequirementsNegativePosition]
  const selectedExtras = filterExtraRequirements(combinedExtraRequirements, timeType)
  return selectedExtras.map((extraRequirement) => {
    let pricingId
    let unitPrice = extraRequirement.unit_price
    let levelPrice = extraRequirement.level_price
    if (!_.isUndefined(extraRequirement.selectedPricing)) {
      unitPrice = extraRequirement.selectedPricing.fees
      levelPrice = extraRequirement.selectedPricing.level_price
      pricingId = extraRequirement.selectedPricing.id
    }
    return {
      extra_requirement_id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      position: extraRequirement.position,
      unit_price: unitPrice,
      level_price: levelPrice,
      extra_requirement_pricing_id: pricingId
    }
  })
}
export const extraRequirementsAttributesForCreate = (
  extraRequirements, extraRequirementsNegativePosition, timeType
) => {
  let selectedExtras = extraRequirements
  if (timeType === FULL_DAY) {
    selectedExtras = extraRequirements.concat(extraRequirementsNegativePosition)
  }
  selectedExtras = filterExtraRequirements(selectedExtras, timeType)
  return selectedExtras.map((extraRequirement) => {
    let unitPrice
    let levelPrice
    let pricingId
    if (!_.isUndefined(extraRequirement.selectedPricing)) {
      unitPrice = extraRequirement.selectedPricing.fees
      levelPrice = extraRequirement.selectedPricing.level_price
      pricingId = extraRequirement.selectedPricing.id
    }
    return {
      id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      is_flat: extraRequirement.is_flat,
      position: extraRequirement.position,
      unit_price: unitPrice,
      level_price: levelPrice,
      extra_requirement_pricing_id: pricingId
    }
  })
}