import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ReimbursementItem extends React.PureComponent {
  render() {
    const { isEnabled } = this.props

    if (!isEnabled) {
      return null
    }

    const {
      arrReimbursement, displayText, isChecked, isLocked,
      popupKey, handleOnChange, itemKey, handlePopupEye,
    } = this.props

    return (
      <div className="block-item No-Border-Bottom" key={`static-reimbursement-${_.snakeCase(itemKey)}`}>
        <label className="default-color default-font">
          {displayText}
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title">
              {_.includes(arrReimbursement, popupKey) && (
                <span className="step-2-eye" onClick={() => handlePopupEye(popupKey)}>
                  <i className="s material-icons Icon">
                    visibility
                  </i>
                </span>
              )
              }
            </div>
          </div>
        </label>
        <div className="block-item-action">
          <div className={`Overlay-Checkbox With-Icon Green-Checkbox ${isLocked ? 'Disable' : ''}`}>
            <input
              type="checkbox"
              name={`booking[${_.snakeCase(itemKey)}]`}
              id={`booking_${_.snakeCase(itemKey)}`}
              value="1"
              checked={isChecked}
              onChange={event => handleOnChange(event, itemKey)}
            />
            <label>
              <i className="b material-icons Icon">
                check
              </i>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

ReimbursementItem.propTypes = {
  displayText: PropTypes.string.isRequired,
  popupKey: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handlePopupEye: PropTypes.func.isRequired,
  arrReimbursement: PropTypes.instanceOf(Array),
  isChecked: PropTypes.bool,
  isLocked: PropTypes.bool,
  isEnabled: PropTypes.bool,
}

ReimbursementItem.defaultProps = {
  arrReimbursement: [],
  isChecked: false,
  isLocked: false,
  isEnabled: false,
}

export default ReimbursementItem
