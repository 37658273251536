import React, { useRef } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { havePopup } from '../../../../utils/common/popupable'
import { POPUP_ID_EXTRA_SERVICE_POPUP_EYE } from '../../../../constants/common/popupConstants'
import { Utils } from '../../../../utils/Utils'

const TitleSub = (props) => {
  const {
    extraRequirement,
    popupType,
    extraWrappedClass,
    extraIconClass,
    isMultiple,
    bookingId
  } = props
  const textRef = useRef()

  const checkHeightPopup = (element) => {
    if (element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  }

  const onClickExtraServiceEyeHandler = (extraRequirementID) => {
    if (isMultiple) {
      document.querySelector(`#booking-${bookingId}-${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID}`).classList.add('visible')
      checkHeightPopup($(`#booking-${bookingId}-${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID} .Normal-Booking`))
      Utils.triggerModifyPopupMultiple($(`#booking-${bookingId}-${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID}`), bookingId)
    } else {
      document.querySelector(`#${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID}`).classList.add('visible')
      checkHeightPopup($(`#${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID} .Normal-Booking`))
    }
  }

  return (
    <div className={`TitleSubtitle-title relative ${extraWrappedClass} max-w-90`}>
      <span>
        <span ref={textRef}>
          {extraRequirement.name}
        </span>
      </span>
      {havePopup(extraRequirement, popupType) && textRef.current?.offsetWidth > 0
        && (
          <span
            className={`step-2-eye ${extraIconClass}`}
            onClick={() => onClickExtraServiceEyeHandler(extraRequirement.id)}
            style={{
              position: 'absolute',
              left: textRef.current?.offsetWidth
            }}
          >
            <i className="s material-icons Icon">
              visibility
            </i>
          </span>
        )
      }
    </div>
  )
}

export default TitleSub

TitleSub.propTypes = {
  extraRequirement: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  popupType: PropTypes.shape({}).isRequired,
  extraWrappedClass: PropTypes.string,
  extraIconClass: PropTypes.string,
  isMultiple: PropTypes.bool,
  bookingId: PropTypes.string
}

TitleSub.defaultProps = {
  extraWrappedClass: '',
  extraIconClass: '',
  isMultiple: false,
  bookingId: ''
}
