import { useEffect, useRef } from 'react'

const createRootElement = (id) => {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

const addRootElement = (rootElement) => {
  document.body.insertBefore(
    rootElement,
    document.body.lastElementChild.nextElementSibling
  )
}

const usePortal = (id, rootClass) => {
  const rootElemRef = useRef(null)

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`)
    const parentElem = existingParent || createRootElement(id)

    if (!existingParent) {
      addRootElement(parentElem)
    }

    parentElem.appendChild(rootElemRef.current)

    return () => {
      rootElemRef.current.remove()
      if (!parentElem.childElementCount) {
        parentElem.remove()
      }
    }
  }, [id])

  if (!rootElemRef.current) {
    rootElemRef.current = document.createElement('div')
    rootClass && rootElemRef.current.setAttribute('class', rootClass)
  }

  return rootElemRef.current
}

export default usePortal
