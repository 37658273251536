import { FULL_DAY, iconTextColors, iconTextPositions, STATUS_DELIVERY_IN_PROGRESS } from "constants/bookingConstants"
import { PIN_BLUE, PIN_YELLOW } from "constants/imageConstants"
import I18n from "i18n/i18n"
import { isUndefined } from "lodash"
import { isEmpty } from "lodash/isEmpty"
import { checkIsSameLocation } from "utils/booking/common"
const currentLocationMarker = (index, length, isFullDayOneLocation = false) => {
    if (index === 0 || isFullDayOneLocation) {
        return {
            url: PIN_BLUE,
            label: I18n.t('label.pin_from'),
            labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]),
            color: iconTextColors.white
        }
    }
    if (length === 2) {
        return {
            url: PIN_YELLOW,
            label: I18n.t('label.pin_to'),
            labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]),
            color: iconTextColors.black
        }
    }
    return {
        url: PIN_YELLOW,
        label: `${index}`,
        labelOrigin: new window.google.maps.Point(iconTextPositions[0], iconTextPositions[1]),
        color: iconTextColors.black
    }
}

const setIsFullDayOneLocation = (isOneLocation, status, timeType) => isOneLocation
    && status === STATUS_DELIVERY_IN_PROGRESS && timeType === FULL_DAY
const handleFullDayOneLocation = (locations, status, timeType) => {
    if (!isEmpty(locations) && locations.length === 2) {
        const isSameLocation = checkIsSameLocation(locations[0].lat, locations[1].lat, locations[0].lng, locations[0].lng)
        return setIsFullDayOneLocation(isSameLocation, status, timeType)
    }
    return false
}
const removeBlurMarker = (locations) => {
    return locations.map((location) => {
        if (location.marker && isUndefined(location.lat) && isUndefined(location.lng)) {
            location.marker.setMap(null)
            return {
                ...location,
                marker: undefined
            }
        }
        return location
    })
}
const resetLocationBeforeOptimize = (booking, locations) => {
    booking.locationBeforeOptimize = locations
}

export { handleFullDayOneLocation, currentLocationMarker, removeBlurMarker, resetLocationBeforeOptimize }