import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { preTimeTypeActionsCreator } from 'store/toolkit/newBooking/preTimeType.reducer'

export const changePrevTimeType = value => dispatch => dispatch(preTimeTypeActionsCreator.changePrevTimeType(value))

export const resetTimeType = () => (dispatch, getState) => {
  const { timeType, prevTimeType } = getState() || {}
  const { data: tempTimeType } = prevTimeType || {}
  if (timeType !== tempTimeType) {
    dispatch(timeTypeUIActionsCreator.changeTimeTypeUI(tempTimeType))
    dispatch(timeTypeActionsCreator.changeTimeType(tempTimeType))
  }
}
