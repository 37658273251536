import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  vehicleTypeInfo: null,
}

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    getVehicleTypeInfoSuccess: (state, action: PayloadAction<any>) => {
      state.vehicleTypeInfo = action.payload
    }
  },
  extraReducers: () => {},
})

export const vehicleActionsCreator = vehicleSlice.actions

export default vehicleSlice
