import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

// ASSETS
import {
  BUSINESS_DEFAULT_LOGO,
  IMAGE_PLACE_HOLDER
} from 'constants/imageConstants'
import _ from 'lodash'
import { switchAccount } from 'store/actions/common/customerActionCreators'
import * as authenticationActionCreators from 'store/actions/common/authenticationActionCreators'
import commonUtils from 'utils/common'
import { isEmpty } from 'lodash-es'
import { showLoading } from 'assets/javascripts/webapp-v2/common'

const BusinessAccountsList = ({ userInfo }) => {
  const currentCustomer = useSelector(state => state.currentCustomer)
  const dispatch = useDispatch()
  const { employs: BpAccounts } = userInfo
  const accountsList = [
    {
      ...userInfo,
      logo_url: userInfo.logo_url || userInfo.avatar_square,
    },
    ..._.map(BpAccounts, acc => ({
      company_id: acc.company_id,
      description: _.get(acc, 'company.description', ''),
      email: userInfo.email,
      id: acc.company_id,
      logo_url: _.get(acc, 'company.logo_url', ''),
      name: _.get(acc, 'company.name', ''),
      phone: _.get(acc, 'company.phone', ''),
      photo_url: _.get(acc, 'company.photo_url', ''),
    })),
  ]

  if (_.isEmpty(accountsList)) return null

  const isActiveItem = account => (userInfo.last_login_employ_id && +userInfo.last_login_employ_id === +account.id)
  || (!userInfo.last_login_employ_id && +userInfo.id === +account.id)

  const getId = (account) => {
    const {
      id
    } = account
    if (userInfo.last_login_employ_id) {
      return {
        oldId: `${userInfo.id}_${userInfo.last_login_employ_id}`,
        newId: userInfo.id === id ? id : `${userInfo.id}_${id}`
      }
    }
    return {
      oldId: userInfo.id,
      newId: `${userInfo.id}_${id}`
    }
  }

  const handleSwitchAccount = async (event, account) => {
    event.preventDefault()
    const { oldId, newId } = getId(account)
    showLoading()
    dispatch(switchAccount(account.id, {}, (response) => {
      if (!isEmpty(response)) {
        const callback = () => {
          const { isMultiple, isBatchEZ, isSmartLoad } = commonUtils
          if (!isMultiple() && !isBatchEZ() && !isSmartLoad()) {
            window.Moengage.track_event('Switch Account', {
              'From Account': oldId,
              'To Account': newId
            })
            localStorage.setItem('switch_account', newId)
            Promise.resolve(window.Moengage.destroy_session()).then(() => { document.location.href = '/' })
            setTimeout(() => { document.location.href = '/' }, 3000)
          }
        }
        const companyId = account.id === currentCustomer.id ? 0 : account.id
        dispatch(authenticationActionCreators.convertToken(currentCustomer.authentication_token, companyId, callback))
      }
    }))
  }

  return (
    <div>
      {
        _.map(accountsList, (account, index) => (
          <div
            className={`Box-List-Item change-account
            ${isActiveItem(account) ? 'active' : ''}`}
            key={account.id}
            onClick={event => handleSwitchAccount(event, account)}
          >
            <div className="rounded Avatar__header">
              <img
                src={account.company_id ? account.photo_url : account.avatar_square}
                onError={(e) => {
                  e.target.src = account.company_id ? BUSINESS_DEFAULT_LOGO : IMAGE_PLACE_HOLDER
                }}
                alt="avatar_customer"
              />
            </div>
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title">
                {account.name}
              </div>
              <div className="TitleSubtitle-subtitle">
                {account.email}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

BusinessAccountsList.propTypes = {
  userInfo: PropTypes.shape({}).isRequired
}

export default BusinessAccountsList
