import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const personalContactsSlice = createSlice({
  name: 'personalContacts',
  initialState,
  reducers: {
    addNewPersonalContact: (state: any, action: PayloadAction<any>) => {
      state.push(action.payload)
    },
    deletePersonalContact: (state, action: PayloadAction<any>) => {
      _.remove(state, { id: action.payload })
    },
    updatePersonalContacts: (state, action: PayloadAction<any>) => action.payload,
    updateSinglePersonalContact: (state, action: PayloadAction<any>) => {
      const index = _.findIndex(state, (personalContact:any) => personalContact.id === action.payload.id)
      if (index !== -1) {
        state[index] = {
          ...state[index],
          ...action.payload.attrs 
        }
      }
    },
  },
  extraReducers: () => {},
})

export const personalContactsActionsCreator = personalContactsSlice.actions

export default personalContactsSlice
