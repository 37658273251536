import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import _, { parseInt } from 'lodash-es'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import { getServiceTypesInfo } from 'store/actions/new_booking/serviceTypeActionCreators'
import vehicleTypeAPIs from 'api/vehicles'
import NewBookingContainer from 'containers/NewBookingContainer'
import { countNumberOnlineDriversActionsCreator } from 'store/toolkit/newBooking/countNumberOnlineDrivers.reducer';
import {
  isBookAgain,
  getParamFromURL,
  isEditBooking,
  isMarketingPage,
  isHideLoadingNewBooking,
  isHideLoadingEditBooking,
  isHideLoadingMarketingBooking as isHideLoadingMarketBooking,
} from '../utils/booking/common'
import StorageKeys from 'constants/storage-keys';
import { isCancelToEdit, isCustomerEditBooking } from 'utils/new_booking/common';
import CustomerAPI from 'api/customers';
import { VALID_PAYMENT_LIST } from 'constants/bookingConstants';
import { WAITING_PAYMENT_BEFORE_EDIT_POPUP } from 'constants/common/popupConstants';


const SingleBookingView = () => {
  const navigate = useNavigate()
  const extraInfos = useSelector(state => state.extraInfos)
  const selectedServiceTypeID = useSelector(state => state.selectedServiceTypeID)
  const bookAgainDetails = useSelector(state => state.bookAgainDetails)
  const currentCustomer = useSelector(state => state.currentCustomer)
  const locations = useSelector(state => state.locations)
  const timeType = useSelector(state => state.timeType)

  const serviceTypes = useSelector(state => state.serviceTypes)
  const areaId = useSelector(state => state.extraInfos?.id)
  const selectedVehicleTypeID = useSelector(state => state.selectedVehicleTypeID)
  const [isGetSuccessDataEdit, setIsGetSuccessDataEdit] = useState(false)
  const [isGetSuccessDataMarketing, setIsGetSuccessDataMarketing] = useState(false)
  const bookingId = window.location.pathname?.split('/')[2]

  const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''


  const dispatch = useDispatch()
  const firstTimeDiscount = null
  const dataHideLoading = {
    serviceTypes, extraInfos, selectedServiceTypeID
  }

  const isHideLoadingNew = isHideLoadingNewBooking(dataHideLoading)
  const isHideLoadingEdit = isHideLoadingEditBooking({ ...dataHideLoading, bookAgainDetails })
  const isHideLoadingMarketingBooking = isHideLoadingMarketBooking(
    { ...dataHideLoading, bookAgainDetails, isGetSuccessDataMarketing }
  )
  const finalAreaId = areaId || window.localStorage.getItem(StorageKeys.AREA_ID)

  const handleGetQuotePayment = async (id) => {
    const params = {
      id,
      section_type: 'customer_quote_payment',
    }
    const quotePayment = await CustomerAPI.getCustomerBooking(id, params)
    const quotePaymentData = quotePayment?.payment

    if (!_.isEmpty(quotePaymentData) && VALID_PAYMENT_LIST.includes(quotePaymentData.status)) {
      window.localStorage.setItem(WAITING_PAYMENT_BEFORE_EDIT_POPUP, bookingId)
      const path = `/bookings/${bookingId}`
      window.location.href = path
    }
  }

  useEffect(() => {
    if (bookingId && isCustomerEditBooking()) {
      handleGetQuotePayment(bookingId)
    }
    if (isMarketingPage) {
      const getSuccessDataMarketing = async () => {
        const quoteId = getParamFromURL('quote_id')
        dispatch(bookingActionCreators.getInfoMarketingBooking(quoteId), () => {
          setIsGetSuccessDataMarketing(true)
        })
      }
      getSuccessDataMarketing()
    }
  }, [])

  useEffect(() => {
    if (finalAreaId  
      && !authenticationToken) dispatch(getServiceTypesInfo(finalAreaId))
    if (finalAreaId && authenticationToken
      && currentCustomer.id) dispatch(getServiceTypesInfo(finalAreaId, currentCustomer.last_login_employ_id))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, currentCustomer.id, authenticationToken, currentCustomer.last_login_employ_id])

  useEffect(() => {
    if (currentCustomer.id && (isEditBooking() || isBookAgain()) && !isGetSuccessDataEdit) {
      const bookingId = window.location.pathname?.split('/')[2]
      dispatch(bookingActionCreators.getInfoEditBooking(bookingId, isBookAgain(), navigate, () => setIsGetSuccessDataEdit(true)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer.id])

  useEffect(() => {
    if (!_.isEmpty(serviceTypes?.[selectedServiceTypeID]?.vehicle_types) && extraInfos?.default_center_lat_lng) {
      vehicleTypeAPIs.getNumberOfOnlineDrivers(selectedVehicleTypeID
        || serviceTypes[selectedServiceTypeID].vehicle_types[0], extraInfos.default_center_lat_lng, (response) => {
        dispatch(countNumberOnlineDriversActionsCreator.getOnlineDriversOnVehicleType(response))
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraInfos?.default_center_lat_lng, serviceTypes])

  useEffect(() => {
    const data = {
      locations_attributes: locations,
      time_type: timeType,
      vehicle_type_id: selectedVehicleTypeID
    }
    dispatch(customerActionCreators.calculateCustomerCashbackPercent(data))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer.current_company_id])

  const hideLoadingBar = () => setTimeout(() => {
    hideLoading()
    const id = document.getElementById('progress-loading-container-id')
    if (id) id.classList.remove('progress-loading-container-visible')
  }, 300)
  useEffect(() => {
    const element = document.getElementById('bar-percent')
    if (!isEditBooking() && !isBookAgain() && element) {
      const oldWidth = element.style.width || 0
      if (serviceTypes.length > 0 || !_.isEmpty(extraInfos.tc_link) || areaId || selectedServiceTypeID) {
        element.style.width = `${((parseInt(oldWidth) / 25) + 1) * 25}%`
        if (serviceTypes.length > 0 && !_.isEmpty(extraInfos.tc_link)
          && areaId && selectedServiceTypeID) hideLoadingBar()
      }
    } else if (element && !isCancelToEdit()) {
      const oldWidth = element.style.width || 0
      // At a time we have only flow edit/book again (isGetSuccessDataEdit) or Marketing (isGetSuccessDataMarketing)
      if (serviceTypes.length > 0 || !_.isEmpty(extraInfos.tc_link) || areaId || isGetSuccessDataEdit
        || isGetSuccessDataMarketing || !_.isEmpty(bookAgainDetails)) {
        element.style.width = `${((parseInt(oldWidth) / 20) + 1) * 20}%`
        if (serviceTypes.length > 0 && !_.isEmpty(extraInfos.tc_link) && areaId && (isGetSuccessDataEdit
        || isGetSuccessDataMarketing) && !_.isEmpty(bookAgainDetails)) hideLoadingBar()
      }
    }
  }, [serviceTypes, extraInfos.tc_link, areaId, isGetSuccessDataEdit, selectedServiceTypeID, bookAgainDetails, isGetSuccessDataMarketing])

  return (
    <>
      {
        (isHideLoadingNew || isHideLoadingEdit || isHideLoadingMarketingBooking)
        && (
          <NewBookingContainer
            area={extraInfos}
            firstTimeDiscount={firstTimeDiscount}
            termsAndConditionsLink={extraInfos.tc_link}
            privacyPolicyLink={extraInfos.privacy_policy_link}
          />)
      }
    </>
  )
}

export default SingleBookingView
