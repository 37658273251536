import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const excelMatrixSlice = createSlice({
  name: 'excelMatrix',
  initialState,
  reducers: {
    updateExcelMatrix: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const excelMatrixActionsCreator = excelMatrixSlice.actions

export default excelMatrixSlice
