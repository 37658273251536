/* eslint-disable react/no-string-refs */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SubAccountAPI from 'api/subAccountTag'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import CustomSelect from '../../common/custom_select/CustomSelect'
// UTILS
import I18n from 'i18n/i18n'
import _ from 'lodash'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'
// ACTIONS
// COMPONENTS
// CONSTANTS
// ASSETS

const mapStateToProps = state => ({
  subAccountTagPicked: state.subAccountTagPicked,
  isCheckedSubAccount: state.checkSubAccount
})

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    checkSubAccountActions: bindActionCreators(checkSubAccountActionsCreator, dispatch)
  }
}
class ModalSubAccountTag extends React.Component {
  constructor() {
    super()
    this.state = {
      isSearch: false,
      listTag: [],
    }
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.onSortTagList = this.onSortTagList.bind(this)
    this.onSearchTag = this.onSearchTag.bind(this)
    this.handleOpenInput = this.handleOpenInput.bind(this)
    this.handleCloseInput = this.handleCloseInput.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.emitChangeDebounced = _.debounce(this.onSearchTag, 300)
    this.LIST_SORT_SUB_ACCOUNT_TAG = [
      { name: I18n.t('webapp.default'), value: 'position' },
      { name: I18n.t('webapp.a_z'), value: 'name' },
      { name: I18n.t('webapp.recently_use'), value: 'last_used_at' },
    ]
  }

  componentDidMount() {
    this.fetchSubAccount()
  }

  onCloseModal() {
    const {
      closeModal,
      subAccountTagPicked,
      isCheckedSubAccount,
      checkSubAccountActions,

    } = this.props
    if (isCheckedSubAccount && _.isEmpty(subAccountTagPicked)) {
      checkSubAccountActions.updateSubAccountCheckBox({ value: false })
    }
    closeModal()
  }

  onChangeSubAccount(item) {
    const { chooseSubAccountTagHandler, closeModal, shouldScroll } = this.props
    const data = {
      sub_account_id: item.id,
      sub_account_name: item.name
    }
    chooseSubAccountTagHandler(data)
    closeModal()
    if (shouldScroll) {
      this.scrollToBottom()
    }
  }

  onSortTagList(e) {
    const { value } = e.target
    const params = {
      sort_by: value,
      sort_dir: value === 'last_used_at' ? 'desc' : 'asc'
    }
    this.fetchSubAccount(params)
  }

  onSearchTag(params) {
    this.fetchSubAccount(params)
  }

  handleChangeInput(e) {
    const { value } = e.target
    const params = {
      search_text: value
    }
    this.emitChangeDebounced(params)
  }

  handleCloseInput() {
    this.setState({
      isSearch: false
    }, () => this.fetchSubAccount())
  }

  handleOpenInput() {
    this.setState({
      isSearch: true
    })
  }

  fetchSubAccount(params = {}) {
    const { currentCustomer } = this.props
    let newParam = { ...params }
    if (currentCustomer.current_company_id && currentCustomer.current_company_id !== 0) {
      newParam = { ...newParam, company_id: currentCustomer.current_company_id }
    }
    SubAccountAPI.getSubAccountTagList(
      currentCustomer.authentication_token,
      newParam,
      (res) => {
        if (res?.data?.data) {
          this.setState({
            listTag: res.data.data,
          })
        }
      }
    )
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToBottom() {
    const element = document.querySelector('.sub-account-tooltip')
    element.scrollIntoView()
  }

  renderSubAccountTag() {
    const { listTag } = this.state
    const { subAccountTagPicked } = this.props

    return listTag && listTag.map(item => (
      <li key={item.id} className={subAccountTagPicked.sub_account_id === item.id ? 'active' : ''}>
        <div className="Overlay-RadioBox min-height50 dlvr-overlay-radio-box pt10 pb10">
          <span className="sa-name-text">
            {item.name}
          </span>
          <input
            type="radio"
            name="sub_account_choice"
            id={item.id}
            onChange={() => this.onChangeSubAccount(item)}
            checked={subAccountTagPicked.sub_account_id === item.id}
          />
          <label className="White-text">
            <i className="b material-icons Icon">
              fiber_manual_record
            </i>
          </label>
        </div>
      </li>
    ))
  }

  render() {
    const { fullScreen } = this.props
    const { isSearch } = this.state
    return (
      <div>
        <div
          className={`visible Modal Modal-ChooseAccount ${fullScreen && 'Full'} zoom-scale-8`}
          ref="chooseAccountModal"
          id="choose-sub-account-tag-modal"
        >
          <div className="SelectAccountBox">
            <div className="Box pt10 pr10 pb10 pl10 z-index-2" ref="form">
              <b className="White-text larger-font Modal-ChooseAccount-Title pt5 pb15 flex flex-space-between-justify">
                {I18n.t('webapp.label.select_sub_account_tag')}
                <i
                  className="b material-icons Icon cur-pointer"
                  onClick={() => this.onCloseModal()}
                >
                  close
                </i>
              </b>

              <div className="Box-Content Modal-ChooseSubAccount-Content White-bg Custom-Scroll-Group Radius-default">
                <div className="borderb">
                  {isSearch ? (
                    <div className="Input mt10 mr10 ml10 mb10" style={{ height: '30px' }}>
                      <i className="b material-icons Icon gray ml10">
                        search
                      </i>
                      <input
                        onChange={this.handleChangeInput}
                        placeholder={I18n.t('webapp.search')}
                      />
                      <i
                        onClick={this.handleCloseInput}
                        className="s material-icons Icon mr10"
                      >
                        close
                      </i>
                    </div>
                  ) : (
                    <div className="flex flex-end-justify pt10 pr10 pl10 pb10 ">
                      {/* <div className="sub-account-select">
                        <select
                          className="sub-account-tag-select br5 mr10"
                          onChange={this.onSortTagList}
                          defaultValue="position"
                        >
                          <option value="position" selected>
                            {I18n.t('webapp.default')}
                          </option>
                          <option value="name">
                            {I18n.t('webapp.a_z')}
                          </option>
                          <option value="last_used_at">
                            {I18n.t('webapp.recently_use')}
                          </option>
                        </select>
                      </div> */}
                      <CustomSelect itemList={this.LIST_SORT_SUB_ACCOUNT_TAG} onChange={this.onSortTagList} />
                      <button
                        className="search-icon flex-shrink0"
                        onClick={this.handleOpenInput}
                        type="button"
                      >
                        <i className="b material-icons Icon gray">
                          search
                        </i>
                      </button>
                    </div>
                  )}
                </div>
                <ul className="sub-account-ul vertical-scroll">
                  {this.renderSubAccountTag()}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="close-modal-overlay fixed"
            onClick={() => this.onCloseModal()}
          />
        </div>
      </div>
    )
  }
}

ModalSubAccountTag.propTypes = {
  currentCustomer: PropTypes.shape({}),
  chooseSubAccountTagHandler: PropTypes.func,
  closeModal: PropTypes.func,
  subAccountTagPicked: PropTypes.shape({}),
  isCheckedSubAccount: PropTypes.bool,
  fullScreen: PropTypes.bool,
  bookingActions: PropTypes.shape(),
  shouldScroll: PropTypes.bool
}

ModalSubAccountTag.defaultProps = {
  currentCustomer: undefined,
  chooseSubAccountTagHandler: () => { },
  closeModal: () => { },
  subAccountTagPicked: {},
  isCheckedSubAccount: false,
  fullScreen: false,
  bookingActions: undefined,
  shouldScroll: true
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSubAccountTag)
