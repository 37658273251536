export enum BOOKING_TYPE {
  BOOKING_LCL,
  BOOKING_FTL,
  BOOKING_PTL,
}
export enum HEADER_STATUS {
  ASSIGNING,
  CONFIRMED,
  GOING_TO_PICKUP,
  AT_PICKUP,
  GOING_TO_DEPOT,
  AT_DEPOT,
  GOODS_ACCEPTANCE,
  AT_SEA,
  AT_DESTINATION_PORT,
  GOING_TO_DESTINATION,
  COMPLETED,
  CANCELED,
  SUBMITTING_EDIT,
}
