export const ExportBookingsUtils = {
  calculateDay(days) {
    const date = new Date()
    date.setDate(date.getDate() - days)
    return date
  }
}

export const regEmailValidate = email => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)

export { ExportBookingsUtils as default }
