import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
// ASSETS
import { LUCKY_STAR, WALLET_ICON } from '../../constants/imageConstants'

// ACTIONS
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'

// CONSTANTS
import { POPUP_CASHBACK_EXPLAIN } from '../../constants/common/popupConstants'
import {
  NOT_SAME_PERCENT
} from '../../constants/common/batchConstants'
// UTILS
import { Utils } from '../../utils/Utils'
import commonUtils from '../../utils/common'
import I18n from 'i18n/i18n'

const mapStateToProps = state => ({
  extraInfos: state.extraInfos,
  currentCustomer: state.currentCustomer,
})

const mapDispatchToProps = dispatch => ({
  currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
})

class CashBackRenderItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [1, 2],
      needTransition: false,
    }
    this.handleSliderTranslateEnd = this.handleSliderTranslateEnd.bind(this)
    this.handleShowPopupExplain = this.handleShowPopupExplain.bind(this)
  }

  componentDidMount() {
    this.callSetInterval()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  callSetInterval() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(() => {
      this.setState({
        needTransition: true
      })
    }, 4000)
  }

  sliderStyle() {
    const { needTransition } = this.state

    if (needTransition) {
      return 'slide-up'
    }

    return ''
  }

  handleSliderTranslateEnd() {
    const { items } = this.state
    this.setState({
      needTransition: false,
      items: [...items.slice(1, items.length), ...items.slice(0, 1)]
    })
  }

  handleShowPopupExplain() {
    const { currentPopupIDActions } = this.props

    $('html, body').scrollTop(0)
    $('body').css('overflow', 'hidden')
    currentPopupIDActions.updateCurrentPopupID(POPUP_CASHBACK_EXPLAIN)
  }

  render() {
    const {
      extraInfos: {
        country_language: countryLanguage,
        cashback_dynamic_texts: cashBackDynamicText,
        currency,
      },
      currentCustomer: {
        credit
      },
      hideCashBackBar,
      cashBackPercent
    } = this.props
    const { items } = this.state
    const creditAmount = _.get(credit, 'amount', 0)
    const displayCreditAvailable = creditAmount ? Utils.formatFee(+creditAmount, countryLanguage, currency) : 0

    const isEveryTitle = commonUtils.isMultiple() || commonUtils.isBatchEZ() || commonUtils.isSmartLoad()

    let textCashBackBar = ''
    if (!_.isEmpty(cashBackDynamicText)) {
      textCashBackBar = isEveryTitle
        ? cashBackDynamicText?.cashback_bar_multiple?.text : cashBackDynamicText?.cashback_bar_single?.text
    }
    const displayCashBackBar = (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
    || (isEveryTitle
      ? !_.isEmpty(cashBackDynamicText?.cashback_bar_multiple)
      : !_.isEmpty(cashBackDynamicText?.cashback_bar_single))

    const CASHBACK_ITEMS = {
      1: {
        icon: LUCKY_STAR,
        content: (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT) ? I18n.t(`webapp.cashback_bar.${isEveryTitle ? 'percent_cashback_every' : 'percent_cashback'}`, { percent: cashBackPercent }) : textCashBackBar,
        visible: displayCashBackBar
      },
      2: {
        icon: WALLET_ICON,
        content: I18n.t('webapp.cashback_bar.credit_available', { creditBalance: displayCreditAvailable }),
        visible: displayCreditAvailable
      },
    }
    const visibleItems = _.filter(Object.values(CASHBACK_ITEMS), ({ visible }) => visible)
    const innerClassName = visibleItems.length > 1 ? this.sliderStyle() : ''

    return (
      <div className={`cashback-bar items-wrapper ${(hideCashBackBar || (!displayCashBackBar && !displayCreditAvailable)) ? 'hide-cashback-bar' : ''}`}>
        <div
          className={`${innerClassName} cur-pointer`}
          onTransitionEnd={this.handleSliderTranslateEnd}
          onClick={() => {
            const isDisplayPopupBar = (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
            || (isEveryTitle
              ? (!_.isEmpty(cashBackDynamicText?.cashback_popup_content_multiple)
                && !_.isEmpty(cashBackDynamicText?.cashback_popup_title_multiple)
              )
              : (!_.isEmpty(cashBackDynamicText?.cashback_popup_content_single)
                && !_.isEmpty(cashBackDynamicText?.cashback_popup_title_single)
              ))
            if (isDisplayPopupBar) {
              this.handleShowPopupExplain()
            }
          }}
        >
          {
            _.map(items, (item) => {
              const cashBackItem = CASHBACK_ITEMS[item]

              if (!cashBackItem.visible) return null

              return (
                <div
                  className="cashback-item"
                  key={item}
                >
                  <img src={cashBackItem.icon} alt="cashback-icon" />
                  <p>
                    {cashBackItem.content}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

CashBackRenderItems.propTypes = {
  extraInfos: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  cashBackPercent: PropTypes.string.isRequired,
  hideCashBackBar: PropTypes.bool,
}

CashBackRenderItems.defaultProps = {
  hideCashBackBar: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBackRenderItems)
