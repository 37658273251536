import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
// ACTIONS
// COMPONENTS
// CONSTANTS
import {
  V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY,
} from 'constants/imageConstants'
import {
  PASSWORD, PASSWORD_CONFIRM, INDEX_0, INDEX_1, NUMBER_6,
} from './constants'
// ASSETS

class ModalConfirmNewPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowPwd: true,
      isConfirmShowPwd: true,
      password: '',
      passwordConfirm: '',
      validateList: [
        {
          name: PASSWORD,
          isValidate: false,
          isValid: false,
          errorText: ''
        },
        {
          name: PASSWORD_CONFIRM,
          isValidate: false,
          isValid: false,
          errorText: ''
        }
      ],
    }
  }

  handleShowPwd(field) {
    const values = {
      ...this.state,
    }
    const value = values[field]
    this.setState({
      [field]: !value
    })
  }

  handleErrorText(value, index) {
    const { password } = this.state
    if (_.isEmpty(value)) {
      return I18n.t('validations.required_password')
    }
    if (value.length < NUMBER_6) {
      return I18n.t('validations.minlength_password', { number: NUMBER_6 })
    }
    if (index === INDEX_1 && value !== password) {
      return I18n.t('validations.equalTo')
    }
    return ''
  }

  handleValidateData(value, index, isSubmit = false) {
    const { password } = this.state
    const { validateList } = this.state
    const newList = [...validateList]
    const validateData = newList[index]
    let isValid = true
    const errorText = this.handleErrorText(value, index)
    if (index === INDEX_0) {
      const { passwordConfirm } = this.state
      if (value.length < NUMBER_6 || _.isEmpty(value)) {
        isValid = false
      }
      let passwordData = {
        ...validateData,
        errorText,
        isValid,
      }
      if (isSubmit) {
        passwordData = {
          ...validateData,
          errorText,
          isValid,
          isValidate: true,
        }
      }
      if (passwordConfirm === value && passwordConfirm.length >= NUMBER_6) {
        const passwordConfirmData = {
          ...newList[INDEX_1],
          errorText: '',
          isValid: true
        }
        newList[INDEX_1] = passwordConfirmData
      }
      newList[index] = passwordData
    }
    if (index === INDEX_1) {
      if ((index === INDEX_1 && value !== password) || value.length < NUMBER_6 || _.isEmpty(value)) {
        isValid = false
      }
      let newData = {
        ...validateData,
        errorText,
        isValid,
      }
      if (isSubmit) {
        newData = {
          ...validateData,
          errorText,
          isValid,
          isValidate: true,
        }
      }
      newList[index] = newData
    }
    this.setState({
      validateList: newList,
    })
  }

  handleChangeValue(event, field, index) {
    const { value } = event.target
    this.handleValidateData(value, index)
    this.setState({
      [field]: value,
    })
  }

  handleValidateList(index, field) {
    const values = {
      ...this.state,
    }
    const value = values[field]
    const { validateList } = this.state
    const newList = [...validateList]
    const data = newList[index]
    const { isValidate } = data
    if (!isValidate && !_.isEmpty(value)) {
      const newField = {
        ...data,
        isValidate: true,
      }
      newList[index] = newField
      this.setState({
        validateList: newList,
      })
    }
  }

  handleSubmit() {
    const { handleUpdatePassword } = this.props
    const { validateList, password, passwordConfirm } = this.state
    const isNotValidForm = _.find(validateList, item => item.isValid === false)
    if (_.isEmpty(isNotValidForm)) {
      const params = {
        password
      }
      handleUpdatePassword(params)
    } else {
      Promise.resolve(this.handleValidateData(password, INDEX_0, true))
        .then(() => this.handleValidateData(passwordConfirm, INDEX_1, true))
    }
  }

  render() {
    const {
      isShowPwd, isConfirmShowPwd, password,
      passwordConfirm, validateList,
    } = this.state
    const logoIcon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    return (
      <div className="visible vertical-scroll Modal">
        <div className="LoginBox vertical-scroll">
          <form className="Box">
            <div className="Box-Head">
              <div className="Logo">
                <img src={logoIcon} alt="deliveree" />
              </div>
              <h3>
                {I18n.t('webapp.label.change_password')}
              </h3>
            </div>
            <div className="Box-Content">
              <div className="Form">
                <div className="FormGroup">
                  <label className="FormGroup-label capitalize">
                    {I18n.t('devise_template.new_password')}
                  </label>
                  <div className={`Input ${validateList[INDEX_0].isValidate && !validateList[INDEX_0].isValid ? 'error-border' : ''}`}>
                    <input
                      value={password}
                      className="dlvr-pwd-field"
                      autoFocus
                      type={isShowPwd ? 'text' : 'password'}
                      tabIndex={INDEX_1}
                      onChange={event => this.handleChangeValue(event, PASSWORD, INDEX_0)}
                      onBlur={() => this.handleValidateList(INDEX_0, PASSWORD)}
                    />
                    <div className="Input-Icon">
                      <input
                        type="checkbox"
                        value="true"
                        style={{ display: 'none' }}
                        checked="checked"
                      />
                      <label>
                        <i className={`b material-icons Icon ${isShowPwd ? 'green' : ''}`} onClick={() => this.handleShowPwd('isShowPwd')}>
                          visibility
                        </i>
                      </label>
                    </div>
                  </div>
                  {validateList[INDEX_0].isValidate && !validateList[INDEX_0].isValid && (
                    <label className="dlvr-error-text">
                      {validateList[INDEX_0].errorText}
                    </label>
                  )}
                </div>
                <div className="FormGroup">
                  <label className="FormGroup-label capitalize">
                    {I18n.t('devise_template.confirm_new_password')}
                  </label>
                  <div className={`Input ${validateList[INDEX_1].isValidate && !validateList[INDEX_1].isValid ? 'error-border' : ''}`}>
                    <input
                      value={passwordConfirm}
                      className="password optional"
                      type={isConfirmShowPwd ? 'text' : 'password'}
                      onChange={event => this.handleChangeValue(event, PASSWORD_CONFIRM, INDEX_1)}
                      onBlur={() => this.handleValidateList(INDEX_1, PASSWORD_CONFIRM)}
                    />
                    <label className="Input-Icon">
                      <input
                        type="checkbox"
                        value="true"
                        style={{ display: 'none' }}
                        checked="checked"
                      />
                      <i className={`b material-icons Icon ${isConfirmShowPwd ? 'green' : ''}`} onClick={() => this.handleShowPwd('isConfirmShowPwd')}>
                        visibility
                      </i>
                    </label>
                  </div>
                  {validateList[INDEX_1].isValidate && !validateList[INDEX_1].isValid && (
                    <label className="dlvr-error-text">
                      {validateList[INDEX_1].errorText}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="Box-Actions">
              <button onClick={() => this.handleSubmit()} className="green Button" type="button">
                {I18n.t('devise_template.change_my_password')}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ModalConfirmNewPassword.defaultProps = {
}

ModalConfirmNewPassword.propTypes = {
  handleUpdatePassword: PropTypes.func.isRequired,
}

export default ModalConfirmNewPassword
