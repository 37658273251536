import { getUserSettingRequest, updateCustomerSettingAPI } from 'api/userProfile'
import _ from 'lodash'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'

const updateCustomerSetting = ({
  checked,
  key,
}) => (dispatch, getState) => {
  const {
    currentCustomer
  } = getState()

  updateCustomerSettingAPI({
    authenToken: currentCustomer.authentication_token,
    queryString: `settings[customer.${key}]=${checked}`,
  }).then((response) => {
    if (response.data.success) {
      dispatch(currentCustomerActionsCreator.updateCustomer({ [key]: checked }))
    }
  })
}

export {
  updateCustomerSetting,
}
