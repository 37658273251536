import React from 'react'
import { LONG_HAUL_WHITE_ICON } from 'constants/imageConstants'
import I18n from 'i18n/i18n'

const LongHaulInvalidDatePopup = () => (
  <div className="Long-Haul-Invalid-Date-Popup">
    <img src={LONG_HAUL_WHITE_ICON} />
    <p>
      {I18n.t('webapp.booking.message_color_banner')}
    </p>
  </div>
)

export default LongHaulInvalidDatePopup
