import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Utils } from 'utils/Utils'
import _ from 'lodash'

class Rating extends React.PureComponent {
  render() {
    const { total, newFormat, countryLanguage } = this.props
    let { rating } = this.props

    if (newFormat) {
      return (
        <div className="flex">
          <i className="b yellow material-icons Icon">
            star
          </i>
          <span className="default-font default-color ml5">
            {Utils.formatNumberByCountry(rating, countryLanguage, 1)}
          </span>
        </div>
      )
    }

    rating = Math.floor((rating || 0) * 2)
    return (
      <div className="RatingStars rtl">
        {_.range(total - (Math.floor(rating / 2)) - (rating % 2)).map((value) => {
          const star = 'star'
          return (
            <i className="b gray material-icons Icon" key={value}>
              {star}
            </i>
          )
        })}
        {_.range(rating % 2).map((value) => {
          const starHalf = 'star_half'
          return (
            <i className="b yellow material-icons Icon" key={value}>
              {starHalf}
            </i>
          )
        })}
        {_.range(Math.floor(rating / 2)).map((value) => {
          const star = 'star'
          return (
            <i className="b yellow material-icons Icon" key={value}>
              {star}
            </i>
          )
        })}
      </div>
    )
  }
}

Rating.propTypes = {
  total: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  newFormat: PropTypes.bool,
  countryLanguage: PropTypes.string,
}

Rating.defaultProps = {
  newFormat: false,
  countryLanguage: undefined,
}

export default connect()(Rating)
