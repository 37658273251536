import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = null
const countNumberOnlineDriversSlice = createSlice({
  name: 'countNumberOnlineDrivers',
  initialState,
  reducers: {
    getOnlineDriversOnVehicleType: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const countNumberOnlineDriversActionsCreator = countNumberOnlineDriversSlice.actions

export default countNumberOnlineDriversSlice
