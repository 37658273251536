import moment from 'moment'
import { DRAFT, BATCHES_PER_PAGE } from 'constants/ezSpreadSheetConstants'
import {
  FULL_DAY,
  WEB_EZ_SHEET,
  LONG_HAUL,
  NOW,
  QUICK_CHOICE,
  SCHEDULE,
  WEB_PLANNER
} from 'constants/bookingConstants'
import { objectToFormData, convertCustomReimbursementToParam, convertBookingCustomReimbursementToParam } from 'utils/new_booking/common'
import { CPODUtils } from 'utils/booking/CPODUtils'
import { TALLY_API_URL } from 'constants/appConstants'
import { STEP_1_PAYLOAD_FIELDS } from './bookings'
import { isEditDraft } from '../utils/booking/common'
import _ from 'lodash'
import apiClient from 'services/axiosApp'
import * as batchUtil from '../utils/batchUtil'
// ASSETS

// It will be used for calculate and getTallyData functions
const generateRequestPayloadForCalculatingBooking = (booking, currentCustomer) => {
  const {
    vehicleTypeID,
    locations,
    extraRequirements = [],
    extraRequirementsNegativePosition = [],
    timeType,
    fullDaySelectedAmount,
    discountCode,
    roundTripDiscount,
    totalDistance,
    bookingTrackingAttr,
    customReimbursements,
    extraInfos,
    use_credit: useCredit
  } = booking
  let {
    pickupTime
  } = booking

  pickupTime = (timeType === NOW || timeType === QUICK_CHOICE) ? undefined : pickupTime
  const bookingExtraRequirementsAttributes = batchUtil.extraRequirementsAttributesForCalculate(
    extraRequirements, extraRequirementsNegativePosition, timeType
  )
  const estimateTransitTimesAttributes = []

  if (pickupTime && booking.transit_time) {
    const estimateTransitTimes = {
      eta: moment(pickupTime).add(booking.transit_time, 's').format()
    }
    if (booking.worst_transit_time) {
      estimateTransitTimes.worst_case_eta = moment(pickupTime).add(booking.worst_transit_time, 's').format()
    }

    estimateTransitTimesAttributes.push(estimateTransitTimes)
  }
  const hasEstimateTransitTime = estimateTransitTimesAttributes.length > 0

  return {
    locations_attributes: locations.map(location => ({
      latitude: location.latitude,
      longitude: location.longitude,
      need_cod: location.need_cod,
      need_pod: location.need_pod,
      cod_invoice_fees: _.toInteger(location.cod_invoice_fees),
      name: location.name,
      extra_requirement_locations_attributes: _.filter(location.extra_requirement_locations, e => e.selected_amount > 0)
    })),
    company_id: currentCustomer.current_company_id,
    booking_tracking_attributes: bookingTrackingAttr,
    vehicle_type_id: vehicleTypeID,
    time_type: timeType,
    ...(pickupTime ? { pickup_time: pickupTime } : {}),
    ...(timeType === FULL_DAY ? { full_day_selected_amount: fullDaySelectedAmount || 1 } : {}),
    discount_code: discountCode,
    round_trip_discount: roundTripDiscount,
    total_distance: totalDistance,
    booking_extra_requirements_attributes: bookingExtraRequirementsAttributes,
    // send back eta_locations_id to server
    ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),
    ...(hasEstimateTransitTime ? { estimate_transit_times_attributes: estimateTransitTimesAttributes } : {}),
    // custom reimbrsement
    booking_reimbursements_attributes: (timeType !== LONG_HAUL && !extraInfos.enable_parking_tolls_feature)
      ? null
      : convertCustomReimbursementToParam(customReimbursements),
    use_credit: useCredit
  }
}

const generateRequestPayloadForCalculatingTransitTime = (booking) => {
  const {
    vehicleTypeID,
    locationsAttributes,
    timeType,
    fullDaySelectedAmount,
    currentCustomer,
    etaLocationsId,
    extraRequirements = [],
  } = booking
  let { pickupTime } = booking
  let bookingExtraRequirementsAttributes = []
  pickupTime = (timeType === NOW || timeType === QUICK_CHOICE) ? undefined : pickupTime
  if (timeType === FULL_DAY) {
    bookingExtraRequirementsAttributes = extraRequirements
      .filter(({ selected_amount: selectedAmount }) => (+selectedAmount))
      .map(item => ({
        extra_requirement_id: item.id,
        is_flat: item.is_flat,
        selected_amount: +item.selected_amount,
        unit_price: item.unit_price,
      }))
  }

  return {
    locations_attributes: locationsAttributes.map(location => ({
      latitude: location.latitude,
      longitude: location.longitude,
      name: location.name,
      need_cod: location.need_cod,
      need_pod: location.need_pod,
      ...(location.cod_invoice_fees ? { cod_invoice_fees: _.toInteger(location.cod_invoice_fees) } : {})
    })),
    vehicle_type_id: vehicleTypeID,
    time_type: timeType,
    ...(pickupTime ? { pickup_time: pickupTime } : {}),
    ...(timeType === FULL_DAY ? { full_day_selected_amount: fullDaySelectedAmount || 1 } : {}),
    ...(currentCustomer.current_company_id ? { company_id: currentCustomer.current_company_id } : {}),
    // send back eta_locations_id to server
    ...(etaLocationsId ? { eta_locations_id: etaLocationsId, locations_are_changed: true } : {}),
    booking_extra_requirements_attributes: bookingExtraRequirementsAttributes,
  }
}

const BatchesAPI = {
  getExtraServices: async (authenticationToken, areaID, companyID, timeType, isFullDay, isLH, callback, isSmartPlanner) => {
    let query = {
      area_id: areaID,
      is_full_day: isFullDay,
      company_id: companyID,
      time_type: timeType,
    }

    if (!isSmartPlanner) {
      query = {
        ...query,
        time_type: timeType,
        dropoff_count: 2,
        is_long_haul: isLH
      }
    }
    try {
      const res = await apiClient.get('/api/v3/batches/extra_services', { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  getBookingExtraRequirements: async (authenticationToken, booking, companyID, callback) => {
    const serviceTypeID = booking.vehicle_type.service_type_id
    const vehicleTypeID = booking.vehicle_type.id
    const timeType = booking.time_type_option.type_key
    const isFullDay = timeType === FULL_DAY
    const dropoffCount = _.size(booking.locations) - 1
    const query = {
      service_type_id: serviceTypeID,
      vehicle_type_id: vehicleTypeID,
      company_id: companyID,
      time_type: timeType,
      is_full_day: isFullDay,
      dropoff_count: dropoffCount,
    }
    try {
      const res = await apiClient.get('/api/v3/batches/booking_extra_requirements', { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  calculate: async (bookingParams, callback) => {
    const currentCustomer = bookingParams[0].currentCustomer
    const params = bookingParams.map(booking => generateRequestPayloadForCalculatingBooking(booking, currentCustomer))
    const dataSend = {
      bookings: params,
      include: ['custom_reimbursements', 'reimbursements']
    }
    try {
      const res = await apiClient.post('/api/v3/batches/calculate', dataSend)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  create: async (currentCustomer, extraRequirements, batch, batchTemplate, extraInfos, callback) => {
    let template = {}
    if (!_.isUndefined(batchTemplate)) {
      const templateMapping = _.isNull(batchTemplate.mapping) ? undefined : batchTemplate.mapping
      _.assign(
        template,
        batchTemplate,
        {
          customer_id: currentCustomer.id,
          company_id: currentCustomer.current_company_id,
          // Does your batch have the same Pickup Location?
          same_pickup: batchTemplate.same_pickup,
          contact_id: batchTemplate.contact_id,
          // Does your batch have the same Pickup Date & Time?
          same_pickup_datetime: batchTemplate.same_pickup_datetime,
          pickup_time: batchTemplate.pickup_time,
          // Does your batch have the same Vehicle Type?
          same_vehicle_type: batchTemplate.same_vehicle_type,
          vehicle_type_id: batchTemplate.vehicle_type_id,
          // Does your batch have the same Extra Services?
          same_extra_requirements: batchTemplate.same_extra_requirements,
          extra_requirements: JSON.stringify(batchTemplate.extra_requirements),
          // Does your batch have the same Custom Reimbursement?
          same_custom_reimbursements: batchTemplate.same_custom_reimbursements,
          same_time_type: batchTemplate.same_time_type,
          // Does your batch have the same Sub Account Tag?
          same_sub_account_tag: batchTemplate.same_sub_account_tag,
          sub_account_tag: batchTemplate.sub_account_tag,
          // Mapping - step 3
          consolidates: batchTemplate.consolidates,
          mapping: JSON.stringify(templateMapping),
          batch_type: batch.batch_type,
        }
      )
    }
    const params = {
      batch: {
        id: batch.id,
        area_id: batch.area_id,
        by_option_extra: batch.by_option_extra,
        bookings: batch.bookings.map((booking) => {
          const sendToFavDriver = booking.send_first_to_favorite || booking.sendFirstToFavorite || false
          const fullDaySelectedAmount = +booking.full_day_selected_amount
          const timeType = booking.time_type
          let pickupTime = booking.pickup_time
          const estimateTransitTimesAttributes = []

          // I believe it is better if the server set pickup time for case of 'now' time type.
          if (timeType === NOW && !booking.quick_choice_id) {
            pickupTime = moment().format()
          }

          if (pickupTime && booking.transit_time) {
            const estimateTransitTimes = {
              eta: moment(pickupTime).add(booking.transit_time, 's').format()
            }
            if (booking.worst_transit_time) {
              estimateTransitTimes.worst_case_eta = moment(pickupTime).add(booking.worst_transit_time, 's').format()
            }

            estimateTransitTimesAttributes.push(estimateTransitTimes)
          }
          const hasEstimateTransitTime = estimateTransitTimesAttributes.length > 0

          return {
            name: booking.name,
            time_type: booking.time_type,
            quick_choice_id: booking.quick_choice_id,
            display_quick_choice_id: booking.display_quick_choice_id,
            vehicle_type_id: booking.vehicle_type_id,
            service_type_id: booking.service_type_id,
            company_id: currentCustomer.current_company_id,
            job_order_number: booking.job_order_number,
            note: booking.note,
            ...(pickupTime ? { pickup_time: pickupTime } : {}),
            marked_as_favorite: booking.marked_as_favorite || false,
            ...(timeType === FULL_DAY ? { full_day_selected_amount: fullDaySelectedAmount || 1 } : {}),
            discount_code: booking.discount_code,
            favorite_driver_is_high_priority: booking.favoriteDriverIsHighPriority,
            locations_attributes: booking.locations.map(location => ({
              order: location.order,
              latitude: location.latitude,
              longitude: location.longitude,
              name: location.name,
              recipient_name: location.recipient_name,
              recipient_phone: location.recipient_phone,
              cod_invoice_fees: _.toInteger(location.cod_invoice_fees),
              description: location.description || '',
              need_cod: location.need_cod || false,
              need_pod: location.need_pod || false,
              cod_note: CPODUtils.getPramsCODPODNote(location, 'cod_note', booking),
              pod_note: CPODUtils.getPramsCODPODNote(location, 'pod_note', booking),
              is_payer: location.is_payer || false,
              address_components: location.address_components,
              is_phone_mask: location.is_phone_mask,
              extra_requirement_locations_attributes: location.extra_requirement_locations_attributes || []
            })),
            booking_extra_requirements_attributes: booking.extra_requirements.map((extraRequirement) => {
              let unitPrice = extraRequirement.unit_price
              let levelPrice = extraRequirement.level_price
              let pricingId = extraRequirement.extra_requirement_pricing_id
              if (!_.isUndefined(extraRequirement.selectedPricing)) {
                unitPrice = extraRequirement.selectedPricing.fees
                levelPrice = extraRequirement.selectedPricing.level_price
                pricingId = extraRequirement.selectedPricing.id
              }
              return {
                extra_requirement_id: extraRequirement.id,
                selected_amount: extraRequirement.selected_amount,
                is_flat: extraRequirement.is_flat,
                position: extraRequirement.position,
                unit_price: unitPrice,
                level_price: levelPrice,
                extra_requirement_pricing_id: pricingId
              }
            }),
            booking_badges_attributes: booking.badges.map(badge => ({
              badgeable_relation_id: badge.id,
              badgeable_relation_type: badge.badgeable_relation_type,
              selected_amount: badge.selected_amount,
            })),
            booking_tracking_attributes: booking.booking_tracking_attributes,
            booking_attachment_ids: booking.booking_attachment_ids,
            related_attachment_ids: booking.relatedAttachmentIDs,
            allow_parking_fees: booking.time_type === LONG_HAUL ? false : booking.allow_parking_fees,
            allow_tolls_fees: booking.time_type === LONG_HAUL ? false : booking.allow_tolls_fees,
            allow_waiting_time_fees: booking.time_type === LONG_HAUL ? true : booking.allow_waiting_time_fees,
            send_first_to_favorite: sendToFavDriver,
            display_total_fees: booking.display_total_fees,
            total_distance: booking.total_distance,
            currency: booking.currency,
            status: booking.status || 'locating_driver',
            batch_tracking_token: booking.batch_tracking_token,
            round_trip_discount: booking.round_trip_discount,
            round_trip_discount_amount: booking.round_trip_discount_amount,
            assign_driver_booking_attributes: booking.assignedDriver
              ? {
                driver_id: booking.assignedDriver.id,
                fleet_partner_id: booking.assignedDriver.fleet_partner_id
              } : undefined,
            require_signatures: booking.require_signatures,
            // send back eta_locations_id to server
            ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),
            ...(hasEstimateTransitTime ? { estimate_transit_times_attributes: estimateTransitTimesAttributes } : {}),
            booking_reimbursements_attributes: (timeType !== LONG_HAUL && !extraInfos.enable_parking_tolls_feature)
              ? null
              : convertBookingCustomReimbursementToParam(booking.custom_reimbursements),
            display_time_type: booking.display_time_type,
            ...(booking.sub_account_tag_attributes && booking.sub_account_tag_attributes.sub_account_id !== null
              && { sub_account_tag_attributes: booking.sub_account_tag_attributes }),
            use_credit: booking.use_credit
          }
        }),
        company_id: currentCustomer.current_company_id,
        desc: batch.desc || '',
        file_name: batch.fileName || '',
        name: batch.name,
        step: batch.step,
        batch_type: batch.batch_type,
        marked_as_favorite_batch: batch.marked_as_favorite_batch,
        cashback_credit_used_display: batch.cashback_credit_used_display
      }
    }
    const removingBatchIDs = []
    const removingDraftBookingIDs = []
    _.forEach(batch.bookings, (booking) => {
      if ((!removingBatchIDs.includes(booking.batchID) && !isEditDraft())) {
        removingBatchIDs.push(booking.batchID)
      }
      if (!removingDraftBookingIDs.includes(booking.draft_id)) {
        removingDraftBookingIDs.push(booking.draft_id)
      }
    })
    if (!_.isEmpty(removingBatchIDs)) {
      params.batch.removing_batch_ids = removingBatchIDs
    }
    if (!_.isEmpty(removingDraftBookingIDs)) {
      params.batch.removing_draft_booking_ids = removingDraftBookingIDs
    }
    // if (!_.isUndefined(batchTemplate)) {
    if (batch.created_by === WEB_EZ_SHEET || batch.created_by === WEB_PLANNER) {
      _.assign(params, { batch_template: template })
    }
    try {
      const res = await apiClient.post('/api/v3/batches', params, {
        headers: {
          'Device-Type': batch.created_by,
          'App-Name': 'Deliveree Webapp'
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  saveAsDraft: async (currentCustomer, batch, batchTemplate, isSmartPlanner, callback) => {
    let template = {}
    // Set draft batch bookings have no attachments
    // batch.bookings.map((booking, index) => {
    //   booking['bookingAttachmentsAttributes'] = []
    // })
    if (!_.isUndefined(batchTemplate)) {
      const templateMapping = _.isNull(batchTemplate.mapping) ? undefined : batchTemplate.mapping
      _.assign(
        template,
        batchTemplate,
        {
          customer_id: currentCustomer.id,
          company_id: currentCustomer.current_company_id,
          // Does your batch have the same Pickup Location?
          same_pickup: batchTemplate.same_pickup,
          contact_id: batchTemplate.contact_id,
          // Does your batch have the same Pickup Date & Time?
          same_pickup_datetime: batchTemplate.same_pickup_datetime,
          pickup_time: batchTemplate.pickup_time,
          // Does your batch have the same Vehicle Type?
          same_vehicle_type: batchTemplate.same_vehicle_type,
          vehicle_type_id: batchTemplate.vehicle_type_id,
          // Does your batch have the same Extra Services?
          same_extra_requirements: batchTemplate.same_extra_requirements,
          extra_requirements: JSON.stringify(batchTemplate.extra_requirements),
          // Does your batch have the same Custom Reimbursement?
          same_custom_reimbursements: batchTemplate.same_custom_reimbursements,
          same_time_type: batchTemplate.same_time_type,
          // Does your batch have the same Sub Account Tag?
          same_sub_account_tag: batchTemplate.same_sub_account_tag,
          sub_account_tag: batchTemplate.sub_account_tag,
          // Mapping - step 3
          consolidates: batchTemplate.consolidates,
          mapping: JSON.stringify(templateMapping),
          batch_type: batchTemplate.batch_type || ''
        }
      )
      _.assign(batch, { pickup_time: batchTemplate.pickup_time })
    }
    let params = {
      batch_template: template,
      batch: {
        id: batch.id,
        area_id: batch.area_id,
        customer_id: currentCustomer.id,
        company_id: currentCustomer.current_company_id,
        contact_id: batch.contact_id,
        desc: batch.desc || '',
        file_name: batch.fileName || '',
        name: batch.name,
        pickup_time: batch.pickup_time || '',
        step: batch.step,
        bookings_count: _.size(batch.bookings),
        bookings: batch.bookings,
        by_option_extra: batch.by_option_extra,
        batch_type: batch.batch_type || '',
        created_by: batch.created_by || ''
      }
    }

    if (isSmartPlanner) {
      const newBatch = {
        ...params.batch,
        pickup_time_mode: batch.pickup_time_mode,
        planner_id: batch.planner_id
      }
      params = {
        ...params,
        batch: newBatch
      }
    }
    try {
      const res = await apiClient.post('/api/v3/batches/save_as_draft', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  get: async (batchID, currentCustomer, callback) => {
    const query = {
      'include': [
        'locations', 'batch_bookings', 'driver', 'service_type', 'vehicle_type', 'driver.vehicles',
        'estimated_working_times', 'fleet_partner', 'reimbursements'
      ],
      'only': [
        'id', 'name', 'file_name', 'desc', 'batch_template_id', 'customer_id', 'company_id', 'contact_id', 'area_id',
        'pickup_time', 'vehicle_type_id', 'extra_requirements', 'created_at', 'updated_at', 'bookings_count', 'status',
        'step', 'total_fee', 'currency', 'bookings', 'arranging_timeout_at', 'marked_as_favorite_batch', 'need_confirmation',
        'created_by', 'waiting_time_intracity', 'over_waiting_time_intracity', 'batch_type'
      ],
      'only[][batch_bookings]': [
        'id', 'name', 'customer_id', 'driver_id', 'batch_id', 'time_type', 'quick_choice_id', 'status',
        'chat_enable', 'media_chat_enable', 'total_distance', 'created_at', 'pickup_time', 'completed_at',
        'payment_type', 'job_order_number', 'number_driver_timeout', 'full_day_selected_amount', 'estimated_working_times',
        'allow_customer_cancel', 'ceb_enabled_feature', 'tally_info', 'need_confirmation', 'company_id', 'settings'
      ],
      'only[][batch_bookings][][driver]': ['id', 'name', 'driver_image_url', 'rating', 'phone', 'current_os_version', 'current_device_type'],
      'only[][batch_bookings][][driver][][vehicles]': ['vehicle_type_name'],
      'only[][batch_bookings][][driver][][vehicles][][vehicle_attributes]': ['vehicle_color', 'plate_number'],
      'only[][batch_bookings][][vehicle_type]': ['name', 'id', 'settings'],
      'only[][batch_bookings][][service_type]': ['name', 'inactive_icon_url', 'active_icon_url', 'id'],
      'only[][batch_bookings][][locations]': ['name', 'latitude', 'longitude', 'address_components', 'arrived_at', 'has_signature'],
      'only[][batch_bookings][][fleet_partner]': ['id', 'name', 'beta', 'photo_url', 'rating'],
      'only[][batch_bookings][][sub_account_tag]': ['id', 'sub_account_id', 'sub_account_name']
    }
    try {
      const res = await apiClient.get(`/api/v3/batches/${batchID}`, { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  getBatch: async (batchID, currentCustomer, callback) => {
    const query = {
      'include': [
        'locations', 'batch_bookings', 'driver', 'service_type', 'vehicle_type', 'driver.vehicles',
        'estimated_working_times', 'fleet_partner', 'reimbursements'
      ],
      'only': [
        'id', 'name', 'file_name', 'desc', 'batch_template_id', 'customer_id', 'company_id', 'contact_id', 'area_id',
        'pickup_time', 'vehicle_type_id', 'extra_requirements', 'created_at', 'updated_at', 'bookings_count', 'status',
        'step', 'total_fee', 'currency', 'bookings', 'arranging_timeout_at', 'marked_as_favorite_batch', 'need_confirmation',
        'created_by', 'waiting_time_intracity', 'over_waiting_time_intracity', 'batch_type'
      ],
      'only[][batch_bookings]': [
        'id', 'name', 'customer_id', 'driver_id', 'batch_id', 'time_type', 'quick_choice_id', 'status',
        'chat_enable', 'media_chat_enable', 'total_distance', 'created_at', 'pickup_time', 'completed_at',
        'payment_type', 'job_order_number', 'number_driver_timeout', 'full_day_selected_amount', 'estimated_working_times',
        'allow_customer_cancel', 'ceb_enabled_feature', 'tally_info', 'need_confirmation', 'company_id', 'settings'
      ],
      'only[][batch_bookings][][driver]': ['id', 'name', 'driver_image_url', 'rating', 'phone', 'current_os_version', 'current_device_type'],
      'only[][batch_bookings][][driver][][vehicles]': ['vehicle_type_name'],
      'only[][batch_bookings][][driver][][vehicles][][vehicle_attributes]': ['vehicle_color', 'plate_number'],
      'only[][batch_bookings][][vehicle_type]': ['name', 'id', 'settings'],
      'only[][batch_bookings][][service_type]': ['name', 'inactive_icon_url', 'active_icon_url', 'id'],
      'only[][batch_bookings][][locations]': ['name', 'latitude', 'longitude', 'address_components', 'arrived_at', 'has_signature'],
      'only[][batch_bookings][][fleet_partner]': ['id', 'name', 'beta', 'photo_url', 'rating'],
      'only[][batch_bookings][][sub_account_tag]': ['id', 'sub_account_id', 'sub_account_name']
    }
    return apiClient.get(`/api/v3/batches/${batchID}`, { params: query })
  },

  getBookingForUpdateChat: async (bookingID, currentCustomer, callback) => {
    const query = {
      'include': ['locations', 'batch_bookings', 'driver', 'service_type', 'vehicle_type', 'driver.vehicles', 'fleet_partner', 'reimbursements'],
      'only': [
        'id', 'name', 'customer_id', 'driver_id', 'batch_id', 'time_type', 'quick_choice_id', 'status', 'chat_enable',
        'media_chat_enable', 'total_distance', 'created_at', 'pickup_time', 'completed_at', 'payment_type', 'job_order_number',
        'estimated_working_times', 'number_driver_timeout', 'full_day_selected_amount', 'tally_info', 'full_day_megazone_blog_link',
        'total_fees', 'currency'
      ],
      'only[][driver]': ['id', 'name', 'driver_image_url', 'rating', 'phone', 'current_os_version', 'current_device_type'],
      'only[][driver][][vehicles]': ['vehicle_type_name'],
      'only[][driver][][vehicles][][vehicle_attributes]': ['vehicle_color', 'plate_number'],
      'only[][vehicle_type]': ['name', 'id', 'settings'],
      'only[][service_type]': ['name', 'inactive_icon_url', 'active_icon_url', 'id'],
      'only[][locations]': ['name', 'latitude', 'longitude', 'address_components', 'arrived_at', 'has_signature', 'signature_updated_at'],
      'only[][fleet_partner]': ['id', 'name', 'beta', 'photo_url', 'rating'],
      'only[][sub_account_tag]': ['id', 'sub_account_id', 'sub_account_name'],
    }
    try {
      const res = await apiClient.get('/api/v3/batches/save_as_draft', { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  paginate: async (
    scope,
    page,
    onlyFields = [],
    currentCustomer,
    searchString,
    areaID,
    includeField = [],
    onlyFieldsBatchBookings = [],
    callback
  ) => {
    const query = {
      scope,
      page,
      company_id: currentCustomer.current_company_id,
      'only': onlyFields,
      search_by: searchString,
      area_id: areaID,
      'include': includeField,
      'only[][batch_bookings]': onlyFieldsBatchBookings
    }
    try {
      const res = await apiClient.get('/api/v3/batches/paginate', { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  bookFromDraft: async (batchID, batchTrackingToken, currentCustomer, callback) => {
    const dataSend = {
      id: batchID,
      batch_tracking_token: batchTrackingToken
    }
    try {
      const res = await apiClient.post(`/api/v3/batches/${batchID}/book_from_draft`, dataSend,
      { 
        'include[]': 'locations', 
        header: {
          'Device-Type': 'web_batch',
          'App-Name': 'Deliveree Webapp'
        }
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  checkOutOfServiceLocaitons: async (batch, currentCustomer, extraInfos, popupScreenType, callback) => {
    const params = {
      popup_screen_type: popupScreenType,
      country_code: extraInfos.country_code,
      bookings: batch.bookings,
    }
    try {
      const res = await apiClient.post('/api/v3/batches/check_batch_locations', params,
      { 
        'include[]': 'locations', 
        header: {
          'Device-Type': 'web_batch',
          'App-Name': 'Deliveree Webapp'
        }
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  checkOutOfServiceBookingLocations: async (
    locations,
    { timeType, serviceTypeID, vehicleTypeID },
    { countryCode, companyId, authenticationToken },
    callback
  ) => {
    // Declare variable
    let requestString = ''
    let lat
    let lng
    const timeTypes = {
      now: 1,
      schedule: 2,
      full_day: 3,
      long_haul: 4
    }

    // stop action when locations empty
    if (locations.length === 0) {
      return
    }

    // Handle logic
    const filterLocations = _.filter(locations, location => (location.lat !== undefined || location.lng !== undefined))
    filterLocations.forEach((location, index) => {
      lat = _.isUndefined(location.marker) ? location.lat : location.marker.getPosition().lat()
      lng = _.isUndefined(location.marker) ? location.lng : location.marker.getPosition().lng()
      requestString += `locations[][temp_id]=${location.temp_id}&locations[][lat]=${lat}&locations[][lng]=${lng}`
      if (index !== locations.size - 1) {
        requestString += '&'
      }
    })

    if (timeType) {
      requestString += `&booking_type=${timeTypes[timeType]}`
    }

    if (serviceTypeID) {
      requestString += `&service_type_id=${serviceTypeID}`
    }

    if (vehicleTypeID) {
      requestString += `&vehicle_type_id=${vehicleTypeID}`
    }

    if (countryCode) {
      requestString += `&country_code=${countryCode}`
    }

    if (companyId && timeType) {
      requestString += `&check_cod_pod[company_id]=${companyId}`
    }

    if (timeType) {
      requestString += `&check_cod_pod[time_type]=${timeType}`
    }
    try {
      const res = await apiClient.get(`/api/v3/batches/check_locations?${decodeURIComponent(requestString)}`,)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  creatBatchBookingAttachments: async (bookingAttachmentsAttributes, callback) => {
    const attachments = _.filter(bookingAttachmentsAttributes, attachment => attachment.file !== undefined)
    const atmForCreating = attachments.map((attachment) => {
      if (!attachment.isAutoAttachment) {
        const id = _.isUndefined(attachment.id) ? '' : attachment.id
        const attachmentTMPID = _.isUndefined(attachment.tmpID) ? attachment.tmp_id : attachment.tmpID
        return {
          attachment_id: id,
          attachment_tmp_id: attachmentTMPID,
          attachment: attachment.file
        }
      }
      return null
    })
    const params = {
      booking_attachments_attributes: atmForCreating
    }
    if (params.booking_attachments_attributes.length > 0) {
      try {
        const res = await apiClient.post(`/api/v3/booking_attachments/create_attachments`, objectToFormData(params),
        { 
          'include[]': 'locations', 
          header: {
            'Device-Type': 'web_batch',
            'App-Name': 'Deliveree Webapp'
          }
        })
        return callback(res.data)
      } catch (err) {
        throw new Error(err)
      }
    }
    return null
  },

  deleteBatchBookingAttachments: async (deleteATMs, callback) => {
    const params = {
      booking_attachments: deleteATMs
    }
    try {
      const res = await apiClient.post('/api/v3/booking_attachments/delete_attachments', params)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  updateTimeForOutOfDateBooking: async (bookingId, pickupTime, currentCustomer, callback) => {
    const params = {
      booking_id: _.toInteger(bookingId),
      pickup_time: pickupTime
    }
    try {
      const res = await apiClient.post('/api/v3/batches/update_booking_pickup_time', params)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  retryBatchBookings: async (bookingIds, currentCustomer, callback) => {
    const params = {
      booking_ids: bookingIds
    }
    try {
      const res = await apiClient.post('/api/v3/batches/retry_selected_bookings', params)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  loadDraftBatches: async (areaID, currentCustomer, filterParams, callback) => {
    const query = {
      company_id: currentCustomer.current_company_id,
      page: filterParams.current_page,
      filter_key: filterParams.filter_key || '',
      is_multiple: filterParams.is_multiple,
      scope: filterParams.scope || DRAFT,
      batch_type: filterParams.batch_type || '',
      per_page: BATCHES_PER_PAGE,
      area_id: areaID,
      'include': ['reimbursements'],
    }
    try {
      const res = await apiClient.get('/api/v3/batches/draft_batches', { params: query })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  loadSavedBatches: async (areaID, currentCustomer, filterParams, callback) => {
    const params = {
      company_id: currentCustomer.current_company_id,
      page: filterParams.current_page,
      filter_key: filterParams.filter_key || '',
      is_multiple: filterParams.is_multiple,
      per_page: BATCHES_PER_PAGE,
      area_id: areaID,
      batch_type: filterParams.batch_type || '',
    }
    try {
      const res = await apiClient.get('/api/v3/batches/saved_batches', { params })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  getTallyData: async ({
    bookingParams,
    specificStep,
    isLocationChanged = true,
    isValidLH = false,
    only = [],
  }, callback) => {
    const currentCustomer = bookingParams.currentCustomer
    let requestPayload = generateRequestPayloadForCalculatingBooking(bookingParams, currentCustomer)

    const { timeType } = bookingParams
    let url = `${TALLY_API_URL}/bookings/calculate`

    // BE want to keep using old api for long haul time type
    if (isValidLH) {
      requestPayload.flow = 'tally'
      requestPayload.time_type = LONG_HAUL
      // BE want to keep using old api for long haul time type
      url = '/api/v3/bookings/calculate'

      // we use the sample endpoint for LONG_HAU, but they require not to sending quote_id
      delete requestPayload.quote_id
    } else if (timeType === LONG_HAUL) {
      requestPayload.time_type = SCHEDULE
    }

    if (+specificStep === 1) {
      requestPayload = _.pick(requestPayload, STEP_1_PAYLOAD_FIELDS)
      requestPayload.locations_are_changed = isLocationChanged
      requestPayload.booking_extra_requirements_attributes = []
    }

    if (!requestPayload.time_type) {
      delete requestPayload.time_type
    }

    if (timeType === LONG_HAUL) {
      // because we want to calculate price of step 1 without extra_requirement_locations
      const locationsAttributes = requestPayload.locations_attributes
      if (locationsAttributes) {
        requestPayload.locations_attributes = locationsAttributes.map((location) => {
          const extraRequirementLocationsAttrs = location.extra_requirement_locations_attributes
          if (extraRequirementLocationsAttrs && extraRequirementLocationsAttrs.length) {
            return {
              ...location,
              extra_requirement_locations_attributes: [],
            }
          }

          return location
        })
      }
    }

    let finalRequestPayload = { ...requestPayload }
    if (!_.isEmpty(only)) {
      finalRequestPayload = {
        ...requestPayload,
        only,
      }
    }

    try {
      const res = await apiClient.post(url, finalRequestPayload)
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  getTallyTransitTime: async ({ bookingParams, isValidLH }, callback) => {
    const currentCustomer = bookingParams.currentCustomer
    let requestPayload = generateRequestPayloadForCalculatingBooking(bookingParams, currentCustomer)
    const requiredParams = [
      'company_id', 'vehicle_type_id',
      'time_type', 'pickup_time',
      'full_day_selected_amount', 'booking_extra_requirements_attributes',
      'locations_attributes', 'round_trip_discount'
    ]

    const { timeType } = bookingParams
    let url = `${TALLY_API_URL}/bookings/calculate_fullday_transit_time`

    if (timeType === LONG_HAUL || isValidLH) {
      requestPayload.flow = 'tally'
      // BE want to keep using old api for long haul time type
      url = '/api/v3/bookings/calculate'

      // we use the sample endpoint for LONG_HAU, but they require not to sending quote_id
      delete requestPayload.quote_id
    }

    requestPayload = _.pick(requestPayload, requiredParams)
    if (!requestPayload.time_type) {
      delete requestPayload.time_type
    }
    try {
      const res = await apiClient.post(url, requestPayload)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getTransitTimeForTally: async ({ bookingParams }, callback) => {
    const currentCustomer = bookingParams.currentCustomer
    let requestPayload = generateRequestPayloadForCalculatingTransitTime(bookingParams, currentCustomer)
    const requiredParams = [
      'company_id', 'vehicle_type_id',
      'time_type', 'pickup_time',
      'full_day_selected_amount', 'locations_attributes',
      'eta_locations_id', 'locations_are_changed',
      'booking_extra_requirements_attributes', 'flow'
    ]

    const { timeType } = bookingParams
    let url = `${TALLY_API_URL}/bookings/calculate`

    if (timeType === LONG_HAUL) {
      // BE want to keep using old api for long haul time type
      requestPayload.flow = 'tally'
      url = '/api/v3/bookings/calculate'
    }

    let requestQuery = { only: 'transit_time,eta_locations_id,worst_transit_time' }
    if (timeType === FULL_DAY) {
      url = `${TALLY_API_URL}/bookings/calculate_fullday_transit_time`
      const originalCallback = callback
      // to transform the response because response of calculate_fullday_transit_time return is different
      //    from response of calculate
      /* eslint-disable-next-line */
      callback = (data) => {
        originalCallback({ object: data })
      }
      requestQuery = ''
    }

    requestPayload = _.pick(requestPayload, requiredParams)
    try {
      const res = await apiClient.post(url, requestPayload, requestQuery)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getReimbursementInfos: async (
    authenticationToken, batchId, callback
  ) => {
    try {
      const res = await apiClient.get(`/api/v3/batches/${batchId}/reimbursement_info`)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  loadDetailBatches: async (currentArea, currentCustomer, params, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/batches/detail_batches`, { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getExtraInfos: (authenToken, query) => {
    return apiClient.get(`/api/v3/batches/batch_extra_infos`, { params: query })
  },
  getBatchInfos: ({
    authenToken,
    batchId,
    query
  }) => {
    return apiClient.get(`/api/v3/batches/${batchId}`, { params: query })
  },
  getBatchTemplate: ({
    authenToken,
    templateId,
    query
  }) => {
    return apiClient.get(`/api/v3/batch_templates/${templateId}`, { params: query })
  },
  getMultipleExtraInfos: async (areaId, authenticationToken) => {
    try {
      const res = await apiClient.get('/api/v3/bookings/multiple_extra_infos', { params: { area_id: areaId } })
      return res?.data
    } catch (err) {
      throw new Error(err)
    }
  },
  favoriteBatchBooking: async (id, params, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${id}/favorite`, params)
      callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  confirmReimbursements: async (batchId, params, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/batches/${batchId}/confirm_reimbursements`, params)
      callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  calculateParallelApi: (booking, currentCustomer) => {
    const params = generateRequestPayloadForCalculatingBooking(booking, currentCustomer)
    const maxRetries = 2
    let retries = 0
    const makeRequest = async () =>  {
      const res = await apiClient.post('/api/v3/batches/calculate_parallel', {
        ...params,
        include: ['custom_reimbursements', 'reimbursements']
      })
      if(res.status === 506 && retries < maxRetries) {
        retries += 1
        return makeRequest()
      } else {
        return res
      }
    }
    return makeRequest()
  },
  getFootNoteBatchApi: (params) => apiClient.get('/api/v3/batches/footnote_text', { params }),
}

export default BatchesAPI
