import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = false
const showQuickChoiceSlice = createSlice({
  name: 'showQuickChoice',
  initialState,
  reducers: {
    changeShowQuickChoices: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const showQuickChoiceActionsCreator = showQuickChoiceSlice.actions

export default showQuickChoiceSlice
