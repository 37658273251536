import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = []

const batchTemplatesSlice = createSlice({
  name: 'batchTemplates',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<any>) => {
      const batchTemplate = _.find(state, { id: action.payload.id })
      if (!_.isUndefined(batchTemplate)) {
        _.assign(batchTemplate, action.payload.attrs)
      }
    },
    destroy: (state, action: PayloadAction<any>) => {
      _.remove(state, { id: action.payload.id })
    },
    getBatchTemplatesSuccess: (state, action: PayloadAction<any>) => {
      return action.payload.payload
    },
  },
  extraReducers: () => {},
})

export const batchTemplatesActionsCreator = batchTemplatesSlice.actions

export default batchTemplatesSlice
