import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Button extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const {
      buttonContent, className, containerClassName, isNotice, onClick
    } = this.props
    return (
      <span className={containerClassName}>
        {isNotice && <span className="tracking-notice" />}
        <button onClick={onClick} className={className} type="button">
          {buttonContent}
        </button>
      </span>
    )
  }
}

Button.propTypes = {
  buttonContent: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isNotice: PropTypes.bool
}

Button.defaultProps = {
  className: '',
  containerClassName: '',
  isNotice: false
}
