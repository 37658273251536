import React from 'react'
import PropTypes from 'prop-types'
// UTILS
import I18n from 'i18n/i18n'

// API
// ACTIONS
// COMPONENTS
// CONSTANTS
import { SEARCH_ICON_EZSHEET } from 'constants/imageConstants'
// ASSETS

class SearchBar extends React.PureComponent {
  render() {
    const {
      searchPlaceholder, openSearch, toggleSearch, searchAction, inputSearchRef, isContactView
    } = this.props
    const isExpandSearch = isContactView || openSearch
    return (
      <div
        ref={(inputRef) => { this.searchCommon = inputRef }}
        className={`Search-Common no-Click ${isExpandSearch ? 'Show' : ''}`}
      >
        <span onClick={() => toggleSearch()}>
          <img src={SEARCH_ICON_EZSHEET} />
        </span>
        <input
          id="inputSearch"
          placeholder={searchPlaceholder || I18n.t('webapp.contact.search_contact')}
          onChange={() => searchAction()}
          ref={inputSearchRef}
        />
        <span
          className={`flex flex-center Search-Common-Close ${isExpandSearch ? '' : 'Hidden-Field'}`}
          onClick={() => toggleSearch()}
        >
          {!isContactView && (
            <i className="material-icons larger-xxs-font-important Icon cur-pointer default-color-icon">
              close
            </i>
          )}
        </span>
      </div>
    )
  }
}

SearchBar.propTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  openSearch: PropTypes.bool.isRequired,
  searchAction: PropTypes.func.isRequired,
  inputSearchRef: PropTypes.func.isRequired,
  isContactView: PropTypes.bool.isRequired,
}

export default SearchBar
