import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VIEW_AS } from 'constants/bookingCardsConstants'

const initialState = VIEW_AS.card

const currentViewAsSlice = createSlice({
  name: 'currentViewAs',
  initialState,
  reducers: {
    updateCurrentViewAs: (state: any, action: PayloadAction<any>) => {
      const url = new URL(window.location as any)
      url.searchParams.set('search[view_as]', action.payload)
      window.history.pushState({}, '', decodeURIComponent(url as any))
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentViewAsActionsCreator = currentViewAsSlice.actions

export default currentViewAsSlice
