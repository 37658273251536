import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const tmpSelectedDateAssignDriverSlice = createSlice({
  name: 'tmpSelectedDateAssignDriver',
  initialState,
  reducers: {
    changeTmpSelectedDateAssignDriver: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const tmpSelectedDateAssignDriverActionsCreator = tmpSelectedDateAssignDriverSlice.actions

export default tmpSelectedDateAssignDriverSlice
