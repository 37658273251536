import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// UTILS
// API
// ACTIONS
// COMPONENTS
// CONSTANTS
// ASSETS

class AlphabetBar extends React.PureComponent {
  render() {
    const {
      filteredContacts, selectedChar, openSearch, alphabetList, firstChar, scrollToChar
    } = this.props
    const charList = alphabetList.concat(['#'])

    return (
      <div className={`Select-Common mr10 ${openSearch ? 'Hidden-Field' : ''}`}>
        <select className="small-font-important" onChange={e => scrollToChar(e.target)} value={selectedChar}>
          {charList.map((char) => {
            const charIndex = _.findIndex(filteredContacts, (contact) => {
              if (char === '#') {
                return !alphabetList.includes(firstChar(contact))
              }
              return firstChar(contact) === char
            })
            return (
              <option key={char} data-index={charIndex} disabled={charIndex === -1}>
                {char}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

AlphabetBar.propTypes = {
  filteredContacts: PropTypes.instanceOf(Array).isRequired,
  selectedChar: PropTypes.string.isRequired,
  openSearch: PropTypes.bool.isRequired,
  alphabetList: PropTypes.instanceOf(Array).isRequired,
  firstChar: PropTypes.func.isRequired,
  scrollToChar: PropTypes.func.isRequired,
}

export default AlphabetBar
