import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
import {
  currentServiceType as currentServiceTypeActions,
  enabledEditExtraRequirement,
} from 'utils/new_booking/common'
import { havePopup } from 'utils/common/popupable'
import { LocationPopover } from 'utils/LocationUtil'
import { Utils } from 'utils/Utils'
import { UtilsHTML } from 'utils/html'
import { FREE_LABEL_KEY } from 'constants/newBookingConstants'
import I18n from 'i18n/i18n'

// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as editModeTextActionCreators from 'store/actions/new_booking/editModeTextActionCreators'

// COMPONENTS
import ExtraRequirementPopover from './ExtraRequirementPopover'

// CONTANSTS
import {
  POPUP_ID_EXTRA_SERVICE_POPUP_EYE,
  POPUP_TYPE_EXTRA_POPUP_EYE,
  POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE,
  POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY,
  POPUP_TYPE_EXTRA_POPUP_EYE_LONG_HAUL,
} from 'constants/common/popupConstants'
import { FULL_DAY, LONG_HAUL } from 'constants/bookingConstants'
import {
  EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
} from 'constants/extraServiceConstants'
import { isEditBooking } from 'utils/booking/common'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import TitleSub from './TitleSub'
// ASSETS

const mapStateToProps = state => ({
  badges: state.extraServices.vehicleTypeBadges,
  companySettings: state.extraServices.companySettings,
  vehicleTypeSettings: state.extraServices.vehicleTypeSettings,
  assignedDriver: state.assignedDriver,
  customReimbursements: state.extraServices.customReimbursements,
  currentServiceType: currentServiceTypeActions(state),
  extraRequirements: state.extraServices.extraRequirements,
  fullDayPricing: state.extraServices.fullDayPricing,
  locations: state.locations,
  timeType: state.timeType,
  extraInfos: state.extraInfos,
  currentStep: state.currentStep,
  bookAgainDetails: state.bookAgainDetails,
  tmpLocations: state.tmpLocations,
  outOfServiceStatus: state.outOfServiceStatus,
  autoExpandReimbursement: state.extraServices.autoExpandReimbursement,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
})

function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    editModeTextActions: bindActionCreators(editModeTextActionCreators, dispatch),
  }
}

class MoreGroupExtraServices extends React.Component {
  static showPopoverHandlerInsurance(id, extraRequirement) {
    const isEnabled = enabledEditExtraRequirement(extraRequirement)
    if (isEnabled) {
      LocationPopover.closeClass($('.Info.Popover'), 'visible')
      const config = {
        type: 'new-booking',
        popover: 30,
        top: 60,
        arrow: 74
      }
      const elementConfig = {
        element: $(`#${id}`),
        arrow_element: $(`#${id} .Popover-Arrow`),
        element_left_position: '100%',
        element_index_position: 10001,
        arrow_element_position: 15
      }
      LocationPopover.init(
        document.getElementById(`${id}_name`),
        $(`#booking_insurance_${extraRequirement.id}`).height(),
        0,
        config,
        elementConfig
      )
    }
  }

  static checkHeightPopup(element) {
    if (element.height() + 120 > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.isLoadExtraService = false
  }

  static onClickExtraServiceEyeHandler(extraRequirementID) {
    $(`#${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID}`).addClass('visible')
    MoreGroupExtraServices.checkHeightPopup($(`#${POPUP_ID_EXTRA_SERVICE_POPUP_EYE}-${extraRequirementID} .Normal-Booking`))
  }

  /* ********************************* */
  /*                                   */
  /*          Extra Services           */
  /*                                   */
  /* ********************************* */

  componentDidMount() {
    const { autoExpandReimbursement } = this.props
    this.setState({ expanded: autoExpandReimbursement })
  }


  componentDidUpdate(prevProps) {
    const {
      autoExpandReimbursement: prevAutoExpandReimbursement,
      timeType: prevTimeType,
      selectedVehicleTypeID: prevSelectedVehicleTypeID,
    } = prevProps
    const {
      extraRequirements, autoExpandReimbursement, timeType, selectedVehicleTypeID,
    } = this.props
    const query = new URLSearchParams(window.location.search)
    const extraServicesParams = decodeURIComponent(query).split('&').filter(x => x.includes('extraServices'))
    if (extraServicesParams.length > 0 && extraRequirements.length > 0 && !this.isLoadExtraService) {
      extraServicesParams.forEach((param) => {
        const extraService = param.split('=')[1].split('-')
        const extraServiceId = Number(extraService[0])
        const findExtraService = extraRequirements.find(x => x.id === extraServiceId)
        if (findExtraService) {
          if (findExtraService.amount === 1) {
            this.onChangeCheckBox(extraServiceId, true)
          } else {
            this.onChangeSelectBox(extraServiceId, Number(extraService[1]))
          }
        }
      })
      this.isLoadExtraService = true
    }
    const isSetExpanded = prevAutoExpandReimbursement !== autoExpandReimbursement || prevTimeType !== timeType
      || prevSelectedVehicleTypeID !== selectedVehicleTypeID
    if (isSetExpanded) {
      this.handleSetExpanded(autoExpandReimbursement)
    }
  }

  handleSetExpanded = (expanded) => {
    this.setState({ expanded })
  }

  onChangeCheckBox(id, selected) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.onChangeCheckBoxAfterCheckBookingDetails.bind(this, id, selected) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeCheckBoxAfterCheckBookingDetails(id, selected)
    }
  }

  onChangeCheckBoxAfterCheckBookingDetails(id, selected) {
    const selectedAmount = selected ? 1 : 0
    const { extraServiceActions, extraInfos, extraRequirements } = this.props
    extraServiceActions.updateExtraRequirement(id, { selected, selected_amount: selectedAmount })
    const extraRequirement = extraRequirements.find(item => item.id === id)
    if (extraRequirement && selectedAmount !== 0) {
      window.Moengage.track_event('Extra Services Selected', {
        'Extra Service ID': extraRequirement.id,
        'Extra Service Name': extraRequirement.name,
        Amount: extraRequirement.unit_price * selectedAmount,
        Quantity: selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  onChangeSelectBox(id, event) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    const dataTarget = { value: Number.isInteger(event) ? event : event.target.value }
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.onChangeSelectBoxAfterCheckBookingDetails.bind(this, id, dataTarget) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangeSelectBoxAfterCheckBookingDetails(id, dataTarget)
    }
  }

  onChangeSelectBoxAfterCheckBookingDetails(id, dataTarget) {
    this.funcName = 'onChangeSelectBox'
    const selectedAmount = dataTarget.value
    const { extraServiceActions, extraRequirements, extraInfos } = this.props
    extraServiceActions.updateExtraRequirement(
      id,
      { selected: (+selectedAmount > 0), selected_amount: +selectedAmount }
    )
    const extraRequirement = extraRequirements.find(item => item.id === id)
    if (extraRequirement && +selectedAmount !== 0) {
      window.Moengage.track_event('Extra Services Selected', {
        'Extra Service ID': extraRequirement.id,
        'Extra Service Name': extraRequirement.name,
        Amount: extraRequirement.unit_price * +selectedAmount,
        Quantity: +selectedAmount,
        Country: extraInfos.country_code.toLowerCase()
      })
    }
  }

  onClickCebDisableCheckbox(fieldName) {
    const { editModeTextActions } = this.props
    editModeTextActions.updateEditModeText(fieldName)
  }

  showPopoverSelectionPerLocations(event) {
    const {
      extraRequirements,
      locationActions,
      extraServiceActions,
    } = this.props
    let extraRequirement
    _.forEach(extraRequirements, (item) => {
      if (item.id === _.toInteger($(event.currentTarget).attr('data-id'))) {
        extraRequirement = item
      }
    })
    if (extraRequirement) {
      extraServiceActions.updateSelectedExtraRequirementForPerLocation(extraRequirement)
      locationActions.initTmpLocations()
      $('.Selection-Per-Location.Popover').addClass('visible')
      $('.Popover-Arrow.Custom').css({ top: $('.CodPod-JS').length ? $('.CodPod-JS').position().top + 32 : 0 })
    }
  }

  extraRequirementInfo(extraRequirement) {
    const {
      timeType,
      fullDayPricing,
      outOfServiceStatus,
      bookAgainDetails,
      extraInfos
    } = this.props
    let totalPrice = 0

    if (extraRequirement.selected_amount === undefined || extraRequirement.selected_amount === 0) {
      return Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, '-')
    }

    if (timeType === FULL_DAY) {
      if (extraRequirement.is_flat) {
        totalPrice = extraRequirement.unit_price
      } else {
        totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount * fullDayPricing.selected_amount
      }
    }

    if (timeType === LONG_HAUL) {
      if (extraRequirement.is_per_unit_fee) {
        totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount
      } else if (extraRequirement.is_flat) {
        totalPrice = extraRequirement.unit_price
      } else {
        totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount

        if (!_.isUndefined(outOfServiceStatus.long_haul_pickup)) {
          totalPrice *= outOfServiceStatus.long_haul_pickup.total_working_days
        } else if (isEditBooking() && bookAgainDetails.distance_fee_details) {
          totalPrice *= bookAgainDetails.distance_fee_details.total_working_days
        }
      }
    }

    if (!_.includes([FULL_DAY, LONG_HAUL], timeType)) {
      totalPrice = extraRequirement.unit_price * extraRequirement.selected_amount
    }
    if (extraRequirement.selected) {
      return Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, I18n.t(FREE_LABEL_KEY))
    }
    return '-'
  }

  extraRequirementPerLocationInfo(extraRequirement) {
    const {
      locations,
      extraInfos
    } = this.props
    let totalPrice = 0
    _.forEach(locations, (location) => {
      const extraRequirementLocation = _.find(location.extra_requirement_locations,
        { extra_requirement_id: extraRequirement.id })
      if (extraRequirementLocation) {
        totalPrice += extraRequirement.unit_price * extraRequirementLocation.selected_amount
      }
    })
    return {
      totalPrice: Utils.formatFee(totalPrice, extraInfos.country_language, extraInfos.currency, '-'),
      selected: totalPrice > 0 ? I18n.t('webapp.pricing_display.selected') : I18n.t('webapp.pricing_display.none')
    }
  }

  /* eslint-disable-next-line */
  renderByOptions(extraRequirement, popupEyeType) {
    const { timeType } = this.props
    const allowOption = extraRequirement.allow_none_option_pricing_list
    const nonePricing = Utils.checkNonePricing(timeType, allowOption) && !extraRequirement.selected
    return (
      <div className="block-item relative" key={`option-${extraRequirement.id}`}>
        <label>
          <div className="TitleSubtitle w-100">
            <TitleSub
              extraRequirement={extraRequirement}
              popupType={popupEyeType}
              extraIconClass="show-extra-popup"
            />
            <div className={`TitleSubtitle-subtitle ${nonePricing ? 'default-color-text' : ''}`}>
              {nonePricing ? 'None' : extraRequirement.selectedPricing.display_fees}
            </div>
          </div>
        </label>
        <div
          className="block-item-action flex-center cur-pointer"
          onClick={() => MoreGroupExtraServices.showPopoverHandlerInsurance(`booking_insurance_${extraRequirement.id}`, extraRequirement)}
        >
          <label className="green">
            {!nonePricing && extraRequirement.selectedPricing.display_level_price}
          </label>
          <i className="xb material-icons Icon">
            keyboard_arrow_right
          </i>
        </div>
        <input id={`booking_insurance_${extraRequirement.id}_name`} className="input-hidden" />
      </div>
    )
  }

  renderBoxFilter(extraRequirement, popupType, type) {
    const fieldName = `booking[booking_extra_requirements_with_deleted_attributes][${extraRequirement.id}]`
    const isEnabledEditExtraRequirement = enabledEditExtraRequirement(extraRequirement)
    const disableClass = isEnabledEditExtraRequirement ? '' : 'Disable'

    return (
      <div className="block-item" key={extraRequirement.id}>
        <label>
          <div className="TitleSubtitle w-100">
            <TitleSub
              extraRequirement={extraRequirement}
              popupType={popupType}
            />
            <div className="TitleSubtitle-subtitle">
              {this.extraRequirementInfo(extraRequirement)}
            </div>
          </div>
        </label>
        <div className="block-item-action">
          {
            !isEnabledEditExtraRequirement && type !== 'checkbox'
            && (
              <div
                className="Ceb-disable-field Disable-checkbox-overlay"
                onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
              />
            )
          }
          {type === 'checkbox'
            ? (
              <div className={`Overlay-Checkbox With-Icon Green-Checkbox ${disableClass}`}>
                {
                  !isEnabledEditExtraRequirement
                  && (
                    <div
                      className="Ceb-disable-field Disable-checkbox-overlay"
                      onClick={() => this.onClickCebDisableCheckbox(extraRequirement.name)}
                    />
                  )
                }
                <input
                  type="checkbox"
                  name={`${fieldName}[selected_amount]`}
                  id={`extra_requirement_${extraRequirement.id}`}
                  value="1"
                  checked={extraRequirement.selected}
                  onChange={e => this.onChangeCheckBox(extraRequirement.id, e.target.checked)}
                />
                <label>
                  <i className="b material-icons Icon">
                    check
                  </i>
                </label>
              </div>
            ) : (
              <select
                name={`${fieldName}[selected_amount]`}
                id={`extra_requirement_${extraRequirement.id}`}
                value={extraRequirement.selected_amount}
                disabled={disableClass}
                onChange={e => this.onChangeSelectBox(extraRequirement.id, e)}
              >
                <option>
                  0
                </option>
                {_.range(extraRequirement.amount).map((value) => {
                  const v = value + 1
                  return (
                    <option value={v} key={v}>
                      {v}
                    </option>
                  )
                })}
              </select>
            )
          }
        </div>
      </div>
    )
  }

  renderForPopupLocations(extraRequirement, popupType) {
    const extraRequirementPerLocationInfo = this.extraRequirementPerLocationInfo(extraRequirement)
    return (
      <div className="block-item" key={extraRequirement.id}>
        <label>
          <div className="TitleSubtitle w-100">
            <TitleSub
              extraRequirement={extraRequirement}
              popupType={popupType}
            />
            <div className="TitleSubtitle-subtitle">
              {extraRequirementPerLocationInfo.totalPrice}
            </div>
          </div>
        </label>
        <div
          className="block-item-action flex-center cur-pointer"
          data-id={extraRequirement.id}
          onClick={event => this.showPopoverSelectionPerLocations(event)}
        >
          <label className="green">
            {extraRequirementPerLocationInfo.selected}
          </label>
          <i className="xb material-icons Icon default-color-active-icon">
            keyboard_arrow_right
          </i>
        </div>
      </div>
    )
  }

  renderSelectedValue() {
    const {
      extraRequirements, badges, companySettings, timeType, vehicleTypeSettings, isBusiness, customReimbursements
    } = this.props
    if (_.isEmpty(extraRequirements)) {
      return <span />
    }

    const isLonghaul = LONG_HAUL === timeType
    const isFullday = FULL_DAY === timeType

    let selectedValue = 0

    _.forEach(customReimbursements, (customReimbursement) => {
      if (customReimbursement.check_by_default && !customReimbursement.hide_from_more_options) {
        selectedValue += 1
      }
    })

    _.forEach(extraRequirements, (extraRequirement) => {
      if (extraRequirement.selected && extraRequirement.section_dropdown) {
        selectedValue += 1
      }
    })

    _.forEach(badges, (badge) => {
      if (badge.selected) {
        selectedValue += 1
      }
    })

    if (isBusiness && companySettings.allow_tolls_fees && vehicleTypeSettings.enable_tolls && !isLonghaul) {
      selectedValue += 1
    }

    if (isBusiness && companySettings.allow_parking_fees && vehicleTypeSettings.enable_parking && !isLonghaul) {
      selectedValue += 1
    }

    if (isBusiness && companySettings.allow_waiting_time_fees
      && vehicleTypeSettings.enable_waiting_time && !isFullday) {
      selectedValue += 1
    }

    if (selectedValue === 0) {
      return <span />
    }

    return (
      <span>
        &nbsp;&bull;&nbsp;
        {I18n.t('webapp.new_booking.step_2.extra_services_selected', { amount: selectedValue })}
      </span>
    )
  }

  renderTimeType(key, value) {
    const {
      assignedDriver,
      extraRequirements,
      currentServiceType,
      timeType,
      currentStep,
    } = this.props

    if (_.isEmpty(extraRequirements)) {
      return <span />
    }

    let popupType
    if (currentServiceType.is_package) {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE
    } else if (timeType === FULL_DAY) {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY
    } else if (timeType === LONG_HAUL) {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE_LONG_HAUL
    } else {
      popupType = POPUP_TYPE_EXTRA_POPUP_EYE
    }

    return [
      extraRequirements.map((extraRequirement) => {
        const isFilterByKey = !!extraRequirement[key] === value
        if (
          currentStep === 2
          && extraRequirement.is_flat_per_location === true
          && timeType === LONG_HAUL
          && isFilterByKey
        ) {
          return this.renderForPopupLocations(extraRequirement, popupType)
        }
        if (extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS && isFilterByKey) {
          const allowOption = extraRequirement.allow_none_option_pricing_list
          return currentStep === 2 && [
            <ExtraRequirementPopover
              key={extraRequirement.id}
              extraRequirement={extraRequirement}
              handleClose={() => LocationPopover.closeClass($(`#booking_insurance_${extraRequirement.id}`), 'visible')}
              allowNonePricing={Utils.checkNonePricing(timeType, allowOption)}
              isSelectPricing={extraRequirement.selected}
              assignedDriver={assignedDriver}
            />,
            this.renderByOptions(extraRequirement, popupType)
          ]
        }
        return isFilterByKey && this.renderBoxFilter(extraRequirement, popupType, extraRequirement.amount === 1 ? 'checkbox' : 'selectbox')
      })
    ]
  }

  verifyKey(key, value) {
    const { extraRequirements } = this.props

    let verify = true
    if (_.isEmpty(extraRequirements)) {
      verify = false
    }
    verify = false
    _.forEach(extraRequirements, (extraRequirement) => {
      const isFilterByKey = !!extraRequirement[key] === value
      if (isFilterByKey) {
        verify = true
      }
    })

    return verify
  }

  render() {
    const { expanded } = this.state
    const {
      content, children, extraRender, extraRequirements,
    } = this.props

    return (
      <div>
        <div className="ExtraServices block">
          {!_.isEmpty(extraRequirements) && UtilsHTML.renderSectionTitle(I18n.t('webapp.label.services'))}
          {this.renderTimeType('section_dropdown', false)}
        </div>
        {/* {Option More} */}
        <div className="mt20 mr10 ml10 White-bg Radius-default MoreOptions">
          <div className={`o-hidden ${expanded ? 'height-auto' : 'height-0'}`}>
            <div
              key="action-more-options"
              className="Title-bg Radius-Top-Default flex flex-start pt10 pb10 pl15 pr15"
            >
              <b className="default-small-font default-color flex-index">
                {I18n.t('webapp.label.more_options')}
              </b>
            </div>
            {extraRender()}
            {this.verifyKey('section_dropdown', true) ? (
              <div className="ExtraServices block" key="extra-more-options">
                {this.renderTimeType('section_dropdown', true)}
              </div>
            ) : null}
            {children}
          </div>
          {!expanded && [
            content(),
            <div className="Title-bg Radius-Bottom-Default flex flex-center pt10 pb10 pl15 pr15" key="more-option">
              <span
                className="small-font Dark-Green-text cur-pointer"
                onClick={() => this.setState({ expanded: true })}
              >
                {I18n.t('webapp.label.more_options')}
                {this.renderSelectedValue()}
              </span>
            </div>
          ]}
        </div>
      </div>
    )
  }
}

MoreGroupExtraServices.propTypes = {
  assignedDriver: PropTypes.arrayOf(PropTypes.shape({})),
  badges: PropTypes.arrayOf(PropTypes.shape({})),
  bookAgainDetails: PropTypes.shape({}),
  customReimbursements: PropTypes.arrayOf(PropTypes.shape({})),
  // children: PropTypes.oneOfType([
  //   PropTypes.shape({}),
  //   PropTypes.arrayOf(PropTypes.shape({})),
  //   PropTypes.bool,
  // ]),
  companySettings: PropTypes.shape({}).isRequired,
  content: PropTypes.func,
  currentPopupIDActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  currentServiceType: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  editModeTextActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  expanded: PropTypes.bool,
  extraInfos: PropTypes.shape({}),
  extraRender: PropTypes.func,
  extraRequirements: PropTypes.arrayOf(PropTypes.shape({})),
  extraServiceActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  fullDayPricing: PropTypes.shape({}),
  infoAssignDriverPopupActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  isBusiness: PropTypes.bool,
  locationActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  outOfServiceStatus: PropTypes.shape({}),
  timeType: PropTypes.string,
  currentStep: PropTypes.number,
  vehicleTypeSettings: PropTypes.shape({}),
  autoExpandReimbursement: PropTypes.bool,
  selectedVehicleTypeID: PropTypes.number,
}

MoreGroupExtraServices.defaultProps = {
  assignedDriver: undefined,
  bookAgainDetails: undefined,
  customReimbursements: [],
  currentServiceType: undefined,
  currentStep: 2,
  children: false,
  content: undefined,
  expanded: false,
  extraInfos: undefined,
  extraRender: undefined,
  extraRequirements: undefined,
  fullDayPricing: undefined,
  isBusiness: false,
  locations: undefined,
  outOfServiceStatus: undefined,
  timeType: undefined,
  vehicleTypeSettings: {},
  badges: [],
  autoExpandReimbursement: false,
  selectedVehicleTypeID: 0,
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreGroupExtraServices)
