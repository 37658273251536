import React from 'react';
import { V2_ICON_WHITE_TRANSPORTIFY, V2_ICON_WHITE } from 'constants/imageConstants';
import commonUtils from 'utils/common'
import { isCustomerEditBooking, isCustomerEditPickupTime } from 'utils/new_booking/common'
import {
  isBookAgain,
  isMarketingPage,
  isNewBooking
} from 'utils/booking/common'

interface LoadingModalProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const LoadingModal = (props: LoadingModalProps) => {
  const isShowLoadingBar = (isNewBooking || isCustomerEditBooking()
    || isCustomerEditPickupTime() || isMarketingPage || isBookAgain())

  return (
    <>
      <div className="visible vertical-scroll Modal escape_popup_no_action" id="loading-modal">
        <div className="locating-drivers">
          <div className="Loader">
            <div className="Loader-Icon">
              <div className="Logo">
                <img src={commonUtils.isTransportify() ? V2_ICON_WHITE_TRANSPORTIFY : V2_ICON_WHITE} alt="loading modal" />
              </div>
            </div>
          </div>
        </div>
        {isShowLoadingBar && (
          <div className="progress-loading-container" id="progress-loading-container-id">
            <div id="progress-loading">
              <div id="bar-percent" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LoadingModal;
