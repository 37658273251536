import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
// UTILS
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// ACTIONS
import * as preferenceDriverActionCreators from 'store/actions/common/preferenceDriverActionCreators'
// COMPONENTS
import Rating from '../Rating'
import DriverStatus from './DriverStatus'
import firebaseInit from 'utils/firebaseInit'
// CONSTANTS
import {
  DRIVER_PREFERENCES,
  ADD_FAVORITE_DRIVERS,
} from 'constants/newBookingConstants'
import {
  POPUP_ID_YOUR_RECENT_DRIVERS
} from 'constants/common/popupConstants'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { showLoading, hideLoading } from 'assets/javascripts/webapp-v2/common'

// API
// ASSETS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  currentPopupID: state.currentPopupID,
  extraInfos: state.extraInfos
})

const mapDispatchToProps = dispatch => ({
  preferenceDriverActions: bindActionCreators(preferenceDriverActionCreators, dispatch),
  currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
})

class RecentDriversPopup extends React.Component {
  static formattedDriverName(driver) {
    if (_.isNull(driver.fleet_partner_id)) {
      return Utils.driverFirstName(driver.name)
    }
    return `${Utils.driverFirstName(driver.name)}, ${I18n.t('webapp.fleet_partner.fleet_infomation')}`
  }

  constructor(props) {
    super(props)
    this.state = {
      processingDrivers: {},
      drivers: [],
      onlineDrivers: []
    }
    this.updateDrivers = this.updateDrivers.bind(this)
    this.addToList = this.addToList.bind(this)
    this.closePopup = this.closePopup.bind(this)
  }

  componentWillMount() {
    const {
      currentCustomer,
      preferenceDriverActions
    } = this.props
    showLoading()
    const params = { page: 1, authentication_token: currentCustomer.authentication_token }
    if (currentCustomer.current_company_id) {
      params.company_id = currentCustomer.current_company_id
    }
    firebaseInit.load()
    preferenceDriverActions.recentDrivers(params, this.updateDrivers)
  }

  componentWillUnmount() {
    const { firebaseClients } = this.state
    if (!_.isUndefined(firebaseClients)) {
      _.each(firebaseClients, firebaseClient => firebaseClient.off())
    }
  }

  isOnline(driverID) {
    const { onlineDrivers } = this.state
    return _.includes(onlineDrivers, driverID)
  }

  handleConfirm() {
    const { drivers, processingDrivers } = this.state
    const { currentCustomer, preferenceDriverActions } = this.props
    const attribute = []
    _.each(Object.keys(processingDrivers), (id) => {
      const driver = _.find(drivers, { id: _.toInteger(id) })
      if (processingDrivers[`${id}`]) {
        attribute.push({
          driver_id: driver.id,
          fleet_partner_id: driver.fleet_partner_id,
          item_type: 'banned',
        })
      } else {
        attribute.push({
          driver_id: driver.id,
          fleet_partner_id: driver.fleet_partner_id,
          item_type: 'favorite',
        })
      }
    })
    showLoading()
    preferenceDriverActions.updatePreferenceListDrivers(
      {
        authentication_token: currentCustomer.authentication_token,
        customer_drivers_attributes: attribute,
        item_type: 'favorite',
        destroy: false,
        company_id: currentCustomer.current_company_id,
        is_recent_drivers_for_booking: true
      },
      () => this.closePopup(ADD_FAVORITE_DRIVERS)
    )
  }

  closePopup(type = undefined) {
    const {
      bookingID,
      currentPopupIDActions,
      callbackRetryBooking,
      callbackLoadAPIAvailableDrivers
    } = this.props
    if (type === DRIVER_PREFERENCES.all) {
      window.setSendBookingToAllDrivers = bookingID || true
      if (!_.isUndefined(callbackRetryBooking)) {
        callbackRetryBooking()
      }
    } else if (type === ADD_FAVORITE_DRIVERS) {
      window.addToFavoriteDrivers = bookingID || true
      if (!_.isUndefined(callbackLoadAPIAvailableDrivers)) {
        callbackLoadAPIAvailableDrivers()
      }
    }
    this.setState({ processingDrivers: {} })
    Utils.hideOverlayStep2(false)
    currentPopupIDActions.updateCurrentPopupID('')
  }

  addToList(driverID, isBanned, fleetPartnerID, fleetPartnerName) {
    const { processingDrivers: processingDriversState } = this.state
    let processingDrivers = { ...processingDriversState }
    if (processingDrivers[`${driverID}`] === isBanned) {
      processingDrivers = _.omit(processingDrivers, driverID)
    } else {
      if (fleetPartnerID && !isBanned) {
        toastr.remove()
        toastr.options = {
          positionClass: 'toast-top-center toast-remove-icon',
          timeOut: '5000',
          preventDuplicates: true,
        }
        Utils.showToastrMessage(
          'warning',
          I18n.t(
            'label.preference_driver.favorite_fleet_partner_notice',
            { fleet_name: fleetPartnerName }
          )
        )
      }
      processingDrivers[`${driverID}`] = isBanned
    }
    this.setState({
      processingDrivers: _.assign({}, processingDrivers)
    })
  }

  updateDrivers(drivers) {
    // Because we are using same code with sort in banned drivers
    // And this popup is no need to check banned or favorite drivers.
    Utils.orderDriversByName(drivers, DRIVER_PREFERENCES.banned, false)
    this.listenFirebase(drivers)
  }

  listenFirebase(drivers) {
    const { onlineDrivers } = this.state
    const inputs = { drivers, onlineDrivers }
    const callbacks = {
      updateOnlineDriverIDs: listID => this.setState({ onlineDrivers: listID }),
      updateFireBase: firebaseClients => this.setState({ firebaseClients, drivers })
    }
    Utils.checkAssignablOnlineDrivers(inputs, callbacks)
  }

  renderDriver(driver) {
    const { processingDrivers } = this.state
    const { extraInfos } = this.props
    const inListFavorite = processingDrivers[`${driver.id}`]
    const countryLanguage = extraInfos.country_language
    let vehicleBrandNameAndColor

    if (driver.current_vehicle) {
      const vehicleBrandName = driver.current_vehicle.vehicle_attributes.vehicle_brand_name
      const vehicleColor = driver.current_vehicle.vehicle_attributes.vehicle_color
      if (vehicleBrandName && vehicleColor) {
        vehicleBrandNameAndColor = `${vehicleBrandName} (${I18n.t(`webapp.tracking.${vehicleColor}`)})`
      }
    }

    return (
      <div key={driver.id} className="DriverBlock flex-index pt10 pr10 pb10 pl10" id={`available-driver-${driver.id}`}>
        <div className="xxb rounded Avatar">
          <img src={driver.driver_image_url} />
        </div>
        <div className="DriverInfo flex-index padding-horizontal-10">
          <h3 className="default-font default-color">
            {RecentDriversPopup.formattedDriverName(driver)}
          </h3>
          {driver.fleet_partner_id
            ? [
              <Rating total={5} rating={driver.rating} countryLanguage={countryLanguage} key="rating" newFormat />,
              <div className="default-font default-color" key="fleet-name">
                {driver.fleet_partner_name}
              </div>
            ]
            : [
              <div className="default-font default-color" key="vehicle-type-name">
                {driver.preferences_info.vehicle_type_names.join(', ')}
              </div>,
              vehicleBrandNameAndColor && (
                <div className="default-font default-color" key="brand-color">
                  {vehicleBrandNameAndColor}
                </div>
              )
            ]
          }
        </div>
        <DriverStatus
          driver={driver}
          tab={DRIVER_PREFERENCES.favorite}
          showSearchDrivers={false}
          isOnline={this.isOnline(driver.id)}
          addToList={this.addToList}
          showFavoriteText
          inListFavorite={inListFavorite}
          processingDrivers={processingDrivers}
        />
      </div>
    )
  }

  renderDrivers() {
    const { drivers } = this.state
    if (_.isEmpty(drivers)) {
      return (
        <div className="Modal-SplitView Modal-Recent-Drivers">
          <div className="Popup">
            <div className="Nomal center Recent-Drivers-Empty">
              {I18n.t('preference_drivers.poup_available_drivers.no_available_driver')}
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="Modal-SplitView Modal-Recent-Drivers">
        <div className="Popup">
          <div className="Nomal Recent-Drivers-List">
            {drivers.map(driver => this.renderDriver(driver))}
          </div>
        </div>
        <p className="larger-font reset p m center">
          <a href="/bookings?scope=delivery_completed" target="_blank" className="underline capitalize default-color-active">
            {I18n.t('webapp.action.add_more_favorite_drivers')}
          </a>
        </p>
      </div>
    )
  }

  renderConfirmActions() {
    const { drivers, processingDrivers } = this.state
    return (
      <div className={`Modal-Actions Box-Actions ${_.isEmpty(drivers) ? '' : 'w100'}`}>
        <div>
          <button
            type="button"
            className={`gray Button ${_.isEmpty(drivers) ? '' : 'flex-index w100 m-w100'}`}
            onClick={() => this.closePopup()}
          >
            {I18n.t('webapp.action.cancel')}
          </button>
          {
            !_.isEmpty(processingDrivers)
              ? (
                <button
                  type="button"
                  className="green Button flex-index w100 m-w100"
                  onClick={() => this.handleConfirm()}
                >
                  {I18n.t('webapp.action.confirm')}
                </button>
              ) : null
          }
        </div>
      </div>
    )
  }

  render() {
    const { drivers } = this.state
    const { bookingID, currentPopupID, isScale } = this.props
    const popupID = bookingID ? `${bookingID}-${POPUP_ID_YOUR_RECENT_DRIVERS}` : POPUP_ID_YOUR_RECENT_DRIVERS
    if (currentPopupID !== popupID || _.isUndefined(drivers)) {
      return (
        <span />
      )
    }
    return (
      <div
        className={`vertical-scroll Modal Modal-Basic visible ${isScale ? 'zoom-scale-8' : ''}`}
        id="popup-recents-drivers"
      >
        <div className="Box z-index-2">
          <div className="Modal-Head Box-Head">
            {I18n.t('label.preference_driver.recent_drivers')}
          </div>
          <div className="Modal-Content Box-Content">
            {this.renderDrivers()}
          </div>
          {
            !_.isEmpty(drivers)
              ? this.renderConfirmActions()
              : null
          }
        </div>
        <div className="overlay" onClick={this.closePopup} />
      </div>
    )
  }
}

RecentDriversPopup.propTypes = {
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  currentPopupID: PropTypes.string.isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  preferenceDriverActions: PropTypes.shape({}).isRequired,
  isScale: PropTypes.bool,
  bookingID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  callbackRetryBooking: PropTypes.func,
  callbackLoadAPIAvailableDrivers: PropTypes.func,
  extraInfos: PropTypes.shape({}).isRequired,
}

RecentDriversPopup.defaultProps = {
  isScale: false,
  bookingID: undefined,
  callbackRetryBooking: undefined,
  callbackLoadAPIAvailableDrivers: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentDriversPopup)
