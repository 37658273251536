import { merge } from 'lodash-es'
// devise
import devise_EN from 'i18n/locales/devise/en.json';
import devise_EN_ID from 'i18n/locales/devise/en-id.json';
import devise_ID from 'i18n/locales/devise/id.json';
import devise_TH from 'i18n/locales/devise/th.json';

// devise SMS
import devise_sms_EN from 'i18n/locales/devise_sms/en.json';
import devise_sms_ID from 'i18n/locales/devise_sms/id.json';
import devise_sms_TH from 'i18n/locales/devise_sms/th.json';
import devise_sms_TL from 'i18n/locales/devise_sms/tl.json';

// devise activeable SMS
import devise_sms_activable_EN from 'i18n/locales/devise_sms_activable/en.json';
import devise_sms_activable_ID from 'i18n/locales/devise_sms_activable/id.json';
import devise_sms_activable_TH from 'i18n/locales/devise_sms_activable/th.json';
import devise_sms_activable_TL from 'i18n/locales/devise_sms_activable/tl.json';

// enum
import enum_EN from 'i18n/locales/enum/en.json';
import enum_ID from 'i18n/locales/enum/id.json';
import enum_TH from 'i18n/locales/enum/th.json';
import enum_TL from 'i18n/locales/enum/tl.json';

// errors
import errors_EN from 'i18n/locales/errors/en.json';
import errors_ID from 'i18n/locales/errors/id.json';
import errors_TH from 'i18n/locales/errors/th.json';

// export
import export_EN from 'i18n/locales/export/en.json';
import export_ID from 'i18n/locales/export/id.json';
import export_TH from 'i18n/locales/export/th.json';
import export_TL from 'i18n/locales/export/tl.json';

// faker
import faker_ID from 'i18n/locales/faker/id.json';

// helpers
import helpers_EN from 'i18n/locales/helpers/en.json';
import helpers_EN_ID from 'i18n/locales/helpers/en-id.json';
import helpers_ID from 'i18n/locales/helpers/id.json';
import helpers_TH from 'i18n/locales/helpers/th.json';
import helpers_TL from 'i18n/locales/helpers/tl.json';

// kaminari
import kaminari_EN from 'i18n/locales/kaminari/en.json';
import kaminari_ID from 'i18n/locales/kaminari/id.json';
import kaminari_TH from 'i18n/locales/kaminari/th.json';

// mailer
import mailer_EN from 'i18n/locales/mailer/en.json';
import mailer_EN_ID from 'i18n/locales/mailer/en-id.json';
import mailer_ID from 'i18n/locales/mailer/id.json';
import mailer_TH from 'i18n/locales/mailer/th.json';
import mailer_TL from 'i18n/locales/mailer/tl.json';

// main
import main_EN from 'i18n/locales/main/en.json';
import main_EN_ID from 'i18n/locales/main/en-id.json';
import main_ID from 'i18n/locales/main/id.json';
import main_TH from 'i18n/locales/main/th.json';
import main_TL from 'i18n/locales/main/tl.json';

// services
import services_EN from 'i18n/locales/services/update_booking/unmet_requirements/en.json';
import services_ID from 'i18n/locales/services/update_booking/unmet_requirements/id.json';
import services_TH from 'i18n/locales/services/update_booking/unmet_requirements/th.json';

// VIEW
// ====== admin
import admin_booking_EN from 'i18n/locales/views/admin/bookings/en.json';
import admin_booking_ID from 'i18n/locales/views/admin/bookings/id.json';
import admin_booking_TH from 'i18n/locales/views/admin/bookings/th.json';

import admin_companies_EN from 'i18n/locales/views/admin/companies/en.json';

import admin_tooltip_EN from 'i18n/locales/views/admin/tooltip/en.json';
import admin_tooltip_ID from 'i18n/locales/views/admin/tooltip/id.json';
import admin_tooltip_TH from 'i18n/locales/views/admin/tooltip/th.json';

// ====== analytics
import analytics_EN from 'i18n/locales/views/analytics/en.json';
import analytics_ID from 'i18n/locales/views/analytics/id.json';
import analytics_TH from 'i18n/locales/views/analytics/th.json';

// ====== batches
import batches_EN from 'i18n/locales/views/batches/en.json';
import batches_ID from 'i18n/locales/views/batches/id.json';
import batches_TH from 'i18n/locales/views/batches/th.json';

// ====== booking_details
import booking_details_EN from 'i18n/locales/views/booking_details/en.json';
import booking_details_ID from 'i18n/locales/views/booking_details/id.json';
import booking_details_TH from 'i18n/locales/views/booking_details/th.json';

// ====== business
import business_EN from 'i18n/locales/views/business/en.json';
import business_ID from 'i18n/locales/views/business/id.json';
import business_TH from 'i18n/locales/views/business/th.json';

import business_credit_balances_EN from 'i18n/locales/views/business/credit_balances/en.json';
import business_credit_balances_ID from 'i18n/locales/views/business/credit_balances/id.json';
import business_credit_balances_TH from 'i18n/locales/views/business/credit_balances/th.json';

import business_employees_EN from 'i18n/locales/views/business/employees/en.json';
import business_employees_ID from 'i18n/locales/views/business/employees/id.json';
import business_employees_TH from 'i18n/locales/views/business/employees/th.json';

// ====== closures
import closures_EN from 'i18n/locales/views/closures/en.json';
import closures_ID from 'i18n/locales/views/closures/id.json';
import closures_TH from 'i18n/locales/views/closures/th.json';

// ====== contacts
import contacts_EN from 'i18n/locales/views/contacts/en.json';
import contacts_EN_ID from 'i18n/locales/views/contacts/en-id.json';
import contacts_ID from 'i18n/locales/views/contacts/id.json';
import contacts_TH from 'i18n/locales/views/contacts/th.json';

// ====== draft_bookings
import draft_bookings_EN from 'i18n/locales/views/draft_bookings/en.json';
import draft_bookings_ID from 'i18n/locales/views/draft_bookings/id.json';
import draft_bookings_TH from 'i18n/locales/views/draft_bookings/th.json';

// ====== fleet_accounts
import fleet_accounts_EN from 'i18n/locales/views/fleet_accounts/en.json';
import fleet_accounts_ID from 'i18n/locales/views/fleet_accounts/id.json';
import fleet_accounts_TH from 'i18n/locales/views/fleet_accounts/th.json';

// ====== fleet_parners
import fleet_partners_EN from 'i18n/locales/views/fleet_partners/en.json';
import fleet_partners_EN_ID from 'i18n/locales/views/fleet_partners/en-id.json';
import fleet_partners_ID from 'i18n/locales/views/fleet_partners/id.json';
import fleet_partners_TH from 'i18n/locales/views/fleet_partners/th.json';
import fleet_partners_TL from 'i18n/locales/views/fleet_partners/tl.json';

import fleet_partners_validations_EN from 'i18n/locales/views/fleet_partners/validations/en.json';
import fleet_partners_validations_ID from 'i18n/locales/views/fleet_partners/validations/id.json';
import fleet_partners_validations_TH from 'i18n/locales/views/fleet_partners/validations/th.json';

// ====== popup
import popup_EN from 'i18n/locales/views/popup/en.json';
import popup_ID from 'i18n/locales/views/popup/id.json';
import popup_TH from 'i18n/locales/views/popup/th.json';

// ====== validations
import validations_EN from 'i18n/locales/views/validations/en.json';
import validations_ID from 'i18n/locales/views/validations/id.json';
import validations_TH from 'i18n/locales/views/validations/th.json';

// ====== Webapp
import webapp_EN from 'i18n/locales/webapp/en.json';
import webapp_EN_ID from 'i18n/locales/webapp/en-id.json';
import webapp_ID from 'i18n/locales/webapp/id.json';
import webapp_TH from 'i18n/locales/webapp/th.json';
import webapp_TL from 'i18n/locales/webapp/tl.json';
// ====== Webapp
import lcl_EN from 'i18n/locales/views/lcl/en.json';
import lcl_ID from 'i18n/locales/views/lcl/id.json';
import lcl_TH from 'i18n/locales/views/lcl/th.json';

const mergeEn = merge(
  devise_EN.en,
  devise_sms_EN.en,
  devise_sms_activable_EN.en,
  enum_EN.en,
  errors_EN.en,
  devise_sms_activable_EN.en,
  devise_sms_activable_EN.en,
  export_EN.en,
  helpers_EN.en,
  kaminari_EN.en,
  mailer_EN.en,
  main_EN.en,
  services_EN.en,
  admin_booking_EN.en,
  admin_companies_EN.en,
  admin_tooltip_EN.en,
  analytics_EN.en,
  batches_EN.en,
  booking_details_EN.en,
  business_EN.en,
  business_credit_balances_EN.en,
  business_employees_EN.en,
  closures_EN.en,
  contacts_EN.en,
  draft_bookings_EN.en,
  fleet_accounts_EN.en,
  fleet_partners_EN.en,
  fleet_partners_validations_EN.en,
  popup_EN.en,
  webapp_EN.en,
  validations_EN.en,
  lcl_EN.en
)

const mergeEnId = merge(
  devise_EN_ID['en-id'],
  helpers_EN_ID['en-id'],
  mailer_EN_ID['en-id'],
  main_EN_ID['en-id'],
  contacts_EN_ID['en-id'],
  fleet_partners_EN_ID['en-id'],
  webapp_EN_ID['en-id']
)

const mergeID = merge(
  devise_ID.id,
  devise_sms_ID.id,
  devise_sms_activable_ID.id,
  enum_ID.id,
  errors_ID.id,
  export_ID.id,
  faker_ID.id,
  helpers_ID.id,
  kaminari_ID.id,
  mailer_ID.id,
  main_ID.id,
  services_ID.id,
  admin_booking_ID.id,
  admin_tooltip_ID.id,
  analytics_ID.id,
  batches_ID.id,
  booking_details_ID.id,
  business_ID.id,
  business_credit_balances_ID.id,
  business_employees_ID.id,
  closures_ID.id,
  contacts_ID.id,
  draft_bookings_ID.id,
  fleet_accounts_ID.id,
  fleet_partners_ID.id,
  fleet_partners_validations_ID.id,
  popup_ID.id,
  webapp_ID.id,
  validations_ID.id,
  lcl_ID.id
)

const mergeTH = merge(
  devise_TH.th.devise,
  devise_sms_TH.th,
  devise_sms_activable_TH.th,
  enum_TH.th,
  errors_TH.th,
  export_TH.th,
  helpers_TH.th,
  kaminari_TH.th,
  mailer_TH.th,
  main_TH.th,
  services_TH.th,
  admin_booking_TH.th,
  admin_tooltip_TH.th,
  analytics_TH.th,
  batches_TH.th,
  booking_details_TH.th,
  business_TH.th,
  business_credit_balances_TH.th,
  business_employees_TH.th,
  business_TH.th,
  closures_TH.th,
  contacts_TH.th,
  draft_bookings_TH.th,
  fleet_accounts_TH.th,
  fleet_partners_TH.th,
  fleet_partners_validations_TH.th,
  popup_TH.th,
  webapp_TH.th,
  validations_TH.th,
  lcl_TH.th
)

const mergeTL = merge(
  devise_sms_TL.tl,
  devise_sms_activable_TL.tl,
  enum_TL.tl,
  export_TL.tl,
  helpers_TL.tl,
  mailer_TL.tl,
  main_TL.tl,
  fleet_partners_TL.tl,
  webapp_TL.tl
)
/** @var {resources} */
const resources = {
  en: {
    translation: {
      ...mergeEn
    }
  },
  en_id: {
    translation: {
      ...mergeEnId
    }
  },
  id: {
    translation: {
      ...mergeID
    }
  },
  th: {
    translation: {
      ...mergeTH
    },
  },
  tl: {
    translation: {
      ...mergeTL
    },
  },
  vi: {
    translation: {},
  },
};

export default resources;
