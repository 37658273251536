import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TABS } from 'constants/bookingCardsConstants'

const initialState = TABS[0].scope

const currentTabSlice = createSlice({
  name: 'currentTab',
  initialState,
  reducers: {
    updateCurrentTab: (state: any, action: PayloadAction<any>) => {
      const url = new URL(window.location as any)
      url.searchParams.set('search[scope]', action.payload)
      window.history.pushState({}, '', decodeURIComponent(url as any))
      return action.payload
    },
  },
  extraReducers: () => {},
})

export const currentTabActionsCreator = currentTabSlice.actions

export default currentTabSlice
