import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}
const outOfServiceStatusSlice = createSlice({
  name: 'outOfServiceStatus',
  initialState,
  reducers: {
    updateOutOfServiceStatus: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const outOfServiceStatusActionsCreator = outOfServiceStatusSlice.actions

export default outOfServiceStatusSlice
