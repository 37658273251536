import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = []

const dataChartDriversSlice = createSlice({
  name: 'dataChartDrivers',
  initialState,
  reducers: {
    receiveDataChartDrivers: (state: any, action: PayloadAction<any>) => {
      return action.payload.dataChartDrivers
    },
  },
  extraReducers: () => {},
})

export const dataChartDriversActionsCreator = dataChartDriversSlice.actions

export default dataChartDriversSlice
