import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomerAPI from '../../api/customers'
import { DELIVEREE_WEBAPP_HOST, LCL_CUSTOMER_URL, TRANSPORTIFY_WEBAPP_HOST } from '../../constants/appConstants'
import {
  FTL_FRONT_PAGE,
  LCL_FRONT_PAGE,
  LIVE_CHAT,
  MORE_FRONT_PAGE_CONTAINER,
  MORE_FRONT_PAGE_SERVICE,
  PTL_FRONT_PAGE,
  WALLET_ICON,
  FINAL_LEO_GRAPHIC,
  SUMATRA,
  BACK_TO_TOP_ICON,
  MORE_FRONT_PAGE_SERVICE_ID,
  MORE_FRONT_PAGE_SAVER,
} from '../../constants/imageConstants'
import { Utils } from '../../utils/Utils'
import BookingCard from 'components/common/BookingCard'
import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import SettingAPI from 'api/settings'
import i18n from 'i18n/i18n'

const FrontPage = () => {
  const currentCustomer = useSelector((state) => state.currentCustomer)
  const extraInfos = useSelector((state) => state.extraInfos)

  const [listBooking, setListBooking] = useState([])
  const [listMore, setListMore] = useState([])
  const frontPageRef = useRef()
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const countryCode = extraInfos?.country_code || currentCustomer.country_code
  const liveChatContent = countryCode !== 'th' ? t('lcl.chat24h') : t('lcl.chatTh')
  const isBusiness = !!currentCustomer?.last_login_employ_id
  const isShowWallet =
    currentCustomer.id &&
    currentCustomer?.credit?.amount > 0 &&
    ((isBusiness && extraInfos?.enabled_ewallet?.includes('business')) ||
      (!isBusiness && extraInfos?.enabled_ewallet?.includes('individual')))
  const isShowBatch = extraInfos?.enable_batch_multiple
  const isShowSmartLoad = extraInfos?.enable_smart_load
  const isShowAppExclusive = currentCustomer.id && (isShowBatch || isShowSmartLoad)

  const listServices = [
    {
      type: 'FTL',
      isShow: true,
      image: FTL_FRONT_PAGE,
      title: t('webapp.new_booking.step_1.whole_vehicle'),
      content: t('lcl.book_vehicle_description'),
    },
    {
      type: 'PTL',
      isShow: extraInfos.ltl_enable_partial_load,
      image: PTL_FRONT_PAGE,
      title: t('webapp.new_booking.step_1.partial_load'),
      content: t('lcl.partial_load_description'),
    },
    {
      type: 'LCL',
      isShow: extraInfos.turn_on_lcl_on_front_page,
      image: LCL_FRONT_PAGE,
      title: t('lcl.ship_lcl'),
      content: t('lcl.ship_lcl_description'),
      isNewTag: extraInfos?.lcl_display_new_badge,
    },
  ]

  const handleChooseService = async (type) => {
    const companyId = currentCustomer?.current_company_id || ''
    const param = Utils.buildParamToFTL({
      countryCode: 'id',
      areaId: extraInfos?.area_id,
      companyId,
    })
    switch (type) {
      case 'LCL':
        try {
          await window.Moengage.track_event('Select LCL On Front Page')
        } finally {
          window.location.replace(`${LCL_CUSTOMER_URL}?${param}`)
        }
        break
      case 'PTL':
        try {
          await window.Moengage.track_event('Select LTL On Front Page')
        } finally {
          window.location.href = '/bookings/new?ptl=true'
        }
        break
      default:
        try {
          await window.Moengage.track_event('Select FTL On Front Page')
        } finally {
          window.location.href = '/bookings/new?ftl=true'
        }
        break
    }
  }

  useEffect(() => {
    const getBooking = async () => {
      const {
        data: { data },
      } = await CustomerAPI.getFrontPageBooking({
        from_time: '',
        to_time: '',
        sort_dir: 'desc',
        page: 1,
        per_page: 3,
        'company_ids[]': currentCustomer?.last_login_employ_id || 0,
      })
      setListBooking(data || [])
    }
    const getLinkMore = async () => {
      const {
        data: { data = [] },
      } = await SettingAPI.getFrontPageLink(countryCode)
      const listMoreValue = data.filter((item) => item?.value) || []
      setListMore(listMoreValue)
    }
    getLinkMore()
    if (currentCustomer.authentication_token) getBooking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderBooking = () => {
    if (!listBooking.length) return <></>
    return (
      <>
        <div className="front-page__list-booking">
          <p className="booking-card__title front-page__title m0">{t('lcl.recent_bookings')}</p>
          <button
            className="btn-see-all"
            onClick={async () => {
              try {
                await window.Moengage.track_event('Select See All On Recent Bookings Front Page')
              } finally {
                window.location.href = '/bookings'
              }
            }}
            type="button"
          >
            <span>{t('lcl.see_all')}</span>
          </button>
        </div>
        <div className="booking-card-wrapper">
          {!!listBooking.length &&
            listBooking.map((item, index) => (
              <BookingCard
                specialClass={index === listBooking.length - 1 && 'mr0'}
                countryCode={countryCode}
                key={index}
                booking={item}
              />
            ))}
        </div>
      </>
    )
  }

  const backToTop = () => {
    frontPageRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }
  const toggleVisible = () => {
    const scrolled = frontPageRef.current.scrollTop
    if (scrolled > 50) {
      setVisible(true)
    } else if (scrolled <= 50) {
      setVisible(false)
    }
  }
  const handleRedirect = (key) => {
    const itemSelect = listMore.find((item) => item.key === key)
    const lang = i18n.language
    const companyId = currentCustomer?.current_company_id || ''
    const param = Utils.buildParamToFTL({
      countryCode: 'id',
      areaId: extraInfos?.area_id,
      companyId,
    })
    if (key === 'second_box_url') {
      return window.open(lang === 'id' ? "https://www.delivereetech.com/id/bandingkan-layanan-standar-vs-saver/ " : "https://www.delivereetech.com/id/en/compare-services-standard-vs-saver/", '_blank')
    }
    if (key === 'third_box_url') return (window.location = `${LCL_CUSTOMER_URL}?${param}`)
    if (itemSelect) return window.open(`${itemSelect.value}`, '_blank')
  }

  useEffect(() => {
    const frontPageEl = frontPageRef.current
    frontPageEl.addEventListener('scroll', toggleVisible)
    return () => {
      frontPageEl.removeEventListener('scroll', toggleVisible)
    }
  }, [])

  return (
    <div className="frontpage-floating">
      <div ref={frontPageRef} id="front-page-wrapper" className="front-page-wrapper">
        <div className="feature-project">
          <div className="front-page__title mb10">{t('lcl.main_services')}</div>
          {listServices.map(
            (item, index) =>
              item.isShow && (
                <Card
                  key={index}
                  onClick={() => handleChooseService(item.type)}
                  className="cur-pointer"
                  classContent="front-page__content"
                  isNewTag={item.isNewTag}
                  image={item.image}
                  title={item.title}
                  content={item.content}
                />
              )
          )}
        </div>
        {renderBooking()}
        <div className="mt30">
          <div className="flex front-page__support">
            {isShowWallet ? (
              <>
                <div className="mr20">
                  <div>
                    <img src={WALLET_ICON} alt="wallet" />
                    <p className="front-page__title">
                      {Utils.formatFee(
                        currentCustomer?.credit?.amount,
                        currentCustomer.country_code,
                        currentCustomer?.credit?.currency
                      )}
                    </p>
                  </div>
                  <p className="front-page__content">{t('lcl.credits_available')}</p>
                  <button
                    onClick={async () => {
                      try {
                        await window.Moengage.track_event('Select Wallet On Front Page')
                      } finally {
                        window.location.href = isBusiness
                          ? `/business/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
                          : `/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
                      }
                    }}
                    type="button"
                  >
                    <span>{t('business.credit_balances.index.wallet')}</span>
                  </button>
                </div>
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                  <button
                    onClick={async () => {
                      try {
                        await window.Moengage.track_event('Select CS Chat On Front Page')
                      } finally {
                        if (!window.fcWidget.isOpen()) window.fcWidget.open()
                      }
                    }}
                    type="button"
                  >
                    <span>{t('lcl.chat_now')}</span>
                  </button>
                </div>
              </>
            ) : (
              <div className="front-page__full">
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                </div>
                <button
                  onClick={() => {
                    if (!window.fcWidget.isOpen()) window.fcWidget.open()
                  }}
                  type="button"
                >
                  <span>{t('lcl.chat_now')}</span>
                </button>
              </div>
            )}
          </div>
          {isShowAppExclusive && (
            <div className="front-page__create" style={{ backgroundImage: `url(${FINAL_LEO_GRAPHIC})` }}>
              <p className="front-page__title mb5">{t('lcl.exclusive')}</p>
              <p className="front-page__content m0">{t('lcl.manage_multiple')}</p>
              <div>
                {isShowBatch && (
                  <>
                    <button
                      className="batch"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select Multiple On Front Page')
                        } finally {
                          window.location.href = '/bookings/multiple'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.batch')}</span>
                    </button>
                    <button
                      className="batch-ez"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select EZ Sheet On Front Page')
                        } finally {
                          window.location.href = '/batches/ez_spread_sheet'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.upload.ez_sheet')}</span>
                    </button>
                  </>
                )}
                {isShowSmartLoad && (
                  <>
                    <button
                      className="batch-smart"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select SLP On Front Page')
                        } finally {
                          window.location.href = '/batches/smart_load_planner'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.smart_load_planner')}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="front-page__more">
            <p className="front-page__title">{t('lcl.what_new')}</p>
            <img
              className={`${listMore.some((obj) => obj.key === 'first_box_url') ? 'cur-pointer' : ''}`}
              onClick={async () => {
                try {
                  await window.Moengage.track_event('Select On 3 Services Banner')
                } finally {
                  handleRedirect('first_box_url')
                }
              }}
              src={i18n.language === 'id' ? MORE_FRONT_PAGE_SERVICE_ID : MORE_FRONT_PAGE_SERVICE}
              alt=""
              srcSet=""
            />
            <div className="flex justify-content-between">
              <div
                className={extraInfos.turn_on_lcl_on_front_page ? 'cur-pointer' : ''}
                onClick={async () => {
                  try {
                    await window.Moengage.track_event('Select on Saver vs Standard Banner')
                  } finally {
                    if (extraInfos.turn_on_lcl_on_front_page) handleRedirect('second_box_url')
                  }
                }}
              >
                <img src={MORE_FRONT_PAGE_SAVER} alt="" />
                <div>
                  <p className="front-page__title m0">{t('lcl.saver_vs_standard')}</p>
                  <p className="front-page__content">{t('lcl.saver_is_now_available')}</p>
                </div>
              </div>
              <div
                className="cur-pointer"
                onClick={async () => {
                  try {
                    await window.Moengage.track_event('Select On LCL Service Banner')
                  } finally {
                    if (extraInfos.turn_on_lcl_on_front_page) handleRedirect('third_box_url')
                  }
                }}
              >
                <div>
                  <p className="front-page__title m0">{t('lcl.introducing_lcl')}</p>
                  <p className="front-page__content">{t('lcl.ship_lcl_intro')}</p>
                </div>
                <img src={MORE_FRONT_PAGE_CONTAINER} alt="" />
              </div>
            </div>
          </div>
          {visible && (
            <div onClick={backToTop} className="back-to-stop cur-pointer flex">
              <img src={BACK_TO_TOP_ICON} alt="" className="mr10" />
              <div>{t('lcl.back_to_top')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default FrontPage
