import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import { signUp } from 'store/actions/common/customerActionCreators'
import { socialLoginAPI } from 'store/actions/social_login/socialLoginActionCreators'
import CommonUtils from 'utils/common'
import I18n from 'i18n/i18n'

const ModalMerge = (props) => {
    const { paramMerge, socialToken, checkRedirectPage, reloadTopNavAfterSignIn } = props
    const dispatch = useDispatch()
    const mergeBusinessName = useRef()
    const mergeReferredBy = useRef()
    const extraInfos = useSelector(state => state.extraInfos)
    const onSubmitHandler = (event) => {
        event.preventDefault()
        const params = {
            country_code: paramMerge.countryCode,
            email: paramMerge.email,
            phone: paramMerge.phone,
            first_name: paramMerge.firstName,
            last_name: paramMerge.lastName,
            business_name: mergeBusinessName.current?.value || '',
            referred_by: mergeReferredBy.current?.value || '',
            access_token: socialToken,
            provider: paramMerge.provider
        }
        dispatch(signUp(params, (response) => {
                if (response.access_token) {
                    dispatch(socialLoginAPI(
                        response.access_token, null, reloadTopNavAfterSignIn(() => checkRedirectPage())
                    ))
                }
            }))
    }
    const LOGO_ICON = CommonUtils.isTransportify()
        ? V2_ICON_GREEN_TRANSPORTIFY
        : V2_ICON_GREEN
    return (
        <div className="visible vertical-scroll Modal">
            <div className="MergeBox LoginBox vertical-scroll">
                <form className="Box box-merge" onSubmit={onSubmitHandler}>
                    <div className="Box-Head">
                        <div className="Logo">
                            <img src={LOGO_ICON} alt="deliveree" />
                        </div>
                        <p className="SignupBox-line">
                            {I18n.t('devise_template.already_a_member', { app_name: CommonUtils.appName() })}
                            &nbsp;
                        </p>
                    </div>
                    <div className="Box-Content">
                        <div className="Form">
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.email_address')}
                                </label>
                                <div className="Input disabled">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        value={paramMerge.email}
                                        name="customer[email]"
                                    />
                                </div>
                            </div>
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.phone_number')}
                                </label>
                                <div className="Input disabled">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        value={paramMerge.phone}
                                        name="customer[phone]"
                                    />
                                </div>
                            </div>
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.first_name')}
                                </label>
                                <div className="Input disabled">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        value={paramMerge.firstName}
                                        name="customer[first_name]"
                                    />
                                </div>
                            </div>
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.last_name')}
                                </label>
                                <div className="Input disabled">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        value={paramMerge.lastName}
                                        name="customer[last_name]"
                                    />
                                </div>
                            </div>
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.business_name')}
                                </label>
                                <div className="Input">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        defaultValue={paramMerge.businessName}
                                        ref={mergeBusinessName}
                                        name="customer[business_name]"
                                    />
                                </div>
                            </div>
                            <div className="FormGroup">
                                <label className="FormGroup-label">
                                    {I18n.t('devise_template.referred_by')}
                                </label>
                                <div className="Input">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        defaultValue={paramMerge.referredBy}
                                        ref={mergeReferredBy}
                                        name="customer[referred_by]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Box-Actions">
                        <input
                            className="green Button"
                            type="submit"
                            value={I18n.t('devise_template.create_my_account')}
                        />
                    </div>
                    <div className="copyright Box-Actions">
                        <div className="w400">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: I18n.t('devise_template.by_clicking', {
                                        term_link: extraInfos.tc_link,
                                        privacy_link: extraInfos.privacy_policy_link,
                                        app_name: CommonUtils.appName(),
                                    })
                                }}
                            />
                        </div>
                        <div className="line10" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalMerge