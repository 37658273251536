import _ from 'lodash'
import { calendarBookingActionsCreator } from 'store/toolkit/calendarBooking/calendarBooking.reducer'
// Add id to array current id
export const add = id => (dispatch, getState) => {
  const { data } = getState().calendarBooking || {}
  const arrCalendarID = _.clone(data, true)
  arrCalendarID.push(id)
  dispatch(calendarBookingActionsCreator.addIdCalendar({
    data: _.uniq(arrCalendarID)
  }))
}

// Remove id on array current id
export const remove = id => (dispatch, getState) => {
  const { data } = getState().calendarBooking || {}
  const arrCalendarID = _.clone(data, true)
  _.remove(arrCalendarID, c => c === id)
  dispatch(calendarBookingActionsCreator.removeIdCalendar({
    data: arrCalendarID
  }))
}
// Reset id on array current id
export const reset = () => (dispatch) => {
  dispatch(calendarBookingActionsCreator.resetCalendar())
}
// Update arr id calendar
export const update = arrId => (dispatch) => {
  dispatch(calendarBookingActionsCreator.updateCalendar({
    data: arrId
  }))
}
