import React from 'react'
import PropTypes from 'prop-types'

class CustomerDNDCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: props.currentCustomer.show_phone_number_to_driver,
    }
  }

  onChangeCheckBox(event) {
    const {
      onChangeSettings
    } = this.props
    const checked = event.target.checked ? 1 : 0
    this.setState({
      isChecked: checked,
    })

    onChangeSettings(event.target.checked, 'show_phone_number_to_driver')
  }

  render() {
    const { isChecked } = this.state
    return (
      <div>
        <a>
          <div className="Overlay-Checkbox Green-Checkbox With-Icon">
            <input
              type="checkbox"
              name="show_phone_number_to_driver_value"
              id="show_phone_number_to_driver_value"
              value="1"
              checked={isChecked}
              onChange={e => this.onChangeCheckBox(e)}
            />
            <label>
              <i className="b material-icons Icon">
                check
              </i>
            </label>
          </div>
        </a>
      </div>
    )
  }
}

CustomerDNDCheckbox.propTypes = {
  currentCustomer: PropTypes.shape({
    show_phone_number_to_driver: PropTypes.bool,
  }).isRequired,
}

export default CustomerDNDCheckbox
