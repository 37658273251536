import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  map: null,
  directionsDisplay: null,
  directionsService: null,
  geocoder: null,
  polygonZones: null,
}
const googleMapSlice = createSlice({
  name: 'googleMap',
  initialState,
  reducers: {
    loadMapToStore: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})
export const googleMapActionsCreator = googleMapSlice.actions

export default googleMapSlice
