import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const bookingAgainDetailsSlice = createSlice({
  name: 'bookingAgainDetails',
  initialState,
  reducers: {
    updateBookAgainDetails: (state: any, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload
    }),
    updateBookAgainSettlementDetails: (state: any, action: PayloadAction<any>) => {
      state.settlement_details = action.payload || null
    },
  },
  extraReducers: () => {},
})

export const bookingAgainDetailsActionsCreator = bookingAgainDetailsSlice.actions

export default bookingAgainDetailsSlice
