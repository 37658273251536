import locationUtils from './location'
import toastr from 'utils/toast'
import I18n from 'i18n/i18n'

import * as IMAGES from 'constants/imageConstants'

const currentLocationUtils = {
  getLocationAfterCheckBookingDetails(googleMap, extraInfos) {
    if (!navigator.geolocation) return null

    const self = this

    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (pst) => {
          resolve(self.getLocationDefault(pst, googleMap, extraInfos))
        },
        () => {
          // Failed to get current location

          toastr.remove()

          toastr.options = {
            positionClass: 'toast-top-center new-booking custom-width',
          }

          // Need translations for deliveree & transportify

          toastr.info(I18n.t('webapp.geolocation'))
        }
      )
    })
  },

  getLocationDefault(position, googleMap, extraInfos) {
    // Get geo location result

    const self = this

    return new Promise((resolve) => {
      function processGeolocationResult(pos) {
        const html5Lat = pos.coords.latitude // Get latitude

        const html5Lon = pos.coords.longitude // Get longitude

        // const html5TimeStamp = position.timestamp; //Get timestamp

        // const html5Accuracy = position.coords.accuracy; //Get accuracy in meters

        return `${html5Lat.toFixed(8)}, ${html5Lon.toFixed(8)}`
      }

      function getCurrentAddress(location) {
        locationUtils.handleCallGeocodeAPI({ latlng: location }, (results) => {
          if (results) {
            const { formattedAddress } = locationUtils.getDataResponse(results[0])

            const data = self.setDefaultAddress('', googleMap, extraInfos, position, formattedAddress)

            resolve(data)
          }

          resolve(null)
        })
      }

      // Check value is present or not & call google api function

      function initializeCurrent(latcurr, longcurr) {
        if (latcurr !== '' && longcurr !== '') {
          const latlng = `${latcurr},${longcurr}`

          getCurrentAddress(latlng)
        }

        return null
      }

      const currLatLong = processGeolocationResult(position).split(',')

      initializeCurrent(currLatLong[0], currLatLong[1])
    })
  },

  setDefaultAddress(
    addressFirstLocation = '',
    googleMap,
    extraInfos,
    position,
    positionAddress,
    addressComponents = []
  ) {
    let marker = {}

    const pinfrom = {
      bool: true,

      icon: '',
    }

    let locationAttrs = {
      lat: undefined,

      lng: undefined,

      name: undefined,

      marker: undefined,

      contact_id: undefined,
    }

    let currentLat

    let currentLng

    let currentLatBlank

    let currentLngBlank

    if (extraInfos.default_center_lat_lng || position) {
      currentLatBlank = extraInfos.default_center_lat_lng.split(',')[0]

      currentLngBlank = extraInfos.default_center_lat_lng.split(',')[1]

      currentLat = position ? position.coords.latitude : currentLatBlank

      currentLng = position ? position.coords.longitude : currentLngBlank

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(currentLat, currentLng),

        map: googleMap?.map || '',

        animation: window.google.maps.Animation.DROP,

        draggable: true,
      })

      if (I18n.language === 'en') {
        pinfrom.icon = IMAGES.PIN_BLUE_EN
      } else {
        switch (extraInfos.country_code) {
          case 'VN':

          case 'vn':
            pinfrom.icon = IMAGES.PIN_BLUE_EN

            break

          case 'TH':

          case 'th':
            pinfrom.icon = IMAGES.PIN_BLUE_TH

            break

          case 'ID':

          case 'id':
            pinfrom.icon = IMAGES.PIN_BLUE_ID

            break

          case 'PH':

          case 'ph':
            pinfrom.icon = IMAGES.PIN_BLUE_EN

            break

          default:

          // code block
        }
      }

      locationAttrs = {
        pinfrom,

        lat: currentLat,

        lng: currentLng,

        name: positionAddress || addressFirstLocation,

        marker,

        contact_id: undefined,

        address_components: addressComponents || extraInfos.default_address_component_pickup,
      }

      return locationAttrs
    }

    return null
  },
}

export default currentLocationUtils
