import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const geoQuerySlice = createSlice({
  name: 'geoQuery',
  initialState,
  reducers: {
    setGeoQuery: (state: any, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const geoQueryActionsCreator = geoQuerySlice.actions

export default geoQuerySlice
